import React from "react"
import {useTranslation} from "react-i18next";
import {Form, Offcanvas, Spinner, Table, Image, Stack} from "react-bootstrap";
import {FakeProject} from "../../inter_project_search_new/lightdata";
import {InfinityProject} from "../../../infinity/InfinityProject";
import UsersApi, {useAvailableUsers} from "../../../api/UsersApi";

import {InfinityOffcanvasWrapper} from "../../../components/offcanvas";
import {useGlobalPopup} from "../../../zustands/globalPopupZus";
import {BasicMiddleSpinner} from "../../../components/spinners";
import MyTable from "../../../components/mytable";
import {BasicStateSearchInput} from "../../../components/searchInput";
import UserSelectOffcavnas from "../../../components/users/SelectUserOffcanvas";
import {SelectUserView, SelectUserMUISelect} from "../../../components/selectviews/SelectUserView";
import {ProjectSelectView, SelectProjectMUISelect} from "../../../components/selectviews/ProjectSelectView";
import AreaMuiSelect from "../../../components/selectviews/AreaSelectView";
import {ViewAsCustomMUISelect} from "../../../components/selectviews/ViewAsSelectView";


interface Props {
    projects: Array<FakeProject>
    activeProject: FakeProject | null
    projectChanged: (project: FakeProject) => void

    enableChooseArea: boolean
    activeAreaUid: string
    areaChanged: (value: string) => void

    activeUserUid: string
    setActiveUserUid: (data: string) => void,

    viewAs: string,
    setViewAs: (view: string) => void

}

export default function ProjectVisitsFilteringBar({
                                                      projects,
                                                      activeProject,
                                                      projectChanged,
                                                      enableChooseArea,
                                                      activeAreaUid,
                                                      areaChanged,
                                                      activeUserUid,
                                                      setActiveUserUid,
                                                      viewAs,
                                                      setViewAs
                                                  }: Props) {

    const [t] = useTranslation()


    return (
        <div className={"hstack gap-3"}>


            <SelectProjectMUISelect
                activeProject={activeProject}
                setActiveProject={projectChanged}/>

            {
                activeProject ?
                    <>
                        {
                            enableChooseArea && <AreaMuiSelect
                                activeAreaUid={activeAreaUid}
                                areaChanged={areaChanged}
                                activeProject={activeProject}
                            />
                        }

                    </>
                    : <Spinner animation={"border"} variant={"danger"}/>

            }




            {
                activeProject &&
                <>
                    <SelectUserMUISelect activeUser={activeUserUid} setActiveUser={setActiveUserUid}/>
                </>
            }

            <ViewAsCustomMUISelect
                viewAs={viewAs}
                setViewAs={setViewAs}
                custom={[
                    ["table", "core.table"],
                    ["map", "core.map"]
                ]}
            />

        </div>
    )
}

