import React from "react";
import {Cell, Pie, PieChart} from "recharts";

import {useTranslation} from "react-i18next";

import DashBoardTile from "../../../components/dashboard/DashBoardTile";

import TourStatisticsApi from "../../../api/TourStatisticsApi";
import {formatPercentage, formatPercentageToNumber} from "../../../formatters/percentage";
import {OnlySpinner} from "../../../components/spinners";


import BestWorstQuizTables from "../BestWorstQuizTables";
import ProjectsApi from "../../../api/ProjectsApi";

interface QuizzesStatisticsDashboardViewProps {
    projectType: number
}

export default function GlobalQuizzesDashBoard({projectType}: QuizzesStatisticsDashboardViewProps) {

    const [t] = useTranslation()

    const [result, setResult] = React.useState({count: 0, success_rate: 0})
    const [isLoading, setIsLoading] = React.useState(true)

    const [questionsLoading, setQuestionsLoading] = React.useState(true)

    const [questionsPercentage, setQuestionsPercentage] = React.useState(0)

    React.useEffect(() => {
        setIsLoading(true)
        setQuestionsLoading(true)

        TourStatisticsApi.getTakenQuizCountAndSuccessRate(projectType).then((value) => {
            setResult(value)
            setIsLoading(false)
        })

        TourStatisticsApi.GetAllQuestionsSuccessRate(projectType).then((value) => {
            setQuestionsPercentage(value)
            setQuestionsLoading(false)
        })


    }, [])

    return (
        <div>
            {/*<div className="row">*/}


            {/*    <div className="col-3">*/}
            {/*        <_AverageSuccessRate isLoading={isLoading} count={result.success_rate}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <_QuizzesPair projectType={projectType}/>

            <div className="row">
                <div className="col-4">
                    <_AverageQuizSuccessRate isLoading={isLoading} count={result.success_rate}/>
                    <_AverageQuizSuccessRateChart isLoading={isLoading} count={result.success_rate}/>
                </div>

                <div className="col-3">
                    <_TotalQuizzesTakenCount isLoading={isLoading} count={result.count}/>
                </div>

            </div>

            <BestWorstQuizTables projectType={projectType}/>

        </div>
    )

}


const BOARD_STYLE: any = {height: "100px"}

function _TotalQuizzesTakenCount({isLoading, count}) {

    const [t] = useTranslation()


    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.statistics.total-quizzes-taken")}:</div>
            {isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{count}</h3>}
        </DashBoardTile>
    )
}

//
// function _AverageSuccessRate({isLoading, count}) {
//
//     const [t] = useTranslation()
//
//     return (
//         <DashBoardTile style={BOARD_STYLE}>
//             <div className={"noWrap"}>{t("core.success.average")}:</div>
//             {isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{formatPercentage(count)}</h3>}
//         </DashBoardTile>
//     )
// }


function _QuizzesPair({projectType}) {

    const [isLoading, setIsLoading] = React.useState(true)

    const [totalQuizes, setTotalQuizzes] = React.useState(0)
    const [totalQuestions, setTotalQuestions] = React.useState(0)

    React.useEffect(() => {

        setIsLoading(true)

        ProjectsApi.getProjectStatsOfProjectOf(projectType).then((projects) => {
            let totalQuizesCount = 0
            let totalQuestionsCount = 0

            projects.map((val) => {
                totalQuizesCount += val.QuizBoard ?? 0
                totalQuestionsCount += val.questionsCount ?? 0
            })

            setTotalQuizzes(totalQuizesCount)
            setTotalQuestions(totalQuestionsCount)

            setIsLoading(false)
        })
    }, [])

    return (
        <div className="row">
            <div className="col-3">
                <_TotalQuizzesCount isLoading={isLoading} count={totalQuizes}/>
            </div>

            <div className="col-3">
                <_TotalQuestionsCount isLoading={isLoading} count={totalQuestions}/>
            </div>

            <div className="col-3">

            </div>
        </div>
    )
}

function _TotalQuizzesCount({isLoading, count}) {

    const [t] = useTranslation()

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.total.quiz.count")}:</div>
            {isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{count}</h3>}
        </DashBoardTile>
    )
}

function _TotalQuestionsCount({isLoading, count}) {

    const [t] = useTranslation()

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.total.questions.count")}:</div>
            {isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{count}</h3>}
        </DashBoardTile>
    )
}

function _AverageQuizSuccessRate({isLoading, count}) {

    const [t] = useTranslation()

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.quizzes.successRate")}:</div>
            {isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{formatPercentage(count)}</h3>}
        </DashBoardTile>
    )
}


const RED = "#c00000"
const GREEN = "#70ad47"

function _AverageQuizSuccessRateChart({isLoading, count}) {

    const [t] = useTranslation()

    const data01 = [
        {
            "name": "Bad",
            "value": formatPercentageToNumber(1 - count)
        },
        {
            "name": "Good",
            "value": formatPercentageToNumber(count)
        }]

    if (isLoading) {
        return (<OnlySpinner/>)
    } else {
        return (
            <DashBoardTile style={{height: "200px"}}>
                <PieChart width={730} height={250}>
                    <Pie data={data01}
                         dataKey="value"
                         nameKey="name"
                         cx="50%"
                         cy="50%"
                         outerRadius={50}
                         fill="#8884d8"
                         label={true}
                    >
                        <Cell fill={RED}/>
                        <Cell fill={GREEN}/>
                    </Pie>
                </PieChart>
            </DashBoardTile>
        )
    }


}

function _AverageQuestionsSuccessRateChart({isLoading, count}) {

    const [t] = useTranslation()

    const data01 = [
        {
            "name": "Group A",
            "value": 400
        },
        {
            "name": "Group B",
            "value": 300
        }]

    return (
        <DashBoardTile style={{height: "200px"}}>
            <PieChart width={730} height={350}>
                <Pie data={data01}
                     dataKey="value"
                     nameKey="name"
                     cx="50%"
                     cy="50%"
                     outerRadius={50}
                     fill="#8884d8"
                >
                    <Cell fill={RED}/>
                    <Cell fill={GREEN}/>
                </Pie>
            </PieChart>
        </DashBoardTile>
    )
}



