import * as three from "three"
import {Vector2, Vector3} from "three"


class PanoramaCameraControl {


    element: HTMLElement

    constructor(camera: three.Camera, renderer: three.WebGLRenderer) {

        this.clock = new three.Clock();
        this.camera = camera
        this.element = renderer.domElement

        this.lastZoomDistance = 0
        this.lastSmoothCameraTime = 0;
        this.cameraMotionSpeed = new Vector2(0, 0);
        this.initialCameraMotionSpeed = new Vector2(0, 0);
        this.mouseMotion = new Vector2(0, 0);
        this.relativeMotion = new Vector2(0, 0);
        this.fov = 85;
        this.targetFov = 85;
        this.maxFov = 85
        this.minFov = 10

        this.cameraRotationActive = false;
        this.zoomEnabled = true;
        this.rotationLocked = false;
        this.smoothCameraEnabled = true;

        this.camera.fov = this.fov
        this.camera.updateProjectionMatrix()

        this.previousTouch = new three.Vector2(0, 0)


    }


    enable = () => {
    }

    autoAttachOnRenderer = () => {
        this.element.addEventListener("pointerdown", this.onLeftDown)
        this.element.addEventListener("pointerup", this.onLeftUp)
        this.element.addEventListener("pointermove", this.onPointerMove)
        this.element.addEventListener("wheel", this.onWheel)


        this.element.addEventListener("touchend", this.onFingerUp)
        this.element.addEventListener("touchstart", this.onFingerDown)
        this.element.addEventListener("touchmove", this.onFingerMove)
    }

    autoAttachOnWindow = () => {
        window.addEventListener("pointerdown", this.onLeftDown)
        window.addEventListener("pointerup", this.onLeftUp)
        window.addEventListener("pointermove", this.onPointerMove)
        window.addEventListener("wheel", this.onWheel)


        window.addEventListener("touchend", this.onFingerUp)
        window.addEventListener("touchstart", this.onFingerDown)
        window.addEventListener("touchmove", this.onFingerMove)
    }

    update = () => {
        this.StepSmoothCamera()
    }

    UpdateLastCameraMotionTime = () => {
        this.lastSmoothCameraTime = new Date().getTime() * 0.001
    }

    StepSmoothCamera = () => {

        if (this.targetFov !== this.fov) {
            this.fov = this.fov + (this.targetFov - this.fov) * 0.15

            if (Math.abs(this.targetFov - this.fov) < 1) {
                this.fov = this.targetFov
            }

            this.camera.fov = this.fov
            this.camera.updateProjectionMatrix()
        }

        if (this.cameraRotationActive) {
            const timeDif = new Date().getTime() * 0.001 - this.lastSmoothCameraTime;

            if (timeDif === 0) {
                // console.log("Init smooth camera motion speed skipped!")
                return
            }
            this.UpdateLastCameraMotionTime();
            const pixelToRadians = 2500;
            const speed = 0.001 * (this.fov / 50);
            const maxSpeed = 10;

            let newSpeedX = this.mouseMotion.x / timeDif / pixelToRadians;
            newSpeedX = Math.max(-maxSpeed, Math.min(maxSpeed, newSpeedX));

            let newSpeedY = this.mouseMotion.y / timeDif / pixelToRadians;
            newSpeedY = Math.max(-maxSpeed, Math.min(maxSpeed, newSpeedY));

            this.initialCameraMotionSpeed.setX(this.initialCameraMotionSpeed.x * 0.5 + newSpeedX * 0.5)
            this.initialCameraMotionSpeed.setY(this.initialCameraMotionSpeed.y * 0.5 + newSpeedY * 0.5)

            let minSpeed = 0;
            if (this.initialCameraMotionSpeed.length() < minSpeed) {
                this.initialCameraMotionSpeed.set(0, 0);
            }

            this.checkVerticalClamp()

            this.camera.rotateOnAxis(new Vector3(1, 0, 0), this.mouseMotion.y * speed)
            this.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), this.mouseMotion.x * speed)

            this.mouseMotion.set(0, 0)
        }
        if (this.cameraRotationActive === false && this.cameraMotionSpeed.length() !== 0 && this.smoothCameraEnabled) {
            const timeDif = new Date().getTime() * 0.001 - this.lastSmoothCameraTime;
            if (timeDif === 0) {
                // console.log("Smooth camera motion speed skipped!")
                return;
            }

            this.UpdateLastCameraMotionTime();

            this.checkVerticalClamp()

            this.camera.rotateOnAxis(new Vector3(1, 0, 0), this.cameraMotionSpeed.y * timeDif)
            this.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), this.cameraMotionSpeed.x * timeDif)

            const speedDif = this.initialCameraMotionSpeed.clone().normalize();
            speedDif.setX(speedDif.x * (timeDif * 6))
            speedDif.setY(speedDif.y * (timeDif * 6))


            this.cameraMotionSpeed.setX(this.cameraMotionSpeed.x - speedDif.x)
            this.cameraMotionSpeed.setY(this.cameraMotionSpeed.y - speedDif.y)

            const copySign = (x, y) => {
                return Math.sign(x) === Math.sign(y) ? x : -x
            }

            if (copySign(1, this.cameraMotionSpeed.x) !== copySign(1, this.initialCameraMotionSpeed.x)) {
                this.cameraMotionSpeed.setX(0);
            }
            if (copySign(1, this.cameraMotionSpeed.y) !== copySign(1, this.initialCameraMotionSpeed.y)) {
                this.cameraMotionSpeed.setY(0);
            }
        }
    }

    checkVerticalClamp = () => {
        const actAngle = this.camera.getWorldDirection(new Vector3(0, 1, 0)).angleTo(new Vector3(0, 1, 0))
        if (actAngle < 0.8) {
            this.mouseMotion.setY(-2)
            this.cameraMotionSpeed.setY(0)
        } else if (actAngle > 2.7) {
            this.mouseMotion.setY(2)
            this.cameraMotionSpeed.setY(0)
        }
    }

    onLeftDown = (event: MouseEvent) => {

        if (event.isPrimary === false) {
            return
        }

        this.cameraRotationActive = true
        this.initialCameraMotionSpeed.set(0, 0)
        this.cameraMotionSpeed.set(0, 0)

        this.UpdateLastCameraMotionTime()

        event.preventDefault()

    }

    onLeftUp = (event: MouseEvent) => {

        if (event.isPrimary === false) {
            return
        }

        if (this.cameraRotationActive) {
            this.cameraMotionSpeed.setX(this.initialCameraMotionSpeed.x)
            this.cameraMotionSpeed.setY(this.initialCameraMotionSpeed.y)
            this.cameraRotationActive = false;
            this.mouseMotion.set(0, 0);

            this.UpdateLastCameraMotionTime();
        }
    }

    onPointerMove = (event: MouseEvent) => {

        if (event.isPrimary === false) {
            return
        }

        this.relativeMotion.set(0, 0)
        if (this.rotationLocked == false && this.cameraRotationActive) {

            const dif = this.mouseMotion.clone()
            dif.add(new Vector2(event.movementX, event.movementY))
            this.relativeMotion.set(dif.x, dif.y)

        }

        this.mouseMotion.setX(this.relativeMotion.x)
        this.mouseMotion.setY(this.relativeMotion.y)
    }

    onFingerMove = (event: TouchEvent) => {

        if (this.isTouchScaling) {

            if (this.cameraRotationActive) {
                this.cameraMotionSpeed.setX(this.initialCameraMotionSpeed.x)
                this.cameraMotionSpeed.setY(this.initialCameraMotionSpeed.y)
                this.cameraRotationActive = false;
                this.mouseMotion.set(0, 0);

                this.UpdateLastCameraMotionTime();
            }

            if (event.touches.length === 2) {
                const distance = Math.hypot(
                    event.touches[0].pageX - event.touches[1].pageX,
                    event.touches[0].pageY - event.touches[1].pageY);

                const diff = this.lastZoomDistance - distance

                if (Math.abs(diff) > 10) {

                    if (diff > 0) {
                        this.targetFov += 5
                        this.targetFov = three.MathUtils.clamp(this.targetFov, this.minFov, this.maxFov)
                    } else {
                        this.targetFov -= 5
                        this.targetFov = three.MathUtils.clamp(this.targetFov, this.minFov, this.maxFov)
                    }

                    this.lastZoomDistance = distance

                    event.preventDefault()
                }


            }

        } else {

            if (event.touches.length === 1) {
                if (this.cameraRotationActive) {
                    const touch = event.touches[0]

                    this.relativeMotion.set(0, 0)

                    if (this.rotationLocked == false) {

                        const motion = this.previousTouch.clone()
                        motion.sub(new three.Vector2(touch.clientX, touch.clientY))

                        const dif = this.mouseMotion.clone()
                        dif.add(new Vector2(-motion.x, -motion.y))

                        this.relativeMotion.set(dif.x, dif.y)
                        this.previousTouch.set(touch.clientX, touch.clientY)

                    }


                    this.mouseMotion.setX(this.relativeMotion.x)
                    this.mouseMotion.setY(this.relativeMotion.y)
                }
            }

        }


    }


    onPinchZoomStart = (pinch, event) => {
        console.log("Here")
    }

    onPinchZoomEnd = (pinch, event) => {
        console.log("Here")
    }

    onDoubleClick = (pinch, event) => {
        console.log("Doubpe tap")
    }

    onWheel = (event: MouseEvent) => {
        if (event.ctrlKey) {
            return
        }

        //TODO here naimplementovat rychlost pomocou optimalneho fov
        //const rotateSpeed = -(this.camera.fov / this.cameraOptimapFov)

        if (event.deltaY > 0) {
            this.targetFov += 20
            this.targetFov = three.MathUtils.clamp(this.targetFov, this.minFov, this.maxFov)
        } else {
            this.targetFov -= 20
            this.targetFov = three.MathUtils.clamp(this.targetFov, this.minFov, this.maxFov)
        }

        event.preventDefault()

    }

    onFingerDown = (event: TouchEvent) => {
        if (event.touches.length === 2) {
            this.isTouchScaling = true
        } else {
            if (event.touches.length == 1) {
                this.isTouchScaling = false
                const touch = event.touches[0]

                this.previousTouch.set(touch.clientX, touch.clientY)

                this.cameraRotationActive = true;
                this.initialCameraMotionSpeed.set(0, 0);
                this.cameraMotionSpeed.set(0, 0);

                this.UpdateLastCameraMotionTime()
            }

        }

    }

    onFingerUp = (event: TouchEvent) => {
        if (event.touches.length > 1) {

        } else {
            if (this.cameraRotationActive) {
                this.cameraMotionSpeed.setX(this.initialCameraMotionSpeed.x)
                this.cameraMotionSpeed.setY(this.initialCameraMotionSpeed.y)
                this.cameraRotationActive = false;
                this.mouseMotion.set(0, 0);

                this.UpdateLastCameraMotionTime();
            }
        }
    }

    resetView() {
        this.camera.rotation.set(0, 0, 0)
    }

    lookAt(x, y, z) {
        this.camera.lookAt(x, y, z)
    }

}

export default PanoramaCameraControl