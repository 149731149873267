import * as three from "three"
import {InfinityBoardParent} from "./board_Parent";

import {InfinityMeshesLoader} from "../../infinity/InfinityMeshesLoader";

import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {MeshAsset} from "../../ela/assets/asset_Mesh";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";

export class InfinityMeshBoard extends InfinityBoardParent {

    type = "mesh"

    clock = new three.Clock()

    glft: GLTF = null
    meshParent: three.Group = null

    animationMixer: three.AnimationMixer = null

    f: three.Vector3 = null
    t: three.Vector3 = null
    r: three.Vector3 = null

    overAllScale = 1

    animation_name = ""

    automatic_rotation_enabled = false

    automatic_rotation_speed = 1

    automatic_rotation_axis_enabled_x = false
    automatic_rotation_axis_enabled_y = false
    automatic_rotation_axis_enabled_z = false

    constructor(spot) {
        super(spot)
        this.icon = "EI.MeshBoard"

        this.isOpenedInWorld = false
    }

    //     const MULTICONSTANT = 300
    //
    // const newPosition = new three.Vector3()
    // newPosition.copy(this.position)
    // newPosition.multiply(new three.Vector3(MULTICONSTANT, MULTICONSTANT, MULTICONSTANT))

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)

        const MULTICONSTANT = 300

        this.overAllScale = object["overall_scale"] * MULTICONSTANT

        const rotation = object["manual_position"]
        this.f = rotation["f"]
        this.t = rotation["t"]
        this.r = rotation["r"]

        this.automatic_rotation_enabled = object["automatic_rotation_enabled"]

        this.automatic_rotation_speed = object["automatic_rotation_speed"]

        this.automatic_rotation_axis_enabled_x = object["automatic_rotation_axis_enabled_x"]
        this.automatic_rotation_axis_enabled_y = object["automatic_rotation_axis_enabled_y"]
        this.automatic_rotation_axis_enabled_z = object["automatic_rotation_axis_enabled_z"]


    }

    clicked() {
        GuidedTourManager.taskBoardClicked(this, 1)

        super.fillBoardVisited(1)

        this.clickableIcon.getRoot().visible = false

        if (this.glft) {
            this.glft.scene.visible = true
        } else {
            this._loadModel()
        }


    }

    getFinalPosition() {
        return this.placeHolder.position
    }

    fillToWorld(world) {

        if (this._isEnabled === false) {
            return
        }

        const group = world.secondaryNotSelectableAndNotRotatableGroup

        if (this.autoUnpack) {

            this.isOpenedInWorld = true
            if (this.contentUid === "") {
                return
            }

            if (this.glft) {
                this.glft.scene.visible = true
                group.add(this.meshParent)

            } else {
                this._loadModel()
            }

        } else {
            this.isOpenedInWorld = false
            super.fillToWorld(world);
        }

    }

    _loadModel() {
        this.isOpenedInWorld = true
        const asset = this.getMeshAsset()
        if (asset.exists) {
            const loader = new InfinityMeshesLoader(this.getParentProject())
            loader.LoadMesh(asset, this.meshWasAdded)
        }
    }

    CleanUp() {
        super.CleanUp()

        if (this.glft) {
            this.glft.scene.visible = false
        }

    }

    worldRotated() {

    }


    meshWasAdded = (extension: string, gltf: GLTF) => {
        console.log(this.contentUid)

        if (!this.parentSpot.GetPanoramaAsset().is_previewed) {
            return
        }

        if (extension === "fbx") {
            alert("FBX unimplemented")
        } else {
            if (gltf.scene) {
                const scene = gltf.scene

                const rotationMatrix = new three.Matrix4()

                rotationMatrix.set(
                    this.f.x, this.t.x, this.r.x, 0,
                    this.f.y, this.t.y, this.r.y, 0,
                    this.f.z, this.t.z, this.r.z, 0,
                    0, 0, 0, 1
                )

                const sceneGroup = new three.Group()

                sceneGroup.rotation.setFromRotationMatrix(rotationMatrix)
                sceneGroup.updateMatrixWorld(true)

                const MULTICONSTANT = 300

                const newPosition = new three.Vector3()
                newPosition.copy(this.position)
                newPosition.multiply(new three.Vector3(MULTICONSTANT, MULTICONSTANT, MULTICONSTANT))


                const rotationVector = new three.Vector3();
                rotationVector.copy(newPosition)
                rotationVector.applyAxisAngle(new three.Vector3(0, 1, 0), this.parentSpot.spotRotation.y)

                this.glft = gltf
                let mixer: three.AnimationMixer
                mixer = new three.AnimationMixer(scene)

                // gltf.scene.traverse((obj: three.Mesh) => {
                //     if (obj.isMesh) {
                //         obj.geometry.computeBoundingSphere()
                //     }
                // })

                if (gltf.animations.length > 0) {
                    mixer.clipAction(gltf.animations[0]).play()
                    this.animationMixer = mixer
                }

                sceneGroup.position.set(rotationVector.x, rotationVector.y, rotationVector.z)
                sceneGroup.scale.set(this.overAllScale, this.overAllScale, this.overAllScale)

                this.meshParent = sceneGroup

                sceneGroup.add(scene)

                const world = this.getParentProject().getWorld()
                world.secondaryNotSelectableAndNotRotatableGroup.add(sceneGroup)

            }

        }
    }

    getMeshAsset(): MeshAsset {
        return this.getParentProject().meshes.get(this.contentUid)
    }

    getWebPreviewImage() {
        const meshAsset = this.getMeshAsset()
        if (meshAsset) {
            return meshAsset.getWebPreviewImage()
        } else {
            return ""
        }
    }

    step() {

        if (this.glft) {

            if (this.animationMixer) {
                this.animationMixer.update(this.clock.getDelta())
            }

            if (this.automatic_rotation_enabled) {

                const rotationSpeed = three.MathUtils.degToRad(this.automatic_rotation_speed)

                if (this.automatic_rotation_axis_enabled_y) {
                    this.glft.scene.rotateY(rotationSpeed)
                }
                if (this.automatic_rotation_axis_enabled_x) {
                    this.glft.scene.rotateZ(rotationSpeed)
                }
                if (this.automatic_rotation_axis_enabled_z) {
                    this.glft.scene.rotateX(rotationSpeed)
                }
            }
        }

    }


    setVisibilityStatus(status: boolean) {
        if (this.isOpenedInWorld) {
            this.meshParent.visible = status
        } else {
            super.setVisibilityStatus(status)
        }
    }


    isVisible(): boolean {
        if (this.isOpenedInWorld) {
            return this.meshParent.visible
        } else {
            return super.isVisible();
        }
    }
}