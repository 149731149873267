import React from "react";
import {useTranslation} from "react-i18next";

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

export default function PeriodMUISelect({
                                            period,
                                            onPeriodChanged
                                        }) {

    const [t] = useTranslation()

    return (
        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.statistics.period")}:</InputLabel>
            <Select
                value={period}
                onChange={(e: SelectChangeEvent) => {
                    onPeriodChanged(e.target.value)
                }}
                label={"period"}
            >
                <MenuItem value={"Y"}>{t("core.year")}</MenuItem>
                <MenuItem value={"M"}>{t("core.month")}</MenuItem>
                <MenuItem value={"W"}>{t("core.week")}</MenuItem>
                <MenuItem value={"D"}>{t("core.day")}</MenuItem>
            </Select>
        </FormControl>
    )
}


interface CustomPeriodsMUISelectProps {
    periods: Array<string>
    period: string
    onPeriodChanged: (newPeriod: string) => void
}

export function CustomPeriodsMUISelect({
                                           periods,
                                           period,
                                           onPeriodChanged
                                       }: CustomPeriodsMUISelectProps) {

    const [t] = useTranslation()

    return (
        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.month")}:</InputLabel>
            <Select
                value={period}
                onChange={(e: SelectChangeEvent) => {
                    onPeriodChanged(e.target.value)
                }}
                label={"period"}
            >
                {
                    periods.map((val) => {
                        return (
                            <MenuItem value={val} key={val}>{val}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}