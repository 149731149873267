import React from "react";
import {INFINITY_PROJECT_TYPES} from "../../infinity/InfinityProject";
import {useTranslation} from "react-i18next";
import DashBoardTile, {DashBoardTileNoMiddle} from "../../components/dashboard/DashBoardTile";
import {useAvailableProjects, useAvailableProjectsByType} from "../../api/ProjectsApi";
import {BasicMiddleSpinner, OnlySpinner} from "../../components/spinners";
import {FakeProject} from "../inter_project_search_new/lightdata";
import {Card} from "react-bootstrap";


const BOARD_STYLE: any = {height: "100px"}

interface ByProjectTypesListProps {
    projectType: number
}

export default function ByProjectTypesList({projectType}: ByProjectTypesListProps) {

    const [t] = useTranslation()

    const [projects, isLoading] = useAvailableProjectsByType(projectType)

    if (isLoading) {
        return (
            <DashBoardTile>
                <OnlySpinner/>
            </DashBoardTile>
        )
    } else {
        return (
            <DashBoardTileNoMiddle>
                <h5 style={{textAlign: "left"}}>{t(projectType === INFINITY_PROJECT_TYPES.GUIDED ? "core.trainings.list" : "core.tours.list")}:</h5>

                <div style={{
                    overflowX: "auto",
                    marginBottom: "15px"
                }}>
                    <div className={"hstack gap-3"} style={{
                        paddingBottom: "15px"
                    }}>
                        {
                            projects.map((value) => {
                                return (
                                    <div key={value.uid}>
                                        <_ProjectView project={value}/>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </DashBoardTileNoMiddle>
        )
    }
}

interface _ProjectViewProps {
    project: FakeProject
}

function _ProjectView({project}: _ProjectViewProps) {

    const tmp = new FakeProject()

    tmp.load_from_json(project)

    return (
        <Card>

            <Card.Body>
                <img

                    src={tmp.getWebPreviewImage()}
                    style={{
                        width: "200px",
                        height: "100px"
                    }}/>
            </Card.Body>

            <Card.Footer>
                <div className={"noWrap"}>{project.name}</div>
            </Card.Footer>

        </Card>

    )
}