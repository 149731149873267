import React from "react";

import {DigitalTwinAsset, DigitalTwinDataSectionTypesProperty} from "../../ela/assets/asset_DigitalTwin";

import {CloseButton} from "../icon_click_buttons";

interface DigitalTwinBoardViewProps {
    boardName: string;
    asset: DigitalTwinAsset;
    onclose: Function;
}

interface DigitalTwinSectionsViewProps {
    boardName: string;
    asset: DigitalTwinAsset;
}

interface DigitalTwinSectionViewProps {
    section: DigitalTwinDataSectionTypesProperty;
    projectUid: string;
    assetUid: string;
    sectionIndex: number;
}

const FONTSTYLE = {
    color: "white"
}

const CELLSTYLE = {
    background: "rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
}


const DigitalTwinSectionView = ({section, projectUid, assetUid, sectionIndex}: DigitalTwinSectionViewProps) => {

    const sColor = section.color

    const [value, setValue] = React.useState(section.isStatic ? section.value : "---")

    const [activeTimeOut, setActiveTimeOut] = React.useState(null)

    const [failedToFetch, setFailedToFetch] = React.useState(false)
    const [failedWhy, setFailedWhy] = React.useState("")

    const fetchPath = `/dtfetch/${projectUid}/${assetUid}/${sectionIndex}`

    const refreshValue = () => {
        if (section.isStatic === false) {
            fetch(fetchPath).then(response => response.text()).then((response) => {
                let canFetchAgain = false

                try {
                    const value = JSON.parse(response)
                    if (value["status"]) {
                        setValue(value["value"])
                        canFetchAgain = true
                    } else {
                        setFailedToFetch(true)
                        setFailedWhy("")
                        canFetchAgain = true
                    }
                } catch (e) {
                    setFailedToFetch(true)
                    setFailedWhy("Something bad")
                    canFetchAgain = false
                }

                if (canFetchAgain) {
                    setActiveTimeOut(setTimeout(() => {
                        refreshValue()
                    }, section.updateInterval * 1000))
                }

            })
        }
    }

    React.useEffect(() => {
        refreshValue()

        return () => {
            if (activeTimeOut) {
                console.log("Here")
                clearTimeout(activeTimeOut)
            }
        }
    }, [])

    return (
        <>
            <div style={{
                ...CELLSTYLE,
                background: `rgba(${sColor.r * 255}, ${sColor.g * 255}, ${sColor.b * 255}, ${sColor.a})`,
                margin: "5px",
                padding: "5px",
            }}>
                <h3 style={{...FONTSTYLE,}}>
                    {section.key}
                </h3>


                {
                    failedToFetch && <h4 style={{color: "red", textAlign: "right"}}>Connection Error: {failedWhy}</h4>
                }

                {
                    failedToFetch === false && <>
                        <h4 style={{...FONTSTYLE, textAlign: "right"}}>
                            {value} {section.unit}
                        </h4>
                    </>
                }

            </div>
            <div style={{marginBottom: "15px"}}/>
        </>
    )
}

export const DigitalTwinBoardView = ({asset, boardName}: DigitalTwinSectionsViewProps) => {
    return (<div style={{
        ...CELLSTYLE,
        padding: "10px",
    }}>
        <h2 style={{color: "white"}}>{boardName}</h2>

        {
            asset.data.map((value, index) => {
                return <DigitalTwinSectionView key={index} projectUid={asset.projectUid}
                                               assetUid={asset.uid}
                                               section={value}
                                               sectionIndex={index}/>
            })
        }
    </div>)
}

export const DigitalTwinBoardPopupView = ({asset, boardName, onclose}: DigitalTwinBoardViewProps) => {

    const closeBtnStyle = {
        position: "absolute",
        top: "0px",
        right: "0px",
        transform: "translate(50%, -50%)"
    }


    return (<div>
        <div style={{
            backdropFilter: "blur(5px)",
            position: "absolute",
            left: "0px",
            top: "0px",
            minWidth: "100%",
            width: "100%",
            height: "100%",
        }}>
            <div style={{
                maxWidth: "75%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",

            }}>
                <div style={{
                    width: "450px",

                }}>
                    <DigitalTwinBoardView asset={asset} boardName={boardName}/>
                    <CloseButton style={closeBtnStyle} onClick={onclose}/>
                </div>
            </div>
        </div>

    </div>)

}


