import axios, {AxiosResponse} from "axios";

import type {Response_CreateProjectGroup, Response_GetAllProjectGroups} from "./ProjectGroupsApiInterfaces";
import {Response_GetAllProjectGroupsByName} from "./ProjectGroupsApiInterfaces";
import {useAvailableProjectGroups} from "../zustands/projectGroupsZus";
import {useGlobalAppLock} from "../zustands/globalAppLockZus";
import i18n from "i18next";


export default class ProjectGroupsApi {


    static async refreshZustand(isBlocking: boolean = false) {

        if (isBlocking) {
            useGlobalAppLock.setState({isAppLocked: true})
        }

        const response = await ProjectGroupsApi.getAllProjectGroups()

        const projects = [...response.data.groups, {name: i18n.t("core.unasigned"), uid: ""}]

        useAvailableProjectGroups.setState({availableGroups: projects})


        if (isBlocking) {
            useGlobalAppLock.setState({isAppLocked: false})
        }


    }


    static async getAllProjectGroups(): Promise<AxiosResponse<Response_GetAllProjectGroups>> {
        return await axios.get("/api/projectgroups/get_project_groups")
    }

    static async getMyAvailableGroupsWithAdminPermissions() {
        return await axios.get("/api/projectgroups/get_project_groups_with_admin_permission")
    }


    static async createProjectGroup(groupName): Promise<AxiosResponse<Response_CreateProjectGroup>> {
        const form = new FormData()
        form.set("group_name", groupName)

        return await axios.post("/api/projectgroups/create_project_group", form)

    }

    static async getUserGroupAsignments(userIdentifier: string) {
        return await axios.get(`/api/projectgroups/group_assignments?user_identifier=${userIdentifier}`)
    }


    static async getUserGroupAsignmentsByGroupNames(userIdentifier: string): Promise<AxiosResponse<Response_GetAllProjectGroupsByName>> {
        return await axios.get(`/api/projectgroups/group_assignments_by_name?user_identifier=${userIdentifier}`)
    }

    static async getUsersAndGroupAssignments(): Promise<AxiosResponse<[]>> {
        const result = await axios.get(`/api/projectgroups/get-all-group-assignments`)

        return result.data
    }

    static async asignUserToProjectAndGroup(userIdentifier: string, project_group: string) {
        const formData = new FormData()
        formData.set("user_identifier", userIdentifier)
        formData.set("project_group", project_group)
        return await axios.post(`/api/projectgroups/set_group_asignment`, formData)
    }

    static async removeUserAsignment(userIdentifier: string, project_group: string) {
        return await axios.delete(`/api/projectgroups/remove_user_assignment?user_identifier=${userIdentifier}&project_group=${project_group}`)
    }

    static async deleteProjectGroup(projectGroup: string, redirectGroup: string = "") {
        return await axios.delete(`/api/projectgroups/delete_project_group?group_id=${projectGroup}&redirect_id=${redirectGroup}`)
    }

    static async getProjectsInGroupCounts(groupId) {
        return await axios.get(`/api/projectgroups/get_project_in_group_count?group_id=${groupId}`)
    }

    static async renameProjectGroup(groupId, newName) {
        return await axios.put(`/api/projectgroups/rename_project_group?group_id=${groupId}&new_name=${newName}`)
    }


}