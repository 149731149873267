import React from "react";

import create from "zustand"


interface GlobalAppLock {
    isAppLocked: boolean,
    isFailed: boolean,
    failError: number,
    failMessage: string,
    lock: any
    unlock: any
    fail: any

}

export const useGlobalAppLock = create<GlobalAppLock>((set) => ({
    isAppLocked: false,
    isFailed: false,
    failError: -1,
    failMessage: "",
    lock: () => set((newState) => ({isAppLocked: true})),
    unlock: () => set((newState) => ({isAppLocked: false})),
    fail: () => set((newState) => ({isFailed: true})),

}))

