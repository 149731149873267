import * as three from "three"
import * as THREE from "three"

import {Sky} from "three/examples/jsm/objects/Sky";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import VrAvatar from "./vr/VrAvatar";


import {TWEEN} from "three/examples/jsm/libs/tween.module.min";


export default class DefaultSceneConfigurator {

    static STYLE_FULL_WINDOW_CANVAS = {
        zIndex: "-100",
        width: " 100%",
        height: "100%",
        position: "absolute",
        left: "0px",
        top: "0px",
        touchAction: "none",
    }

    constructor() {


    }


    attachVrAvatar(renderer, scene) {
        return new VrAvatar(renderer, scene, scene)
    }

    attachOrbitControls(renderer, camera) {
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 1.6, 0);
        controls.update();
    }

    attachOrbitMiddleControls(renderer, camera) {
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.maxPolarAngle = Math.PI / 2;
        controls.minPolarAngle = Math.PI / 3;
        controls.enableDamping = true;
        controls.enablePan = false;
        controls.dampingFactor = 0.1;
        controls.autoRotate = false; // Toggle this if you'd like the chair to automatically rotate
        controls.autoRotateSpeed = 0.2; // 30
    }

    attachRendererToHtml(renderer, tag: string) {
        const container = document.getElementById(tag)
        container.appendChild(renderer.domElement);
    }

    createRenderer() {
        return new three.WebGLRenderer({alpha: false, "antialias": true})
    }

    createCamera() {
        return new three.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    }

    createScene() {
        return new three.Scene()
    }

    configureRendererPbr(renderer: three.WebGLRenderer) {
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0xFFFFFF, 1)

        renderer.physicallyCorrectLights = true
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = three.PCFSoftShadowMap

        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ReinhardToneMapping
        renderer.toneMappingExposure = 1

    }

    configurePanoramaRenderer(renderer: three.WebGLRenderer) {
        renderer.setSize(window.innerWidth, window.innerHeight)
        renderer.setClearColor(0x000000, 1)
        renderer.setPixelRatio(window.devicePixelRatio)
        renderer.shadowMap.enabled = true

        renderer.physicallyCorrectLights = true
        // renderer.outputEncoding = THREE.sRGBEncoding;

    }

    enableRendererAutoResize(renderer: three.WebGLRenderer, camera: three.PerspectiveCamera) {
        window.addEventListener("resize", () => {
            const width = window.innerWidth
            const height = window.innerHeight
            camera.aspect = width / height
            camera.updateProjectionMatrix()
            renderer.setSize(width, height)
        })
    }

    enableAutoDraw(renderer: three.WebGLRenderer, scene: three.Scene, camera: three.PerspectiveCamera, beforeRenderUpdate) {
        renderer.setAnimationLoop(() => {
            TWEEN.update()
            if (beforeRenderUpdate) {
                beforeRenderUpdate()
            }
            renderer.render(scene, camera)
        })
    }


    configureSky(renderer: three.WebGLRenderer, scene: three.Group) {
        const sky = new Sky();
        sky.scale.setScalar(450000);
        scene.add(sky);

        const uniforms = sky.material.uniforms
        uniforms["turbidity"].value = 10
        uniforms["rayleigh"].value = 3
        uniforms["mieCoefficient"].value = 0.005
        uniforms["mieDirectionalG"].value = 0.7

        const phi = THREE.MathUtils.degToRad(-90 - 2);
        const theta = THREE.MathUtils.degToRad(180);

        const sun = new THREE.Vector3()
        sun.setFromSphericalCoords(1, phi, theta);

        uniforms["sunPosition"].value.copy(sun)
    }


    initVignete = () => {
        // const bgColor1 = '#ffffff'
        // const bgColor2 = '#353535'
        //
        // this.vignette = createBackground({
        //     aspect: this.camera.aspect,
        //     grainScale: false ? 0 : 0.001,
        //     colors: [bgColor1, bgColor2]
        // });
        // this.vignette.name = 'Vignette';
    }

    configureStudioLighting(scene: three.Group) {
        const ambientColor = 0xFFFFFF
        const ambientIntensity = 0.3
        const directIntensity = 0.8 * Math.PI
        const directColor = 0xFFFFFF

        const light1 = new three.AmbientLight(ambientColor, ambientIntensity);
        light1.name = 'ambient_light';
        scene.add(light1);

        const light2 = new three.DirectionalLight(directColor, directIntensity);
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = 'main_light'
        light2.castShadow = true
        light2.shadow.mapSize.width = 1024
        light2.shadow.mapSize.height = 1024

        scene.add(light2);
    }


    addPanoramaLightning(scene: three.Group) {
        const ambientIntensity = 1
        const directIntensity = 2

        const light1 = new three.AmbientLight(0xFFFFFF, ambientIntensity);
        light1.name = 'ambient_light';
        scene.add(light1);

        const light2 = new three.DirectionalLight(0xFFFFFF, directIntensity);
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = 'main_light'
        scene.add(light2);

    }

    // addPanoramaLightning(scene: three.Scene) {
    //     const hemiLight = new three.HemisphereLight(0xffffff, 0xffffff, 1);
    //     hemiLight.lookAt(0, 0, 0)
    //     hemiLight.position.y = 50
    //     hemiLight.position.x = 50
    //     hemiLight.intensity = 5
    //     scene.add(hemiLight)
    //
    //     const ambientLight = new three.AmbientLight(0x404040);
    //     ambientLight.intensity = 1
    //     scene.add(scene)
    // }


    initEnviromentEqirectangularMap(scene: three.Scene, photoPath) {
        const texture = new THREE.TextureLoader().load(photoPath)
        texture.mapping = THREE.EquirectangularReflectionMapping
        texture.encoding = THREE.sRGBEncoding

        scene.background = texture
        scene.environment = texture
    }

    attachDefaultFloor(group: three.Group) {
        const floorGometry = new THREE.PlaneGeometry(4, 4);
        const floorMaterial = new THREE.MeshStandardMaterial({
            color: 0x222222,
            roughness: 1.0,
            metalness: 0.0
        });
        const floor = new THREE.Mesh(floorGometry, floorMaterial);
        floor.rotation.x = -Math.PI / 2;
        group.add(floor);

        const grid = new THREE.GridHelper(10, 20, 0x111111, 0x111111);
        grid.position.y = -0.05
        group.add(grid);

        return floor

    }

}