import React from "react";


import {useNavigate} from "react-router-dom";


import {ViewContainer} from "../shared/containers";

import SelectProjectView from "../components/project/SelectProjectView";
import {FakeProject} from "./inter_project_search_new/lightdata";

export default function ProjectsView() {

    const navigate = useNavigate()


    return (
        <ViewContainer>
            <SelectProjectView onSelected={(project: FakeProject) => {
                navigate(`/project/${project.uid}`)
            }}/>
        </ViewContainer>

    )


}
