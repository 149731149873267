import React from "react";
import {Offcanvas} from "react-bootstrap";


interface InfinityOffcanvasWrapperProps {
    header: any
    closeButton?: boolean
    onHide?: () => void
    body: any
    mainStyle?: any
}

export function InfinityOffcanvasWrapper({
                                             header,
                                             closeButton,
                                             onHide,
                                             body,
                                             mainStyle,
                                         }: InfinityOffcanvasWrapperProps) {

    const _mstyle = mainStyle ?? {}


    return (
        <Offcanvas onHide={onHide} show={true} placement={"end"} style={{
            margin: "15px",
            ..._mstyle
        }}>

            <Offcanvas.Header className={"custom-white"} closeButton={closeButton}>
                {
                    header
                }
            </Offcanvas.Header>

            <Offcanvas.Body className={"custom-white"}>
                {
                    body
                }
            </Offcanvas.Body>


        </Offcanvas>
    )
}