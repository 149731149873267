import * as three from "three"


export class SceneFooter extends three.Object3D {


    constructor(imagePath: string) {
        super()

        const material = new three.MeshBasicMaterial({color: 0xFFFFFF, transparent: true});
        const geometry = new three.PlaneGeometry(1, 1,);
        //const geometry = new three.PlaneGeometry(1, 1, 1, 1);

        const plane = new three.Mesh(geometry, material);

        let loader: three.TextureLoader = new three.TextureLoader()

        loader.load(imagePath, (texture: three.Texture) => {
            material.map = texture
            material.needsUpdate = true
        });

        plane.position.set(0, -200, 0)
        plane.scale.set(50, 50, 50)
        plane.lookAt(new three.Vector3(0, 0, 0))


        this.add(plane)
    }


}