import {useTranslation} from "react-i18next";
import {useActiveTestTour} from "../../zustands/activeTestTourZus";
import {InfinityModal} from "../../components/modals";
import React from "react";
import {TourTask} from "../../ela/assets/asset_TourTask";
import {GuidedTaskRecord} from "../../GuidedTourRecorder";
import {useActiveProject} from "../../project/project_zustands/activeProjectZus";


interface PlayModalProps {
    index: number
    onHide: () => void
    onPlayPressed: () => void
    cancelEnabled?: boolean
    onCancel?: () => void
}

export default function TaskPlayModal({
                                          onHide,
                                          onPlayPressed,
                                          index,
                                          cancelEnabled,
                                          onCancel
                                      }: PlayModalProps) {

    const projectZus = useActiveProject()

    const [t] = useTranslation()

    const activeTaskZus = useActiveTestTour()

    const task = activeTaskZus.tourRecorded.records[index]


    const playPressed = () => {
        activeTaskZus.setTask(task)
        onPlayPressed()
    }

    const _title = task.header.replaceAll("\n", "<br/>")

    const SKIP_ENABLED = projectZus.project.task_tour_configuration.skip_enabled ?? true

    return (
        <InfinityModal
            size={"xl"}
            headerChildren={<div dangerouslySetInnerHTML={{__html: `<h5>${_title}<h5/>`}}/>}
            bodyChildren={<_PlayerBody task={task}/>}
            footerChildren={<div className={"hstack gap-2"}>

                {
                    (cancelEnabled && SKIP_ENABLED) && (
                        <button
                            className={"btn btn-danger"}
                            onClick={onCancel}

                        >
                            {t("core.home")}
                        </button>
                    )
                }

                <button
                    onClick={playPressed}
                    style={{marginLeft: "auto"}}
                    className={"btn btn-success"}
                >
                    {t("core.continue")}
                </button>
            </div>}
        />
    )
}


interface _PlayerBodyProps {
    task: GuidedTaskRecord
}

function _PlayerBody({task}: _PlayerBodyProps) {

    console.log(task)

    const _body = task.body.replaceAll("\n", "<br/>")

    return (
        <div className={"vstack gap-1"}>

            {
                task._task.tour_task.image_included &&
                (
                    <div style={{}}>
                        <img
                            style={{
                                maxHeight: "20em",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block"
                            }}
                            src={task._task.tour_task.GetImagePath()}
                        />
                    </div>
                )
            }

            <div style={{width: "100%"}}>
                <div dangerouslySetInnerHTML={{__html: _body}}/>
            </div>

        </div>
    )
}

