import React from "react";
import {ComposableMap, Geographies, Geography, Graticule, Marker, ZoomableGroup} from "react-simple-maps";
import {InfinityProject} from "../../infinity/InfinityProject";
import {useNavigate} from "react-router-dom";

const geoUrl =
    "/geo/europe.json";
// "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/europe.json";

export default function EuropeMap({visibleProjects, onSelected}) {

    const _visibleProject: Array<InfinityProject> = visibleProjects ?? []

    return (
        <ComposableMap
            width={800}
            height={400}
            projection="geoAzimuthalEqualArea"
            projectionConfig={{
                rotate: [-5.0, -45.0, 0],
                scale: 1300,

            }}
        >
            <ZoomableGroup>
                <Graticule stroke="#EAEAEC"/>
                <Geographies geography={geoUrl}>
                    {({geographies}) =>
                        geographies.map((geo) => (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                fill="#9998A3"
                                stroke="#EAEAEC"
                                style={{
                                    default: {outline: "none"},
                                    hover: {outline: "none"},
                                    pressed: {outline: "none"},
                                }}
                            />
                        ))
                    }
                </Geographies>

                {/*Pozicia je obratena oproti google mapam (latitude, longitude)*/}
                {
                    _visibleProject.map((value) => {
                        //Tato libka berie naopak poradie
                        return (
                            <Marker key={value.uid} coordinates={[value.lon ?? 0, value.lat ?? 0]}>
                                <circle r={5}
                                        fill="#F00"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            onSelected(value)
                                        }}
                                />
                                <text
                                    textAnchor="middle"
                                    y={15}
                                    style={{
                                        fontFamily: "system-ui",
                                        fill: "red",
                                        fontSize: 10
                                    }}
                                >
                                    {value.name}
                                </text>
                            </Marker>
                        )

                    })
                }

            </ZoomableGroup>


        </ComposableMap>
    );
};

