import React, {useState} from 'react';

import "./ImagePopup.css"

function ImageZoom({src, onClick}) {
    const [zoomed, setZoomed] = useState(true);

    return (
        <div style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(15px)",
        }}
             onClick={onClick}
        >
            <img
                src={src}
                className={`zoomable-image ${zoomed ? 'zoomed' : ''}`}
            />
        </div>
    );
};

export default ImageZoom;