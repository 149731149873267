import React from "react"

import Switch, {Case} from 'react-switch-case'

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import VisitorsStatisticsApi from "../../api/VisitorsStatisticsApi";

import {StatisticsItem} from "../../api/VisitorsStatisticsApiInterface";

import {useTranslation} from "react-i18next";
import useCookie from "react-use-cookie";
import {BasicMiddleSpinner} from "../../components/spinners";
import MyTable from "../../components/mytable";
import {saveChartData} from "./stats_chart_tools";
import {DashBoardTileNoMiddle} from "../../components/dashboard/DashBoardTile";
import PeriodMUISelect, {CustomPeriodsMUISelect} from "../../components/selectviews/PeriodSelectView";
import ViewAsMUISelect from "../../components/selectviews/ViewAsSelectView";
import ProjectsChartComparison from "./project_visits/ProjectsChartComparison";


export default function AppVisitsView() {

    return (
        <>
            <ProjectsChartComparison projectUid={""}/>
            <div style={{marginBottom: "50px"}}/>
            <_AppVisitsView/>

        </>

    )

}


function _AppVisitsView() {
    const [data, setData] = React.useState<Array<StatisticsItem>>(new Array())

    const [daysPeriodData, setDaysPeriodData] = React.useState<Map<string, Array<StatisticsItem>>>(new Map())
    const [dayPeriod, setDayPeriod] = useCookie("app-stats-dat-period", "")
    const [dayPeriods, setDayPeriods] = React.useState<Array<string>>([])

    const [period, setPeriod] = useCookie("app-stats-period", "W")


    const [viewAs, setViewAs] = useCookie("app-stats-viewas", "chart")

    const [isLoading, setIsLoading] = React.useState(false)


    const isDayOrWeek = period === "D" //|| period === "W"

    React.useEffect(() => {

        setIsLoading(true)
        VisitorsStatisticsApi.getAllAppStatistics(period).then((result) => {

            let convertedData: Array<any> = result.data.data.map((value) => {
                return {
                    "name": value.day,
                    "value": value.count,
                }
            })

            if (isDayOrWeek) {
                const tmpMap = new Map<string, Array<any>>()

                convertedData.forEach((val) => {
                    const splitted = val.name.split("-")
                    const together = `${splitted[0]}-${splitted[1]}`

                    const array = tmpMap.get(together)
                    if (array) {
                        array.push(val)
                    } else {
                        const tmp = new Array<any>()
                        tmp.push(val)
                        tmpMap.set(together, tmp)
                    }
                })

                const keys = Array.from(tmpMap.keys())
                keys.reverse()

                const lastKey = keys[0]

                setDayPeriods(keys)
                setDayPeriod(lastKey)
                setDaysPeriodData(tmpMap)

                const dataToShow = tmpMap.get(lastKey)
                if (dataToShow) {
                    saveChartData(dataToShow)
                    setData(dataToShow)
                } else {
                    setData([])
                }
            } else {
                saveChartData(convertedData)
                setData(convertedData as any)
            }

            setIsLoading(false)
        })

    }, [period])


    return (
        <div className={"vstack gap-3"}>
            <div style={{margin: "auto"}}>
                <DashBoardTileNoMiddle>
                    <div className={"hstack gap-3"}>
                        <PeriodMUISelect period={period} onPeriodChanged={setPeriod}/>

                        {
                            isDayOrWeek &&
                            <CustomPeriodsMUISelect
                                period={dayPeriod}
                                periods={dayPeriods}
                                onPeriodChanged={(newPeriod) => {
                                    setDayPeriod(newPeriod)
                                    const tmp = daysPeriodData.get(newPeriod)
                                    if (tmp) {
                                        saveChartData(tmp)
                                        setData(tmp)
                                    }

                                }}/>
                        }

                        <ViewAsMUISelect viewAs={viewAs} setViewAs={setViewAs}/>
                    </div>
                </DashBoardTileNoMiddle>
            </div>

            {
                isLoading ? <BasicMiddleSpinner/> :
                    <Switch condition={viewAs}>
                        <Case value={"chart"}>
                            <_ChartView data={data}/>
                        </Case>
                        <Case value={"table"}>
                            <_TableView data={data}/>
                        </Case>
                    </Switch>
            }


        </div>
    )
}


function _ChartView({data}) {
    return (

        <DashBoardTileNoMiddle

            style={{
                height: "600px",

            }}
        >
            <ResponsiveContainer width={"100%"} height={"600px"}>
                <div style={{
                    backgroundColor: "white",
                    margin: "auto",
                    padding: "25px",
                    overflowX: "scroll"
                }}>
                    <BarChart
                        width={1000}
                        height={500}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            dataKey="name"
                            tick={CustomizedAxisTick}
                            interval={3}
                            height={50}
                        />
                        <YAxis/>
                        <Tooltip/>

                        <Bar dataKey="value" fill="#db333c"/>

                    </BarChart>
                </div>
            </ResponsiveContainer>
        </DashBoardTileNoMiddle>

    )
}

function _TableView({data}) {

    const [t] = useTranslation()

    return (
        <MyTable>

            <thead>
            <tr>
                <td>Id</td>
                <td>{t("core.time")}</td>
                <td>{t("core.count")}</td>
            </tr>
            </thead>

            <tbody>
            {
                data.map((record, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{record.name}</td>
                            <td>{record.value}</td>
                        </tr>
                    )
                })
            }
            </tbody>

        </MyTable>
    )
}


function CustomizedAxisTick(props) {
    const {x, y, payload} = props;
    const name = payload.value;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0}
                  y={0}
                  dy={12}
                  fontSize="smaller"
                  textAnchor="end"
                  fill="#666"
                  transform="rotate(-45)"
                  style={{
                      fontSize: "15px"
                  }}
            >
                {name}

            </text>
        </g>
    );
};