import AnswerBase, {QuestionBase} from "./QuestionBase";


export class TextAnswer extends AnswerBase {

    getCopy() {
        const new_answer = new TextAnswer()
        new_answer.text = this.text
        new_answer.validity = this.validity
        new_answer.isSelected = this.isSelected
        return new_answer

    }

}

export class ImageAnswer extends AnswerBase {
    image = ""

    getCopy() {
        const new_answer = new ImageAnswer()
        new_answer.text = this.text
        new_answer.validity = this.validity
        new_answer.isSelected = this.isSelected
        new_answer.image = this.image
        return new_answer
    }

    to_json_result(): any {
        return {
            ...super.to_json_result(),
            Image: this.image
        }
    }

    from_json(answer_data: any) {
        super.from_json(answer_data)
        this.image = answer_data.ImagePath ?? ""
    }

    from_json_with_result(answer_data: any) {
        this.from_json(answer_data)
        super.from_json_with_result(answer_data)
    }

}


export class TextQuestion extends QuestionBase {

    getCopy() {
        const new_question = new TextQuestion()
        new_question.name = this.name
        new_question.isMultiAnswer = this.isMultiAnswer

        this.answers.forEach((answer) => {
            new_question.answers.push(answer.getCopy())
        })
        return new_question
    }

    to_json_result(): any {

        return {
            ...super.to_json_result(),
            Type: "text"
        }
    }

    from_json(question_data) {
        super.from_json(question_data)

        const answers = question_data.Answers ?? []

        answers.forEach((answer_data: any) => {
            const answer = new TextAnswer()
            answer.from_json(answer_data)
            this.answers.push(answer)
        })

    }

    from_json_with_result(question_data) {
        super.from_json_with_result(question_data)

        const answers = question_data.Answers ?? []

        answers.forEach((answer_data: any) => {
            const answer = new TextAnswer()
            answer.from_json_with_result(answer_data)
            this.answers.push(answer)
        })

    }
}
