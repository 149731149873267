import React from "react";

import LanguageSelectionView from "./general/LanguageSelectionView";
import {Stack} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default function SettingsGeneral({}) {

    const [t] = useTranslation()

    return (
        <Stack gap={3}>
            <LanguageSelectionView/>

            <div style={{
                marginLeft: "auto",
                marginTop: "20px"
            }}>
                <button
                    className={"btn btn-outline-danger btn-sm"}
                    onClick={() => {
                        window.location = window.location.origin + "/.auth/logout"
                    }}
                >
                    {t("core.logout")}
                </button>

            </div>


        </Stack>
    )
}


