import React from "react";
import {useTranslation} from "react-i18next";

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {InfinityProject} from "../../infinity/InfinityProject";


interface AreaMuiSelectProps {
    activeAreaUid: string
    areaChanged: (areaUid: string) => void
    activeProject: InfinityProject | any

}

export default function AreaMuiSelect({
                                          activeAreaUid,
                                          areaChanged,
                                          activeProject
                                      }: AreaMuiSelectProps) {

    const [t] = useTranslation()

    return (
        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.area")}:</InputLabel>
            <Select
                label={"area"}
                value={activeAreaUid}
                onChange={(e: SelectChangeEvent) => {
                    areaChanged(e.target.value)
                }}
            >
                {
                    Array.from(activeProject.areas.values()).map((value) => {

                        return <MenuItem key={value.uid} value={value.uid}>{value.name}</MenuItem>
                    })
                }

            </Select>
        </FormControl>

    )
}