import React from "react";
import {useTranslation} from "react-i18next";

import TrainingStatisticsDashboardView from "./dashboards/TrainingStatisticsDashboardView";
import GlobalQuizzesDashBoard from "./dashboards/GlobalQuizzesDashBoard";
import LicenceManager from "../../api/LicenceManager";
import {INFINITY_PROJECT_TYPES} from "../../infinity/InfinityProject";

export default function GlobalTrainingsView({}) {

    const [t] = useTranslation()

    return (
        <>

            <TrainingStatisticsDashboardView/>
            {
                LicenceManager.f360.quizzes_monitoring &&
                <>
                    <GlobalQuizzesDashBoard projectType={INFINITY_PROJECT_TYPES.GUIDED}/>
                </>
            }

        </>
    )
}

