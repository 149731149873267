import * as three from "three"

import {PanoramaAsset} from "../ela/assets/asset_Panorama";
import {InfinityBoardParent} from "../3d/boards/board_Parent";
import {InfinityGateBoard} from "../3d/boards/board_Gate";

import {InfinityVideoBoard} from "../3d/boards/board_Video";
import {InfinitySoundBoard} from "../3d/boards/board_Sound";
import {InfinityQuizBoard} from "../3d/boards/board_Quiz";
import {InfinityMeshBoard} from "../3d/boards/board_Mesh";
import {InfinityDocumentBoard} from "../3d/boards/board_Document";
import {InfinityInfoBoard} from "../3d/boards/board_Info";
import {InfinityOutlineBoard} from "../3d/boards/board_Outline";
import {InfinityClickBoard} from "../3d/boards/board_Click";

import {AreaAsset} from "../ela/assets/asset_Area";
import {InfinityDigitalTwinBoard} from "../3d/boards/board_DigitalTwin";
import {InfinityFinishBard} from "../3d/boards/board_Finish";

export class InfinitySpot {


    name: string = ""
    panorama_uid: string = ""
    uid: string = ""
    is_previewed = false

    tags = ""

    isAreaTranslation = false
    areaId: string = ""
    area_pos_x = 0
    area_pos_y = 0
    area_pos_z = 0

    area_scale = 2.5

    wasCompleted = false
    wasVisited = false

    colorCorrection = new three.Color(1, 1, 1)

    boards: Map<string, InfinityBoardParent>


    constructor(parentProject) {
        this.parentProject = parentProject

        this.boards = new Map()
        this.spotRotation = new three.Vector3()
        this.rotationMatrixVector = []

    }

    LoadFromJsonObject(data: any) {

        this.uid = data["uid"]
        this.panorama_uid = data["panorama_uid"]
        this.name = data["name"]
        this.areaId = data["area_id"]

        this.area_pos_x = data["area_pos_x"]
        this.area_pos_y = data["area_pos_y"]
        this.area_pos_z = data["area_pos_z"]

        this.area_scale = data.area_scale ?? 2.5

        this.tags = data.search_tags ?? ""

        const colorCorrection = data["color_correction"]
        const colorOffset = data["brightness_offset"]
        this.colorCorrection.r = colorCorrection.x + colorOffset
        this.colorCorrection.g = colorCorrection.y + colorOffset
        this.colorCorrection.b = colorCorrection.z + colorOffset

        const rotationData = data["spot_rotation"]


        if (rotationData) {
            this.spotRotation.x = -three.MathUtils.degToRad(rotationData["x"])
            this.spotRotation.y = -three.MathUtils.degToRad(rotationData["y"])
            this.spotRotation.z = -three.MathUtils.degToRad(rotationData["z"])
        }

        const userData = data["custom_data"]

        if (userData) {
            const spotRotationVector = userData["rotation_matrix_vector"]
            if (spotRotationVector) {
                this.rotationMatrixVector = spotRotationVector
            }
        }


        const boards: Array<any> = data["boards"]
        //console.error("Spot with name: " + this.uid + " has boards count:" + boards.length)
        boards.forEach((value) => {
                const boardType = value["type"]

                let board: InfinityBoardParent = null

                if (boardType === "GateBoard") {
                    board = new InfinityGateBoard(this)
                    //console.log("Adding gate board: " + board.name)
                } else if (boardType === "VideoBoard") {
                    board = new InfinityVideoBoard(this)
                    //console.log("Adding video board")
                } else if (boardType === "SoundBoard") {
                    board = new InfinitySoundBoard(this)
                    //console.log("Adding sound board")
                } else if (boardType === "QuizBoard") {
                    board = new InfinityQuizBoard(this)
                    //console.log("Adding quiz board")
                } else if (boardType === "MeshBoard") {
                    board = new InfinityMeshBoard(this)
                    //console.log("Adding mesh board")
                } else if (boardType === "DocumentationBoard") {
                    board = new InfinityDocumentBoard(this)
                } else if (boardType === "OutlineBoard") {
                    board = new InfinityOutlineBoard(this)
                } else if (boardType === "InfoBoard") {
                    board = new InfinityInfoBoard(this)
                } else if (boardType === "DigitalTwinBoard") {
                    board = new InfinityDigitalTwinBoard(this)
                } else if (boardType === "ClickBoard") {
                    board = new InfinityClickBoard(this)
                } else if (boardType == "FinishBoard") {
                    board = new InfinityFinishBard(this)
                } else {
                    board = new InfinityBoardParent(this)
                }

                board.loadFromJsonObject(value)
                this.boards.set(board.uid, board)

            }
        )
    }

    PostLoad() {
        // this.boards.forEach((board) => {
        //     if (board instanceof InfinityGateBoard) {
        //         if (this.parentProject.spots.has(board.contentUid)) {
        //             board.name = this.parentProject.spots.get(board.contentUid).name
        //         } else {
        //             board.name = "-invalid-"
        //         }
        //     }
        // })

        this.isAreaTranslation = this.isTranslationToNextArea()

    }


    GetPanoramaUid() {
        return this.panorama_uid
    }

    GetPanoramaAssetBrothers(): Array<PanoramaAsset> {
        const brothers: Array<PanoramaAsset> = []
        this.boards.forEach((value: InfinityBoardParent) => {
            if (value instanceof InfinityGateBoard) {
                const brotherSpot = this.parentProject.spots.get(value.contentUid)
                brothers.push(brotherSpot.GetPanoramaAsset()
                )
            }
        })
        return brothers
    }


    GetPanoramaAsset(): PanoramaAsset {
        return this.parentProject.panoramas.get(this.panorama_uid)
    }

    GetBoards() {
        return this.boards
    }

    isTranslationToNextArea() {
        const spots = this.parentProject.spots

        let found = false

        this.boards.forEach((board) => {
            if (board instanceof InfinityGateBoard) {
                const tmpSpot = spots.get(board.contentUid)
                if (tmpSpot) {
                    if (tmpSpot.areaId != this.areaId) {
                        found = true
                        return
                    }
                }
            }
        })

        return found
    }

    GetDefaultBoard(): InfinityBoardParent {
        const tmp = new InfinityGateBoard(this)
        return tmp
    }

    getMapAsset(): AreaAsset | undefined {
        const areas = this.parentProject.areas
        if (areas.has(this.areaId)) {
            return areas.get(this.areaId)
        }
        return undefined
    }

    getBoardsOfType(type: string, filter: string) {

        var boards: Array<InfinityBoardParent> = []

        if (filter === "") {
            boards = Array.from(this.boards.values()).filter((value) => {
                if (value.type === type) {
                    return value
                }
            })
        } else {
            boards = Array.from(this.boards.values()).filter((value) => {
                if (value.type === type && value.getSearchableText().toLowerCase().search(filter) != -1) {
                    return value
                }
            })
        }

        return boards
    }

    getBoardsOnTagFilter(type: string, filter: string) {

        var boards: Array<InfinityBoardParent> = []

        if (filter === "") {
            boards = Array.from(this.boards.values()).filter((value) => {
                if (value.type === type) {
                    return value
                }
            })
        } else {
            boards = Array.from(this.boards.values()).filter((value) => {
                if (value.type === type && value.tags.toLowerCase().search(filter) != -1) {
                    return value
                }
            })
        }

        return boards
    }


    getBoardTypeCount(type: string) {
        let count = 0

        this.boards.forEach((value) => {
            if (value.type === type) {
                count += 1
            }
        })

        return count
    }

    getWebPreviewImage(): string {
        const panorama = this.parentProject.panoramas.get(this.panorama_uid)

        if (panorama) {
            return panorama.getWebPreviewImage()
        } else {
            return ""
        }
    }

    isCompleted(): boolean {
        let wasCompleted = false;

        this.boards.forEach((board: InfinityBoardParent) => {

        })


        return wasCompleted

    }

    getParentAreaName() {
        if (this.parentProject) {
            if (this.areaId !== "") {

                const area: AreaAsset | null = this.parentProject.areas.get(this.areaId)

                if (area) {
                    return area.name
                }


            }
        }

        return "--"
    }

}