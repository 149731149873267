import {getCookie, setCookie} from "react-use-cookie"


const LAST_RESOLUTION = getCookie("panorama_resolution")

export default class PanoramasManager {

    static REALLY_BAD = "512x256"
    static LOW = "2048x1024"
    static MEDIUM = "4096x2048"
    static HIGH = "8196x4096"
    static ULTRA = "16384x8192"

    static ACTIVE_RESOLUTION = LAST_RESOLUTION !== "" ? LAST_RESOLUTION : PanoramasManager.HIGH

    static changeResolution(newResolution: string) {

        PanoramasManager.ACTIVE_RESOLUTION = newResolution
        setCookie("panorama_resolution", newResolution)
    }


    static adaptToNetwork() {

    }

    static adaptToDevice() {

    }

}