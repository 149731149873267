import React from "react";
import {InfinityModal} from "./modals";
import {useGlobalPopup} from "../zustands/globalPopupZus";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";


interface AreYouSureModalProps {
    header?: string
    body?: string
    onYes?: () => void
    onNo?: () => void
}

export function AreYouSureModal({header, body, onYes, onNo}: AreYouSureModalProps) {

    const [t] = useTranslation()

    return (
        <InfinityModal
            closeButton={true}
            onHide={onNo}
            headerChildren={<Modal.Title>{t(header ?? "core.confirm")}</Modal.Title>}
            bodyChildren={<div dangerouslySetInnerHTML={{__html: t(body ? body : "")}}/>}
            footerChildren={<>
                <div className={"hstack gap-1"} style={{marginLeft: "auto"}}>
                    <button className={"btn btn-secondary"} onClick={onNo}>{t("core.no")}</button>
                    <button className={"btn btn-primary"} onClick={onYes}>{t("core.yes")}</button>
                </div>
            </>}
        />
    )
}

export function showYesNoModal(header?: string | any,
                               body?: string | any,
                               onYes?: () => void,
                               onNo?: () => void) {

    useGlobalPopup.getState().pushElement(<AreYouSureModal
            onYes={() => {
                useGlobalPopup.getState().popElement()
                if (onYes) {
                    onYes()
                }
            }}
            onNo={() => {
                useGlobalPopup.getState().popElement()
                if (onNo) {
                    onNo()
                }
            }}
            header={header}
            body={body}
        />
    )

}

