import * as React from "react";

import {InfinitySpot} from "../../../infinity/InfinitySpot";

import {AreaAsset} from "../../../ela/assets/asset_Area";

import {MapTools} from "./MapTools";

import {Maximap} from "./Maximap";
import * as Bs from "react-bootstrap";
import {useActiveSpotZus} from "../../../zustands/activeSpotZus";

import {Minimap3D} from "./Minimap3D";
import urlcWithPath from "../../../urlc";

interface NewMinimapProps {
    activeSpot: InfinitySpot;
    onMaximapActive: Function;
}

export const Minimap = ({onMaximapActive}: NewMinimapProps) => {
    const [isMaximapVisible, setIsMaximapVisible] = React.useState(false);

    const activeSpotZus = useActiveSpotZus();

    const activeSpot = activeSpotZus.activeSpot;

    if (!activeSpot) {
        return (<></>)
    }

    if (activeSpot.parentProject.tour_configuration.minimap_type === "Hidden") {
        return (<></>)
    }

    const activeArea = getAreaFromSpot(activeSpot);

    if (activeArea) {
        const PIXELCONST = 300;

        const MAPSTYLE = {
            ...MapTools.getMapSizeNoUnit(activeArea, PIXELCONST),
            left: "10px",
            bottom: "10px",

            position: "absolute",

            background: "rgba(0, 0, 0, 0.4)",

            borderRadius: "5px",

            borderStyle: "solid",
            borderWidth: "thin",
            borderColor: "white",
        };

        if (isMaximapVisible) {
            return (
                <Maximap
                    activeSpot={activeSpot}
                    onMinimalize={() => {
                        onMaximapActive(false);
                        setIsMaximapVisible(false);
                    }}
                />
            );
        } else {
            if (isPortraitOrTooBig(activeArea, PIXELCONST)) {
                return (
                    <div className={"not-draggable"}>
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                cursor: "pointer",
                                bottom: "5px",
                                position: "absolute",
                                left: "5px",
                            }}
                            onClick={() => {
                                setIsMaximapVisible(true);
                                onMaximapActive(true);
                            }}
                        >
                            <img
                                style={{
                                    display: "box",
                                    objectFit: "contain",
                                    maxWidth: "max-content",
                                    maxHeight: "max-content",
                                    height: "100%",
                                    width: "100%",
                                }}
                                src={urlcWithPath("i-icons/map.png")}
                            />
                        </div>
                    </div>
                );
            } else {
            }

            return (
                <div className={"not-draggable"}>
                    <div style={MAPSTYLE}>
                        <Minimap3D
                            activeSpot={activeSpot}
                            activeArea={activeArea}
                            spots={Array.from(activeSpot.parentProject.spots.values())}
                            onSpotSelected={(spot) => {
                                activeSpot.parentProject.activePlayer.ActivateSpotWithId(
                                    spot.uid
                                );
                            }}
                        />

                        <MaximalizeButton
                            onClick={() => {
                                setIsMaximapVisible(true);
                            }}
                        />
                    </div>
                </div>
            );
        }
    } else {
        return <></>;
    }
};

function getAreaFromSpot(spot: InfinitySpot): AreaAsset {
    if (spot) {
        const project = spot.parentProject;

        if (project.areas.has(spot.areaId)) {
            return project.areas.get(spot.areaId);
        } else {
            return null;
        }
    }
    return null;
}

const MaximalizeButton = ({onClick}) => {
    const maixmalizeButtonStyle = {
        right: "-15px",
        top: "-15px",
        position: "absolute",
        margin: "0px 0px",
        width: "30px",
        height: "30px",
        cursor: "pointer",
    };

    return (
        <div style={{}} onClick={onClick}>
            <Bs.Image
                src={urlcWithPath("i-icons/box-arrow-in-up-right.svg")}
                style={maixmalizeButtonStyle}
            />
        </div>
    );
};

function isPortraitOrTooBig(area: AreaAsset, pixelConstant: number) {
    if (window.innerHeight > window.innerWidth) {
        return true;
    } else {
        // const tmp = MapTools.calculateMapSizeFromRatio(area, pixelConstant)
        //
        // if (tmp.x > (window.innerWidth * 0.25) || tmp.y > (window.innerHeight * 0.25)) {
        //     return true
        // }
        return false;
    }

    return false;
}
