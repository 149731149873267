import React from "react";


export function useEffectOnce(effect, deps) {
    const effectFn = React.useRef(effect)
    const destroyFn = React.useRef()
    const effectCalled = React.useRef(false)
    const rendered = React.useRef(false)
    const [, refresh] = React.useState(0)

    if (effectCalled.current) {
        rendered.current = true
    }

    React.useEffect(() => {
        if (!effectCalled.current) {
            destroyFn.current = effectFn.current()
            effectCalled.current = true
        }

        refresh(1)

        return () => {
            if (rendered.current === false) return
            if (destroyFn.current) destroyFn.current()
        }
    }, deps)
}