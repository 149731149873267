import React from "react";

import {Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import useCookie from "react-use-cookie";

export default function LanguageSelectionModal({onHide}) {

    const [t, tInsntance] = useTranslation()

    const [userLanguage, setUserLanguage] = useCookie("language", tInsntance.language)

    const langChanged = (e) => {
        const value = e.target.value
        tInsntance.changeLanguage(value)
        setUserLanguage(value)
    }

    React.useEffect(() => {
        //Setting last app language
        setUserLanguage(userLanguage)
    }, [])

    return (
        <Modal show={true} onHide={onHide}>
            <Modal.Header closeButton className={"custom-white"}>
                {t("core.language")}
            </Modal.Header>
            <Modal.Body className={"custom-white"}>

                <Form.Text>
                    {t("core.language")}
                </Form.Text>

                <Form.Select value={userLanguage} onChange={langChanged}>
                    <option value={"en"}>English</option>
                    <option value={"cz"}>Czech</option>
                    <option value={"sk-SK"}>Slovak</option>
                </Form.Select>

            </Modal.Body>
        </Modal>
    )
}