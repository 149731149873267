import React from "react";
import {useTranslation} from "react-i18next";
import {useInterProjectSearch} from "./ipszus";
import {BasicMiddleSpinner} from "../../components/spinners";
import UniversalInterProjectSearchTable from "./new_ips_universal_table_view";


let SEARCH_TIME_OUT = null

const AllSearchView = React.forwardRef(({searchType}, ref) => {

        const [t] = useTranslation()

        const selectedProjects = useInterProjectSearch()

        const [visibleProjectWithResult, setVisibleProejctWithResult] = React.useState(selectedProjects.availableProjects)

        const [isSearching, setIsSearchig] = React.useState(false)

        const runSearch = (searchKey: string) => {
            if (searchKey !== "") {
                const newResult = new Array()
                selectedProjects.markedProjects.forEach((value) => {
                    let tmp = null

                    if (searchType === "byname") {
                        tmp = value.createNewOnNameFilter(searchKey)
                    } else {
                        tmp = value.createNewOnTagFilter(searchKey)
                    }

                    if (tmp) {
                        newResult.push(tmp)
                    }
                })

                setVisibleProejctWithResult(newResult)
            } else {
                setVisibleProejctWithResult(selectedProjects.markedProjects)
            }
        }

        const onSearchTyping = (searchKey) => {

            setIsSearchig(true)

            if (SEARCH_TIME_OUT) {
                clearTimeout(SEARCH_TIME_OUT)
                SEARCH_TIME_OUT = null
            }

            SEARCH_TIME_OUT = setTimeout(() => {
                runSearch(searchKey)
                setIsSearchig(false)
            }, 500)


        }

        React.useImperativeHandle(ref, () => ({
            onSearchTyping,
        }))

        React.useEffect(() => {
            setVisibleProejctWithResult(selectedProjects.markedProjects)
        }, [selectedProjects.markedProjects])

        //
        //
        // React.useEffect(() => {
        //     setVisibleProejctWithResult([...selectedProjects.availableProjects])
        // }, [selectedProjects.availableProjects])
        //
        // console.log(visibleProjectWithResult)


        if (isSearching) {
            return (<BasicMiddleSpinner/>)

        } else if (visibleProjectWithResult.length === 0) {
            return (
                <div>{t("core.no.data")}</div>
            )
        } else {
            return (
                <UniversalInterProjectSearchTable searchType={searchType}
                                                  visibleProjectWithResult={visibleProjectWithResult}/>
            )
        }


    }
)


export default AllSearchView