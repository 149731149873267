import React from "react";
import {Canvas} from "@react-three/fiber";
import {AreaAsset} from "../../../ela/assets/asset_Area";
import {InfinitySpot} from "../../../infinity/InfinitySpot";
import {Box, Html, MapControls} from "@react-three/drei";
import AreaPlaneMesh from "../../../gui/content_players/map/AreaPlaneMesh";


interface Visits3DMapProps {
    activeArea: AreaAsset
    spots: Array<InfinitySpot>
    spotsData: Map<string, number>
}

export default function ProjectVisits3DMap({activeArea, spots, spotsData}) {

    return (
        <div style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#272730"
        }}>
            <Canvas camera={{position: [0, 50, 0]}}>
                <_MapWorld activeArea={activeArea} spots={spots} spotsData={spotsData}/>
            </Canvas>
        </div>
    )
}

function _MapWorld({activeArea, spots, spotsData}) {

    return (
        <>
            <_Controls activeArea={activeArea}/>

            <ambientLight intensity={0.1}/>
            <directionalLight position={[50, 30, 20]}/>

            <React.Suspense fallback={""}>
                {
                    activeArea.fileExists && <AreaPlaneMesh area={activeArea} texture={activeArea.getMaximapPath()}/>
                }
            </React.Suspense>

            <_AreaSpots
                spotsData={spotsData}
                spots={spots}
                activeArea={activeArea}
            />
        </>
    )
}

function _Controls({}) {

    const ref = React.useRef()

    return (<>
        <MapControls
            ref={ref}
            makeDefault
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2.5}
            enableDamping={false}/>
    </>)
}

interface _AreaSpotsProps {
    spots: Array<InfinitySpot>
    activeArea: AreaAsset
    spotsData: Map<string, number>
}

function _AreaSpots({
                        spots,
                        activeArea,
                        spotsData
                    }) {


    let max = 0

    spotsData.forEach((value: number) => {
        if (value > max) {
            max = value
        }
    })


    return (
        <group>
            {
                spots.map((value: InfinitySpot, index) => {
                    if (value.areaId === activeArea.uid) {

                        const visits = spotsData.has(value.uid) ? spotsData.get(value.uid) : 0

                        return (
                            <_AreaSpot key={value.uid} spot={value} visits={visits} max={max}/>
                        )
                    }
                })
            }


        </group>
    )
}

const MATERIALS = [
    <meshStandardMaterial color={"darkblue"}/>,
    <meshStandardMaterial color={"blue"}/>,
    <meshStandardMaterial color={"green"}/>,
    <meshStandardMaterial color={"yellow"}/>,
    <meshStandardMaterial color={"orange"}/>,
    <meshStandardMaterial color={"red"}/>
]


function _materialCounter(value: number, max: number) {

    if (max !== 0) {
        const index = Math.floor(((value / max) * 10) / 2)
        return MATERIALS[index]
    } else {

        return MATERIALS[0]
    }

}

function _calculateHeights(value: number, max: number) {

    if (value === 0) {
        return 0.15
    } else {
        if (max != 0) {
            return ((value / max) * 10) + 0.15
        }
    }

}

function _AreaSpot({spot, visits, max}) {

    const _spot: InfinitySpot = spot

    const position = [spot.area_pos_x, 0, spot.area_pos_z]

    const pointScale = _spot.area_scale

    const height = _calculateHeights(visits, max)


    return (
        <group position={position}>
            <group scale={[pointScale, height, pointScale]}>
                <Box
                    position={[0, 0.5, 0]}
                    scale={[0.7, 1, 0.7]}
                >
                    {_materialCounter(visits, max)}
                </Box>

            </group>


            <Html
                position={[0, height, 0]}
                scale={pointScale}
                transform
                center
                zIndexRange={[0, 5]}
                rotation={[Math.PI / -2, 0, 0]}
            >
                <div
                    className={"not-draggable"}
                    style={{
                        background: "rgba(0, 0, 0, 0)",
                        color: "white",
                        fontSize: "10px",
                        // transform: "translate(-50%, 50%)",
                        textAlign: "center",
                        borderRadius: "5px",
                        padding: "2px 2px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        // width: `${pointScale * 5.5}px`,
                        // height: `${pointScale * 5.5}px`,
                        textShadow: "1px 1px 1px #000000",
                    }}>
                    {visits}
                </div>

            </Html>
        </group>

    )
}