import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import {
  handleGlobalAppFail,
  lockAppBeforeResponse,
  unlockAppOnResponse,
} from "./GlobalAppLockHelper";
import AppBackendManager from "./AppBackendManager";
import { useAvailableProjectsZus } from "../zustands/availableProjectsZus";
import { VideoAsset } from "../ela/assets/asset_Video";
import { FakeProject } from "../routes/inter_project_search_new/lightdata";
import {
  INFINITY_PROJECT_TYPES,
  InfinityProject,
  InfinityProjectFetch,
} from "../infinity/InfinityProject";
import React from "react";
import urlcWithPath from "../urlc";

export default class ProjectsApi {
  static async refreshZustand() {
    const response = await ProjectsApi.getAllProjects();

    useAvailableProjectsZus.setState({ availableProjects: response });

    return null;
  }

  static async GetDocumentSasWithUid(
    project_uid: string,
    document_uid: string,
    extension: string
  ): Promise<string> {
    return (
      await axios.get(
        `/api/project/document-link/${project_uid}?document_uid=${document_uid}&document_ext=${extension}`
      )
    ).data;
  }

  static async getProjectWithUid(
    project_uid: string
  ): Promise<InfinityProject> {
    const projectPath = urlcWithPath("infinity/" + project_uid + "/project_data.json");

    const response = await axios.get(projectPath);

    const project = new InfinityProject();

    await project.load_from_json_async(response.data);

    return project;
  }

  static async refreshProjects(autoAppLock = false) {
    const request = axios.put("/api/projects/refreshprojectstable");

    request.catch(handleGlobalAppFail);

    if (autoAppLock) {
      lockAppBeforeResponse();

      request.then(unlockAppOnResponse);
    }

    return await request;
  }

  static async getSearchableProject(project_id): Promise<InfinityProject> {
    if (AppBackendManager.isBackendAvailable) {
      const response = await axios.get(
        urlcWithPath(`search/search_cache.json?project_uid=${project_id}`)
      );
      const project = new FakeProject();
      project.load_from_json(response.data);
      return project;
    } else {
      const response = await axios.get(
        urlcWithPath(`infinity/${project_id}/search_cache.json`)
      );
      const project = new FakeProject();
      project.load_from_json(response.data);
      return project;
    }
  }

  static async getSearchCache(): Promise<Array<InfinityProject>> {
    const response = await axios.get(`/api/search/search_all_cache`);

    const projects = [];

    response.data.projects.map((data) => {
      const project = new FakeProject();
      project.load_from_json(data);
      projects.push(project);
    });

    return projects;
  }

  static async getProjectStats(project_id: string): Promise<any> {
    return await axios.get(
      urlcWithPath(`search/project_stats.json?project_uid=${project_id}`)
    );
  }

  static async getProjectStatsOfProjectOf(
    project_type: number
  ): Promise<Array<any>> {
    return (
      await axios.get(urlcWithPath(`search/projects-stats?type=${project_type}`))
    ).data.projects;
  }

  static async getVideoSas(videoAsset: VideoAsset) {
    return await axios.get(
      `/api/project/video-link/${videoAsset.projectUid}?video_uid=${videoAsset.uid}&video_extension=${videoAsset.file_extension}`
    );
  }

  static async getAllProjects(): Promise<Array<InfinityProjectFetch>> {
    const response = await axios.get(urlcWithPath("infinity/projects.json"));

    const projects = response.data.projects ?? [];

    projects.forEach((value) => {
      if (!value.ProjectType) {
        value.ProjectType = INFINITY_PROJECT_TYPES.BASIC;
      }
    });

    return projects;
  }

  static async getAllProjectsOfType(
    type: number
  ): Promise<Array<InfinityProjectFetch>> {
    const response = await axios.get(
      `/api/project/get-all-projects-of-type?type=${type}`
    );

    return response.data.projects ?? [];
  }

  static async changeProjectGroup(projectId: string, groupId: string) {
    const formData = new FormData();
    formData.set("project_id", projectId);
    formData.set("group_id", groupId);

    return await axios
      .put("/api/projects/change_project_group", formData)
      .catch(handleGlobalAppFail);
  }

  static async checkProjectExistence(
    projectId: string
  ): Promise<AxiosResponse<{ exists: boolean }>> {
    return await axios.get(
      `/api/projects/checkprojectexistence?project_id=${projectId}`
    );
  }

  static async postFile(file: File, options) {
    const formData = new FormData();
    formData.append("file", file);
    return await axios.post(
      "/api/projects/uploadprojectfile",
      formData,
      options
    );
  }

  static async PrepareFileChunk(file: File, chunkOffset: number, chunk: any) {
    const formData = new FormData();

    formData.append("file", new Blob([chunk]));

    const config: AxiosRequestConfig<any> = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Offset": chunkOffset,
      },
    };

    return await axios.post(
      "/api/projects/upload-file-chunk",
      formData,
      config
    );
  }

  static async reserveProjectUpload(file: File, group: string) {
    const _group = group !== "" ? `group=${group}` : "";
    const formData = new FormData();
    formData.append("file", file);
    return await axios.post(
      `/api/projects/preprare-project-upload?${_group}`,
      formData
    );
  }

  static async getFilesDif(
    projectUid: string,
    files: Array<string>
  ): Promise<Array<string>> {
    const form = new FormData();
    form.set("files_list", files);
    const response = await axios.put(
      `/api/projects/remove-existing-files?project_id=${projectUid}`,
      form
    );
    return response.data.to_remove;
  }

  static async changeLatLon(project_uid: string, lat: number, lot: number) {
    return await axios.put(
      `/api/projects/changelatlon?project_id=${project_uid}&lat=${lat}&lon=${lot}`
    );
  }

  static async changeProjectName(project_uid: string, name: string) {
    return await axios.put(
      `/api/projects/change-project-name?project_id=${project_uid}&new_name=${name}`
    );
  }

  static async GetAllProjectsOfType(type: number): Promise<Array<any>> {
    const data = await axios.get(
      `/api/project/get-all-projects-of-type?type=${type}`
    );
    return data.data.projects;
  }

  static async GetProjectJson(projectUid: string) {
    await axios.get(`/api/project/get-project-data?project_uid=${projectUid}`);
  }
}

export function useAvailableProjects(): [Array<FakeProject>, boolean] {
  const [isLoading, setIsLoading] = React.useState(true);

  const [projects, setProjects] = React.useState<Array<FakeProject>>([]);

  React.useEffect(() => {
    setIsLoading(true);
    ProjectsApi.getAllProjects().then((value) => {
      setProjects(value);
      setIsLoading(false);
    });
  }, []);

  return [projects, isLoading];
}

export function useAvailableProjectsByType(
  type: number
): [Array<FakeProject>, boolean] {
  const [isLoading, setIsLoading] = React.useState(true);

  const [projects, setProjects] = React.useState<Array<FakeProject>>([]);

  React.useEffect(() => {
    setIsLoading(true);
    ProjectsApi.getAllProjectsOfType(type).then((value) => {
      setProjects(value);
      setIsLoading(false);
    });
  }, []);

  return [projects, isLoading];
}
