import axios from "axios";
import {useActiveUserZus} from "../zustands/activeUserZus";

export default class AuthenticationManager {

    static async authMe() {

        let returnObject = {}

        await axios.get("/.auth/me").then(async (azureResponse) => {

            if (azureResponse.data.length !== 0) {
                const response = azureResponse.data[0]

                let auth_provider = response.provider_name
                let token = response.id_token

                returnObject["jwt"] = token

                const form = new FormData()
                form.set("provider_name", auth_provider)
                form.set("jwt", token)

                await axios.post(`/api/users/getmytypeandactivateme?provider_name=${auth_provider}`, form).then((userResponse) => {
                    const data = userResponse.data

                    returnObject["jwt"] = data["encodedToken"]

                    useActiveUserZus.setState({
                        activeUser: {
                            "name": data["name"],
                            "type": data["user_type"],
                        }
                    })
                })

            } else {

                console.error("Auth me is invalid, running in emergency mode")

                const emergencyData = await AuthenticationManager.authMeEmergency()

                returnObject["jwt"] = emergencyData["jwt"]

                useActiveUserZus.setState({
                    activeUser: {
                        "name": emergencyData["name"],
                        "type": emergencyData["user_type"],
                    }
                })
            }


        })


        return returnObject
    }


    static async authMeEmergency(): Promise<any> {

        const result = await axios.post(`/api/users/activate-emergency-mode`)

        const data = result.data

        useActiveUserZus.setState({
            activeUser: {
                "name": data["name"],
                "type": data["user_type"],
            }
        })
        return result.data
    }


}