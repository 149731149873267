import {Button, Form, ListGroup, Offcanvas, Spinner, Stack} from "react-bootstrap"
import UsersApi from "../../api/UsersApi";
import React from "react";
import {useTranslation} from "react-i18next";
import type {UserInterface} from "../../api/UserApiInterfaces";
import type {AvailableProjectItemInterface} from "../../api/ProjectsApiInterfaces";
import ProjectGroupsApi from "../../api/ProjectGroupsApi";
import {InfinityOffcanvasWrapper} from "../../components/offcanvas";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import {InfinityModal} from "../../components/modals";


let GROUPS = new Map()


export default function EditUserOffcanvas({onRefresh, userIdentifier, onHide}) {

    const [t, _] = useTranslation()

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {

        GROUPS.clear()
        ProjectGroupsApi.getAllProjectGroups().then((response) => {
            response.data.groups.forEach((value) => {
                GROUPS.set(value.uid, value)
            })
        })

        UsersApi.getUserData(userIdentifier).then((response) => {
            setUser(response.data.user)

        })
    }, [])


    return (
        <InfinityOffcanvasWrapper
            onHide={onHide}
            closeButton={true}
            header={
                <Offcanvas.Title>
                    {
                        t("core.user")
                    }
                </Offcanvas.Title>
            }
            body={
                <>

                    {
                        user ? <UserEditView user={user} onRefresh={onRefresh}/> :
                            <Spinner animation={"border"} variant={"danger"} style={{
                                margin: "auto",
                            }}/>
                    }
                </>
            }
        />
    )

}

function UserEditView({user, onRefresh}) {

    const _user: UserInterface = user


    const [t] = useTranslation()


    const [useGroup, setUserGroup] = React.useState(_user.user_group)


    const userGroupChanged = (e) => {
        const group = e.target.value
        _user.user_group = group
        setUserGroup(group)
        UsersApi.changeUserGroup(user.email, group).then(() => {
            onRefresh()
        })
    }


    return (
        <>
            <div>
                <Stack gap={3}>
                    <div>
                        <Form.Label>{t("core.name")}:</Form.Label>
                        <Form.Control type={"input"} disabled={true} value={_user.name}/>
                    </div>

                    {/*<div>*/}
                    {/*    <Form.Label>{t("core.surname")}:</Form.Label>*/}
                    {/*    <Form.Control type={"input"} disabled={true} value={_user.surname}/>*/}
                    {/*</div>*/}

                    <div>
                        <Form.Label>{t("core.email")}:</Form.Label>
                        <Form.Control type={"input"} disabled={true} value={_user.email}/>
                    </div>

                    <div>
                        <Form.Label>{t("core.usergroup")}:</Form.Label>
                        <Form.Select value={useGroup} onChange={userGroupChanged}>
                            <option value={"superadmin"}>Super admin</option>
                            <option value={"admin"}>Admin</option>
                            <option value={"user"}>User</option>
                        </Form.Select>
                    </div>

                    {_user.user_group !== "superadmin" && <>
                        <Stack gap={3}>
                            <Form.Label>{t("core.projectgroups")}:</Form.Label>
                            <_AvailableProjectGroupsView onRefresh={onRefresh} user={_user}/>

                            <_AddUserToGroupBar onRefresh={onRefresh} user={_user}/>
                        </Stack>
                    </>
                    }

                </Stack>

            </div>

        </>
    )
}


function _AddUserToGroupBar({user, onRefresh}) {


    const [t] = useTranslation()
    const usePopup = useGlobalPopup()

    const addToGroupPressed = () => {
        usePopup.pushElement(
            <_AddUserModal user={user} onRefresh={onRefresh} onHide={() => {
                usePopup.popElement()
            }
            }/>
        )
    }


    return (
        <>
            <Button
                variant={"success"}
                onClick={addToGroupPressed}
                style={{
                    margin: "auto",
                }}>
                {t("core.add")}
            </Button>

        </>
    )

}

function _AddUserModal({onHide, user, onRefresh}) {

    const [t] = useTranslation()

    const _user: UserInterface = user

    const [isLoading, setIsLoading] = React.useState(false)

    const [availableProjects, setAvailableProjects] = React.useState<Array<AvailableProjectItemInterface>>([])

    const [projectGroup, setProjectGroup] = React.useState("")

    const submit = () => {
        ProjectGroupsApi.asignUserToProjectAndGroup(_user.uid, projectGroup).then((response) => {
            onRefresh()
            onHide()
        })

    }

    const projectGroupSelected = (e) => {
        const newProject = e.target.value
        setProjectGroup(newProject)
    }

    React.useEffect(() => {
        ProjectGroupsApi.getAllProjectGroups().then((response) => {
            const groups: [] = response.data.groups

            if (groups.length > 0) {
                setProjectGroup(groups[0].uid)
            }


            setAvailableProjects(groups)
        })

    }, [])

    return (
        <InfinityModal
            onHide={onHide}
            closeButton={true}

            headerChildren={
                <></>
            }

            bodyChildren={
                <div>
                    <Stack gap={3}>

                        <div>
                            <Form.Label>User:</Form.Label>
                            <Form.Control disabled={true} value={_user.uid}/>
                        </div>

                        <div>
                            <Form.Label>Groups:</Form.Label>
                            <Form.Select value={projectGroup} onChange={projectGroupSelected}>
                                {
                                    availableProjects.map((value) => {
                                        return (
                                            <option value={value.uid} key={value.uid}>{value.name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </div>


                    </Stack>


                </div>
            }

            footerChildren={
                <Button onClick={submit}>{t("core.submit")}</Button>
            }


        />
    )
}


function _AvailableProjectGroupsView({user, onRefresh}) {

    const _user: UserInterface = user

    const [userAvailableGroups, setUserAvailableGroups] = React.useState([])

    const refreshUserAvailableProjects = () => {
        ProjectGroupsApi.getUserGroupAsignmentsByGroupNames(user.email).then((result) => {
            setUserAvailableGroups(result.data.groups)

        })
    }

    const removeUserAsignment = (groupId) => {
        ProjectGroupsApi.removeUserAsignment(_user.uid, groupId).then(() => {
            refreshUserAvailableProjects()
            onRefresh()
        })
    }

    React.useEffect(refreshUserAvailableProjects, [])

    return (
        <div>
            {
                userAvailableGroups.map((value) => {
                    return (
                        <ListGroup.Item key={value.uid}>
                            <Stack gap={3} direction={"horizontal"}>

                                <div><b>{value.name}</b></div>

                                <div style={{marginLeft: "auto"}}>

                                    <Stack direction={"horizontal"} gap={3}>

                                        <div style={{
                                            color: "red",
                                            cursor: "pointer",

                                        }} onClick={() => {
                                            removeUserAsignment(value.uid)
                                        }}>X
                                        </div>
                                    </Stack>

                                </div>

                            </Stack>
                        </ListGroup.Item>
                    )
                })
            }
        </div>
    )
}