import React from "react"
import {ButtonGroup, Card, ToggleButton, ToggleButtonGroup} from "react-bootstrap";


import "./QuizView.css"


import {TextQuestionView} from "./TextQuestionView";
import {ImageQuestionView} from "./ImageQuestionView";
import {useTranslation} from "react-i18next";
import {Quiz} from "../../../ela/quiz/Quiz";
import {QuestionBase} from "../../../ela/quiz/QuestionBase";
import {ImageQuestion} from "../../../ela/quiz/ImageQuestion";

export function QuizView({activeQuiz, onQuizFinished, project}) {

    const [activeQuestion, setActiveQuestion] = React.useState(activeQuiz.questions[0])
    const [activeQuestionIndex, setActiveQuestionIndex] = React.useState(0)
    const [isEvaluationMode, setIsEvaluationMode] = React.useState(activeQuiz.isEvaluated)

    const getActiveQuestion = () => {
        return activeQuiz.questions[activeQuestionIndex]
    }

    const activateQuestion = (questionId: number) => {
        setActiveQuestionIndex(questionId)
        setActiveQuestion(activeQuiz.questions[questionId])
    }

    const question = getActiveQuestion()

    return (
        <Card className={"QuizCard"}>
            <Card.Header>

                <h3
                    className={"QuizHeader"}
                    style={{marginTop: "15px", marginBottom: "15px"}}
                >{question.name}</h3>
            </Card.Header>

            <Card.Body className={"QuizBody"}>
                {
                    question instanceof ImageQuestion
                        ?
                        <ImageQuestionView
                            isEvaluationMode={isEvaluationMode}
                            activeQuestion={activeQuestion}
                            project={project}
                        />
                        :
                        <TextQuestionView
                            isEvaluationMode={isEvaluationMode}
                            activeQuestion={activeQuestion}
                        />
                }


            </Card.Body>
            <_QuizFooter

                activeQuiz={activeQuiz}
                activeQuestionIndex={activeQuestionIndex}
                onEvaluatePressed={() => {
                    setIsEvaluationMode(true)
                }}

                onQuizFinished={onQuizFinished}

                onQuestionActivate={activateQuestion}
                onNextPressed={() => {
                    activateQuestion(activeQuestionIndex + 1)
                }}

            />
        </Card>
    )

}


interface FooterProps {
    activeQuiz: Quiz
    onEvaluatePressed: () => void
    onQuizFinished: () => void
    onQuestionActivate: (questionIndex: number) => void
    activeQuestionIndex: number
    onNextPressed: () => void
}

function _QuizFooter({
                         activeQuiz,
                         activeQuestionIndex,
                         onEvaluatePressed,
                         onQuestionActivate,
                         onQuizFinished,
                         onNextPressed
                     }: FooterProps) {

    const [t] = useTranslation()

    const [isInEvaluation, setIsInEvaluation] = React.useState(activeQuiz.isEvaluated)

    const getVariantFromQuestion = (question: QuestionBase) => {

        let returnValue = "warning"


        if (isInEvaluation) {
            const sucRate = question.GetSuccessRate()

            if (sucRate == 1) {
                returnValue = "success"
            } else if (sucRate == 0) {
                returnValue = "danger"
            } else {
                returnValue = "warning"
            }

        } else {
            returnValue = "secondary"
        }


        return returnValue
    }


    function _get_end_button() {
        return (
            <button className={"btn btn-primary"}
                    style={{
                        margin: "5px",
                        marginLeft: "auto",
                    }}
                    onClick={() => {
                        onQuizFinished()
                    }}>
                {t("core.quiz.end")}
            </button>

        )
    }

    function _get_evaluation_button() {
        return (
            <button className={"btn btn-secondary"}
                    style={{
                        margin: "5px",
                        marginLeft: "auto",
                    }}
                    onClick={(e) => {
                        setIsInEvaluation(true)
                        onEvaluatePressed()
                    }}>
                {t("core.evaluate")}
            </button>
        )
    }

    function _get_next_button() {
        return (
            <button className={"btn btn-primary"}
                    style={{
                        margin: "5px",
                        marginLeft: "auto",
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        onNextPressed()
                    }}>

                {t("core.next")}
            </button>
        )
    }

    if (activeQuiz.questions.length > 1) {
        return (
            <Card.Footer>
                <div className={"hstack gap-0"}>
                    <ButtonGroup
                    >
                        {activeQuiz.questions.map((question: QuestionBase, i: number) => {
                                return <ToggleButton
                                    id={`${i}`}
                                    type={"radio"}
                                    variant={getVariantFromQuestion(question)}
                                    value={i}
                                    key={i}
                                    checked={i === activeQuestionIndex}
                                    onChange={(e: any) => {
                                        onQuestionActivate(Number(e.currentTarget.value))
                                    }}

                                > {i + 1}</ToggleButton>
                            }
                        )}
                    </ButtonGroup>
                    <>

                        {
                            isInEvaluation ? _get_end_button() : <>

                                {
                                    (activeQuiz.questions.length - 1 === activeQuestionIndex) ?
                                        _get_evaluation_button() : _get_next_button()
                                }


                            </>
                        }
                    </>
                </div>


            </Card.Footer>
        )
    } else {
        return isInEvaluation ? _get_end_button() : _get_evaluation_button()
    }
}


