import axios, {AxiosPromise, AxiosResponse} from "axios";
import GuidedTourRecorder from "../GuidedTourRecorder";


export class SaveQuizParams {
    project_uid = ""
    project_name = ""

    spot_uid = ""
    spot_name = ""

    board_uid = ""

    board_name = ""

    success = 0

    project_type = 0

    run_uid = ""
    user_uid = ""

    duration = 0

}

export class SaveBoardVisitParams {
    project_uid = ""
    project_name = ""

    spot_uid = ""
    spot_name = ""

    board_uid = ""
    board_name = ""

    success = 0

    run_uid = ""

}

export interface QuizRecordResponse {
    RowKey: string
    PartitionKey: string
    ProjectName: string
    BoardName: string
    ProjectUid: string
    RunUid: string
    TimeStamp: Date
    BoardUid: string
    SpotUid: string
    RecordUid: string
    Success: number
    UserUid: string

}

export interface GetBoardVisitsResponse {
    RowKey: string,
    BoardName: string,
    BoardUid: string,
    SpotName: string,
    SpotUid: string,
    Visits: number,
}

export interface UsersTrainingCountResponse {
    TrainingsCount: number
    UsersCount: number
}


function orderTrainingResults(data: Array<QuizRecordResponse>) {

    data.forEach((tmpRecord) => {
        tmpRecord.TimeStamp = new Date(`${tmpRecord.TimeStamp} UTC`)

    })
    return data.sort((a, b) => {
        return b.TimeStamp - a.TimeStamp
    })

}

export default class TourStatisticsApi {


    static async getStatsOfProject(projectUid: string = "all", trainee_uid: string = "all"): Promise<any> {
        const queryString = `/api/statistics/trainingsof?project_uid=${projectUid}&trainee_uid=${trainee_uid}`
        return (await axios.get(queryString)).data.result
    }

    static getStatRecord(recordUid: string) {
        return axios.get(`/api/statistics/record?record_uid=${recordUid}`)
    }

    static async saveGuidedTour(recorder: GuidedTourRecorder) {
        const tourDataStr = JSON.stringify(recorder.to_json_object())
        const formData = new FormData()
        formData.set("result", tourDataStr)
        const response = await axios.post("/api/statistics/save-training-result", formData)
    }

    static async saveSpotVisit(project_uid: string, spot_uid: string): Promise<any> {
        axios.put(`/api/visitors/spot-visited?spot_uid=${spot_uid}&project_uid=${project_uid}`)
    }

    static async getProjectSpotsVisits(project_uid: string): Promise<Map<string, number>> {
        const response = await axios.get(`/api/visitors/get-project-spots-visits?project_uid=${project_uid}`)

        return new Map<string, number>(Object.entries(response.data))
    }

    static async getProjectUserSpotsVisits(project_uid: string, user_uid: string): Promise<Map<string, number>> {
        const response = await axios.get(`/api/visitors/get-project-user-spots-visits?project_uid=${project_uid}&user_uid=${user_uid}`)

        return new Map<string, number>(Object.entries(response.data))

    }

    static async saveQuiz(params: SaveQuizParams, quiz_object: any): Promise<any> {
        const formData = new FormData()

        formData.append("result", JSON.stringify(quiz_object))

        axios.post(`/api/statistics/save-quiz-result`, formData, {
            params: params
        })

    }

    static async GetQuizResultsOfProjectAndUser(projectUid: string, userUid: string): Promise<Array<QuizRecordResponse>> {
        const response = await axios.get("/api/statistics/get-quiz-results", {
            params: {
                project_uid: projectUid,
                user_uid: userUid,
            }
        })
        return orderTrainingResults(response.data.result)
    }


    static async getQuizRecord(recordUid: string): Promise<any> {
        const response = await axios.get("/api/statistics/get-quiz-record", {
            params: {record_uid: recordUid}
        })

        return response.data
    }


    static async saveBoardVisit(params: SaveBoardVisitParams): Promise<any> {
        axios.put(`/api/statistics/save-board-visit`, null, {
            params: params
        })
    }

    static async getBoardVisit(project_uid: string): Promise<Array<GetBoardVisitsResponse>> {
        const response = await axios.get("/api/visitors/get-boards-visits", {
            params: {project_uid: project_uid}
        })
        return response.data
    }

    static async getAllSameQuizesWithIdentifiers(projectUid: string, spotUid: string, boardUid: string): Promise<Array<any>> {

        const response = await axios.get("/api/statistics/get-quiz-records-of", {
            params: {
                project_uid: projectUid,
                spot_uid: spotUid,
                board_uid: boardUid,
            }
        })

        return response.data
    }

    static async getTrainingCountOf(userIdentifier: string): Promise<number> {
        const response = await axios.get("/api/statistics/get-trainings-count-of")
        return response.data
    }

    static async getUsersTrainingsCount(): Promise<UsersTrainingCountResponse> {
        const response = await axios.get("/api/statistics/get-trainings-count")

        const result: UsersTrainingCountResponse = {
            TrainingsCount: 0,
            UsersCount: 0
        }
        const arrayData: Array<any> = response.data

        let reduced = 0

        arrayData.forEach((value) => {
            result.UsersCount += 1
            reduced += value.Count
        })

        result.TrainingsCount = reduced
        return result
    }

    static async getAverageSuccessRate(): Promise<number> {
        const data = (await axios.get("/api/statistics/get-average-success-rate")).data.status
        return data
    }

    static async getTotalTrainingDuration(): Promise<number> {
        return (await axios.get("/api/statistics/get-total-training-duration")).data.status
    }

    static async getAverageTrainingDuration(): Promise<number> {
        return (await axios.get("/api/statistics/get-average-training-duration")).data.status
    }

    static async getTakenQuizCountAndSuccessRate(projectType: number): Promise<{
        count: number,
        success_rate: number
    }> {
        return (await axios.get("/api/statistics/get-taken-quizzes-count-and-sucrate", {
            params: {
                project_type: projectType
            }
        })).data
    }

    static async GetAllQuestionsSuccessRate(projectType: number): Promise<number> {
        const data = await TourStatisticsApi.GetAllQuizResultsOfProjectType(projectType)

        let result = 0
        return 0
    }

    static async GetAllQuizResultsOfProjectType(projectType: number): Promise<Array<QuizRecordResponse>> {
        const response = await axios.get("/api/statistics/get-all-quiz-results-of-project-type", {
            params: {
                project_type: projectType
            }
        })

        return orderTrainingResults(response.data.result)
    }

    static async GetAllQuizResultsOfProject(projectUid: string): Promise<Array<QuizRecordResponse>> {
        const response = await axios.get("/api/statistics/get-all-quiz-results", {
            params: {
                project_uid: projectUid
            }
        })

        return orderTrainingResults(response.data.result)
    }

    static async GetAllQuizDataFilesOfProjectType(projectType: number): Promise<Array<any>> {
        const response = await axios.get("/api/statistics/get-all-quiz-data-of-project-type", {
            params: {
                project_type: projectType
            }
        })
        return response.data.status
    }

}

