import React from "react";
import BoolCheckBoxInput from "../../uitoolkit/BoolInput";
import {useGlobalAppLock} from "../../zustands/globalAppLockZus";
import StorageApi from "../../api/StorageApi";

import WebProjectProperties from "../../data/WebProjectProperties";
import {BasicMiddleSpinner} from "../../components/spinners";


export default function WebProjectPropertiesView({projectUid}) {

    const [props, setProps] = React.useState<WebProjectProperties | null>(null)

    async function refresh() {
        const tmpData = new WebProjectProperties()
        const dataStr = await StorageApi.GetFileStringSafe(projectUid, "web_config.json", "{}")
        const storageData = JSON.parse(dataStr)

        tmpData.FromJson(storageData)
        setProps(tmpData)
    }

    React.useEffect(() => {
        refresh()
    }, [projectUid])

    if (props) {
        return (
            <_WebProjectPropertiesView projectUid={projectUid} webConfig={props}/>
        )
    } else {
        return (
            <BasicMiddleSpinner/>
        )
    }

}


function _useSavePressed(projectUid: string, config: WebProjectProperties) {

    const lock = useGlobalAppLock()

    async function savePressed() {
        lock.lock()
        await StorageApi.UploadFileString(projectUid, "web_config.json", JSON.stringify(config.ToJson()))
        lock.unlock()
    }

    return savePressed
}

interface _WebProjectPropertiesViewProps {
    projectUid: string
    webConfig: WebProjectProperties
}

function _WebProjectPropertiesView({projectUid, webConfig}: _WebProjectPropertiesViewProps) {

    const save = _useSavePressed(projectUid, webConfig)

    return (
        <div className={"vstack gap-3"}>
            <BoolCheckBoxInput
                object={webConfig.quizSettings}
                label={"quiz.use.custom.id"}
                value={"custom_user_id"}
                onChange={() => {
                    console.log("Saved")
                    save()
                }}
            />
            <BoolCheckBoxInput
                object={webConfig.quizSettings}
                label={"quiz.repeat"}
                value={"repeat_enabled"}
                onChange={() => {
                    console.log("Saved")
                    save()
                }}
            />

        </div>
    )
}