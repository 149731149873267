import {World} from "../3d/world/world";

import {InfinityProject} from "./InfinityProject";

import {InfinitySpot} from "./InfinitySpot";

import {COOKIE_MANAGER} from "../CookieManager";

import {useActiveSpotZus} from "../zustands/activeSpotZus";
import {NewWorld, WorldSingleton} from "../3d/world/NewWorld";
import {generateUUID} from "three/src/math/MathUtils";
import TimeManager from "../managers/TimeManager";


export class ProjectPlayer {

    worldWrapper: NewWorld | any = null

    expectedSpot = ""
    expectedBoard = ""

    navLinkingEnabled = true

    activeSpot: InfinitySpot | any = null
    activeProject: InfinityProject | any = null


    static INSTANCE: ProjectPlayer | any = null


    worldWasLoaded(world: World) {
        this.worldWrapper = world
    }


    getWorld(): World {
        return this.worldWrapper
    }

    LoadProject(project: InfinityProject) {
        this.activeProject = project

        this.activeProject.run_token = generateUUID()

        this.activeProject.activePlayer = this

        this.worldWrapper.getWorld().reset()

        if (this.activeProject.footerEnabled) {
            this.worldWrapper.getWorld().setFooter(this.activeProject.getFooterPath())
        }

        if (this.expectedSpot != "") {

            setTimeout(() => {
                this.ActivateSpotWithId(this.expectedSpot)

                setTimeout(() => {
                    WorldSingleton.INSTANCE.resetCamera()
                }, 100)

            }, 0)
        } else {
            setTimeout(() => {
                this.ActivateWelcomeSpot()


                setTimeout(() => {
                    WorldSingleton.INSTANCE.resetCamera()
                }, 100)
            }, 0)
        }
    }


    ActivateSpotWithId(spotUid: string, ignoreHistory = false) {
        const spot = this.activeProject.spots.get(spotUid)

        COOKIE_MANAGER.addSpotToPath(this.activeProject.uid, spotUid)

        if (spot) {
            this.worldWrapper.getWorld().clearActiveContent()

            useActiveSpotZus.getState().setActiveSpot(spot)


            this.ActivateSpot(spot, "") // ignoreHistory
        } else {
            console.error(this.activeProject.name)
            console.error("Provided spot doesn't exists")
            alert("Provided link don't exists anymore")
        }
    }

    ActivateWelcomeSpot() {
        let welcomeSpot = this.activeProject.spots.get(this.activeProject.welcomeSpotUid)

        if (!welcomeSpot) {
            welcomeSpot = Array.from(this.activeProject.spots.values())[0]
        }

        COOKIE_MANAGER.addSpotToPath(this.activeProject.uid, this.activeProject.welcomeSpotUid)


        this.activeSpot = welcomeSpot
        this.ActivateSpot(this.activeSpot, "")


    }

    ActivateSpot(spot: InfinitySpot, initialBoardUid: string) {


        if (this.activeSpot) {
            const oldPanorama = this.activeSpot.GetPanoramaAsset()
            oldPanorama.is_previewed = false
            this.activeSpot.is_previewed = false
        }

        this.activeSpot = spot

        this.worldWrapper.oldRotationIndex = this.worldWrapper.activeRotationIndex
        this.worldWrapper.activeRotationIndex = this.activeSpot.spotRotation.y

        this.worldWrapper.loadSpot(this.activeSpot, initialBoardUid)

    }

}