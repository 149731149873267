import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useInterProjectSearch} from "./ipszus";
import {BasicMiddleSpinner} from "../../components/spinners";
import UniversalInterProjectSearchTable from "./new_ips_universal_table_view";
import Switch, {Case} from 'react-switch-case';
import UniversalInterProjectTilesView from "./new_ips_universal_tiles_view";

let SEARCH_TIME_OUT = null


const ByNameSearchView = React.forwardRef(({viewAs, category}, ref) => {

    const [t] = useTranslation()

    const navigate = useNavigate()

    const selectedProjects = useInterProjectSearch()

    const [visibleProjectWithResult, setVisibleProejctWithResult] = React.useState([])

    const [isSearching, setIsSearchig] = React.useState(false)

    const runSearch = (searchKey: string) => {

        const newResult = new Array()

        selectedProjects.markedProjects.forEach((value) => {
            const tmp = value.createNewOnNameAndCategory(searchKey, category)
            if (tmp) {
                newResult.push(tmp)
            }
        })
        setVisibleProejctWithResult(newResult)
    }

    const onSearchTyping = (searchKey: string) => {

        setIsSearchig(true)

        if (SEARCH_TIME_OUT) {
            clearTimeout(SEARCH_TIME_OUT)
            SEARCH_TIME_OUT = null
        }

        SEARCH_TIME_OUT = setTimeout(() => {
            runSearch(searchKey)
            setIsSearchig(false)
        }, 500)

    }
    React.useImperativeHandle(ref, () => ({
        onSearchTyping
    }))

    React.useEffect(() => {
        runSearch("")
    }, [category])

    React.useEffect(() => {
        runSearch("")
    }, [selectedProjects.markedProjects])

    if (isSearching) {
        return (
            <BasicMiddleSpinner/>
        )
    } else if (visibleProjectWithResult.length === 0) {
        return (
            <div>{t("core.no.data")}</div>
        )
    } else {
        return (
            <Switch condition={viewAs}>
                <Case value={"table"}>
                    <UniversalInterProjectSearchTable searchType={"byname"}
                                                      visibleProjectWithResult={visibleProjectWithResult}
                    />
                </Case>
                <Case value={"tiles"}>
                    <UniversalInterProjectTilesView visibleProjectWithResult={visibleProjectWithResult}
                    />
                </Case>
            </Switch>
        )
    }
})


export default ByNameSearchView
