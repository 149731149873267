import { AssetParent } from "./asset_Parent";
import * as three from "three";
import { JSONObject } from "three/examples/jsm/loaders/IFCLoader";
import urlcWithPath from "../../urlc";

export class DigitalTwinDataSectionTypesProperty {
  uid = "";
  key = "";
  lang = "";
  icon = "";
  value = null;
  unit = "";
  isStatic = true;
  externalLink = "";
  fileType = "";
  updateInterval = 1;
  externalFilterValue = "";
  color = new three.Color(0, 0, 0);

  static CreateFromJsonObject(object) {
    const tmp = new DigitalTwinDataSectionTypesProperty();

    tmp.key = object["key"];
    tmp.lang = object["name"];
    tmp.icon = object["icon"];
    tmp.value = object["value"];
    tmp.unit = object["unit"];

    tmp.isStatic = object["is_static"];
    tmp.externalLink = object["external_link"];
    tmp.fileType = object["file_type"];
    tmp.updateInterval = object["update_interval"];
    tmp.externalFilterValue = object["external_filter_value"];

    const color = object["color"];

    tmp.color.r = color.x;
    tmp.color.g = color.y;
    tmp.color.b = color.z;
    // tmp.color.a = color.w

    return tmp;
  }
}

export class DigitalTwinAsset extends AssetParent {
  folderName = "DigitalTwin";

  data: Array<DigitalTwinDataSectionTypesProperty> = new Array();

  fromJsonObject(object) {
    super.fromJsonObject(object);

    const fetchPath = urlcWithPath(
      `infinity/${this.projectUid}/DigitalTwin/${this.uid}/default.json`
    );

    fetch(fetchPath)
      .then((response) => response.json())
      .then((jResponse) => {
        const data_sections = jResponse["data_sections"];

        data_sections.forEach((data: JSONObject) => {
          this.data.push(
            DigitalTwinDataSectionTypesProperty.CreateFromJsonObject(data)
          );
        });
      });
  }
}
