import React from "react";
import * as three from "three";

import { Box, Circle, MapControls, Plane, useGLTF } from "@react-three/drei";

import { Canvas, useThree } from "@react-three/fiber";
import { AreaAsset } from "../../../ela/assets/asset_Area";
import { InfinitySpot } from "../../../infinity/InfinitySpot";
import ActiveSpotVisualization from "./ActiveSpotVisualization";
import AreaPlaneMesh from "./AreaPlaneMesh";
import LicenceManager from "../../../api/LicenceManager";
import VisitedSpotOverlay from "./VisitedSpotOverlay";
import urlcWithPath from "../../../urlc";

interface Maximap3DProps {
  activeSpot: InfinitySpot;
  spots: Array<InfinitySpot>;
  activeArea: AreaAsset;
  onSpotSelected: any;
}

interface AreaPlaneProps {
  area: AreaAsset;
}

interface AreaSpotsProps {
  spots: Array<InfinitySpot>;
  activeArea: AreaAsset;
  activeSpot: InfinitySpot;
  onSpotSelected: Function;
  viewMode: string;
  namesVisible: boolean;
  dimension: string;
}

const CAMERA_DEFAULT_HEIGHT = 25;

const AreaSpot = ({ spot, color, onSpotSelected, mesh, isActive }) => {
  const _mesh: three.Mesh = mesh;

  const [hover, setHover] = React.useState(false);

  const { gl } = useThree();

  const position = [spot.area_pos_x, 0, spot.area_pos_z];

  const pointScale = spot.area_scale;

  const twins = spot.getBoardsOfType("DigitalTwinBoard", "");

  const hovered = () => {
    setHover(true);
    gl.domElement.style.cursor = "pointer";
  };

  const unhovered = () => {
    setHover(false);
    gl.domElement.style.cursor = "grab";
  };

  return (
    <group position={position}>
      {isActive && (
        <ActiveSpotVisualization pointScale={pointScale} color={color} />
      )}

      <group scale={pointScale}>
        <primitive object={_mesh} />

        {LicenceManager.f360.visited_spot_in_map && spot.wasVisited && (
          <VisitedSpotOverlay />
        )}

        <Box
          position={[0, 0.5, 0]}
          scale={[0.7, 1, 0.7]}
          onClick={() => {
            onSpotSelected(spot);
          }}
          visible={false}
          onPointerOver={hovered}
          onPointerOut={unhovered}
        />
      </group>
    </group>
  );
};

const AreaSpots = ({
  spots,
  dimension,
  activeArea,
  activeSpot,
  onSpotSelected,
}: AreaSpotsProps) => {
  const { scene, materials } = useGLTF(urlcWithPath(`spot2D.glb`));

  React.useEffect(() => {
    const tmp = activeArea.spots_color;

    Object.entries(materials).forEach(([key, material]) => {
      material.color = {
        r: tmp.x,
        g: tmp.y,
        b: tmp.z,
      };
    });
  });

  return (
    <group>
      {spots.map((value: InfinitySpot, index) => {
        if (value.areaId === activeArea.uid) {
          return (
            <React.Suspense fallback={null} key={index}>
              <AreaSpot
                key={value.uid}
                spot={value}
                dimension={dimension}
                onSpotSelected={onSpotSelected}
                mesh={scene.clone(true)}
                isActive={activeSpot === value}
                color={
                  new three.Color(
                    activeArea.spots_color_selected.x,
                    activeArea.spots_color_selected.y,
                    activeArea.spots_color_selected.z
                  )
                }
              />
            </React.Suspense>
          );
        }
      })}
    </group>
  );
};

function _Controls({ activeArea, activeSpot }) {
  const ref = React.useRef();

  const _area: AreaAsset = activeArea;
  const _spot: InfinitySpot = activeSpot;

  const { camera } = useThree();

  React.useEffect(() => {
    const mapControls: MapControls = ref.current;

    camera.position.set(_spot.area_pos_x, camera.position.y, _spot.area_pos_z);
    mapControls.target.set(_spot.area_pos_x, 0, _spot.area_pos_z);
    mapControls.update();
  }, [activeSpot]);

  return (
    <>
      <MapControls
        ref={ref}
        makeDefault
        enableRotate={true}
        minPolarAngle={0}
        maxPolarAngle={0}
        enableDamping={false}
      />
    </>
  );
}

const MapWorld = ({
  activeArea,
  activeSpot,
  spots,
  onSpotSelected,
}: Maximap3DProps) => {
  return (
    <>
      <_Controls activeArea={activeArea} activeSpot={activeSpot} />
      <ambientLight intensity={1} />

      {/*<pointLight position={[10, 10, 10]}/>*/}
      {/*<Box position={[-1.2, 0, 0]}/>*/}
      {/*<Box position={[1.2, 0, 0]}/>*/}

      <React.Suspense fallback={""}>
        {activeArea.fileExists && (
          <AreaPlaneMesh
            area={activeArea}
            texture={activeArea.getMaximapPath()}
          />
        )}
      </React.Suspense>

      <AreaSpots
        spots={spots}
        activeArea={activeArea}
        activeSpot={activeSpot}
        onSpotSelected={onSpotSelected}
      />
    </>
  );
};

export function Minimap3D({
  activeArea,
  activeSpot,
  spots,
  onSpotSelected,
}: Maximap3DProps) {
  const _area: AreaAsset = activeArea;
  const _spot: InfinitySpot = activeSpot;

  //camera={{position: [_spot.area_pos_x, 50, _spot.area_pos_z]}}

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Canvas
        camera={{
          position: [0, CAMERA_DEFAULT_HEIGHT, -_area.area_pose.z * 0.5],
        }}
      >
        <MapWorld
          activeSpot={activeSpot}
          spots={spots}
          activeArea={activeArea}
          onSpotSelected={onSpotSelected}
        />
      </Canvas>
    </div>
  );
}
