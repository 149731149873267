import React from "react";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import ProjectsView from "./routes/ProjectsView";
import {
  OnlyAdminView,
  OnlySuperAdminView,
} from "./components/user_dispatcher";
import ProjectsAdminViewWrapper from "./routes/ProjectsAdminView";
import SearchView from "./routes/inter_project_search_new/SearchView";
import UserView from "./routes/UserView";
import StatisticsView from "./routes/StatisticsView";
import TrainingPrintView from "./routes/statistics/TrainingPrintView";
import MountProjectWrapper from "./components/MountProjectWrapper";
import WorldPlayer from "./WorldPlayer";

export default function AppRoutes({}) {
  return (
      <Routes>
        {/*<Route path={"/"}><HomeContent/></Route>*/}
        <Route path={"/"} element={<ProjectsView />} />
        <Route path={"/projects"} element={<ProjectsView />} />

        <Route
          path={"/adminprojects"}
          element={
            <OnlyAdminView>
              {" "}
              <ProjectsAdminViewWrapper />
            </OnlyAdminView>
          }
        />

        <Route path={"/search"} element={<SearchView />} />

        <Route
          path={"/users"}
          element={
            <OnlySuperAdminView>
              <UserView />
            </OnlySuperAdminView>
          }
        />

        <Route
          path={"/statistics"}
          element={
            <OnlyAdminView>
              <StatisticsView />
            </OnlyAdminView>
          }
        />

        <Route
          path={"/project/:projectid"}
          element={<_ProjectPlayerWrapper />}
        />

        <Route
          path={"/statistics/tourrecord/:recorduid"}
          element={<TrainingPrintView />}
        />
      </Routes>
  );
}

function _ProjectPlayerWrapper() {
  const searchParams = new URLSearchParams(useLocation().search);

  return (
    <MountProjectWrapper>
      <WorldPlayer searchParams={searchParams} />
    </MountProjectWrapper>
  );
}
