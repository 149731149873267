import React from "react";
import {useTranslation} from "react-i18next";
import DashBoardTile from "../../components/dashboard/DashBoardTile";
import {OnlySpinner} from "../../components/spinners";

import GlobalQuizzesDashBoard from "./dashboards/GlobalQuizzesDashBoard";
import ByProjectTypesList from "./ByProjectTypesList";
import {INFINITY_PROJECT_TYPES} from "../../infinity/InfinityProject";
import ProjectsApi from "../../api/ProjectsApi";
import VisitorsStatisticsApi from "../../api/VisitorsStatisticsApi";
import LicenceApi from "../../api/LicenceApi";
import LicenceManager from "../../api/LicenceManager";


export default function GlobalToursView() {

    return (
        <div>

            <div className="row">
                <div className="col">
                    <_TotalToursCount/>
                </div>

                <div className="col">
                    <_TotalVisitsView/>
                </div>

                <div className="col"/>
            </div>

            <ByProjectTypesList projectType={INFINITY_PROJECT_TYPES.BASIC}/>


            {
                LicenceManager.f360.quizzes_monitoring &&
                <GlobalQuizzesDashBoard projectType={INFINITY_PROJECT_TYPES.BASIC}/>
            }


        </div>
    )
}

function _TotalToursCount() {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState(0)

    React.useEffect(() => {

        setIsLoading(true)

        ProjectsApi.GetAllProjectsOfType(INFINITY_PROJECT_TYPES.BASIC).then((value) => {
            setValue(value.length)
            setIsLoading(false)
        })

    }, [])

    return (
        <DashBoardTile style={{height: "100px"}}>
            <div className={"noWrap"}>{t("core.projects.count")}:</div>

            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{value}</h3>
            }
        </DashBoardTile>
    )
}

function _TotalVisitsView() {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState(0)

    React.useEffect(() => {

        setIsLoading(true)

        VisitorsStatisticsApi.GetAllVisitsOfProjectOfType(INFINITY_PROJECT_TYPES.BASIC).then((value) => {
            setValue(value)
            setIsLoading(false)
        })

    }, [])


    return (
        <DashBoardTile style={{height: "100px"}}>
            <div className={"noWrap"}>{t("core.total.visits")}:</div>

            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{value}</h3>
            }
        </DashBoardTile>
    )
}
