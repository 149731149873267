import React from "react";

export default class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {error: ""};
    }

    componentDidCatch(error) {
        this.setState({error: `${error.name}: ${error.message}`});
    }

    render(): any {
        const {error} = this.state as any;

        if (error) {
            return (
                <_ErrorView/>
            );
        } else {
            return <>{this.props.children}</>;
        }
    }
}

function _ErrorView({error = ""}): any {
    return (
        <div style={{
            position: "fixed",
            left: "0px",
            right: "0px",
            width: "100%",
            height: "100%",
            background: "#272730",
        }}>
            <div style={{
                color: "white",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%)"
            }}>
                Opps, something went wrong, restart the app
            </div>
            {error}
        </div>
    )
}