import create from "zustand"


interface LayoutZus {
    useExperimentalLayout: boolean
    setUseExperimentalLayout: (value: boolean) => void
}


export const useLayoutZus = create<LayoutZus>((set) => ({
    useExperimentalLayout: false,
    setUseExperimentalLayout: (status: boolean) => set((newState) => ({useExperimentalLayout: status})),
}))

