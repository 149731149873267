import React from "react";
import {GuidedTourManager, useActiveTestTour} from "../../zustands/activeTestTourZus";

import {InfinityModal} from "../../components/modals";
import {GuidedTaskRecord} from "../../GuidedTourRecorder";

import {useTranslation} from "react-i18next";

import {showSubmitTaskTourResult} from "./TaskTourResultView";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import TaskPlayModal from "./TaskPlayModal";
import urlcWithPath from "../../urlc";
import {useActiveProject} from "../../project/project_zustands/activeProjectZus";


export default function TaskTourHud({}) {
    const tourZus = useActiveTestTour()
    const popup = useGlobalPopup()


    const submited = () => {
        window.location.replace("/")
    }

    const endPressed = () => {
        tourZus.tourRecorded.end()
        showSubmitTaskTourResult(submited)
    }


    const _showTaskPopup = () => {

        const __hidePopup = () => {
            popup.popElement()
        }

        popup.pushElement(
            <TaskPlayModal
                onHide={__hidePopup}
                index={tourZus.tourRecorded.activeRecordIndex}
                onPlayPressed={__hidePopup}
            />
        )
    }

    const skipPressed = () => {
        GuidedTourManager.nextTaskClicked()
        _showTaskPopup()
    }

    const nextPressed = () => {
        GuidedTourManager.nextTaskClicked()
        _showTaskPopup()
    }


    const activeRecordIndex = tourZus.tourRecorded.activeRecordIndex
    const activeRecord = tourZus.tourRecorded.records[activeRecordIndex]

    const isLast = (tourZus.tourRecorded.records.length - 1) === activeRecordIndex


    if (tourZus.task) {
        return (
            <_TaskView
                isLast={isLast}
                task={activeRecord}
                onNext={nextPressed}
                onSkip={skipPressed}
                onFinish={endPressed}
            />
        )
    } else {
        return (<></>)
    }

}

interface _TaskViewProps {
    task: GuidedTaskRecord
    onNext: () => void
    onFinish: () => void
    onSkip: () => void
    isLast: boolean
}

function _TaskView({
                       task,
                       onNext,
                       onSkip,
                       onFinish,
                       isLast
                   }: _TaskViewProps) {

    const projectZus = useActiveProject()

    const [t] = useTranslation()

    const [showPreview, setShowPreview] = React.useState(false)

    const _title = task.header.replaceAll("\n", "<br/>")

    const percentage = task.getPercentage() * 100

    const isCompleted = task.foundBoards === task.expectedBoards

    const FONT_COLOR = isCompleted ? "lightgreen" : "white"

    const FONT_SIZE = "12pt"

    const SKIP_ENABLED = projectZus.project.task_tour_configuration.skip_enabled ?? true
    const AUTO_NEXT = projectZus.project.task_tour_configuration.auto_next_task ?? false

    React.useEffect(() => {
        if (isCompleted && AUTO_NEXT && (isLast === false)) {
            onNext()
        }
    })


    return (
        <>
            <div style={{
                position: "absolute",
                left: "10px",
                top: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                borderRadius: "15px",
                //To make object in middle
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "auto",
                minWidth: "350pt",
                padding: "30px",
                paddingTop: "5px",
                paddingBottom: "10px",

            }}

            >

                <div className={"vstack gap-0"}>


                    <div className={"hstack gap-2"}>
                        <img src={urlcWithPath("i-icons/arrow-down-white.svg")} style={{
                            height: "15pt",
                            cursor: "pointer"
                        }}
                             onClick={() => {
                                 setShowPreview(true)
                             }}
                        />

                        <div style={{
                            color: FONT_COLOR,
                            fontSize: FONT_SIZE,

                        }}
                             dangerouslySetInnerHTML={{__html: _title}}
                        />

                        <div style={{
                            color: FONT_COLOR,
                            fontSize: FONT_SIZE
                        }}>
                            ({task.foundBoards}/{task.expectedBoards})
                        </div>


                        <div style={{
                            borderRadius: "5px",
                            color: "white",
                            fontSize: FONT_SIZE,
                            marginLeft: "auto",
                            cursor: "pointer"
                        }}
                             onClick={() => {
                                 if (isLast) {
                                     onFinish()
                                 } else {
                                     if (isCompleted) {
                                         onNext()
                                     } else {
                                         onSkip()
                                     }
                                 }

                             }}
                        >

                            {
                                (() => {
                                    if (isLast) {
                                        if (isCompleted) {
                                            return (<u style={{fontSize: FONT_SIZE}}>{t("core.submit")}</u>)
                                        } else {
                                            if (SKIP_ENABLED) {
                                                return (<u style={{fontSize: FONT_SIZE}}>{t("core.finish")}</u>)
                                            }
                                        }
                                    } else {

                                        if (isCompleted) {
                                            return (
                                                <u style={{fontSize: FONT_SIZE}}>{">>"} {t("core.next")}</u>
                                            )
                                        } else {
                                            if (SKIP_ENABLED) {
                                                return (
                                                    <u style={{fontSize: FONT_SIZE}}>{">>"} {t("core.skip")}</u>)
                                            } else {
                                                return <div/>
                                            }
                                        }
                                    }
                                })()
                            }

                        </div>


                    </div>

                    <div className="progress" style={{
                        height: "3pt",
                        marginTop: "8pt"
                    }}>
                        <div className="progress-bar bg-success"
                             role="progressbar" aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                             style={{
                                 width: `${percentage}%`,
                                 color: "black",
                             }}/>
                    </div>

                </div>


            </div>


            {
                showPreview &&
                <_ActiveTaskPreview task={task} onHide={() => setShowPreview(false)}/>
            }
        </>
    )
}

function _ActiveTaskPreview({
                                task, onHide
                            }) {

    const _title = task.header.replaceAll("\n", "<br/>")
    const _body = task.body.replaceAll("\n", "<br/>")


    return (
        <InfinityModal
            closeButton={true}
            onHide={onHide}
            headerChildren={<div dangerouslySetInnerHTML={{__html: _title}}/>}
            bodyChildren={<div dangerouslySetInnerHTML={{__html: _body}}/>}
            footerChildren={<>

            </>}
        />
    )

}