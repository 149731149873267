import * as three from "three"

import {XRControllerModelFactory} from "three/examples/jsm/webxr/XRControllerModelFactory";

import {MyVRButton} from "./MyVRButton";

import vrPointer from "../resources/vrPointer.png"
import lineTexture from "./guiclickertexture.png"
import DeviceManager from "../../deviceManager";

class VrAvatarHand {

    isTriggerPressed = false

    constructor(index, renderer: three.WebGLRenderer, group: three.Group, intersectGroup) {
        const controllerModelFactory = new XRControllerModelFactory()

        const controllerGrip = renderer.xr.getControllerGrip(index)

        const model = controllerModelFactory.createControllerModel(controllerGrip)
        model.visible = false
        controllerGrip.add(model)

        controllerGrip.addEventListener("selectstart", this.onTriggerPressed)
        controllerGrip.addEventListener("selectend", this.onTriggerUnpressed)
        controllerGrip.addEventListener("squeezestart", this.onGripPressed)
        controllerGrip.addEventListener("squeezeend", this.onGripUnpressed)

        this.intersectGroup = intersectGroup

        this.controller = controllerGrip

        group.add(controllerGrip);

        this.rayLineGroup = new three.Group()

        const lineImage = new three.TextureLoader().load(lineTexture)
        lineImage.encoding = three.sRGBEncoding

        const geometry = new three.PlaneGeometry(0.01, 1)

        this.rayLineMaterial = new three.MeshStandardMaterial({
            transparent: true,
            map: lineImage,
            side: three.DoubleSide
        })

        const rayLine = new three.Mesh(geometry, this.rayLineMaterial)
        rayLine.name = "RaycasterLine" + index
        rayLine.position.set(0, 0.5, 0)
        rayLine.rotateY(Math.PI / 2)

        const lineSubGroup = new three.Group()
        lineSubGroup.add(rayLine)
        lineSubGroup.rotateX(Math.PI / 2)
        lineSubGroup.rotateY((Math.PI / 4))

        //const controller = renderer.xr.getController(index)

        const spriteTexture = new three.TextureLoader().load(vrPointer)

        const spriteMaterial = new three.SpriteMaterial({map: spriteTexture})
        this.controllerSprite = new three.Sprite(spriteMaterial)
        this.controllerSprite.position.set(0, 0, 100)
        this.controllerSprite.scale.set(15, 15, 1)

        this.rayLineGroup.add(lineSubGroup)
        this.rayLineGroup.add(this.controllerSprite)

        // const xRotationValue = Math.PI - Math.PI / 16
        //
        // if (index == 0) {
        //     this.rayLineGroup.rotateX(xRotationValue)
        // } else {
        //     this.rayLineGroup.rotateX(-xRotationValue)
        // }
        //
        // this.rayLineGroup.rotateZ(three.MathUtils.degToRad(-60))
        // this.rayLineGroup.rotateY(three.MathUtils.degToRad(-45))
        this.rayLineGroup.rotateX(Math.PI / 2)

        controllerGrip.add(this.rayLineGroup)

        // lineSubGroup.add(new three.AxesHelper(1))


        this.selectedObject = null

        this.rayCaster = new three.Raycaster()


    }

    step() {
        this.rayCast()
    }

    onTriggerPressed = () => {
        this.isTriggerPressed = true

        this.setRayColor("lightblue")
        if (this.selectedObject) {
            this.selectedObject.clicked()
        }
    }

    onTriggerUnpressed = () => {
        this.isTriggerPressed = false
        this.setRayColor("white")
    }

    onGripPressed = () => {

    }

    onGripUnpressed = () => {

    }

    rayCast = () => {
        const direction = new three.Vector3()
        this.rayLineGroup.getWorldDirection(direction)


        this.rayCaster.set(this.rayLineGroup.position, direction)

        const intersects = this.rayCaster.intersectObject(this.intersectGroup, true)

        if (intersects.length > 0) {
            const res = intersects.filter(function (res) {
                return res && res.object;
            })[0]

            if (res && res.object) {
                let component: any = res.object.userData["handler"]

                if (component) {
                    if (this.selectedObject === component) {
                        return
                    } else if (this.selectedObject === null) {
                        this.onSelectStart()
                        this.selectedObject = component
                        this.selectedObject.setSelected()
                    } else {
                        this.selectedObject.setDeselected()
                        this.selectedObject = component
                        this.selectedObject.setSelected()
                    }
                }
            } else {
                this.selectedObject = null
                this.onSelectEnd()
            }

        } else {
            if (this.selectedObject != null) {
                this.selectedObject.setDeselected()
                this.selectedObject = null
            }

            this.onSelectEnd()
            this.selectedObject = null

        }

    }


    setRayColor = (colorString: string) => {
        const color = new three.Color(colorString)
        this.rayLineMaterial.color = color
        this.controllerSprite.material.color = color
    }

    onSelectStart = () => {
        if (this.isTriggerPressed === false) {
            this.setRayColor("lightgreen")
        }
    }

    onSelectEnd = () => {
        if (this.isTriggerPressed === false) {
            this.setRayColor("white")
        }
    }


}


export default class VrAvatar {

    isLoaded = false
    teleportEnabled = true

    avatarGroup = new three.Group()

    leftHand: VrAvatarHand = null
    rightHand: VrAvatarHand = null


    //teleportacia link https://immersive-web.github.io/webxr-samples/teleportation.html
    //teleportacia link https://medium.com/@darktears/adding-support-for-vr-inputs-with-webxr-and-three-js-235b40beb6f0
    constructor(renderer: three.WebGLRenderer, group: three.Group, intersectGroup: three.Group) {

        if ('xr' in navigator) {

            const enabled = (DeviceManager.isLowPerformance() == false)

            if (enabled) {
                navigator.xr.isSessionSupported('immersive-vr').then((supported) => {

                    if (supported) {
                        renderer.xr.enabled = true;
                        document.body.appendChild(MyVRButton.createButton(renderer));
                        group.add(this.avatarGroup)

                        this.leftHand = new VrAvatarHand(0, renderer, this.avatarGroup, intersectGroup)
                        this.rightHand = new VrAvatarHand(1, renderer, this.avatarGroup, intersectGroup)

                        this.isLoaded = true

                    } else {
                        console.log("Vr found, not running VR avatar")
                    }


                });
            }


        }


    }

    step() {
        if (this.isLoaded) {
            //this.leftHand.step()
            this.rightHand.step()
        }

    }


}

