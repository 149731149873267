import React from "react";

import {useTranslation} from "react-i18next";

import {Form} from "react-bootstrap";
import useCookie from "react-use-cookie";


export default function LanguageSelectionView({}) {

    const [t, tInsntance] = useTranslation()

    const [language, setLanguage] = useCookie("language", tInsntance.language)

    const langChanged = (e) => {
        const value = e.target.value
        tInsntance.changeLanguage(value)
        setLanguage(value)
    }

    return (

        <div>
            <Form.Text>{t("core.language")}:</Form.Text>
            <Form.Select value={language} onChange={langChanged}>
                <option value={"en"}>En</option>
                <option value={"sk"}>Sk</option>
                <option value={"cz"}>Cz</option>
                {/*<option value={"cz"}>Cz</option>*/}
            </Form.Select>
        </div>
    )
}

