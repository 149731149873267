import {AssetParent} from "./asset_Parent";


export class OutlineAsset extends AssetParent {
    folderName = "Outlines"
    vertices = new Array<number>()

    fromJsonObject(object: any) {
        super.fromJsonObject(object)

        object["vertices"].forEach((vrtx) => {
            this.vertices.push(vrtx)
            // this.vertices.push(vrtx["x"])
            // this.vertices.push(vrtx["y"])
            // this.vertices.push(vrtx["z"])

        })

    }

}