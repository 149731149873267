import React from "react";


import create from "zustand"


interface ActiveUserInterface {
    name: string;
    type: string;
    uid: string;
}

const initialUser: ActiveUserInterface = {
    name: "",
    type: "",
    uid: "",
}

interface ActiveUserZus {
    activeUser: ActiveUserInterface,
    setActiveUser: (user: any) => void
}

export const useActiveUserZus = create<ActiveUserZus>((set) => ({
    activeUser: initialUser,
    setActiveUser: (user: ActiveUserInterface) => set((newState) => ({activeUser: user})),
}))

