import axios from "axios";
import AppBackendManager from "./AppBackendManager";
import urlcWithPath from "../urlc";

export default class LicenceApi {
  static async getLicence(): Promise<any> {
    console.log(AppBackendManager.isBackendAvailable);

    if (AppBackendManager.isBackendAvailable) {
      const response = await axios.get("/api/licences/get-licence");
      return response.data;
    } else {
      const response = await axios.get(urlcWithPath("license.json"));
      return response.data;
    }
  }
}
