import React from 'react';
import * as Bs from "react-bootstrap"
import {CloseButton} from "../../icon_click_buttons";
import AppBackendManager from "../../../api/AppBackendManager";

export function DocumentPlayer({src, onClose}) {

    const wrapperStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",

    }

    const documentStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "50%",
        // top: "2.5%",
        transform: "translate(-50%, 0%)",
        // background: "red",
        borderRadius: "15px",
    }

    const closeBtnStyle = {
        position: "absolute",
        top: "0px",
        right: "0px",
    }

    const SRC = AppBackendManager.isBackendAvailable ? `${src}&embedded=true` : `${src}?embedded=true`

    return (
        <div style={wrapperStyle}>
            <Bs.Container style={documentStyle}>
                {/*<embed src={src + "#toolbar=0"}/>*/}
                <iframe src={SRC}
                        style={{width: "100%", height: "95%", marginTop: "2.5%"}}
                        type={"application/pdf"}
                />

            </Bs.Container>
            <CloseButton style={closeBtnStyle} onClick={onClose}/>
        </div>


    )


}
