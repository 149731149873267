import {useTranslation} from "react-i18next";

import {Card, Spinner} from "react-bootstrap";
import React from "react";

import axios from "axios";
import ProjectPropertiesOffCanvas from "./ProjectPropertiesOffCanvas";
import RemoveProjectModal from "./RemoveProjectModal";
import {useNavigate} from "react-router-dom";
import {FakeProject} from "../inter_project_search_new/lightdata";
import {useGlobalPopup} from "../../zustands/globalPopupZus";

import IGui from "../../components/IGui";

export default function ProjectAdminThumbnail({
                                                  icon,
                                                  fakeProject,
                                                  activeGroup,
                                                  route,
                                                  beeingDeleted
                                              }) {

    const _fakeProject: FakeProject = fakeProject

    const imageStyle = {
        objectFit: "contain",
        marginTop: "5px",
        marginLeft: "auto",
        marginRight: "auto",
    }

    const navigate = useNavigate()

    const [t, _] = useTranslation()

    const globalPopupZus = useGlobalPopup()

    const setRemoveProjectContent = () => {

        globalPopupZus.pushElement(<RemoveProjectModal projectName={_fakeProject.name} projectId={_fakeProject.uid}
                                                       onHide={() => {
                                                           globalPopupZus.popElement()
                                                       }}/>
        )

    }

    const setAnalysisContent = () => {


        globalPopupZus.pushElement(<ProjectPropertiesOffCanvas
            fakeProject={fakeProject}
            activeGroup={activeGroup}
            onHide={() => {
                globalPopupZus.popElement()
            }}/>
        )


    }

    const openClicked = () => {
        navigate(route)
    }

    React.useEffect(() => {
        if (beeingDeleted) {
            axios.get(`/checkdeletingstatus/${_fakeProject.uid}`).then((response) => {
                console.log(response.data.status)
            })
        }
    }, [])


    const cardMediaProps = {loading: "lazy"} as any

    return (
        <>
            <IGui.Card>

                <IGui.CardMedia
                    sx={{height: 140}}
                    {...cardMediaProps}
                    image={icon}
                />

                <IGui.CardContent>
                    <IGui.Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        {_fakeProject.name}
                    </IGui.Typography>

                    {
                        beeingDeleted && <>
                            <Spinner variant={"danger"} animation="border" style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%"
                            }}/>
                        </>
                    }

                </IGui.CardContent>

                <IGui.CardActions>
                    <IGui.Button label={"core.open"} onClick={openClicked}/>
                    <IGui.Button label={"core.properties"} onClick={setAnalysisContent}/>
                    <IGui.Button label={"core.remove"} color={"error"} onClick={setRemoveProjectContent}/>
                </IGui.CardActions>
            </IGui.Card>


        </>
    )
}


