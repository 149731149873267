import React from "react";

import {useTranslation} from "react-i18next";

import {StatisticsViewHeader, TrainingsViewFiltering} from "./TrainingsViewFiltering";

import TourStatisticsApi from "../../api/TourStatisticsApi";


import {OnlyAdminView} from "../../components/user_dispatcher";

import {useNavigate} from "react-router-dom";


import {showYesNoModal} from "../../components/AreYouSurePopup";
import MyTable from "../../components/mytable";
import {FakeProject} from "../inter_project_search_new/lightdata";

import DashBoardTile, {DashBoardTileNoMiddle} from "../../components/dashboard/DashBoardTile";
import {BasicMiddleSpinner, OnlySpinner} from "../../components/spinners";
import TimeManager from "../../managers/TimeManager";
import {useAvailableProjectsZus} from "../../zustands/availableProjectsZus";
import {useAvailableProjects} from "../../api/ProjectsApi";
import useCookie from "react-use-cookie";
import {INFINITY_PROJECT_TYPES} from "../../infinity/InfinityProject";


export default function IndividualTrainingsView() {

    const [projectsTmp, isLoading] = useAvailableProjects()

    const projects = projectsTmp.filter((project) => {
        return project.ProjectType === INFINITY_PROJECT_TYPES.GUIDED
    })

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        if (projects.length > 0) {
            return (<_IndividualTrainingsView project={projects}/>)
        } else {
            return (<div>No project available</div>)
        }
    }

}


interface _IndividualTrainingsView {
    project: Array<FakeProject>
}

function _IndividualTrainingsView({project}: _IndividualTrainingsView) {

    const [t] = useTranslation()

    const projectsZus = useAvailableProjectsZus()

    const [isLoading, setIsLoading] = React.useState(true)

    const [statisticsRecords, setStatisticsRecords] = React.useState([])

    const [activeProject, setActiveProject] = React.useState<FakeProject>(project[0])

    const [activeUser, setActiveUser] = useCookie("individual-trainings-last-selected-user", "")

    const [viewAs, setViewAs] = React.useState("table")

    const refreshTrainings = () => {

        setIsLoading(true)

        const userFetch = activeUser !== "" ? activeUser : "all"

        TourStatisticsApi.getStatsOfProject(activeProject.uid, userFetch).then((result) => {
            setStatisticsRecords(result)
            setIsLoading(false)
        })
    }

    const resetFilters = () => {
        setActiveUser("")
    }

    React.useEffect(refreshTrainings, [activeProject, activeUser])

    return (
        <div className={"vstack gap-3"}>

            <div style={{marginLeft: "auto", marginRight: "auto"}}>

                <DashBoardTileNoMiddle>
                    <TrainingsViewFiltering
                        onFiltersReset={resetFilters}
                        activeProject={activeProject}
                        setActiveProject={setActiveProject}
                        activeUser={activeUser}
                        setActiveUser={setActiveUser}
                        viewAs={viewAs}
                        setViewAs={setViewAs}
                    />
                </DashBoardTileNoMiddle>


            </div>

            {
                isLoading ? <DashBoardTile><OnlySpinner/></DashBoardTile> :
                    <_StatisticsTableView statisticsRecords={statisticsRecords} refreshTrainings={refreshTrainings}/>
            }

        </div>
    )
}


function _StatisticsTableView({statisticsRecords, refreshTrainings}) {
    return (
        <MyTable>
            <StatisticsViewHeader/>

            <tbody>
            {
                statisticsRecords.map((value, index) => {
                    return (
                        <_StatisticsRowItem
                            key={value.uid}
                            onRefresh={refreshTrainings}
                            statsResult={value}/>
                    )
                })
            }
            </tbody>

        </MyTable>
    )
}

function _StatisticsRowItem({statsResult, onRefresh}) {

    const [t] = useTranslation()

    const navigate = useNavigate()


    const removeConfirmed = () => {
        TourStatisticsApi.removeStatisticsItem(statsResult.uid).then((result) => {
            onRefresh()
        })
    }

    const removePressed = () => {

        showYesNoModal(null, `${t("core.remove.training.stat")}: <b>${statsResult.datetime_string}</b> - ${statsResult.trainee_name}`, removeConfirmed)

    }

    const openPressed = () => {
        navigate(`/statistics/tourrecord/${statsResult.uid}`)
    }

    return (
        <>
            <tr>
                <td>{TimeManager.convertPureUTCDateToLocalDate(statsResult.datetime_string).toLocaleString()}</td>
                <td>{statsResult.trainee_name}</td>
                {/*<td>{statsResult.trainer_identifier}</td>*/}
                <td>{formatPercentage(statsResult.success_rate)}</td>

                <td>
                    <div className={"hstack gap-2"}>

                        <button
                            className={"btn btn-outline-primary btn-sm"}
                            onClick={openPressed}
                        >
                            {t("core.open")}
                        </button>


                        <OnlyAdminView>
                            <button
                                className={"btn btn-outline-danger btn-sm"}
                                onClick={removePressed}
                            >
                                {t("core.remove")}
                            </button>
                        </OnlyAdminView>
                    </div>

                </td>
            </tr>
        </>
    )
}

const formatPercentage = (value) => {
    return (value * 100).toString().substring(0, 6) + "%"
}

function _buttonActiveView(identifier: string, expectedIdentifier: string): string {
    if (identifier === expectedIdentifier) {
        return "btn btn-primary"
    } else {
        return "btn btn-outline-primary"
    }

}