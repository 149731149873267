import React from "react";

import LicenceManager from "../../api/LicenceManager";
import {useTranslation} from "react-i18next";
import {Image, Stack} from "react-bootstrap";
import RequestLicenseModal from "./RequestLicenseModal";
import urlcWithPath from "../../urlc";

export default function ValidLicenceWrapper({children}) {


    if (LicenceManager.licenseValid) {
        return (
            children
        )
    } else {
        return (
            <>
                {
                    children
                }

                <div style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.50)",
                    zIndex: 150,
                    left: "0px",
                    top: "0px",
                    // pointerEvents: "none"
                }}>
                    <div>
                        <_InvalidLicenseOverlay/>
                    </div>
                </div>

            </>
        )
    }

}

function _InvalidLicenseOverlay() {

    const [t] = useTranslation()

    const [modalVisible, setModalVisible] = React.useState(false)

    return (
        <div style={{
            position: "absolute",
            left: "50%",
            top: "50%"
        }}>
            <Stack gap={3}>
                <Image
                    src={urlcWithPath("i-icons/invalidlicense.svg")}
                    style={{marginLeft: "auto", marginRight: "auto"}}
                />

                <div style={{color: "white"}}>
                    {
                        t("core.handlers.invalid")
                    }
                </div>
                <button className={"btn btn-primary"} onClick={() => setModalVisible(true)}>
                    {t("core.handlers.request")}
                </button>
            </Stack>


            {
                modalVisible &&
                <RequestLicenseModal show={modalVisible} onHide={() => setModalVisible(false)}/>
            }

        </div>
    )
}

export function Editor360Only({children}) {

    if (LicenceManager.editor360Valid) {
        return (
            <>
                {
                    React.Children.toArray(children)
                }
            </>
        )
    } else {
        return (<></>)
    }

}

export function EditorArOnly({children}) {

    if (LicenceManager.editorArValid) {
        return (
            <>
                {
                    React.Children.toArray(children)
                }
            </>
        )
    } else {
        return (<></>)
    }

}


export function AnyEditor({children}) {

    if (LicenceManager.anyEditorValid) {
        return (
            <>
                {
                    React.Children.toArray(children)
                }
            </>
        )
    } else {
        return (<></>)
    }

}
