import React from "react";
import {Button, Form, Offcanvas, Stack} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ProjectsApi from "../../api/ProjectsApi";
import {useGlobalAppLock} from "../../zustands/globalAppLockZus";
import {useAvailableProjectGroups} from "../../zustands/projectGroupsZus";
import ProjectPropertiesTable from "./ProjectPropertiesTable";
import {FakeProject} from "../inter_project_search_new/lightdata";
import {InfinityOffcanvasWrapper} from "../../components/offcanvas";
import LicenceManager from "../../api/LicenceManager";
import IGui from "../../components/IGui";
import WebProjectProperties from "./WebProjectProperties";
import TextInput from "../../uitoolkit/TextInput";


export default function ProjectPropertiesOffCanvas({
                                                       fakeProject,
                                                       activeGroup,
                                                       show,
                                                       onHide
                                                   }) {

    const _fakeProject: FakeProject = fakeProject


    const [t] = useTranslation()

    const lock = useGlobalAppLock()

    const projectGroupsZus: any = useAvailableProjectGroups()


    const groupChanged = (e) => {
        const group = e.target.value

        lock.lock()

        ProjectsApi.changeProjectGroup(_fakeProject.uid, group).then((response) => {

            ProjectsApi.refreshZustand().then(() => {
                onHide()
                lock.unlock()
            })
        })
    }


    const downloadToComparePresseed = () => {

        window.open(`/api/project/get-project-data?project_uid=${_fakeProject.uid}`, "_blank")

        // ProjectsApi.GetProjectJson(_fakeProject.uid).then(() => {
        //
        // })
    }


    return (
        <>
            <InfinityOffcanvasWrapper
                closeButton={true}
                onHide={onHide}
                mainStyle={{width: "600px"}}
                header={<Offcanvas.Title>
                    {_fakeProject.name}
                </Offcanvas.Title>}
                body={
                    <Stack gap={3}>
                        <div>
                            ID: ({_fakeProject.uid})
                        </div>

                        <div>
                            <Form.Label>{t("core.project.group")}:</Form.Label>
                            <Form.Select value={activeGroup} onChange={groupChanged}>
                                {
                                    projectGroupsZus.availableGroups.map((value) => {
                                        return <option value={value.uid} key={value.uid}>{value.name}</option>
                                    })
                                }
                            </Form.Select>
                        </div>


                        <_RenameProjectEditor fakeProject={_fakeProject} onHide={onHide}/>
                        {
                            LicenceManager.projectsMapEnabled &&
                            <_LonLatEditor fakeProject={_fakeProject} onHide={onHide}/>
                        }

                        <WebProjectProperties projectUid={_fakeProject.uid}/>

                        <ProjectPropertiesTable projectId={_fakeProject.uid}/>

                        <div className={"btn btn-primary"} onClick={downloadToComparePresseed}>
                            {t("core.download.to.compare")}
                        </div>

                    </Stack>
                }
            />

        </>
    )
}


function _RenameProjectEditor({fakeProject, onHide}) {

    const [t] = useTranslation()

    const lock = useGlobalAppLock()

    const _fProject: FakeProject = fakeProject

    async function renameProjectPressed() {
        lock.lock()
        await ProjectsApi.changeProjectName(_fProject.uid, _fProject.name)
        onHide()
        lock.unlock()
    }

    return (
        <TextInput
            value={_fProject.name}
            label={"core.project.name"}

            onSubmited={(newValue) => {
                _fProject.name = newValue
                renameProjectPressed()
            }}
        />
    )


}

function _LonLatEditor({fakeProject, onHide}) {

    const _fakeProject: FakeProject = fakeProject

    const [t] = useTranslation()

    const lock = useGlobalAppLock()

    const lanLotInputRef = React.useRef<any>()

    const [prevLatLon] = React.useState(`${_fakeProject.lat},${_fakeProject.lon}`)
    const [latLon, setLanLon] = React.useState(`${_fakeProject.lat},${_fakeProject.lon}`)

    const resetLatLon = () => {
        setLanLon(`${_fakeProject.lat},${_fakeProject.lon}`)
    }


    const onTestChaning = (e) => {
        const value: string = e.target.value
        setLanLon(value)

    }


    const parse = () => {

        const val = lanLotInputRef.current.value

        const split = val.split(",")

        try {
            const _lat = parseFloat(split.at(0))
            const _lon = parseFloat(split.at(1))

            if (isNaN(_lat) || isNaN(_lon)) {
                resetLatLon()
                return false
            }

            setLanLon(`${_lat},${_lon}`)

            _fakeProject.lat = _lat
            _fakeProject.lon = _lon

            return true

        } catch (e) {
            resetLatLon()
            return false
        }

    }

    const submitLatLonPressed = () => {
        lock.lock()

        if (parse()) {
            ProjectsApi.changeLatLon(_fakeProject.uid, _fakeProject.lat, _fakeProject.lon).then(() => {
                onHide()
                lock.unlock()
            })
        } else {
            alert(t("core.invalid.lanlotformat"))
        }


    }


    return (
        <div>
            <Stack gap={3}>
                <Stack direction={"horizontal"} gap={3}>
                    <Form.Text>Lat/Lon:</Form.Text>
                    <Form.Control
                        ref={lanLotInputRef}
                        type={"input"}
                        value={latLon}
                        onChange={onTestChaning}

                    />
                    {
                        (prevLatLon !== latLon) &&
                        <IGui.Button
                            onClick={submitLatLonPressed}
                            label={">"}
                        />

                    }


                </Stack>


            </Stack>
        </div>
    )
}

