export default class MeshElement {

    onSelected: Function
    onDeselected: Function
    onClicked: Function

    setSelected() {
        if (this.onSelected) {
            this.onSelected()
        }

    }

    setDeselected() {
        if (this.onDeselected) {
            this.onDeselected()
        }
    }

    clicked() {
        if (this.onClicked) {
            this.onClicked()
        }
    }
}