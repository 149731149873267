import React from "react";

import {Button, Form, ListGroup, Modal, Offcanvas, Stack} from "react-bootstrap";


import {useTranslation} from "react-i18next";
import IconClickButton from "../../components/IconClickButton";
import ProjectGroupsApi from "../../api/ProjectGroupsApi";
import {ProjectGroupInterface} from "../../api/ProjectGroupsApiInterfaces";
import FakeLinkButton from "../../components/FakeLinkButton";
import {useGlobalAppLock} from "../../zustands/globalAppLockZus";
import {useAvailableProjectGroups} from "../../zustands/projectGroupsZus";
import ProjectsApi from "../../api/ProjectsApi";
import {BasicMiddleSpinner} from "../../components/spinners";
import {InfinityOffcanvasWrapper} from "../../components/offcanvas";
import urlcWithPath from "../../urlc";

export default function ManageProjectGroupOffCanvas({show, onHide}) {

    const [t, _] = useTranslation()

    const groupsZus = useAvailableProjectGroups()

    const [showAddPopup, setShowAddPopup] = React.useState(false)

    const [modalContent, setModalContent] = React.useState(null)

    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        setIsLoading(true)
        ProjectGroupsApi.refreshZustand().then(() => {
            setIsLoading(false)
        })
    }, [])

    const removeGroupPressed = (group) => {
        setModalContent(<_RemoveProjectGroupModal group={group} onHide={() => setModalContent(null)}/>)
    }

    return (
        <>
            {
                show && <InfinityOffcanvasWrapper
                    onHide={onHide}
                    header={
                        <Offcanvas.Title>
                            {
                                t("core.project.groups")
                            }
                        </Offcanvas.Title>
                    }
                    body={
                        <>
                            {
                                isLoading ? <BasicMiddleSpinner/> :
                                    <Stack gap={3}>
                                        <ListGroup>
                                            {
                                                groupsZus.availableGroups.length !== 0 ? groupsZus.availableGroups.map((value) => {
                                                        return (
                                                            <GroupListItem value={value} key={value.uid}
                                                                           onRemove={removeGroupPressed}/>
                                                        )
                                                    }) :
                                                    <div style={{textAlign: "center"}}>No groups defined</div>
                                            }
                                        </ListGroup>

                                        <IconClickButton
                                            lang={"core.add.group"}
                                            icon={urlcWithPath("i-icons/add.svg")}
                                            onclick={() => setShowAddPopup(true)}
                                        />
                                    </Stack>
                            }
                        </>
                    }

                />
            }

            <AddGroupModal show={showAddPopup} onHide={() => setShowAddPopup(false)}/>
            {
                modalContent && modalContent
            }
        </>
    )
}

function GroupListItem({value, onRemove}) {

    const [groupName, setGroupName] = React.useState(value.name)


    const submit = (e) => {
        e.preventDefault()

        if (groupName === "") {
            setGroupName(value.name)
        } else if (groupName !== value.name) {
            ProjectGroupsApi.renameProjectGroup(value.uid, groupName).then(() => {
                ProjectGroupsApi.refreshZustand()
            })
        }

    }


    return (
        <ListGroup.Item key={value.uid}>
            <Stack gap={3} direction={"horizontal"}>
                <Form onSubmit={submit}>
                    <Form.Control type={"text"} value={groupName} onChange={(e) => {
                        setGroupName(e.target.value)
                    }} style={{backgroundColor: "#F7F7F7"}} disabled={value.uid === ""}/>
                </Form>
                {
                    value.uid !== "" &&
                    <FakeLinkButton lang={"core.remove"} onclick={() => {
                        onRemove(value)
                    }} color={"red"}/>
                }
            </Stack>
        </ListGroup.Item>
    )
}


function cantSubmit(str: string) {
    return /^\s*$/.test(str) || str === ""
}

function AddGroupModal({show, onHide}) {

    const [t] = useTranslation()

    const [isProcessing, setIsProcessing] = React.useState(false)

    const [groupName, setGroupName] = React.useState("")

    const lock = useGlobalAppLock()

    const addPressed = () => {

        onHide()

        lock.lock()

        ProjectGroupsApi.createProjectGroup(groupName).then((response) => {
            ProjectGroupsApi.refreshZustand()
            lock.unlock()
        })

    }

    const typing = (e) => {
        const gName = e.target.value
        setGroupName(gName)
    }

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={onHide}
            >
                <Modal.Header closeButton className={"custom-white"}>
                    <Modal.Title>
                        {
                            t("core.group.create")
                        }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className={"custom-white"}>
                    <div>
                        <Form.Label>{t("core.name")}</Form.Label>
                        <Form.Control type={"input"} value={groupName} onChange={typing}/>
                    </div>
                </Modal.Body>

                <Modal.Footer className={"custom-white"}>
                    <Button
                        variant={"success"}
                        onClick={addPressed}
                        disabled={cantSubmit(groupName)}
                    >{t("core.add.group")}</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

function _RemoveProjectGroupModal({group, onHide}) {

    const [t] = useTranslation()

    const _group: ProjectGroupInterface = group

    const [repeatName, setRepeatName] = React.useState("")
    const [redirectGroup, setRedirectGroup] = React.useState("")

    const availableGroupsZus = useAvailableProjectGroups()

    const repeatNameTyping = (e) => {
        setRepeatName(e.target.value)
    }
    const removePressed = () => {
        onHide()
        ProjectGroupsApi.deleteProjectGroup(_group.uid, redirectGroup).then(() => {
            ProjectGroupsApi.refreshZustand(true).then(() => {
                ProjectsApi.refreshZustand()
            })
        })
    }

    return (
        <Modal show={true} onHide={onHide}>
            <Modal.Header closeButton className={"custom-white"}>
                <Modal.Title>{t("core.remove.projectgroup")}: "<b>{_group.name}"</b></Modal.Title>
            </Modal.Header>

            <Modal.Body className={"custom-white"}>
                <Stack gap={3}>
                    <div>
                        <Form.Text>Move to:</Form.Text>
                        <Form.Select value={redirectGroup} onChange={(e) => {
                            setRedirectGroup(e.target.value)
                        }}>
                            {
                                availableGroupsZus.availableGroups.map((value) => {
                                    return (
                                        <option disabled={value.uid === _group.uid} key={value.uid}
                                                value={value.uid}>{value.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Text>Repeat group name:</Form.Text>
                        <Form.Control type={"input"} onChange={repeatNameTyping}></Form.Control>
                    </div>

                </Stack>

            </Modal.Body>

            <Modal.Footer className={"custom-white"}>
                <Button style={{marginLeft: "auto"}} variant={"danger"} onClick={removePressed}
                        disabled={repeatName !== _group.name}>{t("core.remove")}</Button>
            </Modal.Footer>

        </Modal>
    )
}