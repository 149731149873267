import React from 'react';

import * as Bs from "react-bootstrap"

import "./LabelPlayer.css"


import {CrossButton} from "../../icon_click_buttons";
import urlcWithPath from '../../../urlc';


export function LabelPlayer(
    {
        header,
        use_header,
        body,
        use_body,
        image,
        use_image,
        url,
        onClose,
        frameTop,
        frameBottom,
        frameRight,
        frameLeft,
        linesColor
    }
) {


    const getFrameStyle = () => {
        const borderFormat = `5px solid ${linesColor}`


        const style = {
            borderRadius: "0px",
            borderTop: frameTop ? borderFormat : "",
            borderBottom: frameBottom ? borderFormat : "",
            borderRight: frameRight ? borderFormat : "",
            borderLeft: frameLeft ? borderFormat : "",
        }
        return style
    }

    let style: any = {
        maxWidth: (isLandscape() ? "95%" : "95%"),
        height: "95%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        background: "rgba(0, 0, 0, 0.4)",
        borderRadius: "25px",
    }

    style = {
        ...style,
        ...getFrameStyle(),
    }


    return (
        <Bs.Container style={style}>
            <_HeaderTextImageLabel
                style={{margin: "15px 15px 15px 15px"}}
                title={header}
                titleEnabled={use_header}
                body={body}
                bodyEnabled={use_body}
                image={image}
                imageEnabled={use_image}
                url={url}
            />
            {
                onClose && <CrossButton onClick={onClose}/>
            }

        </Bs.Container>

    )
}


const style_common = {
    color: "white"
}

const style_body = {
    ...style_common,
    fontSize: "25px"
}

const style_title = {
    ...style_common,
    fontSize: "35px"
}

const STYLE_IMAGE = {
    objectFit: "contain",
    minHeight: "450px",
    maxHeight: "550px",
    margin: "auto",

}

const STYLE_ONLY_IMAGE = {
    objectFit: "contain",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
    maxHeight: "90vh",
    maxWidth: "90vh",

}


const isLandscape = () => {
    return window.innerWidth > window.innerHeight
}


interface _HeaderTextImageLabel {
    style: any
    title: string
    titleEnabled: boolean
    body: string
    bodyEnabled: string
    image: string
    imageEnabled: boolean
    url: string
}


function _HeaderTextImageLabel({
                                   style,
                                   title,
                                   titleEnabled,
                                   body,
                                   bodyEnabled,
                                   image,
                                   imageEnabled,
                                   url,
                               }: _HeaderTextImageLabel) {

    return (
        <Bs.Container style={{
            ...style,
            overflowY: "auto"
        }}>
            <div className={"vstack gap-3"}>

                {
                    (title !== "" && titleEnabled) &&
                    <p style={{...style_title, alignment: "center"}}
                       dangerouslySetInnerHTML={{__html: title}}/>
                }

                {
                    (image !== "" && imageEnabled) &&
                    <img src={image}
                         style={{
                             ...(
                                 (!titleEnabled && !bodyEnabled && imageEnabled) ? STYLE_IMAGE : STYLE_ONLY_IMAGE)
                         }}/>
                }

                {
                    (body !== "" && bodyEnabled) &&
                    <p style={style_body} dangerouslySetInnerHTML={{__html: body}}/>
                }


                <LinkButton url={url}/>
            </div>

        </Bs.Container>
    )

}


const LinkButton = (props) => {

    if (props.url == "") {
        return <></>
    }


    const containerStyle = {
        position: "absolute",
        width: "25px",
        height: "25px",
        cursor: "pointer",
        right: "0px",
        bottom: "0px",
        marginRight: "5px",
        marginBottom: "5px",

    }

    const clicked = () => {
        const urlToOpen: string = props.url

        if (urlToOpen.indexOf("https://") == 0) {
            window.open(props.url, "_blank")
        } else {
            window.open("https://" + props.url, "_blank")
        }

    }


    return (<Bs.Image src={urlcWithPath("i-icons/url.svg")}
                      style={containerStyle}
                      onClick={clicked}
    />)

}

