import {InfinityBoardParent} from "./board_Parent";

import * as three from "three"
import {StaticIncludes} from "../../static_includes";
import * as Bs from "react-bootstrap";
import React from "react";
import {SoundAsset} from "../../ela/assets/asset_Sound";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";

export class InfinitySoundBoard extends InfinityBoardParent {

    static ACTIVE_SOUND: three.Audio | any = null

    type = "sound"

    constructor(spot) {
        super(spot)
        this.icon = "EI.SoundBoard"

        this.activeTimeout = null
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)
    }


    clicked() {

        GuidedTourManager.taskBoardClicked(this, 1)


        if (InfinitySoundBoard.ACTIVE_SOUND) {
            this.clickableIcon.material.map = StaticIncludes._instnace.GetTexture(this.icon)
            InfinitySoundBoard.ACTIVE_SOUND.stop()
            InfinitySoundBoard.ACTIVE_SOUND = null

            if (this.activeTimeout) {
                clearTimeout(this.activeTimeout)
                this.activeTimeout = null
            }

        } else {
            super.clicked()
            super.fillBoardVisited(1)

            const soundAsset = this.getSoundAsset()

            if (soundAsset) {

                const listener = new three.AudioListener();
                InfinitySoundBoard.ACTIVE_SOUND = new three.Audio(listener);


                this.clickableIcon.material.map = StaticIncludes._instnace.GetTexture("Core.Pause")

                const soundPath = soundAsset.getAbsolutePath()

                const audioLoader = new three.AudioLoader();
                audioLoader.load(soundPath, (buffer) => {
                    const activeSound = InfinitySoundBoard.ACTIVE_SOUND

                    activeSound.setBuffer(buffer);
                    activeSound.setLoop(false);
                    activeSound.setVolume(1);
                    activeSound.play();
                    activeSound.onAfterRender()

                    this.activeTimeout = setTimeout(() => {
                        this.clickableIcon.material.map = StaticIncludes._instnace.GetTexture(this.icon)
                    }, 1000 * buffer.duration)

                })
            }


        }

    }

    CleanUp() {
        if (InfinitySoundBoard.ACTIVE_SOUND) {
            InfinitySoundBoard.ACTIVE_SOUND.stop()
            InfinitySoundBoard.ACTIVE_SOUND = null
        }
    }

    isContentValid(): boolean {
        return this.getSoundAsset()
    }

    getSoundAsset(): SoundAsset {
        return this.getParentProject().sounds.get(this.contentUid)
    }


    getRightClickOptions() {
        return <>
            {
                super.getRightClickOptions()
            }
            <Bs.ListGroup.Item action onClick={this.openInNewWindow}>Open in new window</Bs.ListGroup.Item>
        </>
    }

    openInNewWindow = () => {
        const sound = this.getParentProject().sounds.get(this.contentUid)
        if (sound) {
            window.open(sound.getAbsolutePath(), "_blank")
        }
    }


}