import {QuizRecordResponse} from "../../api/TourStatisticsApi";
import {QuizAsset} from "../../ela/assets/asset_Quiz";


export default class QuizzesRatingManager {
    static async SortQuizzesDueToSuccess(data: Array<QuizRecordResponse>): Promise<Array<QuizRecordResponse>> {
        let result: Array<QuizRecordResponse> = []

        const sumMap = new Map<string, Array<QuizRecordResponse>>()


        data.forEach((value) => {

            const uid = `${value.ProjectUid}-${value.SpotUid}-${value.BoardUid}`

            const vector = sumMap.get(uid)

            if (vector) {
                vector.push(value)
            } else {
                sumMap.set(uid, [value])
            }
        })


        sumMap.forEach((value) => {

            const firstOne = value[0]

            if (firstOne) {
                const tmpResult: QuizRecordResponse = {...firstOne}

                let sumupStat = 0

                value.forEach((tmp) => {
                    sumupStat += Number(tmp.Success)
                })
                tmpResult.Success = sumupStat / value.length

                result.push(tmpResult)

            }
        })

        result = result.sort((a, b) => {
            if (a.Success < b.Success) {
                return 1
            } else {
                return -1
            }
        })

        return result
    }

    static async ExtractProblematicQuestionsFromJstringArray(data: Array<string>): Promise<Array<[string, number]>> {

        const answersMap = new Map<string, Array<number>>()

        data.forEach((value) => {
            const nQuiz = QuizAsset.getQuizFromJsonWithResult(JSON.parse(value))

            nQuiz.questions.forEach((value) => {
                const tmp = answersMap.get(value.name)
                if (tmp) {
                    tmp.push(value.GetSuccessRate())
                } else {
                    answersMap.set(value.name, [value.GetSuccessRate()])
                }
            })
        })

        let resultVec = new Array<[string, number]>()

        answersMap.forEach((values, key) => {

            if (values.length == 0) {
                resultVec.push([key, 0])
            } else {
                let sumUp = 0

                values.forEach((val) => {
                    sumUp += val
                })
                resultVec.push([key, sumUp / values.length])
            }
        })

        resultVec = resultVec.sort((a, b) => {
            return b[1] - a[1]
        })

        return resultVec
    }


}