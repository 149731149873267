import React from "react";

import { useTranslation } from "react-i18next";

import "./App.css";

import ProjectsView from "./routes/ProjectsView";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import WorldPlayer from "./WorldPlayer";

import { Image, Spinner, Stack } from "react-bootstrap";
import UserView from "./routes/UserView";

import useCookie from "react-use-cookie";

import ProjectsAdminViewWrapper from "./routes/ProjectsAdminView";

import StatisticsView from "./routes/StatisticsView";
import AppLockSpinner from "./components/AppLockSpinner";
import AuthenticationManager from "./api/AuthenticationManager";
import { useActiveUserZus } from "./zustands/activeUserZus";
import AppBackendManager from "./api/AppBackendManager";

import {
  OnlyAdminView,
  OnlySuperAdminView,
} from "./components/user_dispatcher";
import TrainingPrintView from "./routes/statistics/TrainingPrintView";
import SearchView from "./routes/inter_project_search_new/SearchView";

import AppNavBar from "./AppNavBar";
import AppNavBarOld from "./AppNavBarOld";
import { ProSidebarProvider } from "react-pro-sidebar";
import MountProjectWrapper from "./components/MountProjectWrapper";
import LicenceManager from "./api/LicenceManager";
import { useGlobalPopup } from "./zustands/globalPopupZus";
import { useCookieBoolean } from "./devtools/hooks";
import { useLayoutZus } from "./zustands/layoutZus";

import AppRoutes from "./AppRoutes";
import urlcWithPath from "./urlc";

//Tato funkcia je kvoli tomu vydrbanemu iframu v body
function removeIFrame() {
  const element = document.body;
  const childrenArray = Array.from(element.children);

  const elementsToRemove: Array<HTMLElement> = [];

  childrenArray.forEach((value: HTMLElement) => {
    if (value.tagName === "IFRAME") {
      elementsToRemove.push(value);
    }
  });

  elementsToRemove.forEach((element) => {
    element.remove();
  });
}

function _GlobalPopup() {
  const popupZus = useGlobalPopup();

  if (popupZus.elements.length > 0) {
    return (
      <>
        {popupZus.elements.map((value, index) => {
          return <React.Fragment key={index}>{value}</React.Fragment>;
        })}
      </>
    );
  } else {
    return <></>;
  }
}

export function App() {
  const [_, tInstance] = useTranslation();

  const layoutZus = useLayoutZus();

  const [userLanguage, setUserLanguage] = useCookie("language", "en");

  const [userJwt, setUserJwt] = useCookie("infinityjwt", "");

  const activeUserZus = useActiveUserZus();

  const [appReady, setAppReady] = React.useState(false);

  React.useEffect(() => {
    tInstance.changeLanguage(userLanguage);

    AppBackendManager.checkBackendAvailability().then(() => {
      if (AppBackendManager.isBackendAvailable) {
        AuthenticationManager.authMe().then((response) => {
          setUserJwt(response.jwt);
          setAppReady(true);
        });
      } else {
        setAppReady(true);
      }

      LicenceManager.init().then(() => {});
    });
  }, []);

  removeIFrame();

  if (appReady) {
    return (
      <>
        <_CookiesComponent />

        <div>
          <div className={"app_background"} />

          <AppLockSpinner />

          {layoutZus.useExperimentalLayout ? (
            <>
              <div style={{}}>
                <ProSidebarProvider>
                  <AppNavBar />
                </ProSidebarProvider>

                <div style={{ flexGrow: 1 }}>
                  <_Main />
                </div>
              </div>
            </>
          ) : (
            <>
              <AppNavBarOld />
              <_Main />
            </>
          )}

          <_GlobalPopup />
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: "#000002",
          position: "absolute",
          left: "0px",
          top: "0px",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <Stack gap={5}>
            <Image
              src={urlcWithPath("i-icons/exe_unlock_tomorrow.svg")}
              style={{
                height: "50px",
              }}
            />
            <Spinner
              animation="border"
              variant="danger"
              style={{
                margin: "auto",
              }}
            />
          </Stack>
        </div>
      </div>
    );
  }
}

function _Main() {
  return (
    <main
      style={{
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <AppRoutes />
    </main>
  );
}

const NavBarIconsClickButton = ({ icon, route, lang }) => {
  const [t, _] = useTranslation();

  const navigate = useNavigate();

  const NAVBARICONSTYLE = {
    width: "45px",
    height: "45px",
    cursor: "pointer",
  };

  return (
    <>
      <Stack>
        <Image
          src={icon}
          style={NAVBARICONSTYLE}
          onClick={() => {
            navigate(route);
          }}
        />
        <div style={{ color: "white" }}>{t(lang)}</div>
      </Stack>
    </>
  );
};

function _CookiesComponent() {
  const layoutZus = useLayoutZus();

  const [useExperimentalLayout, setUseExperimentalLayout] = useCookieBoolean(
    "app-experimantal-layout",
    LicenceManager.usesNewLayoutAsDefault
  );

  React.useEffect(() => {
    layoutZus.setUseExperimentalLayout(useExperimentalLayout);

    window.onkeydown = (evt) => {
      if (evt.keyCode === 119) {
        console.log(useExperimentalLayout);

        const newValue = useExperimentalLayout ? false : true;
        setUseExperimentalLayout(newValue);
        layoutZus.setUseExperimentalLayout(newValue);
      }
    };
  }, []);

  return <></>;
}
