import * as three from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { Texture } from "three";
import urlcWithPath from "./urlc";

class StaticIncludes {
  static _instnace: StaticIncludes = new StaticIncludes();

  font: any = null;
  textures: Map<string, Texture>;

  static Instance(): StaticIncludes {
    return StaticIncludes._instnace;
  }

  constructor() {
    this.textures = new Map();

    const textures = ["EI.SoundBoard", "Core.Pause"];

    const rootPath = urlcWithPath("assets/icons/");

    textures.forEach((value: string) => {
      this.textures.set(
        value,
        new three.TextureLoader().load(rootPath + value + ".png")
      );
    });

    new FontLoader().load(urlcWithPath("font.typeface.json"), (font) => {
      this.font = font;
    });
  }

  GetTexture(textureKey: string) {
    if (this.textures.has(textureKey)) {
      return this.textures.get(textureKey);
    } else {
      console.log("Cached icon mismatch: ", textureKey);
      const rootPath = urlcWithPath("assets/icons/");
      this.textures.set(
        textureKey,
        new three.TextureLoader().load(rootPath + textureKey + ".png")
      );
      return this.textures.get(textureKey);
    }
  }
}

export { StaticIncludes };
