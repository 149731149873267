import {InfinityProject} from "./infinity/InfinityProject";
import {TourTaskAction, TourTaskActionTypes, TourTaskAsset, TourTaskCondition} from "./ela/assets/asset_TourTask";
import {InfinityBoardParent} from "./3d/boards/board_Parent";
import {useActiveUserZus} from "./zustands/activeUserZus";
import {ProjectPlayer} from "./infinity/ProjectPlayer";
import {InfinitySpot} from "./infinity/InfinitySpot";
import TimeManager from "./managers/TimeManager";

export default class GuidedTourRecorder {

    records = new Array<GuidedTaskRecord>()

    project_name = ""
    project_uid = ""
    trainee_name = ""

    start_time = new Date()
    end_time = new Date()

    duration = ""

    success_rate = 0

    activeRecordIndex = 0

    _project: InfinityProject | any = null

    constructor() {

    }

    prepareData(project: InfinityProject) {
        this._project = project

        this.project_name = project.name
        this.project_uid = project.uid

        this.records = new Array()
        this.activeRecordIndex = 0

        console.log(project.tasks.size)

        Array.from(project.tasks.values()).forEach((value: TourTaskAsset) => {
            const spotRecord = GuidedTaskRecord.newFromProject(project, value)

            this.records.push(spotRecord)
        })

        this.records.sort((a, b) => {
            return a.position < b.position ? -1 : (a.position > b.position ? 1 : 0)
        })

    }

    play() {
        this.start_time = new Date()
        this.activateStartActions()
    }

    end() {
        this.end_time = new Date()

        this.success_rate = this.getResultPercentage()
    }

    boardClicked(boardParent: InfinityBoardParent, status: number) {

        const activeTask = this.records[this.activeRecordIndex]

        if (activeTask) {
            activeTask.boardClicked(boardParent, status)
        }
    }

    taskSkipped(): GuidedTaskRecord {
        return this.goOnNextTask()
    }

    to_json_object(): any {
        return {
            project_name: this.project_name,
            project_uid: this.project_uid,
            trainee_name: useActiveUserZus.getState().activeUser.name,
            duration: this.getTourDuration(),
            start_time: this.start_time.toUTCString(),
            end_time: this.end_time.toUTCString(),
            success_rate: this.getResultPercentage(),
            // time_offset: new Date().getTimezoneOffset(),
            "results": this.getJsonResultVector(),
        }
    }


    getStartTimeLocal(): string {
        return this.start_time.toLocaleString()
    }

    getEndTimeLocal(): string {
        return this.end_time.toLocaleString()
    }

    getResultPercentage(): number {

        let percentages = 0

        this.records.forEach((record) => {
            percentages += record.getResultPercentage()

        })

        let percentage = 0

        if (this.records.length == 0) {
            percentage = 0
        } else {
            percentage = percentages / this.records.length
        }

        console.log(`Tour final percentage ${percentage}`)

        return percentage

    }

    getPercentage(): number {

        let percentages = 0

        this.records.forEach((record) => {
            percentages += record.getPercentage()

        })

        let percentage = 0

        if (this.records.length == 0) {
            percentage = 0
        } else {
            percentage = percentages / this.records.length
        }

        console.log(`Tour final percentage ${percentage}`)

        return percentage

    }

    getTourDuration() {
        const duration = new Date(new Date().getTime() - this.start_time.getTime())

        let minutes: any = duration.getMinutes()

        if (minutes < 10) {
            minutes = "0" + minutes
        }

        let seconds: any = duration.getSeconds()

        if (seconds < 10) {
            seconds = "0" + seconds
        }

        return minutes + ":" + seconds
    }

    getJsonResultVector(): Array<any> {

        const result = this.records.map((value) => {
            return value.to_json()
        })

        return result
    }

    from_json(data: any) {
        this.project_uid = data.project_uid ?? ""
        this.project_name = data.project_name ?? ""
        this.trainee_name = data.trainee_name ?? ""
        this.duration = data.duration ?? ""
        this.success_rate = data.success_rate ?? 0

        this.start_time = TimeManager.convertPureUTCDateToLocalDate(data.start_time)
        this.end_time = TimeManager.convertPureUTCDateToLocalDate(data.end_time)

        const results = data.results ?? []

        results.map((value) => {
            const record = GuidedTaskRecord.newFromJson(value)
            this.records.push(record)
        })
    }

    activateStartActions() {
        const active = this.records[this.activeRecordIndex]

        active._start_actions.forEach((action) => {
            this._execAction(action)
        })
    }

    activateEndActions() {
        const active = this.records[this.activeRecordIndex]

        active._end_actions.forEach((action) => {
            this._execAction(action)
        })
    }


    _execAction(action: TourTaskAction) {

        console.log(action.type)

        if (action.type === TourTaskActionTypes.MOVE_TO_SPOT) {
            const spot = action.typeData["spot"]
            if (spot && spot !== "") {
                ProjectPlayer.INSTANCE.ActivateSpotWithId(spot)
            }
        } else if (action.type === TourTaskActionTypes.DEACTIVATE_BOARD) {
            const spots = action.typeData["spots"]

            Object.keys(spots).forEach((key) => {

                const boardsToDeactivate: [] = spots[key]

                const spot: InfinitySpot | any = this._project.spots.get(key)

                if (spot) {

                    spots[key].forEach((value: string) => {

                        boardsToDeactivate.forEach((boardUid: string) => {
                            const board: InfinityBoardParent = spot.boards.get(boardUid)
                            board._isEnabled = false
                            board.taskEnded()
                        })
                    })
                }

            })


        } else if (action.type === TourTaskActionTypes.ACTIVATE_BOARD) {
            const spots = action.typeData["spots"]

            Object.keys(spots).forEach((key) => {

                const boardsToActivate: [] = spots[key]

                const spot: InfinitySpot | any = this._project.spots.get(key)
                if (spot) {

                    spots[key].forEach((value: string) => {
                        boardsToActivate.forEach((boardUid: string) => {
                            const board: InfinityBoardParent = spot.boards.get(boardUid)
                            board._isEnabled = true
                            board.taskEnded()

                        })
                    })

                }

            })
        }
    }


    goOnNextTask(): GuidedTaskRecord {

        this.activateEndActions()

        this._project.spots.forEach((spot: InfinitySpot) => {
            spot.boards.forEach((board) => {
                board._isEnabled = true
                board.taskEnded()
            })
        })

        this.activeRecordIndex += 1
        this.activateStartActions()

        return this.records[this.activeRecordIndex]
    }
}


export class GuidedTaskRecord {

    name = ""
    header = ""
    body = ""
    uid = ""
    spotRecords = new Map<string, GuidedTourSpotRecord>()
    position = 0

    percentage = 0

    expectedBoards = 0
    foundBoards = 0

    image_included = false
    image_extension = "jpg"


    _start_actions = new Array<TourTaskAction>()
    _end_actions = new Array<TourTaskAction>()

    _task: TourTaskAsset | any = null


    static newFromProject(project: InfinityProject, tourTask: TourTaskAsset): GuidedTaskRecord {
        const tmpTask = new GuidedTaskRecord()


        tmpTask.name = tourTask.name
        tmpTask.header = tourTask.tour_task.header
        tmpTask.body = tourTask.tour_task.body
        tmpTask.position = tourTask.tour_task.position

        tmpTask._task = tourTask

        tmpTask._start_actions = tourTask.tour_task.starting_actions
        tmpTask._end_actions = tourTask.tour_task.ending_actions

        tmpTask.uid = tourTask.uid

        tourTask.tour_task.conditions_boards.forEach((value) => {
            const tmp = GuidedTourSpotRecord.newFromProject(project, value)
            tmpTask.expectedBoards += tmp.boardRecords.size

            tmpTask.spotRecords.set(value.spotUid, tmp)
        })


        return tmpTask
    }

    static newFromJson(jObject: any) {
        const tmpTask = new GuidedTaskRecord()

        tmpTask.name = jObject.name ?? ""

        tmpTask.uid = jObject.uid ?? ""

        const spot_data = jObject.spots_data ?? []

        let percentages = 0

        spot_data.forEach((value) => {
            const tmp = GuidedTourSpotRecord.newFromJson(value)
            tmpTask.expectedBoards += tmp.boardRecords.size

            percentages += tmp.percentage

            tmpTask.spotRecords.set(value.uid, tmp)
        })

        if (tmpTask.spotRecords.size > 0) {
            tmpTask.percentage = percentages / tmpTask.spotRecords.size
        }

        return tmpTask
    }


    getPercentage() {

        let result = 0

        if (this.expectedBoards > 0) {
            result = this.foundBoards / this.expectedBoards
        }

        this.percentage = result

        return result
    }


    getResultPercentage(): number {

        let sumPercentage = 0

        Array.from(this.spotRecords.values()).forEach((value) => {
            sumPercentage += value.getPercentage()
        })

        if (this.spotRecords.size !== 0) {
            return sumPercentage / this.spotRecords.size
        }

        return 0
    }

    boardClicked(board: InfinityBoardParent, status: number) {
        const _spotUid = board.parentSpot.uid

        const record = this.spotRecords.get(_spotUid)

        if (record) {
            if (record.boardClicked(board, status)) {
                this.foundBoards += 1
            }
        }
    }

    to_json(): any {
        return {
            name: this.name,
            uid: this.uid,
            percentage: this.getPercentage(),
            spots_data: this.get_spots_data_to_json()
        }
    }

    get_spots_data_to_json(): any {
        return Array.from(this.spotRecords.values()).map((value) => {
            return value.to_json()
        })
    }
}


export class BoardRecord {
    name: string = ""
    uid: string = ""
    icon: string = ""
    percentage: number = 0
    board_type: string = ""

    constructor(uid: string, name: string) {
        this.name = name
        this.uid = uid
    }

}

export class GuidedTourSpotRecord {
    uid = ""
    name = ""

    percentage = 0

    boardRecords = new Map<string, BoardRecord>()


    static newFromProject(project: InfinityProject, spotTask: TourTaskCondition): GuidedTourSpotRecord {
        const newSpotRecord = new GuidedTourSpotRecord()

        const _spot: any = project.spots.get(spotTask.spotUid) ?? {}

        newSpotRecord.name = _spot.name
        newSpotRecord.uid = _spot.uid

        Array.from(spotTask.boards.keys()).forEach((key) => {
            const _tmpBoard: InfinityBoardParent = _spot.boards.get(key)
            const boardRecord = new BoardRecord(key, _tmpBoard.name)
            boardRecord.icon = _tmpBoard.icon
            boardRecord.board_type = _tmpBoard.type
            newSpotRecord.boardRecords.set(key, boardRecord)

        })

        return newSpotRecord
    }

    static newFromJson(jObject: any): GuidedTourSpotRecord {
        const newSpotRecord = new GuidedTourSpotRecord()

        newSpotRecord.name = jObject.name ?? ""
        newSpotRecord.uid = jObject.uid ?? ""

        const boardData = jObject.boards_data ?? []


        let percentages = 0

        Array.from(boardData).forEach((boardRecord: any) => {
            const tmpRecord = new BoardRecord(boardRecord.uid, boardRecord.name)

            tmpRecord.percentage = boardRecord.percentage ?? 0
            tmpRecord.icon = boardRecord.icon ?? ""
            tmpRecord.board_type = boardRecord.board_type ?? ""
            newSpotRecord.boardRecords.set(tmpRecord.uid, tmpRecord)
            percentages += tmpRecord.percentage
        })

        if (newSpotRecord.boardRecords.size > 0) {
            newSpotRecord.percentage = percentages / newSpotRecord.boardRecords.size
        }

        return newSpotRecord
    }

    boardClicked(board: InfinityBoardParent, status: number): boolean {
        if (this.boardRecords.has(board.uid)) {
            const activeRecord: any | BoardRecord = this.boardRecords.get(board.uid)

            if (activeRecord.percentage === 0) {
                activeRecord.percentage = status
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    to_json(): any {
        return {
            name: this.name,
            uid: this.uid,
            percentage: 0,
            boards_data: this.boards_to_json()
        }
    }

    boards_to_json() {

        return Array.from(this.boardRecords.values()).map((value) => {
            return {
                name: value.name,
                uid: value.uid,
                percentage: value.percentage,
                icon: value.icon,
                board_type: value.board_type
            }
        })
    }

    getPercentage(): number {

        let percentages = 0

        this.boardRecords.forEach((val) => {
            percentages += val.percentage
        })


        let result = 0

        if (this.boardRecords.size > 0) {
            result = percentages / this.boardRecords.size
        }


        return result

    }

}

