import {InfinityBoardParent} from "./board_Parent";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";


export class InfinityGateBoard extends InfinityBoardParent {
    type = "gate"


    constructor(spot) {
        super(spot)
        this.icon = "EI.Gate"

    }

    iconRotationEnabled() {
        return true && (this.use_automatic_rotation === false)
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)
    }

    fillToWorld(world) {
        super.fillToWorld(world)

        const spot = this.getParentProject().spots.get(this.contentUid)

        if (spot.wasCompleted) {
            this.setCompletedColor()
        }
    }

    clicked = () => {
        GuidedTourManager.taskBoardClicked(this, 1)
        this.getActivePlayer().ActivateSpotWithId(this.contentUid)
    }


}