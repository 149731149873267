import {InfinitySpot} from "../../../infinity/InfinitySpot";
import {AreaAsset} from "../../../ela/assets/asset_Area";


interface IMapPoint {
    left: number;
    top: number;
    spotId: string;
    name: string;
    isActive: boolean;
    isAreaTranslation: boolean;
}

export class MapTools {

    static getMapSizeNoUnit(map: AreaAsset, pixelConstant) {

        let newSizeX = pixelConstant
        let newSizeY = pixelConstant

        if (map) {

            const result = MapTools.calculateMapSizeFromRatio(map, pixelConstant)

            newSizeX = result.x
            newSizeY = result.y
        }

        const returnValue = {
            width: newSizeX,
            height: newSizeY,
        }

        // console.log(returnValue)

        return returnValue
    }


    static calculateMapSizeFromRatio(map: AreaAsset, pixelConstant: number) {

        const mapSizeX = map.scale_x
        const mapSizeY = map.scale_y

        let newX = 0
        let newY = 0

        if (mapSizeX > mapSizeY) {
            newX = pixelConstant
            newY = pixelConstant * (mapSizeY / mapSizeX)
        } else if (mapSizeY > mapSizeX) {
            newX = pixelConstant * (mapSizeX / mapSizeY)
            newY = pixelConstant
        } else {
            newX = pixelConstant
            newY = pixelConstant
        }

        let notFit = false

        if (window.innerWidth < newX) {
            notFit = true

            newX = window.innerWidth - (window.innerWidth * 0.05)

            if (mapSizeX > mapSizeY) {
                newY = (mapSizeY / mapSizeX) * newX
            } else if (mapSizeX < mapSizeY) {
                newY = (mapSizeX / mapSizeY) * newX
            } else {
                newY = newX
            }

        } else if (window.innerHeight < newY) {
            notFit = true

            newY = window.innerHeight - (window.innerHeight * 0.05)

            if (mapSizeY > mapSizeX) {
                newX = (mapSizeY / mapSizeX) * newY
            } else if (mapSizeY < mapSizeX) {
                newX = (mapSizeX / mapSizeY) * newY
            } else {
                newX = newX
            }
        }

        if (notFit) {
            // console.log("Map not fit not implemented")
            // console.log(window.innerWidth)
            // console.log(window.innerHeight)
        }

        return {x: newX, y: newY}
    }
}