import React from "react";
import {Button, Form, ProgressBar, Stack} from "react-bootstrap";
import ProjectsApi from "../../api/ProjectsApi";
import {useTranslation} from "react-i18next";

import Switch, {Case} from 'react-switch-case'
import ProjectGroupsApi from "../../api/ProjectGroupsApi";
import UploadStreamer from "../../devtools/UploadStreamer";


interface UploadProjectViewProps {
    projectId: string;
    filesMap: Map<string, File>;
}

function lockTabClose() {
    window.onbeforeunload = (e) => {
        const returnValue = 'Download is in progress are you sure you want to close window?'
        e = e || window.event;
        if (e) {
            e.returnValue = returnValue
        }
        return returnValue
    }
}

function unlockTabClose() {
    window.onbeforeunload = null

}

let FILES_TO_BE_UPLOADED = new Array<File>()

let FILES_TO_BE_STREAMED = new Array<File>()

let FILES_TO_BE_STREAMED_COUNT = 0

let FILES_TO_BE_UPLOADED_COUNT = 0


let FILES_SENT = 0

export default function UploadProjectView({
                                              projectId,
                                              filesMap,
                                              onUploadStarted,
                                              onUploadEnded
                                          }: UploadProjectViewProps) {

    const refs = React.useRef([])

    const [uploadIsStarted, setIsUploadStarted] = React.useState(false)
    const [smallFilesUploaded, setSmallFilesUploaded] = React.useState(false)

    const [progressPercentages, setProgressPercentages] = React.useState(0)

    const [streamFileName, setStreamFileName] = React.useState("")

    const [streamProgressPercentages, setStreamProgressPercentages] = React.useState(0)

    const [projectName, setProjectName] = React.useState("")
    const [projectJsonString, setProjectJsonString] = React.useState("")

    const [group, setProjectGroup] = React.useState("")

    const [projectExists, setProjectExists] = React.useState(false)

    let projectIsValid = filesMap.has(`${projectId}/project_data.json`)

    React.useEffect(() => {
        if (projectIsValid) {

            const projectJson = filesMap.get(`${projectId}/project_data.json`)

            const reader = new FileReader()
            reader.onload = (result) => {
                const rawString = result.target.result
                setProjectJsonString(rawString)
                setProjectName(JSON.parse(rawString).name)
            }
            reader.readAsText(projectJson)


            ProjectsApi.checkProjectExistence(projectId).then((result) => {
                setProjectExists(result.data.exists)
            })


        }
    }, [])

    const splitsCount = 8

    const fileStreamEnded = () => {
        unlockTabClose()
        onUploadEnded()
    }


    const startBigFiles = () => {
        streamBigFiles()
    }

    const streamBigFiles = () => {

        setSmallFilesUploaded(true)

        if (FILES_TO_BE_STREAMED.length == 0) {
            fileStreamEnded()
        } else {

            const file = FILES_TO_BE_STREAMED.pop()

            setStreamFileName(file.webkitRelativePath)

            new UploadStreamer().StartStreaming(file,
                (bytesAlreadySend) => {

                    const percentage = (Math.min(bytesAlreadySend, file.size) / file.size) * 100
                    setStreamProgressPercentages(percentage)
                }, () => {
                    streamBigFiles()
                })
        }
    }


    const uploadWithoutPanoramas = () => {


        const keysToRemove = []

        Array.from(filesMap.keys()).forEach((value) => {
            if (value.search("/Panoramas/") != -1) {
                keysToRemove.push(value)
            }
        })

        keysToRemove.forEach((value) => {
            filesMap.delete(value)
        })

        uploadFullPressed()


    }

    const uploadFullPressed = () => {

        console.log("Upload full pressed")

        ProjectsApi.reserveProjectUpload(filesMap.get(`${projectId}/project_data.json`), group).then(() => {

            let finishedSlices = splitsCount

            FILES_TO_BE_UPLOADED = new Array<File>()
            FILES_TO_BE_STREAMED = new Array<File>()

            Array.from(filesMap.values()).forEach((value: File) => {
                if (value.size < (16 * 1024 * 1024)) {
                    FILES_TO_BE_UPLOADED.push(value)
                } else {
                    FILES_TO_BE_STREAMED.push(value)
                }
            })

            FILES_TO_BE_UPLOADED_COUNT = FILES_TO_BE_UPLOADED.length
            FILES_TO_BE_STREAMED_COUNT = FILES_TO_BE_STREAMED.length
            FILES_SENT = 0

            refs.current.forEach((value, index) => {
                value.startUpload(
                    () => {
                        FILES_SENT += 1
                        setProgressPercentages((FILES_SENT / FILES_TO_BE_UPLOADED_COUNT) * 100)
                    },
                    () => {
                        finishedSlices -= 1

                        if (finishedSlices === 0) {
                            startBigFiles()
                        }
                    })
            })

        })
    }


    const uploadPressed = (strategy: string) => {
        setIsUploadStarted(true)
        onUploadStarted()
        lockTabClose()


        if (strategy === UPLOAD_STRATEGIES.FULL) {
            uploadFullPressed()
        } else if (strategy === UPLOAD_STRATEGIES.NOPANO) {
            uploadWithoutPanoramas()
        }
    }

    if (!projectIsValid) {
        return (
            <div color={"red"}>
                This is not valid Project FOLDER!!!!
            </div>
        )
    } else {
        return (
            <>
                <Stack gap={3}>

                    <ProjectUploadInfoView idUploading={uploadIsStarted}
                                           projectId={projectId}
                                           projectName={projectName}
                                           group={group}
                                           setProjectGroup={setProjectGroup}/>

                    <>
                        {
                            uploadIsStarted === false &&
                            <_UploadStrategyBar uploadPressed={uploadPressed} isTypeCreate={!projectExists}/>

                        }

                    </>

                    {
                        !smallFilesUploaded && <>
                            <div>
                                <Form.Text>Total:</Form.Text>
                                <ProgressBar variant={true ? "success" : "primary"} now={progressPercentages}
                                             label={`${(progressPercentages).toString().substring(0, 6)}%`}
                                             style={{
                                                 height: "38px",
                                                 borderStyle: "solid",
                                                 borderColor: "#cfd4da",
                                                 borderWidth: "thin",
                                             }}
                                />
                            </div>
                        </>
                    }

                    {
                        smallFilesUploaded &&
                        <>
                            <div>
                                <Form.Text>Stream
                                    (Left: {FILES_TO_BE_STREAMED.length + 1} / {FILES_TO_BE_STREAMED_COUNT}): {streamFileName}</Form.Text>
                                <ProgressBar variant={true ? "success" : "primary"} now={streamProgressPercentages}
                                             label={`${(streamProgressPercentages).toString().substring(0, 6)}%`}
                                             style={{
                                                 height: "38px",
                                                 borderStyle: "solid",
                                                 borderColor: "#cfd4da",
                                                 borderWidth: "thin",
                                             }}
                                />
                            </div>
                        </>
                    }

                    {
                        !smallFilesUploaded && <>

                            {
                                [...Array(splitsCount).keys()].map((index) => {
                                    return (
                                        <UploadPieceView key={index} ref={(ref) => refs.current[index] = ref}/>
                                    )
                                })
                            }
                        </>
                    }


                </Stack>


            </>
        )
    }

}

const UPLOAD_STRATEGIES = {
    FULL: "full",
    NOPANO: "nopanorama"
}

function _UploadStrategyBar({uploadPressed, isTypeCreate}) {
    const [t] = useTranslation()

    const [strategy, setStrategy] = React.useState("full")

    return (
        <Stack direction={"horizontal"} gap={3}>
            <div style={{whiteSpace: "nowrap"}}>{t("core.upload.strategy")}:</div>
            <Form.Select value={strategy} onChange={(e) => {
                setStrategy(e.target.value)
            }} disabled={isTypeCreate}>
                <option value={UPLOAD_STRATEGIES.FULL}>{t("core.upload.full")}</option>
                <option value={UPLOAD_STRATEGIES.NOPANO}>{t("core.upload.nopanorama")}</option>
            </Form.Select>

            <Button variant={"success"} onClick={() => {
                uploadPressed(strategy)
            }}
                    style={{
                        width: "200px",
                    }}
            >{t("core.upload")}</Button>
        </Stack>

    )
}

function ProjectUploadInfoView({projectId, idUploading, projectName, group, setProjectGroup}) {
    const [t] = useTranslation()

    const [uploadType, setUploadType] = React.useState("Create")
    const [groupName, setGroupName] = React.useState("")

    const [availableGroups, setAvailableGroups] = React.useState([])

    React.useEffect(() => {
        ProjectsApi.checkProjectExistence(projectId).then((result) => {
            const _data = result.data
            if (_data.exists === true) {
                setProjectGroup(_data.group)
                setGroupName(_data.group_name)

                console.log(_data)

                setUploadType("update")
            } else {
                setUploadType("create")
            }
        })

        ProjectGroupsApi.getAllProjectGroups().then((result) => {
            setAvailableGroups(result.data.groups)
        })

    }, [])

    return (
        <>
            <div>
                <Form.Text>{t("core.project.id")}:</Form.Text>
                <Form.Control value={projectId} onChange={() => {
                }} disabled={true}/>
            </div>

            <div>
                <Form.Text>{t("core.project.name")}:</Form.Text>
                <Form.Control value={projectName} onChange={() => {
                }} disabled={true}/>
            </div>

            <Switch condition={uploadType}>
                <Case value={"create"}>
                    <div>
                        <Form.Text>{t("core.project.group")}:</Form.Text>
                        <Form.Select disabled={idUploading} value={group} onChange={(e) => {
                            setProjectGroup(e.target.value)
                        }}>
                            <option value={""}>{t("core.unasigned")}</option>
                            {
                                availableGroups.map((group) => {
                                    return (
                                        <option key={group.uid} value={group.uid}>{group.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </Case>
                <Case value={"update"}>
                    <div>
                        <Form.Text>{t("core.project.group")}:</Form.Text>
                        <Form.Select value={group} disabled={true} onChange={() => {
                        }}>
                            <option value={""}>{t("core.unasigned")}</option>
                            <option value={group}>{groupName}</option>
                        </Form.Select>
                    </div>
                </Case>
            </Switch>

        </>
    )
}


const UploadPieceView = React.forwardRef((props, ref) => {

    const [isFinished, setIsFinished] = React.useState(false)
    const [progress, setProgress] = React.useState(0)
    const [chunkText, setChunkText] = React.useState("-- Ready to start --")
    const [fileName, setFileName] = React.useState("--")

    const startUpload = (onProgress, onFinish) => {

        console.log("Starting upload")

        if (FILES_TO_BE_UPLOADED.length > 0) {


            const _uploadFile = () => {
                setProgress(0)

                const data: File = FILES_TO_BE_UPLOADED.pop()

                if (data) {

                    console.log("Sending <<< ", data.name)
                    setFileName(data.name)
                    ProjectsApi.postFile(data, {
                        onUploadProgress: (progressEvent: ProgressEvent) => {
                        }
                    }).then(() => {
                        console.log("Sended <<< ", data.name)
                        onProgress()
                        _uploadFile()
                    }).catch((e) => {
                        console.error(e)
                        onProgress()
                        _uploadFile()
                    })

                } else {
                    setChunkText("--Finished--")
                    setFileName("--Finished--")
                    setIsFinished(true)
                    onFinish()
                }


            }
            _uploadFile()


            // const _uploadFile = () => {
            //     if (index < _filesSplit.length) {
            //         console.log("Sending <<< ", _filesSplit[index].name)
            //         setFileName(_filesSplit[index].name)
            //         ProjectsApi.postFile(_filesSplit[index]).then(() => {
            //             console.log("Sended <<< ", _filesSplit[index].name)
            //             setProgress((index / (_filesSplit.length - 1)) * 100)
            //             setChunkText(_filesSplit.length - index)
            //             index += 1
            //             _uploadFile()
            //         })
            //     } else {
            //         setChunkText("Finished")
            //         setIsFinished(true)
            //         onFinish()
            //     }
            // }
            // _uploadFile()
        } else {
            setChunkText("Finished")
            setIsFinished(true)
            onFinish()
        }
    }

    React.useImperativeHandle(ref, () => ({
        startUpload,
    }))

    return (
        <div>
            <Form.Text style={{color: isFinished ? "green" : null}}>Chunk: {chunkText} File: {fileName}</Form.Text>
            {/*<ProgressBar variant={isFinished ? "success" : "primary"} now={progress}*/}
            {/*             label={`${progress.toString().substring(0, 6)}%`}*/}
            {/*             style={{*/}
            {/*                 height: "38px",*/}
            {/*                 borderStyle: "solid",*/}
            {/*                 borderColor: "#cfd4da",*/}
            {/*                 borderWidth: "thin",*/}
            {/*             }}*/}
            {/*/>*/}
        </div>
    )
})