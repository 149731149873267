export function milisToMinutes(milis: number) {
    return Math.floor((milis / 1000 / 60) << 0)
}

export function milisToHours(milis: number) {
    return Math.floor((milis / 1000 / 60 / 60) << 0)
}

export function milisToHoursAndMinutes(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return [totalHours, remainingMinutes]
}