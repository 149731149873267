import axios, {AxiosResponse} from "axios";
import {AllAppStatisticsReturn} from "./VisitorsStatisticsApiInterface";


export default class VisitorsStatisticsApi {

    static async getAllAppStatistics(type: string = "D"): Promise<AxiosResponse<AllAppStatisticsReturn>> {
        return await axios.get(`/api/visitors/getallvisits?type=${type}`)
    }

    static async getAllProjectStatistics(projectId: string, type: string = "D"): Promise<AxiosResponse<AllAppStatisticsReturn>> {
        return await axios.get(`/api/visitors/get-project-visits?project_uid=${projectId}&type=${type}`)
    }

    static async getAllProjectsVisits(): Promise<Map<string, number>> {
        const response = await axios.get("/api/visitors/get-all-projects-visits")
        return response.data
    }

    static async GetAllVisitsOfProjectOfType(projectType: number): Promise<number> {
        const response = await axios.get(`/api/visitors/get-visits-of-project-type?type=${projectType}`)
        return response.data.data
    }

}
