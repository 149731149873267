import {useActiveUserZus} from "../zustands/activeUserZus";
import React from "react";


interface _OnlyAdminViewProps {
    children?: string | JSX.Element | JSX.Element[] | any
}


export function OnlySuperAdminView({children}: _OnlyAdminViewProps): any {

    const activeUserZus = useActiveUserZus()
    const activeUser = activeUserZus.activeUser

    if (activeUser.type === "superadmin") {
        return (
            React.Children.toArray(children)
        )
    } else {
        return <></>
    }

}


export function OnlyAdminView({children}: _OnlyAdminViewProps) {

    const activeUserZus = useActiveUserZus()
    const activeUser = activeUserZus.activeUser

    if (activeUser.type === "admin" || activeUser.type === "superadmin") {
        return (
            React.Children.toArray(children) as any
        )
    } else {
        return <></>
    }

}

export function isActiveUserSuperAdmin(): boolean {
    const activeUser = useActiveUserZus.getState().activeUser
    return activeUser.type === "superadmin"
}

export function isActiveUserAdmin(): boolean {
    const activeUser = useActiveUserZus.getState().activeUser
    return activeUser.type === "admin" || activeUser.type === "superadmin"
}