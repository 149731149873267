import {AssetParent} from "./asset_Parent";


export class MeshAsset extends AssetParent {
    folderName = "Meshes"

    exists = false

    fromJsonObject(object: any) {
        super.fromJsonObject(object);
        this.exists = object.custom_data["mesh_exists"]

        // console.log("----------x--------------")
        // console.log(this.exists)


    }
}