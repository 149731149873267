import {QuestionBase} from "./QuestionBase";
import {ImageAnswer} from "./TextQuestion";


export class ImageQuestion extends QuestionBase {
    getCopy() {
        const new_question = new ImageQuestion()
        new_question.name = this.name
        new_question.isMultiAnswer = this.isMultiAnswer

        this.answers.forEach((answer) => {
            new_question.answers.push(answer.getCopy())
        })
        return new_question
    }

    to_json_result(): any {

        return {
            ...super.to_json_result(),
            Type: "image"
        }
    }

    from_json(question_data) {
        // super.from_json(question_data)
        super.from_json(question_data)

        const answers = question_data.Answers ?? []

        answers.forEach((answer_data: any) => {

            if (!answer_data.IsLocked) {
                const answer = new ImageAnswer()
                answer.from_json(answer_data)
                this.answers.push(answer)
            }
        })


    }

    from_json_with_result(question_data) {
        super.from_json_with_result(question_data)

        const answers = question_data.Answers ?? []

        answers.forEach((answer_data: any) => {

            if (!answer_data.IsLocked) {
                const answer = new ImageAnswer()
                answer.from_json_with_result(answer_data)
                this.answers.push(answer)
            }
        })
    }
}
