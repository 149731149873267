import React from "react";

import create from "zustand"

import {FakeProject} from "./lightdata";

export const useInterProjectSearch = create((set) => ({
    refreshVariable: 0,
    availableProjects: [],
    markedProjects: [],

    setAvailableProjects: (projects: Array<FakeProject>) => set((newState) => {
        return {availableProjects: projects, refreshVariable: Math.random()}
    }),
    setMarkedProjects: (projects: Array<FakeProject>) => set((newState) => {
        return {markedProjects: projects}
    }),


}))

