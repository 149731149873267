import React from "react";
import {Circle} from "@react-three/drei";

const VISITED_MATERIAL = <meshBasicMaterial color={"gray"}/>

export default function VisitedSpotOverlay() {
    return (
        <Circle args={[0.35, 32]} position={[0, 0.075, 0]} rotation={[Math.PI / -2, 0, 0]}>
            {VISITED_MATERIAL}
        </Circle>
    )
}