export function elementSwap(array: Array<any>, element: any, direction) {
    const index = array.findIndex(val => element === val)

    let c: any = null

    if ((index + direction) >= array.length) {
        c = array[0]
        array[0] = element
        array[array.length - 1] = c
    } else if ((index + direction) < 0) {
        c = array[array.length - 1]
        array[array.length - 1] = element
        array[0] = c
    } else {
        c = array[index]
        array[index] = array[index + direction]
        array[index + direction] = c
    }
}

export function insertToArray(array: Array<any>, element: any, toIndex: number) {
    array.splice(toIndex, 0, element);
}

export function shuffle(array: Array<any>) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}