import React from 'react';

import {ProjectPlayer} from "./infinity/ProjectPlayer";

import {ActiveProjectSingleton, useActiveProject} from "./project/project_zustands/activeProjectZus";
import {INFINITY_PROJECT_TYPES, InfinityProject} from "./infinity/InfinityProject";
import TaskTourHud from "./gui/controls/TaskTourHud";
import {useEffectOnce} from "./devtools/react_effects";
import {useActiveTestTour} from "./zustands/activeTestTourZus";
import TaskPlayModal from "./gui/controls/TaskPlayModal";
import {useNavigate} from "react-router-dom";
import {useLayoutZus} from "./zustands/layoutZus";
import DeviceDispatcher from "./devtools/DeviceDispatcher";
import {NewWorld} from "./3d/world/NewWorld";


export function WorldPlayer({searchParams}) {

    const navigate = useNavigate()

    const useLayout = useLayoutZus()


    const worldRef = React.useRef<any>()

    const [projectPlayer] = React.useState(new ProjectPlayer())

    const [orientation, setOrientation] = React.useState(false)

    const [prepared, setIsPrepared] = React.useState(false)

    const projectZus = useActiveProject()
    const guidedZus = useActiveTestTour()


    const onPopState = (event) => {
        activateFromState(event.state)
    }

    const activateFromState = (state) => {


        if (state) {
            const activeSpot = state["spot"]
            //Toto nebude fungovat v multi projektovom enviromente treba spravit
            if (activeSpot) {
                if (activeSpot !== "") {
                    projectPlayer.ActivateSpotWithId(activeSpot, true)
                }
            } else {
            }
        } else {
        }

    }

    const activateFromSearchParams = (searchParams) => {

        const tmpSpot = searchParams.get("spot")
        const tmpBoard = searchParams.get("board")


        console.log(searchParams)

        if (projectPlayer.navLinkingEnabled) {
            projectPlayer.expectedSpot = tmpSpot ? tmpSpot : ""
            projectPlayer.expectedBoard = tmpBoard ? tmpBoard : ""
        }
    }


    // const onWorldLoaded = (world: World) => {
    //
    //     activateFromSearchParams(searchParams)
    //
    //     projectPlayer.worldWasLoaded(world)
    //
    // }

    const fun = DeviceDispatcher.isDevelopment() ? useEffectOnce : React.useEffect

    useEffectOnce(() => {

        ProjectPlayer.INSTANCE = projectPlayer

        window.addEventListener("orientationchange", () => {
            setOrientation(!orientation)
        })
        window.onpopstate = onPopState

        const project: InfinityProject = ActiveProjectSingleton.activeProject as InfinityProject

        if (project) {
            activateFromSearchParams(searchParams)

            projectPlayer.worldWasLoaded(worldRef.current)

            projectPlayer.LoadProject(project)

            console.log(`Project is loading: ${project.is_loading}`)


            if (project.type === INFINITY_PROJECT_TYPES.GUIDED) {
                guidedZus.tourRecorded.prepareData(project)
            }

            setIsPrepared(true)
        }


    }, [])

    const worldWasLoaded = (world: World) => {

    }

    const STYLE: any = useLayout.useExperimentalLayout ? {
        position: "relative",
        width: "100%",
        height: "100vh",
        background: "#272730",
        overflowY: "hidden",
        overflowX: "hidden",
        touchAction: "none"
    } : {
        position: "absolute",
        left: "0px",
        top: "0px",
        height: "100%",
        width: "100%",
        background: "#272730",
        overflowY: "hidden",
        overflowX: "hidden",
        touchAction: "none"
    }

    return (
        <div id="background" style={STYLE} className={"not-draggable"}>

            <NewWorld ref={worldRef} onLoaded={worldWasLoaded}/>


            {
                projectZus.project.type === INFINITY_PROJECT_TYPES.GUIDED && (
                    <div>

                        {
                            (prepared && !guidedZus.task && guidedZus.tourRecorded.records.length > 0) && (
                                <TaskPlayModal
                                    cancelEnabled={true}
                                    onHide={() => {
                                    }}
                                    index={0}
                                    onPlayPressed={() => {
                                        guidedZus.tourRecorded.play()
                                    }}
                                    onCancel={() => {
                                        projectZus.setActiveProject(null)
                                        navigate("/projects")
                                    }
                                    }
                                />
                            )
                        }


                        <TaskTourHud/>
                    </div>
                )
            }


        </div>

    )
}

export default WorldPlayer;

