import {AssetParent} from "./asset_Parent";

export class VideoAsset extends AssetParent {

    folderName = "Videos"

    video_ratio_x = 1
    video_ratio_y = 1

    fromJsonObject(object: any) {
        super.fromJsonObject(object)

        this.video_ratio_x = object["video_ratio_x"]
        this.video_ratio_y = object["video_ratio_y"]

    }

}