import {Form, Image, Stack} from "react-bootstrap";
import React from "react";

import {useTranslation} from "react-i18next";
import UsersApi from "../../api/UsersApi";
import { SelectProjectMUISelect} from "../../components/selectviews/ProjectSelectView";
import {SelectUserMUISelect} from "../../components/selectviews/SelectUserView";
import {INFINITY_PROJECT_TYPES} from "../../infinity/InfinityProject";
import urlcWithPath from "../../urlc";


export function TrainingsViewFiltering({
                                           onFiltersReset,
                                           activeProject,
                                           setActiveProject,
                                           activeUser,
                                           setActiveUser,
                                           viewAs,
                                           setViewAs
                                       }) {


    return (
        <div className={"hstack gap-3"}>

            <SelectProjectMUISelect
                activeProject={activeProject}
                setActiveProject={setActiveProject}
                projectType={INFINITY_PROJECT_TYPES.GUIDED}
            />

            <SelectUserMUISelect
                activeUser={activeUser}
                setActiveUser={setActiveUser}
                disableClearUser={true}
            />

            {/*<AvailableTraineesView activeTrainee={activeTrainee} setActiveTrainee={setActiveTrainee}/>*/}

            {/*<FromView activeFrom={activeFrom} setActiveFrom={setActiveFrom}/>*/}
            {/*<ToView activeTo={activeTo} setActiveTo={setActiveTo}/>*/}

            {
                (activeUser || activeProject) &&
                <Image src={urlcWithPath("i-icons/reset_camera.svg")} style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer"
                }}
                       onClick={onFiltersReset}/>

            }


        </div>

    )

}


function AvailableTraineesView(
    {
        activeTrainee, setActiveTrainee
    }
) {
    const [t] = useTranslation()

    const [availableTrainees, setAvailableTrainees] = React.useState([])

    return (
        <>
            <div>{t("core.trainee")}:</div>

            <Form.Select aria-label={"Project"} onChange={(e) => {

            }}>
                <option value={"all"}>--{t("core.all")}--</option>

                {
                    availableTrainees.map((value) => {
                        return (
                            <option key={value.uid} value={value.id}>{value.name}</option>
                        )
                    })
                }
            </Form.Select>


        </>
    )
}


function ToView(
    {
        activeTo, setActiveTo
    }
) {
    const [t] = useTranslation()

    const [availableFrom, setAvailableFrom] = React.useState([])

    return (
        <>
            <div>{t("core.to")}:</div>

            <Form.Select aria-label={"Project"} onChange={(e) => {
                console.log(e.target.value)
            }}>
                <option value={"somevalueA"}>September</option>
                <option value={"someValueB"}>October</option>
            </Form.Select>
        </>
    )
}

function FromView(
    {
        activeFrom, setActiveFrom
    }
) {
    const [t] = useTranslation()

    const [availableFrom, setAvailableFrom] = React.useState([])

    return (
        <>
            <div>{t("core.from")}:</div>

            <Form.Select aria-label={"Project"} onChange={(e) => {
                console.log(e.target.value)
            }}>
                <option value={"somevalueA"}>September</option>
                <option value={"someValueB"}>October</option>
            </Form.Select>
        </>
    )

}

function AvailableTrainersView({activeTrainer, setActiveTrainer: setActiveuser}) {

    const [t] = useTranslation()

    const [availableTrainers, setAvailableTrainers] = React.useState([])

    React.useEffect(() => {
        UsersApi.getAllUsersFromGroup("admin").then((response) => {
            const admins = response.data.users
            UsersApi.getAllUsersFromGroup("superadmin").then((response) => {
                setAvailableTrainers([...response.data.users, ...admins])
            })
        })
    }, [])

    return (
        <>
            <div>{t("core.trainer")}:</div>

            <Form.Select aria-label={"Project"} value={activeTrainer} onChange={(e) => {
                setActiveuser(e.target.value)
            }}>
                <option value={"all"}>--{t("core.all")}--</option>

                {
                    availableTrainers.map((value) => {
                        const identifier = value.email
                        return (
                            <option key={identifier} value={identifier}>{identifier}</option>
                        )
                    })
                }
            </Form.Select>

        </>
    )
}


export function StatisticsViewHeader(
    {}) {


    const [t] = useTranslation()

    return (
        <thead>
        <tr>
            <td><b>{t("core.date")}</b></td>
            <td><b>{t("core.trainee.name")}</b></td>
            {/*<td>{t("core.trainer.name")}</td>*/}
            <td><b>{t("core.successrate")}</b></td>
            <td/>
        </tr>
        </thead>
    )
}

