import React from "react";
import { AreaAsset } from "../../../ela/assets/asset_Area";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import urlcWithPath from "../../../urlc";

interface _AreaPlaneMeshProps {
  area: AreaAsset;
  texture: string;
}

export default function AreaPlaneMesh({ area, texture }: _AreaPlaneMeshProps) {
  const _area: AreaAsset = area;

  const backgroundTexture = useLoader(TextureLoader, urlcWithPath(texture));

  return (
    <group>
      <mesh
        position={[-_area.area_pose.z, 0, _area.area_pose.x]}
        scale={[area.scale_x, area.scale_y, 1]}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial map={backgroundTexture} />
      </mesh>
    </group>
  );
}
