import {QuestionBase} from "./QuestionBase";
import {ImageQuestion} from "./ImageQuestion";
import {TextQuestion} from "./TextQuestion";


export class Quiz {
    name = ""
    questions: Array<QuestionBase> = []
    isEvaluated = false


    getCopy() {
        const tmp = new Quiz()
        tmp.name = this.name
        tmp.isEvaluated = this.isEvaluated

        this.questions.forEach((question) => {
            tmp.questions.push(question.getCopy())
        })

        return tmp


    }

    getSuccessRate() {
        const questionsLength = this.questions.length
        var result = 0

        this.questions.forEach((question) => {
            result += question.GetSuccessRate()
        })

        if (questionsLength > 0) {
            return result / questionsLength
        } else {
            return 0
        }

    }

    to_json_result() {
        return {
            Name: this.name,
            success: this.getSuccessRate(),
            Questions: this.questions_to_array()
        }
    }

    questions_to_array(): any {

        const array = this.questions.map((value) => {
            return value.to_json_result()
        })

        return array
    }


    from_json(quiz_data: any) {
        this.name = quiz_data.Name ?? ""

        const questions = quiz_data.Questions ?? []

        questions.forEach((question_data: any) => {
            const questionType = question_data.Type ?? "text"

            if (questionType === "image") {
                let tmp = new ImageQuestion()
                tmp.from_json(question_data)
                this.questions.push(tmp)

            } else if (questionType === "text") {
                let tmp = new TextQuestion()
                tmp.from_json(question_data)
                this.questions.push(tmp)
            }

        })

    }

    from_json_with_result(quiz_data: any) {
        this.name = quiz_data.Name ?? ""

        const questions = quiz_data.Questions ?? []

        questions.forEach((question_data: any) => {
            const questionType = question_data.Type ?? "text"

            if (questionType === "image") {
                let tmp = new ImageQuestion()
                tmp.from_json_with_result(question_data)
                this.questions.push(tmp)

            } else if (questionType === "text") {
                let tmp = new TextQuestion()
                tmp.from_json_with_result(question_data)
                this.questions.push(tmp)
            }

        })
    }

}