import {AssetParent} from "./asset_Parent";
import axios from "axios";

import {Quiz} from "../quiz/Quiz";


export class QuizAsset extends AssetParent {

    quiz = new Quiz()
    folderName = "Quizzes"

    isLoaded = false

    load_from_json(object: any) {
        super.fromJsonObject(object)
    }

    async sub_load_async() {
        if (this.uid !== "") {
            const quizLoadPath = this.getAbsolutePath()

            if (quizLoadPath !== "") {
                const response = await axios.get(quizLoadPath)
                this.PostLoad(response.data)
            }
        }
        this.isLoaded = true
    }

    PostLoad(quiz_data: any) {
        this.quiz = QuizAsset.getQuizFromJson(quiz_data)
        this.quiz.name = quiz_data["Name"]
    }


    static getQuizFromJson(quiz_data: any) {
        const quiz = new Quiz()
        quiz.from_json(quiz_data)
        return quiz
    }

    static getQuizFromJsonWithResult(quiz_data: any) {

        const quiz = new Quiz()
        quiz.from_json_with_result(quiz_data)

        return quiz
    }


}