export default class DeviceDispatcher {


    static async initImplementation() {

    }

    static isDesktop() {
        return window.__TAURI_IPC__
    }

    static isWeb() {
        return !window.__TAURI_IPC__
    }

    static isDevelopment(): boolean {
        return process.env.NODE_ENV === "development"
    }


}