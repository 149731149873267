import {AssetParent} from "./asset_Parent";
import axios from "axios";


export class TourTaskAsset extends AssetParent {
    folderName = "Tasks"

    tour_task = new TourTask()

    constructor() {
        super();
    }

    getWebPreviewImage(): string {
        return "assets/icons/end_tour.svg"
    }

    async load_from_json_object_async(object: any) {
        super.fromJsonObject(object)

        const result = await axios.get(this.getAbsolutePath())

        this.tour_task._pathPrefix = `${this.GetStorage()}${this.uid}/`

        this.tour_task.from_json_object(result.data)
    }

    load_from_json_object(object: any) {
        super.fromJsonObject(object)


        this.tour_task._pathPrefix = `${this.GetStorage()}${this.uid}/`

        this.tour_task.from_json_object(object)
    }


}

export class TourTask {

    _pathPrefix = ""


    header = ""
    body = ""
    position = 0

    starting_actions = new Array<TourTaskAction>()

    ending_actions = new Array<TourTaskAction>()

    conditions_boards = new Map<string, TourTaskCondition>()

    image_included = false
    image_extension = "jpg"

    constructor() {

    }

    from_json_object(obj: any) {
        console.log(obj)
        this.header = obj.header ?? ""
        this.body = obj.body ?? ""
        this.image_included = obj.image_included ?? false
        this.image_extension = obj.image_extension ?? "jpg"

        this.position = obj.position ?? 0

        this._load_starting_actions(obj.starting_actions ?? [])
        this._load_ending_actions(obj.ending_actions ?? [])
        this._load_condition_board(obj.conditions_boards ?? [])

    }

    _load_condition_board(jObject: Array<any>) {

        Object.keys(jObject).forEach((value) => {
            const _jData = jObject[value]
            this.conditions_boards.set(value, new TourTaskCondition(value, _jData))
        })

    }

    _load_starting_actions(jObject: []) {

        this.starting_actions = jObject.map((actionData: any) => {
            const action = new TourTaskAction()
            action.from_json_object(actionData)
            return action
        })
    }

    _load_ending_actions(jObject: []) {
        this.ending_actions = jObject.map((actionData: any) => {
            const action = new TourTaskAction()
            action.from_json_object(actionData)
            return action
        })
    }

    GetImagePath(): string {
        return `${this._pathPrefix}default.${this.image_extension}`
    }
}


export class TourTaskActionTypes {
    static MOVE_TO_SPOT = "MoveToSpot"
    static DEACTIVATE_BOARD = "DeactivateBoard"
    static ACTIVATE_BOARD = "ActivateBoard"
}


export class TourTaskAction {
    type = ""
    typeData = {}


    from_json_object(jObject: any) {
        this.type = jObject.type

        const board_ids = jObject.boards_ids ?? []

        if (this.type === TourTaskActionTypes.MOVE_TO_SPOT) {
            this.typeData["spot"] = board_ids[0] ?? ""
        } else if (this.type === TourTaskActionTypes.DEACTIVATE_BOARD) {
            this.typeData["spots"] = jObject.boards_ids ?? []
        } else if (this.type === TourTaskActionTypes.DEACTIVATE_BOARD) {
            this.typeData["spots"] = jObject.boards_ids ?? []
        } else {
            console.log("Hide boards is not implemented")
        }
    }

}

export class TourTaskCondition {
    spotUid: string
    boards = new Map<string, boolean>()

    constructor(spotUid: string, boards: Array<string>) {
        this.spotUid = spotUid

        boards.forEach((value) => {
            this.boards.set(value, false)
        })
    }
}


