import {InfinityBoardParent} from "./board_Parent";
import * as three from "three";

import {StaticIncludes} from "../../static_includes";
import {DigitalTwinAsset, DigitalTwinDataSectionTypesProperty} from "../../ela/assets/asset_DigitalTwin";
import {DigitalTwinBoardPopupView} from "../../gui/digitaltwin/DigitalTwinBoardView";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";


class DigitalTwinWorldPanel {

    constructor() {

    }

}


class Alignments {
    static TOP_LEFT = 0
    static TOP_RIGHT = 1
    static BOTTOM_LEFT = 2
    static BOTTOM_RIGHT = 3
    static CENTER = 4
    static LEFT_CENTER = 5
    static RIGHT_CENTER = 6
}


interface TwinBuilderProperties {
    position: three.Vector3;
    width: number;
    asset: three.DigitalTwinAsset;
    parentGroup: three.Group;

}


class TwinBuilder {

    constructor({position, width, asset, parentGroup}: TwinBuilderProperties) {

        const parent = new three.Group()

        console.log(width)
        const planeWidth = (width) * 0.95

        const planeHeight = 0.15

        asset.data.forEach((value: DigitalTwinDataSectionTypesProperty, index) => {
            const segmentGroup = new three.Group()
            parent.add(segmentGroup)

            const bg = new three.MeshBasicMaterial({
                color: new three.Color(0, 0, 0),
                transparent: true,
                opacity: 0.5,
                side: three.DoubleSide
            })

            const plG = new three.PlaneGeometry()
            const plM = new three.Mesh(plG, bg)
            // plM.rotateY(Math.PI / 2)
            plM.scale.set(planeWidth, planeHeight, 1)

            segmentGroup.position.y = -((planeHeight + 0.015) * index)

            segmentGroup.add(plM)

            this.createStaticText(segmentGroup, value.key.split("_")[1], planeHeight, planeWidth, Alignments.TOP_LEFT, 0.04, "white")

            const text = value.isStatic === false ? "---" : value.value + value.unit

            this.createStaticText(segmentGroup, text, planeHeight, planeWidth, Alignments.BOTTOM_RIGHT, 0.055, "white")

            if (value.isStatic === false) {
                this.createStaticText(segmentGroup, "-No server-", planeHeight, planeWidth, Alignments.BOTTOM_LEFT, 0.035, "red")
            }

        })

        parent.position.set(position.x, position.y, position.z)
        parent.scale.set(300, 300, 300)
        parent.lookAt(0, 0, 0)

        parentGroup.add(parent)

    }

    createStaticText(group: three.Group, text: string, height: number, width: number, align: number, fontSize: number, color: string) {
        const font = StaticIncludes._instnace.font

        const shapes = font.generateShapes(text, fontSize);

        const geometry = new three.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        const iconX = -(geometry.boundingBox.max.x / 2)
        const iconY = +geometry.boundingBox.max.y * 2


        const textMesh = new three.Mesh(geometry, color === "white" ? InfinityBoardParent.font_material : new three.MeshBasicMaterial({
            color: new three.Color(color),
        }));


        const zOffset = 0.005
        const sideOffset = 0.075

        if (align === Alignments.TOP_LEFT) {
            const x = (width / -2) + ((width / 2) * sideOffset)
            const y = (height / 2) - (iconY / 2) - (height * sideOffset)
            textMesh.position.set(x, y, zOffset)
            // textMesh.position.set((width / -2) - (iconX * sideOffset), (iconY * -2) - (iconY * sideOffset), zOffset)
        } else if (align === Alignments.BOTTOM_LEFT) {

        } else if (align === Alignments.TOP_RIGHT) {

        } else if (align === Alignments.BOTTOM_RIGHT) {
            const x = (width / 2) + (iconX * 2) - ((width / 2) * sideOffset)
            const y = (height / -2) + (height * sideOffset)
            textMesh.position.set(x, y, zOffset)
            // textMesh.position.set((width / 2) + (iconX * 2) + (iconX * sideOffset), (iconY * -2) - (iconY * sideOffset), zOffset)
        } else if (align === Alignments.CENTER) {

        }


        group.add(textMesh)

    }


}


export class InfinityDigitalTwinBoard extends InfinityBoardParent {
    type = "DigitalTwinBoard"

    constructor(spot) {
        super(spot)
        this.icon = "EI.DigitalTwin"

    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)
        //
        // const multiplyPosition = new three.Vector3(100, 100, 100)
        // const multiplyScale = new three.Vector3(300, 300, 300)
    }

    getTwinAsset(): DigitalTwinAsset {
        const parentProject = this.getParentProject()
        if (parentProject.twins.has(this.contentUid)) {
            return parentProject.twins.get(this.contentUid)
        }
        return null
    }

    isContentValid(): boolean {
        return this.getTwinAsset()
    }

    fillToWorld(world) {
        super.fillToWorld(world)
        return

        if (this.autoUnpack) {
            this.fillToWorldPanel(world)
        } else {
            super.fillToWorld(world)
        }
    }


    clicked(intersection: three.Intersection) {
        GuidedTourManager.taskBoardClicked(this, 1)
        super.fillBoardVisited(1)

        if (true) {
            this.playInModalHud(this.getReactLabelComponent())
        } else {
            if (this.clickableIcon) {
                this.clickableIcon.getRoot().visible = false
            }
            this.fillToWorldPanel(this.getParentProject().getWorld())
        }
    }

    getReactLabelComponent() {
        return <DigitalTwinBoardPopupView asset={this.getTwinAsset()} boardName={this.name}
                                          onclose={() => this.playInModalHud(null)}/>
    }

    fillToWorldPanel(word: World) {
        const maximalizedPos = new three.Vector3()
        maximalizedPos.copy(this.position)
        maximalizedPos.multiply(new three.Vector3(300, 300, 300))

        new TwinBuilder({
            asset: this.getTwinAsset(),
            parentGroup: word.secondaryGroup,
            position: maximalizedPos,
            width: this.scale.x,
        })

        // word.secondaryGroup.add(spm)
    }


}