import React from "react";

import {useTranslation} from "react-i18next";
import {Card, Image, Stack} from "react-bootstrap";


interface IconClickButtonProps {
    icon: string
    lang: string
    onclick: () => any
    disabled?: boolean

}


export default function IconClickButton({icon, lang, onclick, disabled}) {

    const [t, _] = useTranslation()

    return (
        <Card style={{
            margin: "auto",
            cursor: disabled ? null : "pointer",
            background: "white",
            padding: "5px",
            opacity: disabled ? 0.5 : 1
        }}
              onClick={(e) => {
                  if (!disabled) {
                      onclick(e)
                  }
              }}

        >
            <Stack direction={"horizontal"} gap={2}>
                <Image src={icon} style={{
                    width: "35px",
                    height: "35px",
                }}/>
                <div>{t(lang)}</div>
            </Stack>

        </Card>
    )
}
