import React from "react";
import TourStatisticsApi, {GetBoardVisitsResponse} from "../../../api/TourStatisticsApi";
import MyTable from "../../../components/mytable";
import {useTranslation} from "react-i18next";
import {useAvailableProjects} from "../../../api/ProjectsApi";
import {BasicMiddleSpinner} from "../../../components/spinners";
import {FakeProject} from "../../inter_project_search_new/lightdata";

import {ProjectSelectView, SelectProjectMUISelect} from "../../../components/selectviews/ProjectSelectView";

import {TableContainer, TableRow, TableHead, Paper, TableCell, TableBody, Table} from "@mui/material";
import {DashBoardTileNoMiddle} from "../../../components/dashboard/DashBoardTile";


export default function BoardsVisitsView({}) {

    const [projects, isLoading] = useAvailableProjects()

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (
            <_BoardsVisitsView projects={projects}/>
        )
    }

}

function _BoardsVisitsView({projects}: { projects: Array<FakeProject> }) {

    const [fakeProject, setFakeProject] = React.useState<FakeProject | any>(projects.at(0))

    const [data, setData] = React.useState<Array<GetBoardVisitsResponse>>([])

    React.useEffect(() => {

        if (fakeProject) {
            TourStatisticsApi.getBoardVisit(fakeProject.uid).then((response) => {
                setData(response)
            })
        }


    }, [fakeProject])

    return (
        <div className={"vstack gap-3"}>
            <div className={"hstack gap-3"}>
                <div style={{marginLeft: "auto", marginRight: "auto"}}>
                    <DashBoardTileNoMiddle>
                        <SelectProjectMUISelect
                            activeProject={fakeProject}
                            setActiveProject={setFakeProject}/>
                    </DashBoardTileNoMiddle>

                </div>
            </div>

            <_BoardsVisitsTable data={data}/>
        </div>
    )
}


function _BoardsVisitsTable({data}: { data: Array<GetBoardVisitsResponse> }) {

    const [t] = useTranslation()

    return (
        <MyTable>
            <thead>
            <tr>
                <td>Id</td>
                <td>{t("core.spot.name")}:</td>
                <td>{t("core.board.name")}:</td>
                <td>{t("core.visits")}:</td>
            </tr>
            </thead>


            <tbody>

            {
                data.map((value, index) => {

                    return (
                        <tr key={value.BoardUid}>
                            <td>{index + 1}.</td>
                            <td>{value.SpotName}</td>
                            <td>{value.BoardName}</td>
                            <td>{value.Visits}</td>
                        </tr>
                    )

                })
            }

            </tbody>
        </MyTable>
    )

}


function _BoardsVisitsTableNew({data}: { data: Array<GetBoardVisitsResponse> }) {

    const [t] = useTranslation()

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="right">Id</TableCell>
                        <TableCell align="right">{t("core.spot.name")}</TableCell>
                        <TableCell align="right">{t("core.board.name")}</TableCell>
                        <TableCell align="right">{t("core.visits")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((value, index) => (
                        <TableRow
                            key={value.BoardUid}

                        >
                            <TableCell component="th" scope="row">{index + 1}.</TableCell>
                            <TableCell component="th" scope="row">
                                {value.SpotName}
                            </TableCell>

                            <TableCell align="right">{value.BoardName}</TableCell>
                            <TableCell align="right">{value.Visits}</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

