import React from "react"
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FakeProject} from "../inter_project_search_new/lightdata";

import {BasicMiddleSpinner} from "../../components/spinners";


import MyTable from "../../components/mytable";
import {useAvailableProjects} from "../../api/ProjectsApi";

import TourStatisticsApi, {QuizRecordResponse} from "../../api/TourStatisticsApi";

import {SelectUserMUISelect} from "../../components/selectviews/SelectUserView";
import {SelectProjectMUISelect} from "../../components/selectviews/ProjectSelectView";
import {InfinityModal} from "../../components/modals";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import {QuizAsset} from "../../ela/assets/asset_Quiz";
import {formatPercentage} from "../../formatters/percentage";
import QuizzesRatingView from "./QuizzesRatingView";

import AnswerBase from "../../ela/quiz/QuestionBase";
import {Quiz} from "../../ela/quiz/Quiz";
import {DashBoardTileNoMiddle} from "../../components/dashboard/DashBoardTile";


export default function IndividualQuizzesView({}) {
    const [projects, isLoading] = useAvailableProjects()

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (

            <div>

                <_QuizzesStatisticsView projects={projects}/>

            </div>


        )
    }
}


function _QuizzesStatisticsView({projects}: { projects: Array<FakeProject> }) {

    const [t] = useTranslation()

    const [project, setProject] = React.useState(projects.at(0))

    const [user, setUser] = React.useState("")

    const [data, setData] = React.useState<Array<QuizRecordResponse>>([])

    React.useEffect(() => {

        if (project) {
            if (user !== "") {
                TourStatisticsApi.GetQuizResultsOfProjectAndUser(project.uid, user).then((response) => {
                    setData(response)
                })
            } else {
                TourStatisticsApi.GetAllQuizResultsOfProject(project.uid).then((response) => {
                    setData(response)
                })
            }
        } else {

        }

    }, [project, user])


    return (
        <div className={"vstack gap-3"}>

            <_QuizzesStatisticsFilteringBar
                projects={projects}
                activeProject={project}
                activeUser={user}
                setActiveUser={setUser}
                projectChanged={setProject}
            />

            {
                project &&
                <QuizzesRatingView data={data}/>
            }

            <h3 style={{textAlign: "center"}}>{t("core.quiz.individual")}</h3>

            <_StatisticsViewList data={data}/>

        </div>
    )


}

interface _QuizzesStatisticsFilteringBarProps {
    projects: Array<FakeProject>
    activeProject: FakeProject
    projectChanged: (project: FakeProject) => void
    activeUser: string,
    setActiveUser: (user: string) => void
}

function _QuizzesStatisticsFilteringBar
({
     projects,
     activeProject,
     projectChanged,
     activeUser,
     setActiveUser,
 }: _QuizzesStatisticsFilteringBarProps) {

    const [t] = useTranslation()

    return (
        <div style={{marginLeft: "auto", marginRight: "auto"}}>
            <DashBoardTileNoMiddle>
                <div className={"hstack gap-3"}>

                    <SelectProjectMUISelect activeProject={activeProject} setActiveProject={projectChanged}/>
                    <SelectUserMUISelect activeUser={activeUser} setActiveUser={setActiveUser}/>
                </div>
            </DashBoardTileNoMiddle>

        </div>

    )
}

function _StatisticsViewList({data}: { data: Array<QuizRecordResponse> }) {

    const [t] = useTranslation()

    return (
        <MyTable>
            <thead>
            <tr>
                <th>Id</th>
                <th>{t("core.board.name")}</th>
                <th>{t("core.user.identifier")}</th>
                <th>{t("core.date")}</th>
                <th>{t("success")}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                data.map((value, index) => {
                    return (<_QuizRecordResponseTableRow index={index} item={value} key={value.RowKey}/>)
                })
            }

            </tbody>
        </MyTable>
    )
}

function _QuizRecordResponseTableRow({item, index}: { item: QuizRecordResponse, index: number }) {

    const [t] = useTranslation()

    const popup = useGlobalPopup()

    const openClicked = (recordUid: string) => {

        popup.pushElement(<_QuizResultViewModal
                onHide={popup.popElement}
                recordUid={recordUid}
            />
        )

    }

    return (
        <tr>
            <td>{index + 1}.</td>
            <td>{item.BoardName}</td>
            <td>{item.UserUid}</td>
            <td>{item.TimeStamp.toLocaleString()}</td>

            <td>{formatPercentage(item.Success)}</td>
            <td>
                <button
                    className={"btn btn-outline-primary btn-sm"}
                    onClick={() => {
                        openClicked(item.RecordUid)
                    }}

                >
                    {t("core.open")}
                </button>
            </td>
        </tr>
    )
}


function _QuizResultViewModal({onHide, recordUid}) {

    const [t] = useTranslation()

    return (
        <InfinityModal
            closeButton
            size={"xl"}
            onHide={onHide}
            headerChildren={<Modal.Title>{t("core.result")}</Modal.Title>}
            bodyChildren={<_QuizResultViewBody recordUid={recordUid}/>}
            footerChildren={<div style={{height: "25px"}}/>}
        />
    )
}

function _QuizResultViewBody({recordUid}: { recordUid: string }) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [quiz, setQuiz] = React.useState<Quiz | null>(null)

    React.useEffect(() => {

        TourStatisticsApi.getQuizRecord(recordUid).then((value) => {
            const nQuiz = QuizAsset.getQuizFromJsonWithResult(value)
            nQuiz.isEvaluated = true
            setQuiz(nQuiz)
            setIsLoading(false)
        })

    }, [])


    if (isLoading && !quiz) {
        return (
            <>
                <BasicMiddleSpinner/>
            </>
        )

    } else {
        return (
            <>
                <h3 style={{textAlign: "center"}}>{quiz?.name}</h3>

                <div className={"vstack gap-3"}>
                    {
                        quiz?.questions.map((question, index) => {

                            return (
                                <div key={index}>

                                    <h5>{question.name}</h5>

                                    <MyTable>
                                        <thead>

                                        <tr>
                                            <th>Id</th>
                                            <th>{t("core.answer.text")}</th>
                                            <th>{t("core.question.validity")}</th>
                                            <th>{t("core.question.selected")}</th>
                                        </tr>

                                        </thead>

                                        <tbody>

                                        {
                                            question.answers.map((value: AnswerBase, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}.</td>
                                                        <td>{value.text}</td>
                                                        <td>
                                                            <_ValidityCell valid={value.validity}/>
                                                        </td>
                                                        <td>
                                                            <_WasSelectedView validity={value.validity}
                                                                              selected={value.isSelected}/>
                                                        </td>
                                                    </tr>
                                                )

                                            })
                                        }

                                        </tbody>
                                    </MyTable>
                                </div>
                            )

                        })
                    }

                </div>

            </>
        )
    }

}


function _ValidityCell({valid}) {

    if (valid) {
        return (
            <div style={{
                margin: "auto",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "lightgreen"
            }}/>
        )
    } else {
        return null
    }
}


function _WasSelectedView({validity, selected}) {

    let color = "lightgreen"

    if (validity && selected) {
        color = "lightgreen"
    } else if (validity && !selected) {
        return (<></>)
    } else if (!validity && selected) {
        color = "red"
    } else {
        color = "rgba(0, 0, 0, 0)"
    }

    return (
        <div style={{
            margin: "auto",
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: color
        }}/>
    )

}