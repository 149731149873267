import React from "react";
import * as Bs from "react-bootstrap";


import "./QuizPlayer.css"
import {InfinityProject} from "../../../infinity/InfinityProject";
import {useGlobalPopup} from "../../../zustands/globalPopupZus";
import {InfinityModal} from "../../../components/modals";
import ImageZoom from "./ImagePopup";
import IGui from "../../../components/IGui";
import urlcWithPath from "../../../urlc";
import {ImageAsset} from "../../../ela/assets/asset_Image";
import {ImageAnswer} from "../../../ela/quiz/TextQuestion";


const UNCHECKED_ICON = urlcWithPath("i-icons/Checkbox.Empty.svg")
const CHECKED_ICON = urlcWithPath("i-icons/Checkbox.Ok.svg")

const RADIO_ON = urlcWithPath("i-icons/Radio.On.svg")
const RADIO_OFF = urlcWithPath("i-icons/Radio.Off.svg")


const ImageStyle = {
    maxHeight: "150px",
    maxWidth: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5px",
    marginBottom: "5px",
}


function getImage(assetId: string | K, project: InfinityProject): ImageAsset {
    const projectImages = project.images

    if (projectImages.has(assetId)) {
        const path = projectImages.get(assetId).getAbsolutePath()
        return path
    }

}


function EvaluatedAnswer(props) {

    const answer: ImageAnswer = props.answer

    let color = "black"

    if (answer.isSelected && answer.validity) {
        color = "lightgreen"
        //color = "#CAFFCA"
    } else if (answer.isSelected && (answer.validity == false)) {
        color = "red"
        //color = "#FFB3A0"
    } else if ((answer.isSelected == false) && answer.validity) {
        color = "orange"
        //color = "#FFCC00"
    }

    const type = props.isSingle ? "radio" : "checkbox"

    return (
        <div>
            <Bs.Card style={{}}>
                <_CardImage
                    imgPath={getImage(answer.image, props.project)}
                />


                <div style={{color: color, textAlign: "center"}}>
                    <input type={type} className={"CheckBox"} checked={props.answer.isSelected} disabled/>
                    {props.index + 1}. {props.answer.text}
                </div>

            </Bs.Card>

        </div>
    )
}

function _MultiAnswerElement({answer, project, index}) {

    const bgColor = "white"
    const [isSelected, setSeleted] = React.useState(false)

    const onClick = () => {
        if (answer.isSelected) {
            // setBgColor("white")
            // setFontColor("black")
            answer.isSelected = false
            setSeleted(false)
        } else {
            // setBgColor("#0B5ED7")
            // setFontColor("white")
            answer.isSelected = true
            setSeleted(true)
        }

    }

    React.useEffect(() => {
        setSeleted(answer.isSelected)
    }, [answer])

    return (
        <div>
            <Bs.Card style={{

                background: bgColor,
            }}
            >

                <_CardImage
                    imgPath={getImage(answer.image, project)}
                />

                <IGui.BButton
                    className={"QuizButton"} variant={"light"}
                    onClick={onClick}
                    style={{
                        marginLeft: "15px",
                        marginRight: "15px",
                    }}

                >

                    <img src={isSelected ? CHECKED_ICON : UNCHECKED_ICON}
                         style={{
                             width: "20px",
                             height: "20x",
                             margin: "5px",
                             marginTop: "auto",
                             marginRight: "15px",
                             marginBottom: "auto"

                         }}
                    />


                    {index + 1}. {answer.text}
                </IGui.BButton>


            </Bs.Card>

        </div>
    )
}


const SingleAnswerElement = ({answer, project, index, onClick}) => {

    const imgPath = getImage(answer.image, project)

    return (
        <div>
            <Bs.Card style={{}}
                     onClick={() => {
                         onClick(answer)
                     }}>

                <_CardImage
                    imgPath={imgPath}
                />

                <IGui.BButton className={"QuizButton"} variant={"light"}
                              onClick={() => {
                                  onClick(answer)
                              }}
                              style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  cursor: "pointer",
                              }}
                >
                    <img src={answer.isSelected ? RADIO_ON : RADIO_OFF}
                         style={{
                             width: "20px",
                             height: "20x",
                             margin: "5px",
                             marginTop: "auto",
                             marginRight: "15px",
                             marginBottom: "auto"

                         }}
                    />
                    {index + 1}. {answer.text}
                </IGui.BButton>

            </Bs.Card>

        </div>
    )
}


function _CardImage({imgPath}) {

    const popupZus = useGlobalPopup()


    const showPopup = () => {

        popupZus.pushElement(<ImageZoom src={imgPath} onClick={popupZus.popElement}/>)

    }

    return (
        <div style={{
            minHeight: "200px",
            maxHeight: "200px",
        }}>
            <Bs.Card.Img variant="top"
                         src={imgPath}
                         style={{
                             ...ImageStyle,
                             cursor: "zoom-in",


                         }}
                         loading={"lazy"}
                         onClick={showPopup}
                         className={"quizImage"}

            />
        </div>

    )
}

export function ImageQuestionView({activeQuestion, isEvaluationMode, project}) {
    const question: ImageQuestion = activeQuestion

    const [answers, setAnswers] = React.useState([])

    const singleAnswerClicked = (answer) => {

        const tmpAnswers = answers.map((value) => {
            return value
        })

        tmpAnswers.forEach((value) => {
            value.isSelected = false
            answer.isSelected = true
        })

        setAnswers(tmpAnswers)
    }


    const getAnswerView = (id: number) => {
        if (answers.length > id) {
            if (isEvaluationMode) {
                return <EvaluatedAnswer project={project} isSingle={question.isMultiAnswer == false} index={id}
                                        answer={activeQuestion.answers[id]}/>
            } else {
                if (question.isMultiAnswer) {
                    return (
                        <_MultiAnswerElement project={project}
                                             index={id}
                                             answer={answers[id]}

                        />
                    )
                } else {
                    return (
                        <SingleAnswerElement project={project}
                                             index={id}
                                             answer={activeQuestion.answers[id]}
                                             onClick={singleAnswerClicked}
                        />
                    )
                }
            }
        } else {
            return <></>
        }
    }

    const aLength = question.answers.length

    React.useEffect(() => {
        setAnswers(question.answers)
    }, [activeQuestion, isEvaluationMode])


    return (
        <Bs.Container>
            <Bs.Row>
                <Bs.Col>
                    {getAnswerView(0)}
                </Bs.Col>
                <Bs.Col>
                    {getAnswerView(1)}
                </Bs.Col>
            </Bs.Row>

            <Bs.Row style={{marginTop: "15px"}}>
                {
                    aLength > 2 &&
                    <Bs.Col>
                        {getAnswerView(2)}
                    </Bs.Col>
                }

                {
                    aLength > 3 ?
                        <Bs.Col>
                            {getAnswerView(3)}
                        </Bs.Col> : <Bs.Col></Bs.Col>
                }


            </Bs.Row>

        </Bs.Container>
    )
}