import * as three from "three"

export function ellaMatrixToThreeJs(f: any, t: any, r: any): Matrix4 {
    const rotationMatrix = new three.Matrix4()

    rotationMatrix.set(
        f.x, t.x, r.x, 0,
        f.y, t.y, r.y, 0,
        f.z, t.z, r.z, 0,
        0, 0, 0, 1
    )
    return rotationMatrix


}