import React from "react";
import * as three from "three";

import {InfinityBoardParent} from "./board_Parent";

import {DocumentPlayer} from "../../gui/content_players/document/DocumentPlayer";


import {GuidedTourManager} from "../../zustands/activeTestTourZus";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import {BasicMiddleSpinner} from "../../components/spinners";
import AppBackendManager from "../../api/AppBackendManager";
import ProjectsApi from "../../api/ProjectsApi";
import {DocumentAsset} from "../../ela/assets/asset_Document";


interface DocumentPopupProps {
    doc: DocumentAsset
    onClose: () => void;
}

function DocumentPopup({doc, onClose}: DocumentPopupProps) {


    const [isLoading, setIsLoading] = React.useState(true)

    const [urlToSee, setUrlToSee] = React.useState("")


    React.useEffect(() => {
        console.log(doc)
        if (AppBackendManager.isBackendAvailable) {
            ProjectsApi.GetDocumentSasWithUid(doc.projectUid, doc.uid, doc.file_extension).then((value) => {
                setUrlToSee(value)
                setIsLoading(false)
            })
        } else {

            setUrlToSee(doc.getAbsolutePath())
            setIsLoading(false)
        }

    }, [])

    console.log(urlToSee)

    return (
        <>
            <div

                style={{
                    backdropFilter: "blur(5px)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                }}

            >
                {
                    isLoading ? <BasicMiddleSpinner/> :
                        <DocumentPlayer src={urlToSee} onClose={() => {
                            onClose()
                        }}/>
                }


            </div>
        </>

    )


}


export class InfinityDocumentBoard extends InfinityBoardParent {


    type = "document"

    thumbnailMaterial: three.MeshStandardMaterial

    constructor(spot) {
        super(spot)
        this.icon = "EI.DocumentBoard"
        this.useThumbnail = false
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)
        this.useThumbnail = object["useThumbnail"]

    }

    clicked() {

        super.fillBoardVisited(1)
        this.leaveVr()

        const component = this.showDocumentPopup()

    }

    isContentValid(): boolean {
        return this.getDocumentAsset()
    }

    setSelected() {
        if (this.useThumbnail) {
            this.thumbnailMaterial.color.set('#9e9e9e');
        } else {
            super.setSelected();
        }
    }

    setDeselected() {
        if (this.useThumbnail) {
            this.thumbnailMaterial.color.set('#FFFFFF');
        } else {
            super.setDeselected();
        }
    }

    fillToWorld(world: World) {

        if (this.useThumbnail) {
            this.fillThumbNail(world)
        } else {
            super.fillToWorld(world);
        }
    }

    fillThumbNail(world: World) {
        const document = this.getDocumentAsset()

        if (document) {

            const texture = new three.TextureLoader().load(document.getWebPreviewImage())


            this.thumbnailMaterial = new three.MeshBasicMaterial({map: texture})
            const geometry = new three.PlaneGeometry(document.thumbnailRatioX * 0.15, document.thumbnailRatioY * 0.15)
            const mesh = new three.Mesh(geometry, this.thumbnailMaterial)

            mesh.userData["handler"] = this

            const modifier = 300
            const modifiedPos = new three.Vector3()
            modifiedPos.copy(this.position)
            modifiedPos.multiply(new three.Vector3(modifier, modifier, modifier))

            mesh.position.set(modifiedPos.x, modifiedPos.y, modifiedPos.z)
            mesh.scale.set(modifier, modifier, modifier)
            mesh.lookAt(new three.Vector3(0, 0, 0))
            mesh.userData["handler"] = this

            world.secondaryGroup.add(mesh)
        } else {
            super.fillToWorld(world)
        }


    }


    getDocumentAsset() {
        return this.getParentProject().documents.get(this.contentUid)
    }

    showDocumentPopup() {
        const document = this.getDocumentAsset()

        if (document) {
            useGlobalPopup.getState().pushElement(
                <DocumentPopup doc={document} onClose={() => {
                    useGlobalPopup.getState().popElement()
                    GuidedTourManager.taskBoardClicked(this, 1)
                }}/>
            )

        } else {
            alert("No document on this board")
        }
    }


    getRightClickOptions() {
        return <>
            {
                super.getRightClickOptions()
            }
            {/*<ListGroup.Item action onClick={this.openInNewWindow}>Open in new window</ListGroup.Item>*/}
        </>
    }

    openInNewWindow = () => {
        const document = this.getParentProject().documents.get(this.contentUid)
        if (document) {
            window.open(document.getAbsolutePath(), "_blank")
        }
    }

    getWebPreviewImage() {
        const document = this.getDocumentAsset()
        if (document) {
            return document.getWebPreviewImage()
        } else {
            return ""
        }
    }


}