export default class AnswerBase {
    text = ""
    validity = false
    isSelected = false

    getCopy() {
        const new_answer = new AnswerBase()
        new_answer.text = this.text
        new_answer.validity = this.validity
        new_answer.isSelected = this.isSelected
        return new_answer

    }

    to_json_result(): any {
        return {
            AnswerText: this.text,
            Validity: this.validity,
            IsSelected: this.isSelected
        }
    }

    from_json(answer_data: any) {

        this.text = answer_data.AnswerText ?? ""
        this.validity = answer_data.Validity ?? false
    }

    from_json_with_result(answer_data: any) {
        this.from_json(answer_data)
        this.isSelected = answer_data.IsSelected ?? false

    }


}


export class QuestionBase {
    name = ""
    isMultiAnswer = false
    answers: Array<AnswerBase> = []


    GetSuccessRate(): number {
        let successRate = 0

        if (this.isMultiAnswer) {
            let shouldBeGood = 0
            let goodSelected = 0

            this.answers.forEach((value: AnswerBase) => {
                if (value.validity) {
                    ++shouldBeGood
                }
                if (value.validity && value.isSelected) {
                    ++goodSelected
                }
            })

            if (shouldBeGood !== 0) {
                return goodSelected / shouldBeGood
            } else {
                return 0
            }


        } else {
            this.answers.forEach((value: AnswerBase) => {
                if ((value.validity === true) && (value.isSelected === true)) {
                    successRate = 1
                    return
                }
            })

            return successRate
        }

    }

    getCopy() {
        const new_question = new QuestionBase()
        new_question.name = this.name
        new_question.isMultiAnswer = this.isMultiAnswer

        this.answers.forEach((answer) => {
            new_question.answers.push(answer.getCopy())
        })
        return new_question
    }

    to_json_result(): any {

        const answers = this.answers.map((value) => {
            return value.to_json_result()
        })

        return {
            Name: this.name,
            Answers: answers
        }
    }

    from_json_base(question_data: any) {
        this.name = question_data.Name ?? ""
        this.isMultiAnswer = question_data.IsMultiAnswer ?? ""
    }

    from_json(question_data) {
        this.from_json_base(question_data)

    }

    from_json_with_result(question_data) {
        this.from_json_base(question_data)


    }


}

