import * as three from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";

import { PanoramaAsset } from "../assets/asset_Panorama";
import DeviceManager from "../deviceManager";
import PanoramasManager from "../../api/PanoramasManager";

import { shuffle } from "../../devtools/Arrays";
import urlcWithPath from "../../urlc";

export default class PhotoDom {
  theatreEndColor = 0.3;
  moonsCount = 32;
  frontPanoramaMaterials: Array<three.MeshBasicMaterial> = [];

  constructor(
    renderer: three.Renderer,
    camera: three.PerspectiveCamera,
    parent: three.Group,
    options = {}
  ) {
    if (options.moonsCount) {
      this.moonsCount = options.moonsCount;
    }
    this.onLoadingProgress = options["onLoadingProgress"];

    this.renderer = renderer;
    this.camera = camera;

    this.oldPanoramaTexture = new three.Texture();

    this.backPanoramaTexture = new three.TextureLoader().load(
      urlcWithPath("assets/textures/default_dark.png")
    );

    this.backPanoramaMaterial = new three.MeshBasicMaterial({
      map: this.backPanoramaTexture,
      transparent: true,
      side: three.DoubleSide,
      color: new three.Color("red"),
    });

    this.oldPanoramaMaterial = new three.MeshBasicMaterial({
      map: this.oldPanoramaTexture,
      side: three.DoubleSide,
    });

    const oldSphereGeometry = new three.SphereGeometry(1020, 64, 64);
    oldSphereGeometry.scale(-1, 1, 1);

    this.oldSphereMesh = new three.Mesh(
      oldSphereGeometry,
      this.oldPanoramaMaterial
    );
    this.oldSphereMesh.rotateY(-Math.PI / 2);

    this.oldSphereWrapper = new three.Group();
    this.oldSphereWrapper.add(this.oldSphereMesh);

    const backSphereGeometry = new three.SphereGeometry(980, 64, 64);
    backSphereGeometry.scale(-1, 1, 1);

    this.backSphereMesh = new three.Mesh(
      backSphereGeometry,
      this.backPanoramaMaterial
    );
    this.backSphereWrapper = new three.Group();
    this.backSphereWrapper.add(this.backSphereMesh);

    this.frontPanoramasGroup = new three.Group();

    this.frontPanoramaGroupWrapper = new three.Group();
    this.frontPanoramaGroupWrapper.add(this.frontPanoramasGroup);

    for (let i = 0; i < this.moonsCount; i++) {
      const frontPanoramaMaterial = new three.MeshBasicMaterial({
        transparent: false,
        side: three.DoubleSide,
        //color: new three.Color(Math.random(), Math.random(), Math.random())
      });

      const frontSphereGeometry = new three.SphereGeometry(
        960,
        64,
        64,
        0,
        (Math.PI * 2) / this.moonsCount
      );
      frontSphereGeometry.scale(-1, 1, 1);
      const frontSphereMesh = new three.Mesh(
        frontSphereGeometry,
        frontPanoramaMaterial
      );
      frontSphereMesh.rotateY(((-Math.PI * 2) / this.moonsCount) * i);

      this.frontPanoramasGroup.add(frontSphereMesh);
      this.frontPanoramaMaterials.push(frontPanoramaMaterial);
    }

    this.backSphereMesh.rotateY(-Math.PI / 2);
    this.frontPanoramasGroup.rotateY(-Math.PI / 2);

    this.oldSphereWrapper.name = "Old sphere";
    this.backSphereWrapper.name = "Back sphere";
    this.frontPanoramaGroupWrapper.name = "Moons sphere";

    const photoDomGroup = new three.Group();
    photoDomGroup.name = "PhotoDomGroup";

    photoDomGroup.add(this.oldSphereWrapper);
    photoDomGroup.add(this.backSphereWrapper);
    photoDomGroup.add(this.frontPanoramaGroupWrapper);

    parent.add(photoDomGroup);

    this.onAfterPanoramaLoad = null;
    this.isloading = false;
    this.isTweening = false;

    this.scaleDirection = new three.Vector3(0, 0, 0);
    this.moonLoadedIndex = 0;

    this.colorCorrection = new three.Color(1, 1, 1);
  }

  /**
   * options = {
   *  newPanoramaRotation: number,
   *  onPanoramaFullyLoaded: Function,
   *  rotationVector: number[],
   * }
   */
  loadPanoramaAsset(panorama: PanoramaAsset, options = {}) {
    const newPanoramaRotation = options.newPanoramaRotation;
    this.onAfterPanoramaLoad = options.onPanoramaFullyLoaded;

    if (options) {
      this.colorCorrection = options.colorCorrection;
    } else {
      this.colorCorrection = new three.Color(1, 1, 1);
    }

    // this.colorCorrection.r -= 0.5
    // this.colorCorrection.g -= 0.5
    // this.colorCorrection.b -= 0.5

    this.moonLoadedIndex = 0;

    this.isloading = true;

    this.oldSphereMesh.visible = true;
    this.backSphereMesh.visible = false;

    this.frontPanoramasGroup.visible = false;

    this.frontPanoramasGroup.children.forEach((mesh: three.Mesh) => {
      mesh.visible = false;
    });

    this.backPanoramaMaterial.opacity = 1;
    this.backPanoramaMaterial.transparent = true;

    this.splitsLoaded = false;

    this.oldSphereMesh.scale.set(1, 1, 1);

    const rotatable = [this.backSphereMesh, this.frontPanoramasGroup];

    rotatable.forEach((value) => {
      value.rotation.set(0, 0, 0);
      value.rotateY(-Math.PI / 2);

      const matrixVector = options.rotationVector;

      if (true) {
        const rotationMatrix = new three.Matrix4();
        rotationMatrix.set(...matrixVector);

        value.rotation.setFromRotationMatrix(rotationMatrix);
        value.updateMatrixWorld(true);
      } else {
        //value.rotateX(newPanoramaRotation.x)
        value.rotateY(newPanoramaRotation.y);
        //value.rotateZ(newPanoramaRotation.z)
      }
    });

    this.backSphereWrapper.rotation.set(0, 0, 0);
    this.backSphereWrapper.rotateY(-Math.PI / 2);

    this.frontPanoramaGroupWrapper.rotation.set(0, 0, 0);
    this.frontPanoramaGroupWrapper.rotateY(-Math.PI / 2);

    const newColor = this.colorCorrection;
    //const newColor = new three.Color(0.1, 0.1, 0.1)

    this.backPanoramaMaterial.color = newColor;

    this.loadBackTextures(panorama);

    if (DeviceManager.isOculus()) {
      this.loadDefaultPanorama(panorama);
    } else {
      if (
        panorama.file_has_moons &&
        PanoramasManager.ACTIVE_RESOLUTION !== PanoramasManager.REALLY_BAD &&
        PanoramasManager.ACTIVE_RESOLUTION !== PanoramasManager.LOW
      ) {
        this.loadMoons(panorama);
      } else {
        this.loadDefaultPanorama(panorama);
      }
    }

    this.isTweening = true;
    new TWEEN.Tween({ x: 0 })
      .to({ x: 1 }, 1500)
      .onUpdate(this.tweenOpacity)
      .onComplete(this.tweenEnded)
      .start();

    if (this.renderer.xr.isPresenting) {
      console.log("Unimplemented");
      this.renderer.xr.getCamera();
    } else {
      this.camera.getWorldDirection(this.scaleDirection);
    }
  }

  tweenOpacity = (something) => {
    this.backPanoramaMaterial.opacity = something.x;

    const finalScale = new three.Vector3(1, 1, 1);
    finalScale.copy(this.scaleDirection);

    //this.oldSphereMesh.scale.set((finalScale.x + something.x), finalScale.y, finalScale.z)
    //this.oldSphereMesh.scale.x = finalScale.x + something.x
  };

  tweenEnded = (something) => {
    this.isTweening = false;

    this.frontPanoramasGroup.visible = true;

    this.updateFrontFullyLoaded();

    this.backPanoramaMaterial.opacity = 1;
    this.backPanoramaMaterial.transparent = false;

    this.isloading = false;

    if (this.onAfterPanoramaLoad) {
      setTimeout(this.onAfterPanoramaLoad, 1);
    }
  };

  loadBackTextures(panorama: PanoramaAsset) {
    let startIndex = 0;
    let endIndex = panorama.file_lods;

    if (panorama.getFirstLod()) {
      this.backPanoramaMaterial.map = panorama.getFirstLod();
      this.backPanoramaMaterial.map.needsUpdate = true;
      startIndex = 1;
      this.backSphereMesh.visible = true;
    }

    for (let i = startIndex; i < endIndex; i++) {
      const pathToLoad = panorama.getAbsolutePath(i);
      //
      // console.log("Loading:")
      // console.log(pathToLoad)

      new three.TextureLoader()
        .loadAsync(urlcWithPath(pathToLoad))
        .then((texture: three.Texture) => {
          //texture.encoding = three.sRGBEncoding
          if (panorama.is_previewed) {
            this.backPanoramaMaterial.map = texture;
            this.backPanoramaMaterial.map.needsUpdate = true;
            if (i === 0) {
              panorama.setFirstLod(texture);
            }
          }

          this.backSphereMesh.visible = true;

          if (i == endIndex - 1) {
            this.updateFrontFullyLoaded();
          }
        });
    }
  }

  //TODO toto switchovanie treba dat dokopy
  loadDefaultPanorama(panorama: PanoramaAsset) {
    this.backPanoramaMaterial.opacity = 0;

    let path = "";

    if (DeviceManager.isOculus()) {
      path = panorama.GetDefaultPath();
    } else {
      if (PanoramasManager.ACTIVE_RESOLUTION === PanoramasManager.LOW) {
        path = panorama.get2048x1024();
      } else if (
        PanoramasManager.ACTIVE_RESOLUTION === PanoramasManager.REALLY_BAD
      ) {
        path = panorama.getFirstLod();
      } else {
        path = panorama.GetDefaultPath();
      }
    }

    new three.TextureLoader()
      .loadAsync(urlcWithPath(path), (progress) => {})
      .then((texture: three.Texture) => {
        //texture.encoding = three.sRGBEncoding
        if (panorama.is_previewed) {
          this.backPanoramaMaterial.map = texture;
          this.backPanoramaMaterial.needsUpdate = true;

          console.log("------------x----------------");
          console.log("Loaded default sphere");

          this.splitsLoaded = true;
          this.updateFrontFullyLoaded();
        }
      });
  }

  loadMoons(panorama: PanoramaAsset) {
    this.backPanoramaMaterial.opacity = 0;

    this.frontPanoramaMaterials.forEach((material) => {
      material.color = this.colorCorrection;
    });

    if (panorama.file_has_moons) {
      const moonsCount = this.moonsCount;

      let indexes = [];

      for (let moonIndex = 0; moonIndex < moonsCount; moonIndex++) {
        indexes[moonIndex] = moonIndex;
      }

      indexes = shuffle(indexes);

      for (let rndIndex = 0; rndIndex < moonsCount; rndIndex++) {
        const moonIndex = indexes[rndIndex];

        let pathToLoad = "";

        if (DeviceManager.isOculus()) {
          pathToLoad = panorama.getMoonAbsolutePath(moonIndex);
        } else {
          const active = PanoramasManager.ACTIVE_RESOLUTION;

          if (active === PanoramasManager.MEDIUM) {
            pathToLoad = panorama.getMoonAbsolutePath(moonIndex);
          } else if (active === PanoramasManager.HIGH) {
            pathToLoad = panorama.getMoonUltraAbsolutePath(moonIndex);
          }

          //!DeviceManager.isLowPerformance()
        }

        new three.TextureLoader()
          .loadAsync(urlcWithPath(pathToLoad))
          .then((texture: three.Texture) => {
            if (panorama.is_previewed) {
              //console.log(texture.encoding)
              //texture.encoding = three.sRGBEncoding

              const material = this.frontPanoramaMaterials[moonIndex];
              material.map = texture;
              material.needsUpdate = true;

              this.frontPanoramasGroup.children[moonIndex].visible = true;

              // console.log("------------")
              // console.log(this.frontPanoramasGroup.visible)
              // console.log(this.frontPanoramasGroup.children[moonIndex].visible)

              this.moonLoadedIndex++;

              if (this.onLoadingProgress) {
                const progress = (this.moonLoadedIndex / moonsCount) * 100;

                this.onLoadingProgress(progress);
              }

              if (this.moonLoadedIndex == moonsCount) {
                this.splitsLoaded = true;

                if (this.onLoadingProgress) {
                  this.onLoadingProgress(0);
                }

                this.updateFrontFullyLoaded();
              }
            }
          });
      }
    }
  }

  updateFrontFullyLoaded = () => {
    if (this.splitsLoaded && !this.isTweening) {
      setTimeout(this.frontFullyLoaded, 5);
    }
  };

  backFullyLoaded = () => {
    this.isloading = false;
    this.oldSphereMesh.visible = false;
    this.backSphereMesh.visible = true;
    this.frontPanoramasGroup.visible = true;

    if (this.onAfterPanoramaLoad) {
      this.onAfterPanoramaLoad();
    }
  };

  frontFullyLoaded = () => {
    this.isloading = false;
    this.oldSphereMesh.visible = false;
    this.backSphereMesh.visible = true; //Debug

    // this.frontPanoramasGroup.visible = true

    this.splitsLoaded = false;

    this.oldPanoramaMaterial.map = this.backPanoramaMaterial.map;
    this.oldPanoramaMaterial.needsUpdate = true;

    this.oldSphereMesh.rotation.copy(this.backSphereMesh.rotation);

    this.oldSphereWrapper.rotation.set(0, 0, 0);
    this.oldSphereWrapper.rotateY(-Math.PI / 2);
  };

  startFading = (onFadeFinished: Function) => {
    const tween = new TWEEN.Tween({
      r: this.colorCorrection.r,
      g: this.colorCorrection.g,
      b: this.colorCorrection.b,
    })
      .to(
        {
          r: this.theatreEndColor,
          g: this.theatreEndColor,
          b: this.theatreEndColor,
        },
        500
      )
      .onUpdate(this._tweenFade);

    if (onFadeFinished) {
      tween.onComplete(onFadeFinished);
    }

    tween.start();
  };

  endFade = (onFadeFinished) => {
    const tween = new TWEEN.Tween({
      r: this.theatreEndColor,
      g: this.theatreEndColor,
      b: this.theatreEndColor,
    })
      .to(
        {
          r: this.colorCorrection.r,
          g: this.colorCorrection.g,
          b: this.colorCorrection.b,
        },
        1500
      )
      .onUpdate(this._tweenFade);

    if (onFadeFinished) {
      tween.onComplete(onFadeFinished);
    }

    tween.start();
  };

  _tweenFade = (newValue) => {
    const color = new three.Color();
    color.r = newValue.r;
    color.g = newValue.g;
    color.b = newValue.b;

    this.frontPanoramaMaterials.forEach((material) => {
      material.color = color;
    });
  };

  step() {
    // const elements = this.camera.matrix.elements
    //
    // const fx = elements[0]
    // const fy = elements[4]
    // const fz = elements[8]
    //
    // const angle = Math.sin(fz / (Math.sqrt(
    //     (Math.pow(fx, 2) + Math.pow(fz, 2))
    // )))
    //
  }
}
