import axios from "axios";

export default class AppBackendManager {

    static isBackendAvailable = false

    static async checkBackendAvailability() {


        await axios.get("/api/is_backend_available").then((response) => {
            console.log("Backend is available, running in serverside mode")
            AppBackendManager.isBackendAvailable = response.data
        }).catch((e) => {
            console.log("Backend is not available, running in static page mode")
            console.log(e)
            AppBackendManager.isBackendAvailable = false
        })

        return AppBackendManager.isBackendAvailable


    }

}