export function saveChartData(data: Array<any>) {
    if (data.length > 0) {
        data.splice(0, 0, {
            "name": "",
            "value": 0,
        })

        data.push({
            "name": "",
            "value": 0,
        })
    }
}