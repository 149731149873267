import {FakeBoard, FakeProject, FakeSpot} from "./lightdata";
import {Card, Col, Image, Row, Stack} from "react-bootstrap";

import React from "react";

import {useNavigate} from "react-router-dom";
import FakeLinkButton from "../../components/FakeLinkButton";

export default function UniversalInterProjectTilesView({visibleProjectWithResult}) {


    return (
        <>
            {
                visibleProjectWithResult.map((project: FakeProject) => {
                    return (
                        <div key={project.uid}>
                            <h2 style={{textAlign: "center", marginBottom: "15px"}}>{project.name}</h2>

                            <Row xs={1} md={4} className="g-4">
                                {
                                    project.searchCache.map((value) => {
                                        return (<_TileDispatcher element={value} key={value.uid}/>)
                                    })
                                }
                            </Row>

                        </div>
                    )
                })
            }
        </>
    )
}


function _TileDispatcher({element}) {
    if (element.type === "InfoBoard") {
        return (<_InfoBoardTile element={element}/>)
    } else {
        return (<_UniversalTile element={element}/>)
    }

}

const CARD_STYLE = {
    cursor: "pointer",
}

const IMAGE_STYLE = {
    objectFit: "contain",
    marginTop: "5px",

    marginLeft: "auto",
    marginRight: "auto",
}

function _UniversalTile({element}) {

    const navigate = useNavigate()

    const _element: FakeBoard | FakeSpot = element

    return (
        <Col>
            <Card style={CARD_STYLE} onClick={() => {
                navigate(_element.urlPath)
                navigate(0)
            }}>
                <Card.Body>
                    <Card.Img src={_element.previewPath} style={IMAGE_STYLE} loading={"lazy"}/>
                </Card.Body>
                <Card.Footer>
                    <Card.Title style={{textAlign: "center"}}>{_element.name}</Card.Title>
                </Card.Footer>
            </Card>
        </Col>
    )

}

const InfoCardStyle = {
    background: "#F7F7F7",
}

const CardImageStyle = {
    maxHeight: "250px",
    maxWidth: "250px",
    objectFit: "contain",
    margin: "5px 5px 5px 5px",
    marginLeft: "auto",
    marginRight: "auto",
}

function _InfoBoardTile({element}) {
    const navigate = useNavigate()

    const _element: FakeBoard = element

    return (
        <Col>
            <Card style={{...InfoCardStyle}}>

                <Card.Header>
                    <Stack direction={"horizontal"}>

                        <Card.Title>{_element.name}</Card.Title>
                    </Stack>
                </Card.Header>

                <Card.Body>
                    <h6 dangerouslySetInnerHTML={{__html: _element.header}} style={{textOverflow: "ellipsis"}}/>

                    {
                        _element.previewPath !== "" && <Image src={_element.previewPath}
                                                              style={{
                                                                  objectFit: "contain",
                                                                  maxWidth: "250px",
                                                                  maxHeight: "250px",
                                                                  cursor: "pointer"
                                                              }}
                                                              onClick={() => {
                                                                  navigate(element.urlPath)
                                                                  navigate(0)
                                                              }}/>

                    }

                    <div>
                        <p dangerouslySetInnerHTML={{__html: _element.body}} style={{
                            textOverflow: "ellipsis",
                        }}/>
                    </div>

                </Card.Body>

                <Card.Footer>

                    {
                        _element.url != "" && <a href={_element.url} target={"_blank"}
                                                 style={{textOverflow: "ellipsis"}}>
                            {_element.url}
                        </a>
                    }
                    <FakeLinkButton lang={"core.open"} color={"blue"} onclick={() => {
                        navigate(_element.urlPath)
                    }}/>
                </Card.Footer>

            </Card>
        </Col>
    )
}

