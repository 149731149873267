import React from "react";
import {useTranslation} from "react-i18next";

import {useGlobalPopup} from "../../zustands/globalPopupZus";

import ProjectSelectOffcavnas from "../project/SelectProjectOffcanvas";

import {InputLabel, Select, MenuItem, Button, FormControl, SelectChangeEvent} from "@mui/material";

import {FakeProject} from "../../routes/inter_project_search_new/lightdata";

interface SelectProjectBarButtonProps {
    activeProject: FakeProject | null
    setActiveProject: (project: FakeProject) => void
    projectType?: null | number
}

export function ProjectSelectView({activeProject, setActiveProject}: SelectProjectBarButtonProps) {

    const [t] = useTranslation()


    const popupZus = useGlobalPopup()

    const selectProjectPressed = () => {
        popupZus.pushElement(
            <ProjectSelectOffcavnas onHide={popupZus.popElement} onProjectSelected={(project) => {
                setActiveProject(project)
                popupZus.popElement()
            }}/>
        )
    }

    return (
        <>
            <div>{t("core.project")}:</div>

            <button
                className={"btn btn-primary"}
                onClick={selectProjectPressed}
                style={{whiteSpace: "nowrap"}}
            >
                {
                    activeProject ? activeProject.name : "--"
                }
            </button>
        </>
    )

}

export function SelectProjectMUISelect({activeProject, setActiveProject, projectType}: SelectProjectBarButtonProps) {

    const [t] = useTranslation()


    const popupZus = useGlobalPopup()

    const selectProjectPressed = () => {
        popupZus.pushElement(
            <ProjectSelectOffcavnas
                projectType={projectType}
                onHide={popupZus.popElement}
                onProjectSelected={(project) => {
                    setActiveProject(project)
                    popupZus.popElement()
                }}/>
        )
    }

    const tmp = (
        <>
            <div>{t("core.project")}:</div>

            <button
                className={"btn btn-primary"}
                onClick={selectProjectPressed}
                style={{whiteSpace: "nowrap"}}
            >
                {
                    activeProject ? activeProject.name : "    --    "
                }
            </button>
        </>
    )

    return (

        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.project")}:</InputLabel>
            <Select
                value={"0"}
                label={"project"}
                open={false}
                onOpen={selectProjectPressed}
            >
                <MenuItem value={"0"}>
                    {activeProject ? activeProject.name : "--"}
                </MenuItem>
            </Select>
        </FormControl>


    )

}
