import React from "react";

import "./DashBoardTile.css"

import {Card} from "react-bootstrap";

interface DashBoardTileProps {
    disableCenter?: boolean
    style?: React.CSSProperties
    children: any
    onClick?: () => void
}


const INNER_DIV_STYLE: any = {
    position: "absolute",
    left: "0px",
    top: "50%",
    transform: "translate(0%, -50%)",

}

const INNER_DIV_STYLE_CENTER: any = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",

}


export default function DashBoardTile({children, disableCenter, onClick, style}: DashBoardTileProps) {


    return (
        <Card className={"DashBoardTile"}

              style={{
                  height: "150px",
                  position: "relative",
                  cursor: onClick ? "pointer" : "default",
                  ...(style ? style : {})
              }}
        >
            <Card.Body>
                <div
                    onClick={onClick}>


                    <div style={disableCenter ? INNER_DIV_STYLE : INNER_DIV_STYLE_CENTER}>
                        {
                            React.Children.toArray(children)
                        }
                    </div>


                </div>
            </Card.Body>

        </Card>
    )
}

interface DashBoardTileNoMiddleProps {
    style?: React.CSSProperties
    children: any
}


export function DashBoardTileNoMiddle({children, style}: DashBoardTileNoMiddleProps) {
    return (
        <Card className={"DashBoardTile"} style={style ? style : {}}>
            <Card.Body>
                <div>
                    {
                        React.Children.toArray(children)
                    }
                </div>
            </Card.Body>

        </Card>

    )
}