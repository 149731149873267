import React from "react";
import * as Bs from "react-bootstrap";


import "./icon_click_buttons.css"
import urlcWithPath from "../urlc";

export {
    SearchClickButton,
    HideToggleButton,
    CrossButton,
    UniversalButton,
    UniversalRoundButton,
    CloseButton
}

const SearchClickButton = (props) => {
    const style = {
        width: "35px", height: "35px", ...props.style
    }
    return (
        <Bs.Image src={urlcWithPath("i-icons/search_icon.svg")} onClick={props.onClick} style={style}/>
    )

}

const HideToggleButton = () => {
    const style = {
        width: "25px", height: "25px"
    }
    return (
        <Bs.Image src={urlcWithPath("i-icons/search_icon.svg")} style={style}/>
    )

}


const CrossButton = (props) => {
    const css = {
        position: "absolute",
        width: "15px",
        height: "15px",
        top: "5px",
        right: "5px",
        cursor: "pointer",

    }
    return (
        <Bs.Image style={css}
                  onClick={props.onClick}
                  src={urlcWithPath("i-icons/cross_button.svg")}
        />
    )
}


const UniversalButton = (props) => {
    const customStyle = {
        // width: "45px",
        // height: "45px",
        ...props.style,
        cursor: "pointer",
        textAlign: "center",
        display: "block",


    }

    const imageStyle = {
        objectFit: "fill ",
    }

    return (
        <div style={customStyle} onClick={props.onClick}>
            <Bs.Image className={"MinimapMaximalizeIcon"}
                      src={props.src}
                      style={imageStyle}
            />

        </div>
    )
}

const UniversalRoundButton = (props) => {
    const customStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        borderRadius: "50%",
        cursor: "pointer",
        justifyContent: "center",
        display: "flex",
        width: props.width ? props.width : "25px",
        height: props.height ? props.height : "25px",
        ...props.style,
    }

    const imageStyle = {
        //objectFit: "fill",
        width: "50%",
        height: "50%",
        margin: "25% 25% 25% 25%"
    }

    return (
        <div style={customStyle} onClick={props.onClick}>
            <Bs.Image
                src={props.iconSrc}
                style={imageStyle}
            />
        </div>
    )

}

const CloseButton = (props) => {
    const style = {
        position: "absolute",
        top: "0px",
        right: "0px",
        marginRight: "-10px",
        marginTop: "-10px",
    }
    return (
        <UniversalRoundButton style={{...style, ...props.style}} {...{...props, iconSrc: urlcWithPath("i-icons/cross_button.svg")}}/>
    )
}
