import React from "react";

import { useTranslation } from "react-i18next";

import {
  INFINITY_PROJECT_TYPES,
  InfinityProjectFetch,
} from "../../infinity/InfinityProject";
import useCookie from "react-use-cookie";
import ProjectsApi from "../../api/ProjectsApi";
import { ViewContainer } from "../../shared/containers";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import * as Bs from "react-bootstrap";
import EuropeMap from "../../routes/projects/ProjectsMapView";
import { useNavigate } from "react-router-dom";
import MyTable from "../mytable";

import Switch, { Case } from "react-switch-case";
import LicenceManager from "../../api/LicenceManager";
import { FakeProject } from "../../routes/inter_project_search_new/lightdata";
import { useActiveUserZus } from "../../zustands/activeUserZus";
import UsersApi from "../../api/UsersApi";
import IGui from "../IGui";
import urlcWithPath from "../../urlc";

interface SelectProjectViewProsp {
  onSelected: (project: any) => void;
  projectType?: undefined | number;
}

export default function SelectProjectView({
  onSelected,
  projectType,
}: SelectProjectViewProsp) {
  const userZus = useActiveUserZus();

  const [t] = useTranslation();

  const [projects, setProjects] = React.useState<Array<InfinityProjectFetch>>(
    new Array()
  );

  const [visibleProjects, setVisibleProjects] = React.useState<
    Array<InfinityProjectFetch>
  >(new Array());

  const [viewas, setViewAs] = useCookie("projects-view-viewas", "tiles");

  React.useEffect(() => {
    const canUserIterateProjects = UsersApi.canUserIterateProjects();

    if (canUserIterateProjects) {
      if (projectType) {
        ProjectsApi.getAllProjectsOfType(projectType).then((response) => {
          setProjects(response);
          setVisibleProjects(response);
        });
      } else {
        ProjectsApi.getAllProjects().then((response) => {
          setProjects(response);
          setVisibleProjects(response);
        });
      }
    }
  }, []);

  const onSearchTyping = (event) => {
    const searchKey = event.target.value;

    if (searchKey === "") {
      setVisibleProjects(projects);
    } else {
      const filteredProject = projects.filter(
        (project) =>
          project.name.toLowerCase().search(searchKey.toLowerCase()) != -1
      );
      setVisibleProjects(filteredProject);
    }
  };

  return (
    <Container>
      <div
        style={{
          width: "100%",
          maxWidth: "100%",
          marginBottom: "15px",
        }}
      >
        <Stack gap={3} direction={"horizontal"}>
          <Bs.Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            onChange={onSearchTyping}
          />

          <Bs.Form.Text style={{ whiteSpace: "nowrap" }}>
            {t("core.viewas")}:
          </Bs.Form.Text>

          <Bs.Form.Select
            onChange={(e) => {
              setViewAs(e.target.value);
            }}
            value={viewas}
            style={{ maxWidth: "150px" }}
          >
            <option value={"tiles"}>{t("core.tiles")}</option>
            <option value={"table"}>{t("core.table")}</option>
            {LicenceManager.projectsMapEnabled && (
              <option value={"map"}>{t("core.map")}</option>
            )}
          </Bs.Form.Select>
        </Stack>
      </div>

      <Switch condition={viewas}>
        <Case value={"table"}>
          <TableProjectView
            visibleProjects={visibleProjects}
            onSelected={onSelected}
          />
        </Case>
        <Case value={"tiles"}>
          <TilesProjectView
            visibleProjects={visibleProjects}
            onSelected={onSelected}
          />
        </Case>
        <Case value={"map"}>
          <EuropeMap
            visibleProjects={visibleProjects}
            onSelected={onSelected}
          />
        </Case>
      </Switch>
    </Container>
  );
}

interface TilesProjectViewProsp {
  onSelected: (project: any) => void;
  visibleProjects: Array<InfinityProjectFetch>;
}

function TilesProjectView({
  visibleProjects,
  onSelected,
}: TilesProjectViewProsp) {
  const [t] = useTranslation();

  const tours = visibleProjects.filter(
    (value) => value.ProjectType === INFINITY_PROJECT_TYPES.BASIC
  );

  const trainigns = visibleProjects.filter(
    (value) => value.ProjectType === INFINITY_PROJECT_TYPES.GUIDED
  );

  return (
    <div className={"vstack gap-3"}>
      {tours.length > 0 && (
        <>
          <h3>{t("core.tours")}</h3>
          <Row xs={1} md={4} className="g-4">
            {tours.map((value) => {
              return (
                <Col key={value.uid}>
                  <ProjectThumbnail
                    project={value}
                    onSelected={onSelected}
                    icon={urlcWithPath(
                      "infinity/" +
                        value.uid +
                        "/Panoramas/" +
                        value.welcome_panorama +
                        "/preview.png"
                    )}
                    text={value.name}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {trainigns.length > 0 && (
        <>
          <h3>{t("core.trainings")}</h3>
          <Row xs={1} md={4} className="g-4">
            {trainigns.map((value) => {
              return (
                <Col key={value.uid}>
                  <ProjectThumbnail
                    project={value}
                    onSelected={onSelected}
                    icon={urlcWithPath(
                      "infinity/" +
                        value.uid +
                        "/Panoramas/" +
                        value.welcome_panorama +
                        "/preview.png"
                    )}
                    text={value.name}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
}

interface ProjectThumbnailProsp {
  icon: string;
  text: string;
  project: any;
  description?: string;
  onSelected: any;
}

function ProjectThumbnail({
  icon,
  text,
  project,
  description,
  onSelected,
}: ProjectThumbnailProsp) {
  const style: any = {
    cursor: "pointer",
  };

  const imageStyle: any = {
    objectFit: "contain",

    marginLeft: "auto",
    marginRight: "auto",
  };

  const tmp = (
    <Card
      style={style}
      onClick={(e) => {
        onSelected(project);
      }}
    >
      <Card.Body>
        <Card.Img src={icon} style={imageStyle} loading={"lazy"} />
      </Card.Body>
      <Card.Footer>
        <div
          style={{
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {text}
        </div>
      </Card.Footer>
    </Card>
  );

  const cardMediaProps = { loading: "lazy" } as any;

  return (
    <IGui.Card>
      <IGui.CardMedia
        sx={{ height: 140 }}
        {...cardMediaProps}
        image={icon}
        style={{
          cursor: "pointer",
        }}
        onClick={(e) => {
          onSelected(project);
        }}
      />

      <IGui.CardContent>
        <IGui.Typography
          gutterBottom
          variant="h6"
          component="div"
          style={{
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {text}
        </IGui.Typography>
      </IGui.CardContent>
    </IGui.Card>
  );
}

function TableProjectView({ visibleProjects, onSelected }) {
  const [t] = useTranslation();

  return (
    <MyTable>
      <thead>
        <tr>
          <td>
            <b>Id</b>
          </td>
          <td>
            <b>{t("core.name")}</b>
          </td>
          <td>
            <b>{t("core.project.type")}</b>
          </td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        {visibleProjects.map((value, index) => {
          return (
            <tr key={value.uid}>
              <td>{index + 1}</td>
              <td>{value.name}</td>
              <td>
                {t(value.ProjectType === 0 ? "core.tour" : "core.training")}
              </td>
              <td>
                <button
                  className={"btn btn-outline-primary btn-sm"}
                  onClick={() => {
                    onSelected(value);
                  }}
                >
                  {t("core.open")}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </MyTable>
  );
}
