import React from "react";
import DashBoardTile from "../../../components/dashboard/DashBoardTile";
import {useTranslation} from "react-i18next";
import TourStatisticsApi, {UsersTrainingCountResponse} from "../../../api/TourStatisticsApi";
import {BasicMiddleSpinner, OnlySpinner} from "../../../components/spinners";
import {formatPercentage} from "../../../formatters/percentage";
import {milisToHours, milisToHoursAndMinutes, milisToMinutes} from "../../../formatters/time";
import {Spinner} from "react-bootstrap";
import ByProjectTypesList from "../ByProjectTypesList";
import {INFINITY_PROJECT_TYPES} from "../../../infinity/InfinityProject";
import ProjectsApi from "../../../api/ProjectsApi";


export default function TrainingStatisticsDashboardView({}) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState<UsersTrainingCountResponse>({TrainingsCount: 0, UsersCount: 0})

    React.useEffect(() => {
        setIsLoading(true)

        TourStatisticsApi.getUsersTrainingsCount().then((response) => {
            setValue(response)
            setIsLoading(false)
        })
    }, [])


    return (
        <div>

            <div className="row">
                <div className="col-3">
                    <_TotalTrainingsProjectsCount/>
                </div>
                <div className="col-3">
                    <_TotalTrainingsCount isLoading={isLoading} value={value.TrainingsCount}/>
                </div>
                <div className="col">
                    <_TotalUsersCount isLoading={isLoading} value={value.UsersCount}/>
                </div>
                <div className="col">
                    <_AverageSuccessRate/>
                </div>
            </div>

            <ByProjectTypesList projectType={INFINITY_PROJECT_TYPES.GUIDED}/>

            <div className={"row"}>
                <div className={"col-4"}>
                    <_TotalTrainingTime/>

                </div>
                <div className={"col-4"}>
                    <_AvgTrainingTime/>
                </div>
            </div>

        </div>
    )

}


const BOARD_STYLE: any = {height: "100px"}


function _TotalTrainingsProjectsCount({}) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState(0)

    React.useEffect(() => {

        setIsLoading(true)

        ProjectsApi.GetAllProjectsOfType(INFINITY_PROJECT_TYPES.GUIDED).then((value) => {
            setValue(value.length)
            setIsLoading(false)
        })

    }, [])

    return (
        <DashBoardTile style={{height: "100px"}}>
            <div className={"noWrap"}>{t("core.projects.count")}:</div>

            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{value}</h3>
            }
        </DashBoardTile>
    )
}

function _TotalTrainingsCount({isLoading, value}) {

    const [t] = useTranslation()

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.total.visits")}:</div>

            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{value}</h3>
            }

        </DashBoardTile>
    )
}

function _TotalUsersCount({isLoading, value}) {

    const [t] = useTranslation()

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.statistics.total-trainees")}:</div>
            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{value}</h3>
            }
        </DashBoardTile>
    )
}

function _AverageSuccessRate({}) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)
    const [value, setValue] = React.useState(0)

    React.useEffect(() => {
        setIsLoading(true)
        TourStatisticsApi.getAverageSuccessRate().then((response) => {
            setValue(response)
            setIsLoading(false)
        })
    }, [])


    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("core.success.average")}:</div>
            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{formatPercentage(value)}</h3>
            }
        </DashBoardTile>
    )
}

function _AvgTrainingTime({}) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState(0)


    React.useEffect(() => {
        TourStatisticsApi.getAverageTrainingDuration().then((response) => {
            setValue(response)
            setIsLoading(false)
        })
    }, [])

    const time = milisToHoursAndMinutes(value)

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("training.duration.avg")}:</div>
            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{time[0]} h {time[1]} m</h3>
            }
        </DashBoardTile>
    )
}


function _TotalTrainingTime({}) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(true)
    const [value, setValue] = React.useState(0)

    React.useEffect(() => {
        TourStatisticsApi.getTotalTrainingDuration().then((response) => {
            setValue(response)
            setIsLoading(false)
        })
    }, [])

    const time = milisToHoursAndMinutes(value)

    return (
        <DashBoardTile style={BOARD_STYLE}>
            <div className={"noWrap"}>{t("training.duration.total")}:</div>
            {
                isLoading ? <OnlySpinner/> : <h3 style={{textAlign: "center"}}>{time[0]} h {time[1]} min</h3>
            }
        </DashBoardTile>
    )
}


