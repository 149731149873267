import React from "react";
import {Stack} from "react-bootstrap";
// import MinimapSettings from "./settings360/MinimapSettings";
import {Editor360Only} from "../components/licencing/LicenceWrappers";

// import SpotEditorSettings from "./settings360/SpotEditorSettings";
// import BrandingSettings from "./settings360/BrandingSettings";
import {useTranslation} from "react-i18next";

import TourSettings from "./settings360/TourSettings";

export default function Settings360() {

    const [t] = useTranslation()

    return (
        <Stack gap={3}>
            {/*<MinimapSettings/>*/}

            <TourSettings/>

            <Editor360Only>
                {/*<SpotEditorSettings/>*/}
                {/*<BrandingSettings/>*/}
            </Editor360Only>
        </Stack>
    )
}