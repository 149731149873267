import React from "react";


import create from "zustand"
import {InfinityProject} from "../infinity/InfinityProject";




export const useAvailableProjectsZus = create((set) => ({
    availableProjects: [],
    setAvailableProjects: (projects: Array<InfinityProject>) => set((newState) => {
        return {availableProjects: projects}
    }),
}))

