import {Spinner} from "react-bootstrap";
import React from "react";


export function BasicMiddleSpinner() {
    return (
        <div style={{
            display: "flex",
        }}>
            <div style={{
                position: "absolute",
                left: "50%",
                top: "50%",
            }}>

                <div style={{
                    height: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}>
                    <Spinner animation={"border"} variant={"danger"} style={{
                        margin: "auto"
                    }}/>
                </div>
            </div>
        </div>

    )
}

export function OnlySpinner() {
    return (
        <Spinner animation={"border"} variant={"danger"}/>
    )
}