import React from "react";
import * as Bs from "react-bootstrap";
import { InfinityProject } from "../infinity/InfinityProject";
import { useActiveProject } from "../project/project_zustands/activeProjectZus";
import LicenceManager from "../api/LicenceManager";
import urlcWithPath from "../urlc";

function openProjectLink(link) {
  if (link === "" || link === null) {
    window.open("https://exe.sk/");
  } else {
    window.open(link);
  }
}

export function Branding() {
  const activeProjecZus = useActiveProject();

  const brandingStyle: any = {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    marginRight: "10px",
    marginBottom: "10px",
    width: "50px",
    cursor: "pointer",
    opacity: 0.5,
  };

  const link =
    LicenceManager.organizationLink !== ""
      ? LicenceManager.organizationLink
      : activeProjecZus.project.link;

  return (
    <Bs.Image
      onClick={() => openProjectLink(link)}
      src={urlcWithPath(`branding/branding_short.svg`)}
      style={brandingStyle}
    />
  );
}
