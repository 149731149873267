import {PanoramaAsset} from "../ela/assets/asset_Panorama";
import {MeshAsset} from "../ela/assets/asset_Mesh";
import {SoundAsset} from "../ela/assets/asset_Sound";
import {VideoAsset} from "../ela/assets/asset_Video";
import {DocumentAsset} from "../ela/assets/asset_Document";
import {QuizAsset} from "../ela/assets/asset_Quiz";
import {LabelAsset} from "../ela/assets/asset_Label";

import {InfinitySpot} from "./InfinitySpot";
import {AreaAsset} from "../ela/assets/asset_Area";
import {ImageAsset} from "../ela/assets/asset_Image";
import {OutlineAsset} from "../ela/assets/asset_Outline";
import {DigitalTwinAsset} from "../ela/assets/asset_DigitalTwin";
import {TourTaskAsset} from "../ela/assets/asset_TourTask";
import urlcWithPath from "../urlc";
import WebProjectProperties from "../data/WebProjectProperties";
import StorageApi from "../api/StorageApi";
import axios from "axios";

export class InfinityProjectStatus {
    static STATUS_NOT_LOADED = 0;
    static STATUS_LOADED = 1;
    static STATUS_LOADING = 2;
    static STATUS_LOAD_FAILED = 3;
}

export interface InfinityProjectFetch {
    name: string;
    uid: string;
    description: string;
    url_link: string;
    welcome_panorama: string;
    ProjectType: number;
}

export const INFINITY_PROJECT_TYPES = {
    BASIC: 0,
    GUIDED: 1,
};


export class InfinityProject {
    name: string;
    description: string;
    uid: string;
    link: string;
    type: number = INFINITY_PROJECT_TYPES.BASIC;

    status: number = InfinityProjectStatus.STATUS_NOT_LOADED;

    spots: Map<string, InfinitySpot> = new Map();

    sounds: Map<string, SoundAsset> = new Map();
    videos: Map<string, VideoAsset> = new Map();
    meshes: Map<string, MeshAsset> = new Map();
    quizzes: Map<string, QuizAsset> = new Map();
    documents: Map<string, DocumentAsset> = new Map();
    panoramas: Map<string, PanoramaAsset> = new Map();
    labels: Map<string, LabelAsset> = new Map();
    areas: Map<string, AreaAsset> = new Map();
    images: Map<string, ImageAsset> = new Map();
    outlines: Map<string, OutlineAsset> = new Map();
    twins: Map<string, DigitalTwinAsset> = new Map();

    tasks: Map<string, TourTaskAsset> = new Map();
    tasks_list: Array<TourTaskAsset> = new Array();

    web_props = new WebProjectProperties()

    tour_configuration = {}

    run_token = "";

    multimap = new Map([
        ["sounds", this.sounds],
        ["videos", this.videos],
        ["meshes", this.meshes],
        ["quizzes", this.quizzes],
        ["documents", this.documents],
        ["panoramas", this.panoramas],
        ["labels", this.labels],
        ["areas", this.areas],
        ["spots", this.spots],
        ["images", this.images],
        ["outlines", this.outlines],
        ["twins", this.twins],
        ["tasks", this.tasks],
    ]);

    welcomeSpotUid: string = "";
    welcomePanoramaUid: string = "";
    footerEnabled: boolean = true;

    activePlayer = null;

    task_tour_configuration = {}

    is_loading = true

    constructor() {
        this.name = "";
        this.uid = "";
        this.link = "";
        this.description = "";
    }

    GetStatus(): number {
        return this.status;
    }

    getAbsolutePath(): string {
        return urlcWithPath("infinity/" + this.uid + "/");
    }

    async load_from_json_async(data: any) {
        this.status = InfinityProjectStatus.STATUS_LOADING;

        this.name = data["name"];
        this.description = data["description"];
        this.uid = data["uid"];
        this.type = data.type ?? INFINITY_PROJECT_TYPES.BASIC;

        await this._LoadFromDictInternal_Full(data)

        await this._LoadWebProjectProperties(data)

        this.status = InfinityProjectStatus.STATUS_LOADED;
    }

    async _LoadWebProjectProperties() {
        const dataStr = await StorageApi.GetFileStringSafe(this.uid, "web_config.json", "{}")
        this.web_props.FromJson(JSON.parse(dataStr))
    }

    async _LoadFromDictInternal_Full(data: any) {
        this.welcomeSpotUid = data["welcome_spot"];
        this.welcomePanoramaUid = data["welcome_panorama"];

        this.tour_configuration = data.tour_configuration ?? {}

        this.task_tour_configuration = data.task_tour_configuration ?? {}

        await Promise.all([
            this.Load_PanoramasFromJsonObject(data["panoramas"] ?? []),
            this.Load_DocumentsFromJsonObject(data["documents"] ?? []),
            this.Load_VideosFromJsonObject(data["videos"] ?? []),
            this.Load_SoundsFromJsonObject(data["sounds"] ?? []),
            this.Load_MeshesFromJsonObject(data["meshes"] ?? []),
            this.Load_LabelsFromJsonObject(data["labels"] ?? []),
            this.Load_AreasFromJsonObject(data["areas"] ?? []),
            this.Load_ImagesFromJsonObject(data["images"] ?? []),
            this.Load_OutlinesFromJsonObject(data["outlines"] ?? []),
            this.Load_TwinsFromJsonObject(data["digital_twin"] ?? []),
            this.Load_TourTasksFromJsonObject(data["tasks"] ?? []),
        ]);

        this.Load_QuizzesFromJsonObject(data["quizzes"] ?? []);

        const spots = data["spots"];

        if (spots) {
            spots.forEach((value: any) => {
                const tmpSpot = new InfinitySpot(this);
                tmpSpot.LoadFromJsonObject(value);
                this.spots.set(tmpSpot.uid, tmpSpot);
            });
        }

        this.spots.forEach((value) => {
            value.PostLoad();
        });

        this.is_loading = false
    }

    getFooterPath(): string {
        return urlcWithPath("infinity/" + this.uid + "/footer.png");
    }

    Load_QuizzesFromJsonObject(quizData: []) {
        for (let i = 0; i < quizData.length; i++) {
            const tmpQuiz = new QuizAsset();
            tmpQuiz.projectUid = this.uid;
            tmpQuiz.load_from_json(quizData[i]);
            this.quizzes.set(tmpQuiz.uid, tmpQuiz);
        }
    }

    async Load_OutlinesFromJsonObject(outlinesData: []) {
        outlinesData.forEach((value) => {
            const tmpOutline = new OutlineAsset();
            tmpOutline.projectUid = this.uid;
            tmpOutline.fromJsonObject(value);
            this.outlines.set(tmpOutline.uid, tmpOutline);
        });
    }

    async Load_TourTasksFromJsonObject(tasksData: []) {

        console.log("----------------")
        console.log(this.type)
        console.log(this.type === INFINITY_PROJECT_TYPES.GUIDED)

        if (this.type === INFINITY_PROJECT_TYPES.GUIDED) {


            const MERGED_FILE_PATH = `infinity/${this.uid}/Tasks/merge.json`

            const data = await axios.get(MERGED_FILE_PATH).catch(_ => () => {
                console.log(`Unable to find: ${MERGED_FILE_PATH}`)
                return null
            })

            if (data) {
                console.log("Loading from merge")

                const _data = data.data

                console.log(_data)

                for (const o of _data) {

                    console.log(o)
                    const tmpTask = new TourTaskAsset();
                    tmpTask.projectUid = this.uid;
                    tmpTask.load_from_json_object(o)
                    this.tasks.set(tmpTask.uid, tmpTask);
                    this.tasks_list.push(tmpTask);
                }

                console.log(this.tasks)

            } else {

                console.log("Loading task as old one")

                const promisses = []

                for (let i = 0; i < tasksData.length; i++) {
                    const tmpTask = new TourTaskAsset();
                    tmpTask.projectUid = this.uid;

                    promisses.push((async () => {
                        await tmpTask.load_from_json_object_async(tasksData[i]);
                        this.tasks.set(tmpTask.uid, tmpTask);
                        this.tasks_list.push(tmpTask);
                    })())
                }

                await Promise.all(promisses)
            }


        }
    }

    async Load_TwinsFromJsonObject(twinData: []) {
        twinData.forEach((value) => {
            const tmpTwin = new DigitalTwinAsset();
            tmpTwin.projectUid = this.uid;
            tmpTwin.uid = value.uid;
            tmpTwin.fromJsonObject(value);
            this.twins.set(tmpTwin.uid, tmpTwin);
        });
    }

    async Load_AreasFromJsonObject(areasData: []) {
        areasData.forEach((value) => {
            const tmpArea = new AreaAsset();
            tmpArea.projectUid = this.uid;
            tmpArea.fromJsonObject(value);
            this.areas.set(tmpArea.uid, tmpArea);
        });
    }

    async Load_VideosFromJsonObject(videosData: Array<any>) {
        videosData.forEach((value) => {
            const tmpVideo = new VideoAsset();
            tmpVideo.projectUid = this.uid;
            tmpVideo.fromJsonObject(value);
            this.videos.set(tmpVideo.uid, tmpVideo);
        });
    }

    async Load_ImagesFromJsonObject(videosData: Array<any>) {
        videosData.forEach((value) => {
            const tmpVideo = new ImageAsset();
            tmpVideo.projectUid = this.uid;
            tmpVideo.fromJsonObject(value);
            this.images.set(tmpVideo.uid, tmpVideo);
        });
    }

    async Load_SoundsFromJsonObject(soundsData: Array<any>) {
        soundsData.forEach((value) => {
            const tmpSound = new SoundAsset();
            tmpSound.projectUid = this.uid;
            tmpSound.fromJsonObject(value);
            this.sounds.set(tmpSound.uid, tmpSound);
        });
    }

    async Load_DocumentsFromJsonObject(documentsData: Array<any>) {
        documentsData.forEach((value) => {
            const tmpDocument = new DocumentAsset();
            tmpDocument.projectUid = this.uid;
            tmpDocument.fromJsonObject(value);
            this.documents.set(tmpDocument.uid, tmpDocument);
        });
    }

    async Load_MeshesFromJsonObject(meshesData: Array<any>) {
        meshesData.forEach((value) => {
            const tmpMesh = new MeshAsset();
            tmpMesh.projectUid = this.uid;
            tmpMesh.fromJsonObject(value);
            this.meshes.set(tmpMesh.uid, tmpMesh);
        });
    }

    async Load_LabelsFromJsonObject(labelsData: Array<any>) {
        labelsData.forEach((value) => {
            const tmpLabel = new LabelAsset();
            tmpLabel.projectUid = this.uid;
            tmpLabel.fromJsonObject(value);
            this.labels.set(tmpLabel.uid, tmpLabel);
        });
    }

    async Load_PanoramasFromJsonObject(panoramasData: Array<any>) {
        panoramasData.forEach((value) => {
            const tmpPanorama = new PanoramaAsset();
            tmpPanorama.fromJsonObject(value);
            tmpPanorama.projectUid = this.uid;
            this.panoramas.set(tmpPanorama.uid, tmpPanorama);
        });
    }

    getSpotsJsonData() {
        const result = new Array();
        this.spots.forEach((spot) => {
            result.push({
                uid: spot.uid,
                name: spot.name,
                picture: spot.GetPanoramaAsset().getWebPreviewImage(),
                isActive: spot.is_previewed,
            });
        });

        return result;
    }

    getWebPreviewImage() {
        return `infinity/${this.uid}/Panoramas/${this.welcomePanoramaUid}/preview.png`;
        if (this.spots.has(this.welcomeSpotUid)) {
            return this.spots.get(this.welcomeSpotUid).getWebPreviewImage();
        } else {
            return "";
        }
    }

    getBoardsOnFilters(type: string, filter: string) {
        let boards = [];

        this.spots.forEach((value) => {
            boards = boards.concat(value.getBoardsOfType(type, filter));
        });

        return boards;
    }

    getBoardsOnTagFilters(type: string, tag: string) {
        let boards = [];

        this.spots.forEach((value) => {
            boards = boards.concat(value.getBoardsOnTagFilter(type, tag));
        });

        return boards;
    }

    getBoardsCountOnType(type: string, filter: string) {
        let count = 0;

        this.spots.forEach((value) => {
            count += value.getBoardTypeCount(type);
        });

        return count;
    }

    getAssetsOnFilters(filters: string[]) {
        let result = [];
        filters.forEach((value) => {
            result = result.concat(Array.from(this.multimap.get(value).values()));
        });

        return result;
    }

    getWorld() {
        return this.activePlayer.worldWrapper.getWorld();
    }
}
