import React from "react"
import * as Bs from "react-bootstrap"


export let GlobalProgressBarInstance: GlobalProgressBar = null


export class GlobalProgressBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: 0
        }
    }

    componentDidMount() {
        GlobalProgressBarInstance = this
    }

    setValue(value) {
        this.setState({value: value})
    }

    render() {
        const now = 60

        const style = {
            position: "absolute",
            width: "100%",
            height: "5px",
            bottom: "0px",
            left: "0px",
        }

        return (
            <div style={style}>
                {
                    (this.state.value == 0 || this.state.value >= 100) ? <></> :
                        <Bs.ProgressBar now={this.state.value} label={`${now}%`} visuallyHidden/>
                }

            </div>
        )
    }
}
