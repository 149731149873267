import React from "react";
import {Circle} from "@react-three/drei";





export default function ActiveSpotVisualization({wasVisited, pointScale, color}) {
    return (

        <Circle
            args={[1, 64]}
            scale={pointScale * 0.5}
            position={[0, 0.05, 0]}
            rotation={[Math.PI / -2, 0, 0]}>
            <meshBasicMaterial color={color}/>

        </Circle>
    )
}