import {AssetParent} from "./asset_Parent";
import axios from "axios";


export class AreaAssetModelLayer {

    name = ""
    filePath = ""
    local_position = null
    scale = null
    id = "layer_0"

    static NewFromJson(jObject: {}, assetPath) {
        const layer = new AreaAssetModelLayer()
        layer.id = jObject["id"]
        layer.filePath = assetPath + layer.id + ".glb"
        layer.name = jObject["name"]
        layer.local_position = jObject["local_position"]
        layer.scale = jObject["scale"]


        return layer

    }

    getMeshPath(): string {
        return this.filePath
    }

    constructor() {

    }

}

export class AreaAsset extends AssetParent {

    scale_x = 277
    scale_y = 208
    floor = 0

    folderName = "Areas"
    fileExists = false

    meshExists = false
    mesh_object_pose = null
    mesh_object_scale = null

    spots_color = {x: 1, y: 1, z: 1}
    spots_color_selected = {x: 0, y: 1, z: 0}
    area_pose = {x: 0, y: 0, z: 0}

    layers: Array<AreaAssetModelLayer> = []


    getAbsolutePath(): string {
        return this.getMaximapPath()
    }

    getMinimapPath() {
        return `${this.GetStorage()}${this.uid}/minimap.${this.file_extension}`
    }

    getMaximapPath() {
        return `${this.GetStorage()}${this.uid}/maximap.${this.file_extension}`
    }

    getMeshPath() {
        return `${this.GetStorage()}${this.uid}/model.glb`
    }

    getWebPreviewImage(): string {
        return this.getMinimapPath()
    }

    fromJsonObject(object: any) {
        super.fromJsonObject(object)
        this.floor = object["floor"]
        this.scale_x = object["scale_x"]
        this.scale_y = object["scale_z"]

        const cData = object["custom_data"]

        this.fileExists = cData["map_exists"]
        this.meshExists = cData["mesh_exists"]

        this.spots_color = object["spots_color"] ?? this.spots_color
        this.spots_color_selected = object["spots_color_selected"] ?? this.spots_color_selected

        this.area_pose = object.area_pose ?? this.area_pose

        if (this.meshExists) {


            this.mesh_object_pose = object["mesh_object_pose"]
            this.mesh_object_scale = object["mesh_object_scale"]


            axios.get(`${this.GetStorage()}${this.uid}/model_layers.json`).then((value) => {
                value.data["model_layers"].forEach((layerData) => {
                    this.layers.push(AreaAssetModelLayer.NewFromJson(layerData, `${this.GetStorage()}${this.uid}/`))

                })

            }).catch((e) => {
                console.error(e)
            })
        }

    }

}