import {AssetParent} from "./asset_Parent";

export class LabelAsset extends AssetParent {


    header = ""
    body = ""
    image_uid = ""
    url = ""


    getAbsolutePath() {
        return "Labels/" + this.uid + "/" + this.name + "." + this.file_extension
    }

    fromJsonObject(object: any) {
        super.fromJsonObject(object)
        this.header = object["header"]
        this.body = object["body"]
        this.image_uid = object["image_uid"]
        this.url = object["url"]
    }
}