import urlcWithPath from "../../urlc";

export class AssetParent {
  uid: string = "";
  file_extension: string = "";
  name: string = "";
  projectUid: string = "";
  url: string = "";
  description: string = "";
  folderName: string = "";

  fromJsonObject(object: any) {
    this.uid = object["uid"];
    this.file_extension = object["file_extension"];
    this.name = object["name"];
    this.url = object["url"];
    this.description = object["description"];
  }

  getAbsolutePath() {
    return `${this.GetStorage()}${this.uid}/default.${this.file_extension}`;
  }

  GetStorage(): string {
    return (`infinity/${this.projectUid}/${this.folderName}/`);
  }

  getWebPreviewImage(): string {
    return `${this.GetStorage()}${this.uid}/preview.png`;
  }
}
