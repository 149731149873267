import React, {useRef} from "react";
import {useInterProjectSearch} from "./ipszus";
import ProjectsApi from "../../api/ProjectsApi";
import {InfinityProject, InfinityProjectFetch} from "../../infinity/InfinityProject";
import {Container, Form, FormControl, Spinner, Stack} from "react-bootstrap";
import {FakeProject} from "./lightdata";
import {useTranslation} from "react-i18next";
import useCookie from "react-use-cookie";


import Switch, {Case} from "react-switch-case";
import AllSearchView from "./new_ips_all_search";

import ByNameSearchView from "./NewIpsByNameSearchView";
import AppBackendManager from "../../api/AppBackendManager";
import {ViewContainer} from "../../shared/containers";


export default function SearchView({projectUid}) {

    const [loading, setIsLoading] = React.useState(false)

    const avlProjects = useInterProjectSearch();

    const projectsLoaded = (allProjects: Array<FakeProject>) => {
        avlProjects.setAvailableProjects([...allProjects])
        avlProjects.setMarkedProjects([...allProjects])

        setTimeout(() => {
            setIsLoading(false)
        }, 500)

    }

    React.useEffect(() => {
        const realProjects: Array<InfinityProject> = []

        setIsLoading(true)


        if (AppBackendManager.isBackendAvailable) {
            ProjectsApi.getSearchCache().then((response) => {

                projectsLoaded(response)
            })
        } else {

            ProjectsApi.getAllProjects().then((response) => {
                const tmpProjects: Array<InfinityProjectFetch> = response

                tmpProjects.forEach((value) => {

                    ProjectsApi.getSearchableProject(value.uid).then((project) => {
                        realProjects.push(project)

                        if (realProjects.length === tmpProjects.length) {
                            projectsLoaded(realProjects)
                        }
                    })
                })
            })

        }


    }, [])

    if (loading) {
        return (
            <div style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, 50%)"
            }}>
                <Spinner animation={"border"} variant={"danger"}/>
            </div>
        )
    } else {
        return (
            <ViewContainer>
                <_NewInterProjectSearchView activeProjectUid={projectUid}/>
            </ViewContainer>

        )
    }


}

function _NewInterProjectSearchView({activeProjectUid}) {

    const dispatcherRef = useRef()
    const searchLabelRef = useRef()

    const availableProjects = useInterProjectSearch(state => state.availableProjects)
    const setMarkedProjects = useInterProjectSearch(state => state.setMarkedProjects)

    const [searchType, setSearchType] = useCookie("ip_search_type", "byname")
    const [searchedProject, setSearchedProject] = React.useState("all")
    const [viewAs, setViewAs] = useCookie("ip_view_as", "table")
    const [category, setCategory] = useCookie("ip_category", "")


    const _setCategory = (category) => {
        setCategory(category)

        setTimeout(() => {
            const searchValue = searchLabelRef.current.value
            dispatcherRef.current.onSearchTyping(searchValue)
        }, 10)


    }

    const onSearchTyping = (e: any) => {
        const text = e.target.value.toLowerCase().replace("\\", "/")

        dispatcherRef.current.onSearchTyping(text)
    }


    const refreshSearchedProjects = (projects: Set<string>) => {

        if (projects.size === 1 && Array.from(projects.values())[0] === "all") {
            setMarkedProjects(availableProjects)
        } else {
            const tmp = new Array()

            availableProjects.forEach((value: FakeProject) => {
                if (projects.has(value.uid)) {
                    tmp.push(value)
                }
            })
            setMarkedProjects(tmp)
        }


    }

    const searchTypeChanged = (value) => {
        setSearchType(value)

    }

    const projectsSetFromString = (value: string) => {
        return new Set([value])
    }

    const searchedProjectChanged = (newProject: string) => {
        setSearchedProject(newProject)
        refreshSearchedProjects(projectsSetFromString(newProject))
    }


    React.useEffect(() => {

        if (activeProjectUid) {
            setSearchedProject(activeProjectUid)
            refreshSearchedProjects(projectsSetFromString(activeProjectUid))
        } else {
            refreshSearchedProjects(projectsSetFromString(searchedProject))
        }

    }, [availableProjects])

    return (
        <Container>
            <Stack gap={3}>

                <Stack direction={"horizontal"} gap={3}>

                    <_SearchTypeSelectionView searchType={searchType} setSearchType={searchTypeChanged}/>

                    <_SearchedProjectSelectionView searchedProject={searchedProject}
                                                   setSearchedProject={searchedProjectChanged}/>

                    <_ViewAsSelectionType activeValue={viewAs} setViewAs={setViewAs} searchType={searchType}/>
                    {
                        searchType === "byname" &&
                        <_CategorySelectionType category={category} setCategory={_setCategory}/>
                    }
                </Stack>


                {/*<BasicSearchInput*/}
                {/*    ref={searchLabelRef}*/}
                {/*    onSearchTyping={onSearchTyping}*/}
                {/*/>*/}


                <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    ref={searchLabelRef}
                    onChange={onSearchTyping}
                    style={{
                        width: "100%",
                        maxWidth: "100%",
                        marginBottom: "15px"
                    }}
                />

                <_SearchViewDispatcher category={category} searchType={searchType} viewAs={viewAs} ref={dispatcherRef}/>
            </Stack>
        </Container>
    )


}

function _SearchTypeSelectionView({searchType, setSearchType}) {


    const [t, _] = useTranslation()

    return (
        <>
            <div>{t("core.search")}:</div>

            <Form.Select aria-label={"Search"} value={searchType} onChange={(e) => {
                setSearchType(e.target.value)
            }} style={{maxWidth: "250px"}}>
                <option value={"byname"}>{t("core.search.byname")}</option>
                <option value={"bytags"}>{t("core.search.bytags")}</option>
            </Form.Select>

        </>
    )

}

function _SearchedProjectSelectionView({searchedProject, setSearchedProject}) {

    const [t, _] = useTranslation()

    const availableProjects = useInterProjectSearch()

    return (
        <>
            <div>{t("core.project")}:</div>

            <Form.Select aria-label={"Projects"} value={searchedProject} onChange={(e) => {
                setSearchedProject(e.target.value)
            }} style={{maxWidth: "250px"}}>
                <option value={"all"}>{t("core.projects.all")}</option>

                {
                    availableProjects.availableProjects.map((value: InfinityProject, index) => {
                        return (
                            <option key={`${value.uid}-${index}`} value={value.uid}>{value.name}</option>
                        )
                    })
                }
            </Form.Select>

        </>
    )
}

function _CategorySelectionType({category, setCategory}) {
    const [t, _] = useTranslation()


    return (
        <>
            <div style={{whiteSpace: "nowrap"}}>{t("core.type")}:</div>

            <Form.Select value={category} onChange={(e) => {
                setCategory(e.target.value)
            }}
                         style={{maxWidth: "250px"}}
            >
                <option value={""}>--{t("core.all")}--</option>
                <option value={"Spot"}>{t("spots")}</option>
                <option value={"VideoBoard"}>{t("videos")}</option>
                <option value={"DocumentationBoard"}>{t("documents")}</option>
                <option value={"InfoBoard"}>{t("labels")}</option>
                <option value={"MeshBoard"}>{t("meshes")}</option>
                <option value={"SoundBoard"}>{t("sounds")}</option>
                <option value={"OutlineBoard"}>{t("outlines")}</option>
                <option value={"QuizBoard"}>{t("quizzes")}</option>
            </Form.Select>
        </>
    )
}

function _ViewAsSelectionType({activeValue, setViewAs, searchType}) {
    const [t, _] = useTranslation()

    const disabled = searchType === "bytags"

    return (
        <>

            <div style={{whiteSpace: "nowrap"}}>{t("core.viewas")}:</div>

            <Form.Select value={disabled ? "table" : activeValue} onChange={(e) => {
                setViewAs(e.target.value)
            }}
                         style={{maxWidth: "250px"}}
                         disabled={disabled}
            >
                <option value={"tiles"}>{t("core.tiles")}</option>
                <option value={"table"}>{t("core.table")}</option>
            </Form.Select>
        </>
    )


}


const _SearchViewDispatcher = React.forwardRef(({category, searchType, viewAs}, ref) => {

    const activeViewRef = useRef()

    const onSearchTyping = (searchKey) => {
        activeViewRef.current.onSearchTyping(searchKey)
    }

    React.useImperativeHandle(ref, () => ({
        onSearchTyping,
    }))

    return (
        <Switch condition={searchType}>
            <Case value={"byname"}>
                {/*<AllSearchView ref={activeViewRef} searchType={searchType}/>*/}
                <ByNameSearchView ref={activeViewRef} category={category} viewAs={viewAs}/>
            </Case>

            <Case value={"bytags"}>
                <AllSearchView ref={activeViewRef} searchType={searchType}/>
            </Case>

        </Switch>
    )

})