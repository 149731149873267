import axios, {AxiosResponse} from "axios";
import {GetUserResponseInterface, UserInterface} from "./UserApiInterfaces";
import React from "react";
import LicenceManager from "./LicenceManager";
import {useActiveUserZus} from "../zustands/activeUserZus";

export default class UsersApi {
    static async createUser(email, group) {
        const form = new FormData()
        form.set("user_mail", email)
        form.set("group", group)

        return await axios.post("/api/users/create_user", form)
    }

    static async getUserData(userIdentifier: string): Promise<AxiosResponse<GetUserResponseInterface>> {
        return await axios.get(`/api/users/getuser?user_identifier=${userIdentifier}`)
    }

    static async getUserLastVisit(userIdentifier: string): Promise<AxiosResponse<GetUserResponseInterface>> {
        return await axios.get(`/api/visitors/getuser-last-visit?user_identifier=${userIdentifier}`)
    }

    static async getAllUsersLastVisit(): Promise<AxiosResponse<GetUserResponseInterface>> {
        const result = await axios.get("/api/visitors/get-all-users-visits")

        return result.data
    }

    static async removeUser(userIdentifier: string) {
        return await axios.delete(`/api/users/removeuser?user_identifier=${userIdentifier}`)
    }

    static async changeUserGroup(userIdentifier: string, group: string) {
        return await axios.put(`/api/users/change-user-group?user_identifier=${userIdentifier}&group=${group}`)
    }


    static async getAllUsersFromGroup(group: string): Promise<AxiosResponse<IUsersResponse>> {
        return await axios.get(`/api/users/get${group}s`)
    }

    static async getAllUsers() {
        return await axios.get(`/api/users/getallusers`)
    }

    static canUserIterateProjects(): boolean {
        if (LicenceManager.f360.users_projects_listing) {
            return true
        } else {
            const user = useActiveUserZus.getState().activeUser
            return user.type !== "user"
        }
    }

    static canUserSearch(): boolean {
        if (LicenceManager.f360.can_user_search) {
            return true
        } else {
            const user = useActiveUserZus.getState().activeUser
            return user.type !== "user"
        }
    }

}

interface IUsersResponse {
    users: Array<UserInterface>
}


export function useAvailableUsers(group: string | null = null): [Array<UserInterface>, boolean] {

    const [isLoading, setIsLoading] = React.useState(true)

    const [availableUsers, setAvailableUsers] = React.useState<Array<any>>([])

    React.useEffect(() => {
        setIsLoading(true)
        if (group === null) {
            UsersApi.getAllUsers().then((response) => {
                setAvailableUsers(response.data.users)
                setIsLoading(false)
            })
        } else {
            UsersApi.getAllUsersFromGroup(group).then((response) => {
                setAvailableUsers(response.data.users)
                setIsLoading(false)
            })
        }

    }, [])

    return [availableUsers, isLoading]


}