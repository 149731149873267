import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import React from "react";
import { useActiveUserZus } from "./zustands/activeUserZus";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelectionModal from "./components/LanguageSelectionModal";
import {
  OnlyAdminView,
  OnlySuperAdminView,
} from "./components/user_dispatcher";
import urlcWithPath from "./urlc";

export default function AppNavBarOld() {
  const [t] = useTranslation();

  const activeUserZus = useActiveUserZus();

  const activeUser = activeUserZus.activeUser;

  return (
    <div
      className="App-header"
      style={{
        backgroundImage: `url(` + urlcWithPath("background.png") +")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // filter: "blur(4px)",
      }}
    >
      <Navbar
        expand={"lg"}
        variant={"dark"}
        className={"md-3"}
        collapseOnSelect
      >
        <Container fluid>
          <a href={"/"}>
            <Image
              src={urlcWithPath("branding/branding_long.svg")}
              style={{
                height: "35px",
              }}
            />
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{
              marginLeft: "auto",
            }}
          >
            <Nav className={"me-auto"}></Nav>

            <Nav
              className={"justify-content-center"}
              style={{ marginRight: "100px" }}
            >
              <NavLink lang={"app.home"} path={"/"} />

              <OnlyAdminView>
                <NavLink lang={"core.projects"} path={"/adminprojects"} />
              </OnlyAdminView>

              <NavLink lang={"core.search"} path={"/search"} />

              <ActiveUserWrapper>
                <OnlyAdminView>
                  <NavLink lang={"core.statistics"} path={"/statistics"} />
                </OnlyAdminView>

                <OnlySuperAdminView>
                  <NavLink lang={"core.users"} path={"/users"} />
                </OnlySuperAdminView>

                <NavDropdown title={activeUser.name}>
                  <_UserDropDown />
                </NavDropdown>
              </ActiveUserWrapper>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

function ActiveUserWrapper({ children }) {
  const activeUserZus = useActiveUserZus();

  const activeUser = activeUserZus.activeUser;

  if (activeUser.type !== "") {
    return <>{React.Children.toArray(children)}</>;
  } else {
    return <></>;
  }
}

function NavLink({ lang, path }) {
  const [t] = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();

  return (
    <div>
      <Nav.Link
        onClick={() => {
          if (path) {
            navigate(path);
          }
        }}
        style={{ color: location.pathname === path ? "#db333c" : "white" }}
      >
        {t(lang)}
      </Nav.Link>
    </div>
  );
}

function _UserDropDown() {
  const [t] = useTranslation();

  const [show, setShow] = React.useState(false);

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      {show && <LanguageSelectionModal onHide={hideModal} />}

      <NavDropdown.Item onClick={() => setShow(true)}>
        {t("core.language")}
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => {
          window.location = window.location.origin + "/.auth/logout";
        }}
      >
        {t("core.logout")}
      </NavDropdown.Item>
    </>
  );
}
