import {AssetParent} from "./asset_Parent";



export class DocumentAsset extends AssetParent {
    folderName = "Documents"

    thumbnailRatioX = 1
    thumbnailRatioY = 1

    fromJsonObject(object: any) {
        super.fromJsonObject(object);


        const tmpX = object.first_page_ratio_x
        const tmpY = object.first_page_ratio_y

        if (tmpX && tmpY) {
            this.thumbnailRatioX = tmpX
            this.thumbnailRatioY = tmpY
        }


    }



}