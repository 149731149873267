import React from "react";
import * as three from "three";
import { GridHelper } from "three";

import { Box, Circle, Html, MapControls, useGLTF } from "@react-three/drei";

import { Canvas, extend, useThree } from "@react-three/fiber";
import { AreaAsset, AreaAssetModelLayer } from "../../../ela/assets/asset_Area";
import { InfinitySpot } from "../../../infinity/InfinitySpot";

import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { DigitalTwinBoardView } from "../../digitaltwin/DigitalTwinBoardView";
import { Stack } from "react-bootstrap";
import ActiveSpotVisualization from "./ActiveSpotVisualization";
import AreaPlaneMesh from "./AreaPlaneMesh";
import LicenceManager from "../../../api/LicenceManager";
import VisitedSpotOverlay from "./VisitedSpotOverlay";
import urlcWithPath from "../../../urlc";

extend({ GridHelper });

interface Maximap3DProps {
  activeSpot: InfinitySpot;
  spots: Array<InfinitySpot>;
  activeArea: AreaAsset;
}

interface AreaPlaneProps {
  area: AreaAsset;
}

interface AreaSpotsProps {
  spots: Array<InfinitySpot>;
  activeArea: AreaAsset;
  activeSpot: InfinitySpot;
  onSpotSelected: Function;
  namesVisible: boolean;
  dimension: string;
}

const CAMERA_DEFAULT_HEIGHT = 50;

function AreaMeshGroup({ area }: AreaPlaneProps) {
  const _area: AreaAsset = area;

  const { x, y, z } = _area.mesh_object_scale;

  const { c, f, t, r } = _area.mesh_object_pose;

  const groupRef = React.useRef();

  React.useEffect(() => {
    const obj: three.Object3D = groupRef.current;

    const rotationMatrix = new three.Matrix4();

    rotationMatrix.set(
      f.x,
      t.x,
      r.x,
      0,
      f.y,
      t.y,
      r.y,
      0,
      f.z,
      t.z,
      r.z,
      0,
      0,
      0,
      0,
      1
    );

    obj.rotation.setFromRotationMatrix(rotationMatrix);
    //
    obj.rotateY(Math.PI / -2);
  }, []);

  return (
    <group ref={groupRef}>
      {_area.layers.map((value, index) => {
        return <_AreaLayer key={index} modelLayer={value} />;
      })}
    </group>
  );
}

function _AreaLayer({ modelLayer }) {
  const _layer: AreaAssetModelLayer = modelLayer;

  const gltf: GLTF = useGLTF(urlcWithPath(_layer.getMeshPath()));

  const { x, y, z } = _layer.scale;

  const { c, f, t, r } = _layer.local_position;

  const primiveRef = React.useRef();

  React.useEffect(() => {
    const obj: three.Object3D = primiveRef.current;

    const rotationMatrix = new three.Matrix4();

    rotationMatrix.set(
      f.x,
      t.x,
      r.x,
      0,
      f.y,
      t.y,
      r.y,
      0,
      f.z,
      t.z,
      r.z,
      0,
      0,
      0,
      0,
      1
    );

    obj.rotation.setFromRotationMatrix(rotationMatrix);
    //
    obj.rotateY(Math.PI / -2);
  }, []);

  return (
    <React.Suspense fallback={""}>
      <primitive
        ref={primiveRef}
        object={gltf.scene}
        scale={[x, y, z]}
        position={[c.x, c.y, c.z]}
      />
    </React.Suspense>
  );
}

function AreaSpot({
  spot,
  color,
  onSpotSelected,
  mesh,
  isActive,
  namesVisible,
}) {
  const _mesh: three.Mesh = mesh;
  const _spot: InfinitySpot = spot;

  const [hover, setHover] = React.useState(false);

  const { gl } = useThree();

  const position = [spot.area_pos_x, 0, spot.area_pos_z];

  const pointScale = _spot.area_scale;

  const twins = spot.getBoardsOfType("DigitalTwinBoard", "");

  const hovered = () => {
    setHover(true);
    gl.domElement.style.cursor = "pointer";
  };

  const unhovered = () => {
    setHover(false);
    gl.domElement.style.cursor = "grab";
  };

  return (
    <group position={position}>
      {isActive && (
        <ActiveSpotVisualization pointScale={pointScale} color={color} />
      )}

      <group scale={pointScale}>
        <primitive object={_mesh} />

        {LicenceManager.f360.visited_spot_in_map && spot.wasVisited && (
          <VisitedSpotOverlay />
        )}

        <Box
          position={[0, 0.5, 0]}
          scale={[0.7, 1, 0.7]}
          onPointerDown={(e) => {
            console.log(e);
            onSpotSelected(spot);
          }}
          visible={false}
          onPointerOver={hovered}
          onPointerOut={unhovered}
        />
      </group>

      {/*<meshBasicMaterial ref={matref}*/}
      {/*                   color={isActive ? (hover ? "orange" : "green") : (hover ? "orange" : "blue")}/>*/}

      {namesVisible && (
        <Html
          distanceFactor={25}
          position={[0, 0, -1]}
          scale={[0.5, 0.5, 0.5]}
          transform
          occlude
          center
          rotation={[Math.PI / -2, 0, 0]}
        >
          <div
            style={{
              background: "rgba(0, 0, 0, 0.75)",
              color: "white",
              fontSize: "25px",
              // transform: "translate(-50%, 50%)",
              textAlign: "center",
              borderRadius: "5px",
              paddingTop: "10px",
              padding: "10px 15px",
            }}
          >
            {spot.name}
          </div>
        </Html>
      )}

      {twins.length > 0 && (
        <Html distanceFactor={30} position={[0, pointScale * 2, 0]} transform>
          <div
            style={{
              fontSize: "25px",
              transform: "translate(0%, -50%)",
              textAlign: "center",
              borderRadius: "5px",
              paddingTop: "10px",
              padding: "10px 15px",
            }}
          >
            <Stack direction="horizontal" gap={3}>
              {twins.map((twin) => {
                return (
                  <DigitalTwinBoardView
                    key={twin.uid}
                    boardName={twin.name}
                    asset={twin.getTwinAsset()}
                  />
                );
              })}
            </Stack>
          </div>
        </Html>
      )}
    </group>
  );
}

function AreaSpots({
  spots,
  dimension,
  activeArea,
  activeSpot,
  onSpotSelected,
  namesVisible,
}: AreaSpotsProps) {
  const { scene, materials } = useGLTF(urlcWithPath(`spot${dimension}.glb`));

  React.useEffect(() => {
    const tmp = activeArea.spots_color;

    Object.entries(materials).forEach(([key, material]) => {
      material.color = {
        r: tmp.x,
        g: tmp.y,
        b: tmp.z,
      };
    });
  });

  return (
    <group>
      {spots.map((value: InfinitySpot, index) => {
        if (value.areaId === activeArea.uid) {
          return (
            <React.Suspense fallback={null} key={index}>
              <AreaSpot
                key={value.uid}
                spot={value}
                dimension={dimension}
                onSpotSelected={onSpotSelected}
                mesh={scene.clone(true)}
                isActive={activeSpot === value}
                namesVisible={namesVisible}
                color={
                  new three.Color(
                    activeArea.spots_color_selected.x,
                    activeArea.spots_color_selected.y,
                    activeArea.spots_color_selected.z
                  )
                }
              />
            </React.Suspense>
          );
        }
      })}
    </group>
  );
}

function _Controls({ activeSpot, activeArea, dimension }) {
  const ref = React.useRef();

  console.log("Here");

  const _area: AreaAsset = activeArea;

  const _spot: InfinitySpot = activeSpot;

  const { camera } = useThree();

  React.useEffect(() => {
    const mapControls: any = ref.current;

    if (_spot.areaId !== _area.uid) {
      //Zooming in case of area change

      console.log("Zooming outside different area");

      console.log(_area.area_pose);

      camera.position.set(-_area.area_pose.z, 100, _area.area_pose.x);

      mapControls.target.set(-_area.area_pose.z, 0, _area.area_pose.x);
      mapControls.update();
    } else {
      console.log("Zooming to spot");

      camera.position.set(
        _spot.area_pos_x,
        camera.position.y,
        _spot.area_pos_z
      );
      mapControls.target.set(_spot.area_pos_x, 0, _spot.area_pos_z);
      mapControls.update();
    }
  }, [activeSpot, activeArea]);

  return (
    <>
      <MapControls
        ref={ref}
        makeDefault
        minPolarAngle={0}
        maxPolarAngle={dimension === "2D" ? 0 : Math.PI / 2.5}
        enableDamping={false}
      />
    </>
  );
}

function AreaGrid({ area }: AreaPlaneProps) {
  const gridSize =
    area.scale_x > area.scale_y
      ? Math.trunc(area.scale_x * 1.2)
      : Math.trunc(area.scale_y * 1.2);

  return (
    <gridHelper
      args={[gridSize, 20, 0x555555, 0xffffff]}
      position={[0, 0.1, 0]}
    />
  );
}

function MapWorld({
  activeArea,
  dimension,
  activeSpot,
  spots,
  onSpotSelected,
  namesVisible,
  gridVisible,
}: Maximap3DProps | any) {
  return (
    <>
      <_Controls
        activeArea={activeArea}
        activeSpot={activeSpot}
        dimension={dimension}
      />
      <ambientLight intensity={1} />

      {/*<pointLight position={[10, 10, 10]}/>*/}
      {/*<Box position={[-1.2, 0, 0]}/>*/}
      {/*<Box position={[1.2, 0, 0]}/>*/}

      {gridVisible && <AreaGrid area={activeArea} />}

      <React.Suspense fallback={""}>
        {activeArea.fileExists && (
          <AreaPlaneMesh
            area={activeArea}
            texture={activeArea.getMaximapPath()}
          />
        )}
      </React.Suspense>

      {activeArea.meshExists && <AreaMeshGroup area={activeArea} />}

      <AreaSpots
        spots={spots}
        dimension={dimension}
        activeArea={activeArea}
        activeSpot={activeSpot}
        onSpotSelected={onSpotSelected}
        namesVisible={namesVisible}
      />
    </>
  );
}

export function Maximap3D({
  activeArea,
  activeSpot,
  spots,
  onSpotSelected,
  gridVisible,
  dimension,
  namesVisible,
}: Maximap3DProps | any) {
  const _area: AreaAsset = activeArea;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Canvas
        camera={{
          position: [0, CAMERA_DEFAULT_HEIGHT, -_area.area_pose.z * 0.5],
        }}
      >
        <MapWorld
          activeSpot={activeSpot}
          spots={spots}
          activeArea={activeArea}
          onSpotSelected={onSpotSelected}
          gridVisible={gridVisible}
          dimension={dimension}
          namesVisible={namesVisible}
        />
      </Canvas>
    </div>
  );
}
