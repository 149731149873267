import React from "react";


import {useTranslation} from "react-i18next";
import PanoramasManager from "../../api/PanoramasManager";
import {Form} from "react-bootstrap";

export default function TourSettings() {

    const [t] = useTranslation()

    const [resolution, setResolution] = React.useState(PanoramasManager.ACTIVE_RESOLUTION)

    const changeResolution = (e) => {
        const newResolution = e.target.value
        PanoramasManager.changeResolution(newResolution)
        setResolution(newResolution)
    }

    return (
        <>

            <div>
                <Form.Text>{t("core.photoquality")}:</Form.Text>
                <Form.Select value={resolution} onChange={changeResolution}>
                    {/*<option value={PanoramasManager.LOW}>512 x 256 (Fast)</option>*/}
                    <option value={PanoramasManager.LOW}>2048 x 1024 (Low)</option>
                    <option value={PanoramasManager.MEDIUM}>4096 x 2048 (Medium)</option>
                    <option value={PanoramasManager.HIGH}>8192 x 4096 (High)</option>
                </Form.Select>
            </div>

        </>
    )
}
