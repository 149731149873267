import React from 'react';

import {Form, Image, ListGroup, Offcanvas, Stack} from 'react-bootstrap';


import {useTranslation} from "react-i18next";

import {InfinityProject} from "../../infinity/InfinityProject";

import {useNavigate} from "react-router-dom";

import ActiveSpotBoardsVisibilityOffcanvas from "./ActiveSpotBoardsVisibilityOffcanvas";

import SearchView from "../../routes/inter_project_search_new/SearchView";

import {useActiveSpotZus} from "../../zustands/activeSpotZus";
import {InfinityOffcanvasWrapper} from "../../components/offcanvas";
import {useActiveTestTour} from "../../zustands/activeTestTourZus";
import {useLayoutZus} from "../../zustands/layoutZus";
import {useActiveProject} from "../../project/project_zustands/activeProjectZus";
import TourSettings from "../../settings/settings360/TourSettings";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import LicenceManager from "../../api/LicenceManager";
import UsersApi from "../../api/UsersApi";
import {useActiveUserZus} from "../../zustands/activeUserZus";
import urlcWithPath from '../../urlc';


const requestFullscreen = () => {
    if (document.mozFullScreen || document.webkitIsFullScreen) {

        document.exitFullscreen().then(() => {
        })

    } else {
        document.body.requestFullscreen().then(() => {
        }).catch((e) => {

        })
    }
}

interface ActiveProjectOffCanvasProps {
    activeProject: InfinityProject;
    playInWorldHud: any;
}


export const ActiveTourRightBar = ({playInWorldHud}: ActiveProjectOffCanvasProps) => {

    const userZus = useActiveUserZus()
    const projectZus = useActiveProject()
    const popupZus = useGlobalPopup()
    const guidedZus = useActiveTestTour()
    const layoutZus = useLayoutZus()
    const spotZus = useActiveSpotZus()


    const btnStyle: any = {
        position: "absolute",
        top: "0px",
        right: "0px",
        marginRight: "5px",
        marginTop: "5px",

    }

    const navigate = useNavigate()

    const [boardsHidden, setBoardsHidden] = React.useState(false)

    const showProjectOffcanvasPressed = () => {
        popupZus.pushElement(
            <ActiveProjectOffCanvas onHide={() => popupZus.popElement()}
                                    activeProject={activeProject}
                                    playInWorldHud={playInWorldHud}/>
        )
    }

    const showSpotOffCanvasClicked = () => {
        popupZus.pushElement(
            <ActiveSpotBoardsVisibilityOffcanvas onHide={() => popupZus.popElement()}
                                                 activeProject={activeProject}

            />
        )
    }

    const activeProject = projectZus.project

    const ICON_STYLE = {
        width: "35px",
        height: "35px",
        cursor: "pointer"
    }

    React.useEffect(() => {
        setBoardsHidden(false)
    }, [spotZus.activeSpot])

    const canShowHome = !guidedZus.task && layoutZus.useExperimentalLayout === false &&
        UsersApi.canUserIterateProjects()

    if (!LicenceManager.f360.can_see_hud_icons) {
        return (
            <></>
        )
    }

    return (

        <>
            <div style={btnStyle}>


                {
                    canShowHome && <div style={{
                        position: "fixed",
                        left: "0px",
                        top: "0px",
                        margin: "5px"
                    }}>

                        <Image src={urlcWithPath("i-icons/home-white.svg")} style={ICON_STYLE} onClick={() => {
                            navigate("/")
                        }}/>

                    </div>

                }


                <Stack gap={1}>


                    <Image src={urlcWithPath("i-icons/list_icon.svg")} style={ICON_STYLE} onClick={showProjectOffcanvasPressed}/>

                    <div style={{
                        position: "relative"
                    }}>
                        <Image src={urlcWithPath("i-icons/eye.svg")}
                               style={{
                                   ...ICON_STYLE,
                                   opacity: boardsHidden ? 0.5 : 1
                               }}
                               onClick={() => {
                                   setBoardsHidden(!boardsHidden)
                                   activeProject.getWorld().setSecondaryGroupVisibility(boardsHidden)
                               }}/>

                        {
                            LicenceManager.f360.hide_boards_selection_enabled &&
                            <div style={{
                                position: "absolute",
                                right: "-5px",
                                top: "-10px"
                            }}>

                                <Image src={urlcWithPath("i-icons/settings_white.svg")}
                                       style={{
                                           ...ICON_STYLE,
                                           width: "15px",
                                           height: "15px",
                                       }}

                                       onClick={() => {
                                           showSpotOffCanvasClicked(true)
                                       }}/>
                            </div>
                        }


                    </div>


                </Stack>

            </div>

        </>
    )
}

export const ActiveProjectOffCanvas = ({activeProject, onHide, playInWorldHud}) => {

    const {t} = useTranslation()


    const [activeSubContent, setActiveSubContent] = React.useState(null)

    const navigate = useNavigate()

    return (
        <>

            <InfinityOffcanvasWrapper
                closeButton={true}
                onHide={() => {
                    setActiveSubContent(null)
                    onHide()
                }}
                header={<Offcanvas.Title>Menu</Offcanvas.Title>}
                body={
                    <>

                        {activeSubContent ? <>
                                {
                                    activeSubContent
                                }

                            </>
                            :

                            <>
                                <Offcanvas.Title>{t("core.controls")}</Offcanvas.Title>

                                <ListGroup>

                                    {/*<ControlListItem icon={urlc("i-icons/home.svg"} lang={"app.home"} onClick={() => {*/}
                                    {/*    navigate("/")*/}
                                    {/*}}/>*/}

                                    {
                                        UsersApi.canUserSearch() &&
                                        <_InterProjectSearchButton projectUid={activeProject.uid}/>
                                    }

                                    <ControlListItem icon={urlcWithPath("i-icons/reset_camera.svg")} lang={"app.resetCamera"}
                                                     onClick={() => {
                                                         onHide()
                                                         activeProject.getWorld().resetCamera()
                                                     }}/>


                                    <ControlListItem icon={urlcWithPath("i-icons/not_full_screen.svg")} lang={"app.requestFullscreen"}
                                                     onClick={requestFullscreen}/>


                                    {/*<ControlListItem icon={urlc("i-icons/end_tour.svg"} lang={"app.endTour"}*/}
                                    {/*                 onClick={() => {*/}
                                    {/*                     onHide()*/}
                                    {/*                     playInWorldHud(<TourEndWizard activeProject={activeProject}*/}
                                    {/*                                                   onCancel={() => {*/}
                                    {/*                                                       playInWorldHud(null)*/}
                                    {/*                                                   }}/>)*/}
                                    {/*                 }}/>*/}
                                </ListGroup>


                                {/*<div style={{marginTop: "25px"}}>*/}
                                {/*    <AppSettingsBar/>*/}
                                {/*</div>*/}


                                <div style={{marginTop: "25px"}}>
                                    <_SpotSettings/>
                                </div>
                            </>

                        }
                    </>
                }

            />


        </>
    )


}


function ControlListItem({lang, icon, onClick}) {

    const [t] = useTranslation()

    return (
        <ListGroup.Item onClick={onClick} style={{cursor: "pointer"}}>
            <Stack direction={"horizontal"} gap={3}>
                <Image src={icon} style={{
                    width: "25px",
                    height: "25px",
                }}/>
                <div>
                    {t(lang)}
                </div>
            </Stack>
        </ListGroup.Item>
    )
}


function _InterProjectSearchButton({projectUid}) {
    const [t] = useTranslation()


    const [show, setShow] = React.useState(false)

    return (
        <>
            <ControlListItem icon={urlcWithPath("i-icons/search.svg")} lang={"app.search"}
                             onClick={() => setShow(true)}/>

            {
                show &&
                <InfinityOffcanvasWrapper
                    closeButton={true}
                    mainStyle={{width: "90%"}}
                    onHide={() => setShow(false)}
                    header={<Offcanvas.Title>{t("app.search.inter")}</Offcanvas.Title>}
                    body={<SearchView projectUid={projectUid}/>}
                />
            }
        </>
    )
}

function _SpotSettings() {

    const spotZus = useActiveSpotZus()
    const layout = useLayoutZus()

    const [t] = useTranslation()


    return (
        <div className={"vstack gap-3"}>
            <Offcanvas.Title>{t("core.activespot")}</Offcanvas.Title>

            <div>
                <Form.Text>{t("core.name")}:</Form.Text>
                <Form.Control type={"input"} disabled={true} value={spotZus.activeSpot.name}/>
            </div>

            {
                layout.useExperimentalLayout === false &&
                <TourSettings/>
            }

        </div>

    )
}