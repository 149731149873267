import React from "react";

import "./index.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "./i18n";

import { App } from "./App";

//  "proxy": "http://localhost:5000",
import { createRoot } from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <React.Suspense fallback={""}>
      <HashRouter>
        {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
        <App />
      </HashRouter>
      {/* </BrowserRouter> */}
    </React.Suspense>
  </React.StrictMode>
);
