import React from "react";

import * as three from "three";

import {InfinityBoardParent} from "./board_Parent";

import {LabelPlayer} from "../../gui/content_players/label/LabelPlayer";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min";

import {LabelAsset} from "../../ela/assets/asset_Label";
import {ImageAsset} from "../../ela/assets/asset_Image";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";
import {ellaMatrixToThreeJs} from "../math/math";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import urlcWithPath from "../../urlc";

class LineTypes {
    static TOP = 0;
    static BOTTOM = 1;
    static LEFT = 2;
    static RIGHT = 3;
}

const SMALL_LINE_SIZE = 0.0025;

function LabelPlayerComponent({onClose, labelData}) {
    return (
        <div
            style={{
                backdropFilter: "blur(5px)",
                position: "absolute",
                left: "0px",
                top: "0px",
                minWidth: "100%",
                width: "100%",
                height: "100%",
            }}
        >
            <LabelPlayer
                {...labelData}
                onClose={() => {
                    onClose();
                }}
            />
        </div>
    );
}

export class InfinityInfoBoard extends InfinityBoardParent {
    type = "info";

    maximalizedPosition = new three.Vector3(100, 0, 0);
    maximalizedScale = new three.Vector3(0, 0, 0);

    lineColor: three.Color = new three.Color(255, 255, 255);

    frame_left = false;
    frame_right = false;
    frame_top = false;
    frame_bottom = false;

    linesEnabled: boolean = false;
    line_target_enabled: boolean = false;

    lineTarget: three.Vector3 = null;
    lineCorner = new three.Vector4(0, 0, 1, 0);

    labelGroup: three.Group = null;
    lineGroup: three.Group = null;
    lineSubGroup: three.Group = null;
    lineMesh: three.Mesh = null;
    labelPlaneA: three.Mesh = null;
    labelPlaneB: three.Mesh = null;

    isOpenedInWorld = false;

    use_body = true;
    use_header = true;
    use_image = true;

    targetRotatedPoint: three.Object3D = null;

    constructor(spot) {
        super(spot);
        this.icon = "EI.InfoBoard";
    }

    getSearchableText() {
        const label = this.getLabelAsset();
        return `${this.name};${label.body};${label.header};${label.url}`;
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object);
        this.maximalizedPosition.copy(this.position);
        this.maximalizedPosition.multiply(new three.Vector3(100, 100, 100));

        const lineColor = object["line_color"];
        if (lineColor) {
            this.lineColor = new three.Color(lineColor.x, lineColor.y, lineColor.z);
        }

        if (object["frame_enabled"]) {
            this.linesEnabled = true;

            this.frame_right = true;
            this.frame_left = true;
            this.frame_top = true;
            this.frame_bottom = true;
        } else {
            this.linesEnabled = object["lines_enabled"];

            this.frame_right = object["frame_right"];
            this.frame_left = object["frame_left"];
            this.frame_top = object["frame_top"];
            this.frame_bottom = object["frame_bottom"];
        }

        this.use_body = object.use_body ?? this.use_body;
        this.use_header = object.use_header ?? this.use_header;
        this.use_image = object.use_image ?? this.use_image;

        const lineTarget = object["line_target"];

        this.line_target_enabled = object["line_target_enabled"];

        if (lineTarget) {
            this.lineTarget = new three.Vector3(
                lineTarget.x,
                lineTarget.y,
                lineTarget.z
            );

            const cornerData = object["line_corner"];
            this.lineCorner.x = cornerData.x;
            this.lineCorner.y = cornerData.y;
            this.lineCorner.z = cornerData.z;
            this.lineCorner.w = cornerData.w;
        }
    }

    CleanUp() {
        super.CleanUp();

        this.isOpenedInWorld = false;

        if (this.labelGroup) {
            this.labelGroup.clear();
            this.labelGroup = null;
        }
    }

    setVisibilityStatus(status: boolean) {
        if (this.isOpenedInWorld) {
            this.labelGroup.visible = status;
        } else {
            super.setVisibilityStatus(status);
        }
    }

    isVisible(): boolean {
        if (this.isOpenedInWorld) {
            return this.labelGroup.visible;
        } else {
            return super.isVisible();
        }
    }

    isSelected(intersection: three.Intersection) {
        if (intersection) {
            const uvIntersection = intersection.uv;

            if (this.isOpenedInWorld) {
                if (uvIntersection.x > 0.7 && uvIntersection.y < 0.3) {
                    return true;
                }
                if (this.closeEnabled) {
                    if (uvIntersection.x > 0.7 && uvIntersection.y > 0.7) {
                        return true;
                    }
                }

                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    clicked(intersection: three.Intersection) {
        GuidedTourManager.taskBoardClicked(this, 1);

        const asset: LabelAsset = this.getParentProject().labels.get(
            this.contentUid
        );
        const url = asset.url;

        if (intersection && this.isOpenedInWorld) {
            const uvIntersection = intersection.uv;

            if (uvIntersection.x > 0.9 && uvIntersection.y < 0.2) {
                this.leaveVr();

                if (url.includes("http") || url.includes("https")) {
                    window.open(url, "_blank");
                } else {
                    window.open("https://" + url, "_blank");
                }
            }
            if (this.closeEnabled) {
                if (uvIntersection.x > 0.9 && uvIntersection.y > 0.8) {
                    this.hideInWorld();
                }
            }
        } else {
            super.clicked();
            super.fillBoardVisited(1);

            if (this.showAsPopup && !this.isInVR()) {
                this.playInModalHud(this.getReactLabelComponent());
            } else {
                if (this.clickableIcon) {
                    this.clickableIcon.getRoot().visible = false;
                }

                this.AddLabelPlane(this.getParentProject().getWorld());
            }
        }
    }

    getReactLabelComponent() {
        const asset = this.getParentProject().labels.get(this.contentUid);
        const tmp = {
            header: asset.header,
            body: asset.body,
            url: asset.url,
            use_image: this.use_image,
            use_body: this.use_body,
            use_header: this.use_header,
            frameLeft: this.frame_left,
            frameRight: this.frame_right,
            frameTop: this.frame_top,
            frameBottom: this.frame_bottom,
            linesColor: `rgb(${this.lineColor.r * 255}, ${this.lineColor.g * 255}, ${
                this.lineColor.b * 255
            })`,
        };

        if (tmp) {
            const images = this.getParentProject().images;
            if (images.has(asset.image_uid)) {
                tmp["image"] = images.get(asset.image_uid).getAbsolutePath();
            }
        }

        return (
            <LabelPlayerComponent
                onClose={() => {
                    useGlobalPopup.getState().popElement();
                }}
                labelData={{...tmp}}
            />
        );
    }

    GetLabelPath() {
        return `infinity/${this.getParentProject().uid}/Labels/${this.uid}.png`;
    }

    //vyskusat https://github.com/mrdoob/three.js/blob/master/examples/webxr_vr_layers.html

    fillToWorld(world, isControledExternally) {
        if (this.autoUnpack) {
            this.AddLabelPlane(world);
        } else {
            super.fillToWorld(world);
        }
    }

    hideInWorld() {
        this.labelGroup.visible = false;
        this.labelPlaneA.visible = false;
        this.labelPlaneB.visible = false;
        this.isOpenedInWorld = false;
        super.fillToWorld(this.getParentProject().getWorld());
    }

    getLabelAsset(): LabelAsset {
        return this.getParentProject().labels.get(this.contentUid);
    }


    taskEnded() {
        this.setVisibilityStatus(this._isEnabled)

        if (this.labelGroup) {
            this.labelGroup.visible = this._isEnabled
            this.labelPlaneA.visible = this._isEnabled
            this.labelPlaneB.visible = this._isEnabled
        }

    }

    AddLabelPlane(world: World) {
        this.isOpenedInWorld = true;

        if (this.labelGroup) {
            this.labelGroup.visible = this._isEnabled;
            this.labelPlaneA.visible = this._isEnabled;
            this.labelPlaneB.visible = this._isEnabled;
        } else {
            this.loadedOnce = true;
            this.isCompleted = true;

            const projectUid = this.getParentProject().uid;

            const texture = new three.TextureLoader().load(
                urlcWithPath(`infinity/${projectUid}/InfoBoards/${this.uid}.png`)
            );

            const material = new three.MeshBasicMaterial({
                color: 0xffffff,
                transparent: true,
                map: texture,
                depthWrite: false,
                depthTest: false,
            });

            const geometry = new three.PlaneGeometry(this.scale.x, this.scale.y);
            const frontMesh = new three.Mesh(geometry, material);
            frontMesh.renderOrder = 997;
            this.labelPlaneA = frontMesh;

            const backMesh = new three.Mesh(geometry, material);
            backMesh.renderOrder = 997;
            backMesh.rotateY(Math.PI);
            this.labelPlaneB = backMesh;

            const labelAsset = this.getLabelAsset();

            if (labelAsset) {
                if (labelAsset.url !== "") {
                    frontMesh.userData["handler"] = this;
                    backMesh.userData["handler"] = this;
                }
            }

            if (this.closeEnabled) {
                frontMesh.userData["handler"] = this;
                backMesh.userData["handler"] = this;
            }

            this.labelGroup = new three.Group();

            this.labelGroup.add(backMesh);
            this.labelGroup.add(frontMesh);

            this.addLine(this.labelGroup);

            world.secondaryNotRotatedGroup.add(this.labelGroup);

            const rotatedPosition = new three.Vector3();
            rotatedPosition.copy(this.position);
            rotatedPosition.multiply(new three.Vector3(100, 100, 100));
            rotatedPosition.applyAxisAngle(
                new three.Vector3(0, 1, 0),
                this.parentSpot.spotRotation.y
            );

            this.labelGroup.position.set(
                rotatedPosition.x,
                rotatedPosition.y,
                rotatedPosition.z
            );
            this.labelGroup.scale.set(100, 100, 100);

            if (this.use_automatic_rotation) {
                this.labelGroup.lookAt(new three.Vector3(0, rotatedPosition.y, 0));
            } else {
                const rotationMatrix = ellaMatrixToThreeJs(this.f, this.t, this.r);

                this.labelGroup.rotation.setFromRotationMatrix(rotationMatrix);
                this.labelGroup.updateMatrixWorld(true);
            }

            if (this.lineGroup && this.lineTarget) {
                const rotationVector = new three.Vector3();

                rotationVector.copy(this.lineTarget);
                rotationVector.multiply(new three.Vector3(100, 100, 100));
                rotationVector.applyAxisAngle(
                    new three.Vector3(0, 1, 0),
                    this.parentSpot.spotRotation.y
                );

                this.lineGroup.lookAt(
                    rotationVector.x,
                    rotationVector.y,
                    rotationVector.z
                );

                const sourceTarget = new three.Vector3();
                this.lineSubGroup.getWorldPosition(sourceTarget);

                let copy = new three.Vector3();
                copy.copy(rotationVector);
                copy.sub(sourceTarget);

                this.lineSubGroup.scale.set(1, copy.length() / 100, 1);
            }

            material.opacity = 0;

            new TWEEN.Tween({x: 0})
                .to({x: 1}, 1000)
                .onUpdate((newValue) => {
                    material.opacity = newValue.x;
                })
                .start();

            this.labelGroup.visible = this._isEnabled
            this.labelPlaneA.visible = this._isEnabled
            this.labelPlaneB.visible = this._isEnabled
        }
    }


    setSelected() {
    }

    setDeselected() {
    }

    addLine(attachedMesh: three.Mesh) {
        const material = new three.MeshBasicMaterial({
            color: this.lineColor.getHex(),
            side: three.DoubleSide,
        });

        if (this.linesEnabled) {
            if (this.frame_top) {
                this.createLine(attachedMesh, material, LineTypes.TOP);
            }
            if (this.frame_bottom) {
                this.createLine(attachedMesh, material, LineTypes.BOTTOM);
            }
            if (this.frame_left) {
                this.createLine(attachedMesh, material, LineTypes.LEFT);
            }
            if (this.frame_right) {
                this.createLine(attachedMesh, material, LineTypes.RIGHT);
            }
            if (this.line_target_enabled) {
                if (this.lineTarget) {
                    this.createTargetLine(attachedMesh, material);
                }
            }
        }
    }


    getLabelAssetImagePath() {
        const asset = this.getLabelAsset();

        if (asset.image_uid !== "") {
            const image: ImageAsset = this.getParentProject().images.get(
                asset.image_uid
            );

            if (image) {
                return image.getAbsolutePath();
            }
        }
    }

    createLine(
        meshToAttach: three.Mesh,
        material: three.MeshBasicMaterial,
        type
    ) {
        const meshScale = this.scale;
        const smallHeight = SMALL_LINE_SIZE;

        let sizes = {
            width: meshScale.x,
            height: smallHeight,
        };

        let position = {
            x: 0,
            y: 0,
            z: 0.00005,
        };

        let scaleDimension = meshScale.y;

        if (type === LineTypes.TOP) {
            position.y += meshScale.y / 2;
            scaleDimension = meshScale.x;
        } else if (type === LineTypes.BOTTOM) {
            position.y -= meshScale.y / 2;
            scaleDimension = meshScale.x;
        } else if (type === LineTypes.LEFT) {
            scaleDimension = meshScale.y;
            position.x -= meshScale.x / 2;
        } else if (type === LineTypes.RIGHT) {
            scaleDimension = meshScale.y;
            position.x += meshScale.x / 2;
        }

        sizes.width = smallHeight;
        sizes.height = scaleDimension + smallHeight / 2;

        const geometry = new three.CylinderGeometry();
        const mesh = new three.Mesh(geometry, material);
        mesh.renderOrder = 998;
        mesh.scale.set(sizes.width, sizes.height, sizes.width);

        if (type === LineTypes.TOP || type === LineTypes.BOTTOM) {
            mesh.rotateZ(Math.PI / 2);
        }

        mesh.position.set(position.x, position.y, position.z);
        meshToAttach.add(mesh);
    }

    createTargetLine(group, material) {
        const meshScale = this.scale;

        // material.transparent = true
        // material.opacity = 0

        let position = {
            x: 0,
            y: 0,
            z: 0.00005,
        };

        if (this.lineCorner.x === 1) {
            //Bottom left
            position.x -= meshScale.x / 2;
            position.y -= meshScale.y / 2;
        } else if (this.lineCorner.y === 1) {
            //Bottom right
            position.x += meshScale.x / 2;
            position.y -= meshScale.y / 2;
        } else if (this.lineCorner.z === 1) {
            //Top left
            position.x -= meshScale.x / 2;
            position.y += meshScale.y / 2;
        } else if (this.lineCorner.w === 1) {
            //Top right
            position.x += meshScale.x / 2;
            position.y += meshScale.y / 2;
        }

        const lineGroup = new three.Group();

        const sphere = new three.SphereGeometry(SMALL_LINE_SIZE, 16, 16);
        const sphereMesh = new three.Mesh(sphere, material);
        lineGroup.add(sphereMesh);

        const geometry = new three.CylinderGeometry();
        const mesh = new three.Mesh(geometry, material);
        mesh.renderOrder = 998;
        mesh.position.set(0, 0.5, 0);
        mesh.scale.set(SMALL_LINE_SIZE, 1, SMALL_LINE_SIZE);

        const lineSubGroup = new three.Group();
        lineSubGroup.add(mesh);
        lineSubGroup.rotateX(Math.PI / 2);

        lineGroup.add(lineSubGroup);
        lineGroup.position.set(position.x, position.y, position.z);

        group.add(lineGroup);

        this.lineGroup = lineGroup;
        this.lineSubGroup = lineSubGroup;
        this.lineMesh = mesh;
    }


    clickedExternaly() {
        this._isEnabled = true
        this.taskEnded()
    }
}
