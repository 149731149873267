import React from "react";
import * as Bs from "react-bootstrap";


import "./QuizView.css"

import IGui from "../../../components/IGui";
import {TextAnswer, TextQuestion} from "../../../ela/quiz/TextQuestion";
import urlcWithPath from "../../../urlc";


const UNCHECKED_ICON = urlcWithPath("i-icons/Checkbox.Empty.svg")
const CHECKED_ICON = urlcWithPath("i-icons/Checkbox.Ok.svg")

const RADIO_ON = urlcWithPath("i-icons/Radio.On.svg")
const RADIO_OFF = urlcWithPath("i-icons/Radio.Off.svg")


function EvaluatedButton(props) {

    const answer: TextAnswer = props.answer

    let color = "black"

    if (answer.isSelected && answer.validity) {
        color = "lightgreen"
        //color = "#CAFFCA"
    } else if (answer.isSelected && (answer.validity == false)) {
        color = "red"
        //color = "#FFB3A0"
    } else if ((answer.isSelected == false) && answer.validity) {
        // color = "lightgreen"
        color = "#FFCC00"
    }

    const type = props.isSingle ? "radio" : "checkbox"

    return (
        <IGui.BButton style={{pointerEvents: "none"}} className={"QuizButton"} variant={"light"}>
            <div style={{color: color}}>
                <input type={type} className={"CheckBox"} checked={props.answer.isSelected} disabled/>
                {props.index + 1}. {props.answer.text}
            </div>
        </IGui.BButton>
    )
}


function MultiAnswerButton(props) {

    const [checked, setChecked] = React.useState(props.answer.isSelected)

    function swapPressed(e) {
        e.preventDefault()

        const finalStatus = !props.answer.isSelected
        props.answer.isSelected = finalStatus

        setChecked(finalStatus)

    }

    const isEvaluationMode = props.isEvaluationMode

    if (isEvaluationMode) {
        return <EvaluatedButton isSingle={false} answer={props.answer} index={props.index}/>
    } else {
        return <IGui.BButton className={"QuizButton"} variant={"light"}
                             onClick={swapPressed}
        >

            <img src={checked ? CHECKED_ICON : UNCHECKED_ICON}
                 style={{
                     width: "20px",
                     height: "20x",
                     margin: "5px",
                     marginTop: "auto",
                     marginRight: "15px",
                     marginBottom: "auto"

                 }}
            />


            {props.index + 1}. {props.answer.text}
        </IGui.BButton>
    }
}


function SingleAnswerButton(props) {
    if (props.isEvaluationMode) {
        return <EvaluatedButton isSingle={true} answer={props.answer} index={props.index}/>
    } else {
        return (
            <IGui.BButton key={props.index} className={"QuizButton"}
                          variant={props.answer.isSelected ? "light" : "light"}
                          onClick={() => {
                              props.onClick(props.answer)
                          }}
                          value={props.index}>


                <img src={props.answer.isSelected ? RADIO_ON : RADIO_OFF}
                     style={{
                         width: "20px",
                         height: "20x",
                         margin: "5px",
                         marginTop: "auto",
                         marginRight: "15px",
                         marginBottom: "auto"

                     }}
                />
                {props.index + 1}. {props.answer.text}

            </IGui.BButton>
        )
    }


}

interface TextQuestionViewProps {
    isEvaluationMode: boolean
    activeQuestion: TextQuestion
}

export function TextQuestionView({
                                     isEvaluationMode,
                                     activeQuestion,
                                 }: TextQuestionViewProps) {

    const [answers, setAnswers] = React.useState([])

    function singleAnswerClicked(answer) {

        answers.forEach((value) => {
            value.isSelected = false
        })

        answer.isSelected = true

        setAnswers([...activeQuestion.answers])
    }


    const isMultiAnswer = activeQuestion.isMultiAnswer
    const isInEvaluationMode = isEvaluationMode


    React.useEffect(() => {
        setAnswers([...activeQuestion.answers])
    }, [activeQuestion, isEvaluationMode])


    return (
        <div className={"View"}>
            {
                answers.map((answer: TextAnswer, index) => {

                    return (
                        <Bs.Row key={`${activeQuestion.name}-${answer.text}`}>
                            <Bs.ButtonGroup className="mb-2" vertical>
                                {isMultiAnswer ?
                                    <MultiAnswerButton answer={answer}
                                                       isEvaluationMode={isInEvaluationMode}
                                                       index={index}
                                    />
                                    : <SingleAnswerButton answer={answer}
                                                          isEvaluationMode={isInEvaluationMode}
                                                          index={index}
                                                          onClick={singleAnswerClicked}
                                    />
                                }
                            </Bs.ButtonGroup>

                        </Bs.Row>
                    )
                })
            }
        </div>
    )


}