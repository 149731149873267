import * as React from "react";

import {Container, Dropdown, Form, Stack} from "react-bootstrap";

import {InfinitySpot} from "../../../infinity/InfinitySpot";

import {AreaAsset} from "../../../ela/assets/asset_Area";

import {UniversalRoundButton} from "../../icon_click_buttons";


import "./MapPoint.css"
import {Maximap3D} from "./Maximap3D";
import {useTranslation} from "react-i18next";
import {D3MapSettings, MaximapModes} from "./MaximapModes";
import useCookie from "react-use-cookie";
import {useCookieBoolean} from "../../../devtools/hooks";
import urlcWithPath from "../../../urlc";

interface NewMaximapProps {
    onMinimalize: Function;
    activeSpot: InfinitySpot;
}

interface MaximapPointsCanvasProps {
    activeArea: AreaAsset;
    activeSpot: InfinitySpot;
}


function getAreaFromSpot(spot: InfinitySpot): AreaAsset {
    if (spot) {
        const project = spot.parentProject

        if (project.areas.has(spot.areaId)) {
            return project.areas.get(spot.areaId)
        } else {
            return null
        }
    }
    return null
}


interface MaximapControlBarProps {
    onItemChanged: Function;
    onDimensionChanged: Function;
    onViewModeChanged: Function;
    activeSpot: InfinitySpot;
    activeArea: AreaAsset;
    activeMode: string;
    gridVisible: D3MapSettings;
    setGridVisible: Function;
    namesVisible: boolean;
    setNamesVisible: (value: boolean) => void;
}


const MaximapControlBar = ({
                               onItemChanged,
                               activeSpot,
                               activeArea,
                               onDimensionChanged,
                               activeMode,
                               gridVisible,
                               setGridVisible,
                               namesVisible,
                               setNamesVisible
                           }: MaximapControlBarProps) => {

    const [t, _] = useTranslation()

    const style = {
        height: "auto",
        display: "inline",
        left: "0px",
        top: "20px",
        position: "absolute",
        zIndex: 150
    }

    const availableAreas = Array.from(activeSpot.parentProject.areas.values())

    return (<Container style={style}>
            <Stack direction="horizontal" gap={3}>


                {
                    availableAreas.length !== 1 && <>
                        <Dropdown onSelect={(eventKey) => {
                            onItemChanged(activeSpot.parentProject.areas.get(eventKey))
                        }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {activeArea.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    availableAreas.map((value) => {
                                        return (
                                            <Dropdown.Item
                                                className={"dropdown-item"}
                                                eventKey={value.uid}
                                                key={value.uid}
                                            >{value.name}</Dropdown.Item>

                                        )
                                    })
                                }
                            </Dropdown.Menu>

                            {/*<BigRoundButton lang={"3D"} onclick={() => {*/}

                            {/*}} icon={urlc("i-icons/gizmo.scale.svg"}/>*/}

                        </Dropdown>

                    </>
                }

                <button
                    className={"btn btn-success"}
                    onClick={() => {
                        if (activeMode === MaximapModes.D2) {
                            onDimensionChanged(MaximapModes.D3)
                        } else {
                            onDimensionChanged(MaximapModes.D2)
                        }
                    }}
                >
                    {/*{activeMode}*/}

                    {
                        activeMode === MaximapModes.D2 ? <><u>2D</u> / 3D</> : <>2D / <u>3D</u></>
                    }

                </button>


                <Stack direction={"horizontal"} gap={3}>

                    <Form.Check style={{color: "white"}} type={"checkbox"} label={"Grid visible"}
                                checked={gridVisible}
                                onChange={(element) => {
                                    setGridVisible(element.target.checked)
                                }}/>
                    <Form.Check style={{color: "white"}} type={"checkbox"} label={"Spot names"}
                                checked={namesVisible} onChange={(element) => {

                        setNamesVisible(element.target.checked)
                    }}/>

                </Stack>

            </Stack>


        </Container>
    )
}


const MinimalizeButton = (props) => {
    const maixmalizeButtonStyle = {
        right: "10px",
        top: "10px",
        position: "absolute",
        zIndex: 150,

        width: "40px",
        height: "40px",
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "gray",
    }

    return (
        <UniversalRoundButton
            width={maixmalizeButtonStyle.width}
            height={maixmalizeButtonStyle.height}
            iconSrc={urlcWithPath("i-icons/cross-red.svg")}
            onClick={props.onClick}
            style={maixmalizeButtonStyle}
        />

    )
}


export const Maximap = ({
                            activeSpot, onMinimalize
                        }: NewMaximapProps) => {


    const [_activeArea, setActiveArea] = React.useState(getAreaFromSpot(activeSpot))

    const [dimension, setDimension] = useCookie("maximap_dimension", MaximapModes.D2)
    const [gridVisible, setGridVisible] = useCookieBoolean("maximap_grid_visible", false)
    const [namesVisible, setNamesVisible] = useCookieBoolean("map-names-visible", false)

    if (_activeArea) {

        const BACKGROUNDSTYLE = {
            position: "absolute",
            width: "100%",
            height: "100%",

            left: "0px",
            top: "0px",

            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(5px)",
        }

        return (
            <div style={BACKGROUNDSTYLE}>

                <Maximap3D
                    dimension={dimension}
                    activeSpot={activeSpot}
                    activeArea={_activeArea}
                    spots={Array.from(activeSpot.parentProject.spots.values())}
                    onSpotSelected={(spot) => {
                        onMinimalize()
                        activeSpot.parentProject.activePlayer.ActivateSpotWithId(spot.uid)
                    }}
                    gridVisible={gridVisible}
                    namesVisible={namesVisible}

                />

                <MinimalizeButton
                    onClick={() => {
                        setDimension("2D")
                        onMinimalize()
                    }}
                />

                <MaximapControlBar
                    activeArea={_activeArea}
                    activeSpot={activeSpot}

                    onItemChanged={(newArea) => {
                        setActiveArea(newArea)
                    }}
                    onDimensionChanged={(newMode) => {
                        setDimension(newMode)
                    }}
                    activeMode={dimension}

                    gridVisible={gridVisible}
                    setGridVisible={setGridVisible}

                    namesVisible={namesVisible}
                    setNamesVisible={setNamesVisible}
                />
            </div>
        )
    } else {
        return <></>

    }


}