import axios, {AxiosResponse} from "axios";

export default class StorageApi {


    static async UploadFile(container: string, filePath: string, file: File): Promise<boolean> {

        const form = new FormData()

        form.append("file", file)

        await axios.post("/api/storage/upload-file", form, {
            params: {
                container: container,
                file_path: filePath
            }
        })

        return true
    }

    static async UploadAndResizeImage(container: string, filePath: string, file: File, generatePreview: boolean, reduction: number) {

        const form = new FormData()
        form.append("file", file)

        await axios.post("/api/storage/upload-and-resize-image", form, {
            params: {
                container: container,
                file_path: filePath,
                create_thumbnail: generatePreview,
                image_reduction: reduction ?? 512
            }
        })

    }


    static async GetContainerSize(containerName: string): Promise<number> {
        const response = await axios.get("/api/storage/get-container-size", {
            params: {
                container: containerName
            }
        })
        return response.data.status
    }

    static async UploadFileString(containerName: string, filePath: string, fileStr: string): Promise<boolean> {

        const formData = new FormData()

        formData.set("data", fileStr)

        await axios.post("/api/storage/upload-file-string", formData, {
            params: {
                container: containerName,
                file_path: filePath

            }
        })

        return false
    }

    static async GetFileStringSafe(containerName: string, filePath: string, notFound: string): Promise<string> {
        //Transform response zabrani zoserializovaniu do JSON objektu
        const value: AxiosResponse<any> | any = await axios.get("/api/storage/get-file",
            {
                transformResponse: [],
                params: {
                    container: containerName,
                    path: filePath
                }
            }).catch((e) => {
            console.error(e)
            return null
        })

        if (value) {
            return value.data
        } else {
            return notFound
        }

    }

    static async DeleteFolder(containerName: string, folderPath: string): Promise<boolean> {
        await axios.delete("/api/storage/delete-container", {
            params: {
                container: containerName,
                folder_path: folderPath
            }
        })
        return true
    }


    static async ListAndSaveFiles(containerName: string, filePath: string): Promise<boolean> {
        await axios.post("/api/storage/list-and-save-files", null, {
            params: {
                container: containerName,
                file_path: filePath
            }
        })
        return true
    }

    static async DeleteFile(containerName: string, filePath: string): Promise<boolean> {
        await axios.delete("/api/storage/delete-file", {
            params: {
                container: containerName,
                file_path: filePath
            }
        })

        return true
    }

}