import React from "react";
import DashBoardTile, {DashBoardTileNoMiddle} from "../../components/dashboard/DashBoardTile";

import {formatPercentage} from "../../formatters/percentage";
import {OnlySpinner} from "../../components/spinners";
import TourStatisticsApi, {QuizRecordResponse} from "../../api/TourStatisticsApi";
import QuizzesRatingManager from "./QuizzesRatingManager";
import {useTranslation} from "react-i18next";
import MyTable from "../../components/mytable";


interface BestWorstQuizTablesProps {
    projectType: number
}

export default function BestWorstQuizTables({projectType}: BestWorstQuizTablesProps) {
    return (
        <div className={"vstack gap-3"}>
            <_QuizzesSubTable projectType={projectType}/>
            <_QuestionSubTable projectType={projectType}/>

        </div>
    )
}


function _QuestionSubTable({projectType}: BestWorstQuizTablesProps) {

    const [isLoading, setIsLoading] = React.useState(true)

    const [value, setValue] = React.useState<Array<[string, number]>>([])

    React.useEffect(() => {
        TourStatisticsApi.GetAllQuizDataFilesOfProjectType(projectType).then((data) => {
            QuizzesRatingManager.ExtractProblematicQuestionsFromJstringArray(data).then((value) => {
                setValue(value)
                setIsLoading(false)
            })
        })
    }, [])

    if (isLoading) {
        return (
            <DashBoardTile>
                <OnlySpinner/>
            </DashBoardTile>
        )
    } else {
        return (
            <div className="row">
                <div className="col">
                    <_BestQuestionsTable data={value}/>
                </div>

                <div className="col">
                    <_WorstQuestionsTable data={value}/>
                </div>
            </div>

        )
    }


}


function _FakeTableHeader({children}: { children: any }) {
    return (
        <DashBoardTile style={{height: "40px"}}>
            <h5 className={"noWrap"}>{children}</h5>
        </DashBoardTile>
    )
}

function _QuizTableView({data}: { data: Array<QuizRecordResponse> }) {

    const STYLE = {
        marginBottom: "0px",
        height: "40px",
        paddingLeft: "15px"
    }

    const elements = new Array<any>()

    for (let i = 0; i < (data.length > 3 ? 3 : data.length); i++) {
        elements.push(
            <tr key={i}>
                <td>
                    <div className={"noWrap"}>
                        {data[i].BoardName}
                    </div>
                </td>
                <td>
                    {formatPercentage(data[i].Success)}
                </td>
            </tr>
        )
    }

    return (
        <DashBoardTileNoMiddle>
            <MyTable>
                <tbody>

                {
                    elements
                }
                </tbody>
            </MyTable>
        </DashBoardTileNoMiddle>
    )
}


function _QuestionTableView({data}: { data: Array<[string, number]> }) {

    const STYLE = {
        marginBottom: "0px",
        height: "40px",
        paddingLeft: "15px"
    }

    const elements = new Array<any>()

    for (let i = 0; i < (data.length > 3 ? 3 : data.length); i++) {
        elements.push(
            <tr key={i}>
                <td>
                    <div className={"noWrap"}>
                        {data[i][0]}
                    </div>
                </td>
                <td>
                    {formatPercentage(data[i][1])}
                </td>
            </tr>
        )
    }

    return (
        <DashBoardTileNoMiddle>
            <MyTable>
                <tbody>

                {
                    elements
                }
                </tbody>
            </MyTable>
        </DashBoardTileNoMiddle>
    )
}


function _QuizzesSubTable({projectType}) {

    const [isLoading, setIsLoading] = React.useState(true)

    const [data, setData] = React.useState<Array<QuizRecordResponse>>([])

    React.useEffect(() => {
        setIsLoading(true)

        TourStatisticsApi.GetAllQuizResultsOfProjectType(projectType).then((response) => {

            QuizzesRatingManager.SortQuizzesDueToSuccess(response).then((nextResponse) => {
                setData(nextResponse)
                setIsLoading(false)
            })
        })

    }, [projectType])

    return (
        <div className="row">
            <div className="col">
                <_BestQuizzesTable isLoading={isLoading} data={data}/>
            </div>

            <div className="col">
                <_WorstQuizzesTable isLoading={isLoading} data={data}/>
            </div>
        </div>
    )
}

function _BestQuizzesTable({isLoading, data}) {

    const [t] = useTranslation()

    if (isLoading) {
        return (<OnlySpinner/>)
    } else {
        return (
            <>
                <_FakeTableHeader>{t("core.best.quizes")}:</_FakeTableHeader>
                <_QuizTableView data={data}/>
            </>
        )
    }


}


function _WorstQuizzesTable({isLoading, data}) {

    const [t] = useTranslation()
    const copy = [...data]
    copy.reverse()

    if (isLoading) {
        return (<OnlySpinner/>)
    } else {
        return (
            <>
                <_FakeTableHeader>{t("core.worst.quizes")}:</_FakeTableHeader>
                <_QuizTableView data={copy}/>
            </>

        )
    }

}


function _getExampleWrongData(): Array<any> {
    return [
        {BoardName: "M8 - Elektromotor", Success: 0},
        {BoardName: "M8 - Topenie VN batérie", Success: 0.2},
        {BoardName: "M8 - Nabíjačka", Success: 0.3}
    ]
}

function _getExampleGoodData(): Array<any> {
    return [
        {BoardName: "M8 - Autobaterie", Success: 1},
        {BoardName: "M8 - Topenie VN batérie", Success: 0.824},
        {BoardName: "M8 - Nabíjačka", Success: 0.783}
    ]
}


function _BestQuestionsTable({data}) {

    const [t] = useTranslation()

    return (
        <>
            <_FakeTableHeader>{t("core.best.questions")}:</_FakeTableHeader>
            <_QuestionTableView data={data}/>
        </>

    )
}


function _WorstQuestionsTable({data}) {

    const [t] = useTranslation()

    const newData = [...data]
    newData.reverse()


    return (
        <>
            <_FakeTableHeader>{t("core.worst.questions")}:</_FakeTableHeader>
            <_QuestionTableView data={newData}/>
        </>

    )
}

