import urlcWithPath from "../../urlc";

const ASSETS_TRANSITION_MAP = {
  GateBoard: "Panoramas",
  VideoBoard: "Videos",
  SoundBoard: "Sounds",
  QuizBoard: "Quizzes",
  MeshBoard: "Meshes",
  DocumentationBoard: "Documents",
  OutlineBoard: "",
  InfoBoard: "Images",
  DigitalTwinBoard: "DigitalTwins",
  ClickBoard: "",
};
const BOARDS_EMPTY_PLACEHOLDER_ICONS = {
  GateBoard: "EI.Gate.png",
  VideoBoard: "EI.Video.png",
  SoundBoard: "EI.SoundBoard.png",
  QuizBoard: "EI.QuizBoard.png",
  MeshBoard: "EI.MeshBoard.png",
  DocumentationBoard: "EI.DocumentBoard.png",
  OutlineBoard: "EI.OutlineBoard.png",
  InfoBoard: "EI.InfoBoard.png",
  DigitalTwinBoard: "EI.DigitalTwin.png",
  ClickBoard: "Core.ClickOn.png",
};

export class FakeProject {
  uid = "";
  name = "";
  welcome_panorama = "";
  welcome_spot = "";

  spots: Map<string, FakeSpot> = new Map();

  areas: Map<string, FakeAsset | any> = new Map();
  videos: Map<string, FakeAsset | any> = new Map();
  sounds: Map<string, FakeAsset | any> = new Map();
  panoramas: Map<string, FakeAsset | any> = new Map();
  meshes: Map<string, FakeAsset | any> = new Map();
  labels: Map<string, FakeAsset | any> = new Map();
  images: Map<string, FakeAsset | any> = new Map();

  lon: number = 0;
  lat: number = 0;

  type: number = 0;

  searchCache: Array<FakeSpot | FakeBoard> = new Array();

  constructor() {}

  _getNewOnFilter(filter: string, variable: string) {
    const results = new Array();
    this.searchCache.forEach((value) => {
      const nameLower = value[variable].toLowerCase();
      if (nameLower.search(filter) != -1) {
        results.push(value);
      }
    });

    if (results.length > 0) {
      const project = new FakeProject();
      project.uid = this.uid;
      project.name = this.name;
      project.searchCache = results;
      return project;
    } else {
      return null;
    }
  }

  _getNewOnTagFilter(filter: string) {
    // console.log(filter)
    // const filterSet = new Set(filter.split(" "))

    const results = new Array();

    this.searchCache.forEach((value) => {
      const nameLower = value.tags.toLowerCase();
      if (nameLower.search(filter) != -1) {
        results.push(value);
      }
    });

    if (results.length > 0) {
      const project = new FakeProject();
      project.uid = this.uid;
      project.name = this.name;
      project.searchCache = results;
      return project;
    } else {
      return null;
    }
  }

  createNewOnNameFilter(nameFilter: string) {
    return this._getNewOnFilter(nameFilter, "name");
  }

  createNewOnNameAndCategory(nameFilter: string, categoryFilter: string) {
    const results = new Array();

    this.searchCache.forEach((value) => {
      const nameLower = value.name.toLowerCase();
      if (nameFilter === "") {
        if (value.type == categoryFilter || categoryFilter === "") {
          results.push(value);
        }
      } else {
        if (
          (value.type == categoryFilter || categoryFilter === "") &&
          nameLower.search(nameFilter) != -1
        ) {
          results.push(value);
        }
      }
    });

    if (results.length > 0) {
      const project = new FakeProject();
      project.uid = this.uid;
      project.name = this.name;
      project.searchCache = results;
      return project;
    } else {
      return null;
    }
  }

  createNewOnTagFilter(tagFilter: string) {
    return this._getNewOnTagFilter(tagFilter);
  }

  getBoardsOnFilters(type: string, filter: string) {
    let boards = [];

    this.spots.forEach((value) => {
      boards = boards.concat(value.getBoardsOfType(type, filter));
    });

    return boards;
  }

  load_from_json(jObject: FakeProject) {
    this.uid = jObject.uid ?? "";
    this.name = jObject.name ?? "";
    this.welcome_panorama = jObject.welcome_panorama ?? "";
    this.welcome_spot = jObject.welcome_spot ?? "";

    //

    this._load_asset_from_json("areas", jObject.areas ?? []);

    const fakeArea = new FakeAsset();
    fakeArea.name = "<Unasigned>";
    this.areas.set("", fakeArea);

    this._load_asset_from_json("videos", jObject.videos ?? []);
    this._load_asset_from_json("sounds", jObject.sounds ?? []);
    this._load_asset_from_json("panoramas", jObject.panoramas ?? []);
    this._load_asset_from_json("meshes", jObject.meshes ?? []);
    this._load_asset_from_json("labels", jObject.labels ?? []);
    this._load_asset_from_json("images", jObject.images ?? []);

    this._load_spots_from_json(jObject.spots ?? []);

    this._bake_search_cache();
  }

  _bake_search_cache() {
    this.spots.forEach((value) => {
      this.searchCache.push(value);

      Array.from(value.boards.values()).forEach((board: FakeBoard) => {
        if (board.type !== "GateBoard") {
          this.searchCache.push(board);
        }
      });
    });
  }

  _load_spots_from_json(jsonObject: Array<FakeSpot>) {
    const spots = new Map();

    jsonObject.map((value) => {
      const fakeSpot = new FakeSpot();
      fakeSpot.parentProject = this;
      fakeSpot.parentProjectUid = this.uid;
      fakeSpot.load_from_json(value);

      const area = this.areas.get(fakeSpot.areaId);

      if (area) {
        fakeSpot.areaName = area.name;
      }

      fakeSpot.urlPath = urlcWithPath(`project/${this.uid}?spot=${fakeSpot.uid}`);
      fakeSpot.navigationPath = `${fakeSpot.areaName}`;
      fakeSpot.previewPath = urlcWithPath(
        `infinity/${this.uid}/Panoramas/${fakeSpot.panorama_uid}/preview.png`
      );

      fakeSpot.boards.forEach((board: FakeBoard) => {
        board.urlPath = urlcWithPath(`project/${this.uid}?spot=${fakeSpot.uid}`);

        board.navigationPath = `${this.areas.get(fakeSpot.areaId).name}/${
          fakeSpot.name
        }`;

        if (board.type === "OutlineBoard") {
          board.previewPath = urlcWithPath("assets/icons/EI.OutlineBoard.png");
        } else if (board.type === "QuizBoard") {
          board.previewPath = urlcWithPath("assets/icons/EI.QuizBoard.png");
        } else if (board.type === "SoundBoard") {
          board.previewPath = urlcWithPath("assets/icons/EI.SoundBoard.png");
        } else if (board.type === "InfoBoard") {
          const label = this.labels.get(board.content_uid);
          if (label) {
            board.header = label.header;
            board.body = label.body;

            if (label.image_uid !== "") {
              const image = this.images.get(label.image_uid);
              if (image) {
                board.previewPath = urlcWithPath(
                  `infinity/${this.uid}/Images/${label.image_uid}/default.${image.file_extension}`
                );
              }
            }
          }
        } else {
          if (board.content_uid === "") {
            board.previewPath = urlcWithPath(
              `assets/icons/${BOARDS_EMPTY_PLACEHOLDER_ICONS[board.type]}`
            );
          } else {
            board.previewPath = urlcWithPath(
              `infinity/${this.uid}/${ASSETS_TRANSITION_MAP[board.type]}/${
                board.content_uid
              }/preview.png`
            );
          }
        }
      });

      spots.set(fakeSpot.uid, fakeSpot);
    });

    this.spots = spots;
  }

  _load_asset_from_json(
    assetName: string,
    jsonObject: Array<FakeAsset | any> | any
  ) {
    const assets = new Map();
    jsonObject.map((value) => {
      const fakeAsset = new FakeAsset();
      if (assetName == "labels") {
        fakeAsset.load_from_json_as_label(value);
      } else {
        fakeAsset.load_from_json(value);
      }

      assets.set(fakeAsset.uid, fakeAsset);
    });

    this[assetName] = assets;
  }

  getWebPreviewImage() {
    return urlcWithPath(
      `infinity/${this.uid}/Panoramas/${this.welcome_panorama}/preview.png`
    );
  }
}

export class FakeAsset {
  uid = "";
  name = "";
  file_extension = "";

  constructor() {}

  load_from_json(jObject: FakeAsset) {
    this.uid = jObject.uid ?? "";
    this.name = jObject.name ?? "";
    this.file_extension = jObject.file_extension ?? "";
  }

  load_from_json_as_label(jObject: FakeAsset) {
    this.load_from_json(jObject);

    this.body = jObject.body ?? "";
    this.header = jObject.header ?? "";
    this.image_uid = jObject.image_uid ?? "";
    this.url = jObject.url ?? "";
  }

  getWebPreviewImage(): string {
    return urlcWithPath(
      `infinity/${this.parentProjectUid}/Panoramas/${this.panorama_uid}/preview.png`
    );
  }
}

export class FakeSpot {
  uid = "";
  name = "";
  type = "Spot";
  panorama_uid = "";
  areaId = "";
  areaName = "";
  tags = "";

  urlPath = "";
  navigationPath = "";
  previewPath = "";

  parentProjectUid = "";
  parentProject = null;
  boards: Map<FakeBoard> = new Map();

  constructor() {}

  load_from_json(jObject: FakeSpot) {
    this.uid = jObject.uid ?? "";
    this.name = jObject.name ?? "";
    this.panorama_uid = jObject.panorama_uid ?? "";
    this.areaId = jObject.area_id ?? "";
    this.tags = jObject.search_tags ?? "";
    this.boards = this.get_boards_from_jobject(jObject.boards ?? []);
  }

  get_boards_from_jobject(jObject: Array<FakeBoard>): Map<FakeBoard> {
    const boards = new Map();

    jObject.map((value) => {
      const fakeBoard = new FakeBoard();
      fakeBoard.load_from_json(value);
      fakeBoard.parentSpotUid = this.uid;
      fakeBoard.parentSpot = this;
      boards.set(fakeBoard.uid, fakeBoard);
    });
    return boards;
  }
}

export class FakeBoard {
  uid = "";
  name = "";
  tags = "";
  type = "";
  content_uid = "";
  parentSpotUid = "";
  urlPath = "";
  navigationPath = "";
  previewPath = "";

  constructor() {}

  load_from_json(jObject: FakeBoard) {
    this.uid = jObject.uid ?? "";
    this.name = jObject.name ?? "";
    this.tags = jObject.search_tags ?? "";
    this.type = jObject.type;
    this.content_uid = jObject.content_uid ?? "";
  }
}
