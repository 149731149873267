import React from "react";
import {Table} from "react-bootstrap";


type MyTableProps = {
    children?: any,
    style?: React.StyleHTMLAttributes<any>
}

export default function MyTable({children, style}: MyTableProps) {
    return (
        <Table bordered striped hover style={{
            background: "white",
            ...(style ?? {})
        }}>
            {
                children
            }
        </Table>
    )
}