import { useState } from "react";
import { useTranslation } from "react-i18next";

const LABEL_STYLE = {
  width: "150px",
  fontSize: "15px",
};

const CONTENT_STYLE = {
  display: "flex",
  alignItems: "center",
  minHeight: "24px",  
};

const INPUT_STYLE = {
  border: "1px solid transparent",  
  height: "20px",
  width: "20px",
};

interface BoolInpuntProps {
  object: object;
  label: string;
  value: string;
  onChange?: (value: boolean) => void;
}

export default function BoolCheckBoxInput({
  object,
  label,
  value,
  onChange,
}: BoolInpuntProps) {
  const [t] = useTranslation();

  const [val, setVal] = useState(object[value]);

  return (
    <div style={CONTENT_STYLE}>
      <div style={LABEL_STYLE}>{t(label)}:</div>

      <input
        style={INPUT_STYLE}
        type={"checkbox"}
        checked={val}
        onChange={(event) => {
          const newValue = event.target.checked;
          setVal(newValue);
          object[value] = newValue;

          onChange && onChange(newValue);
        }}
      />
    </div>
  );
}
