import React from "react";
import {useTranslation} from "react-i18next";
import ProjectsApi from "../../api/ProjectsApi";
import {Spinner, Table} from "react-bootstrap";
import MyTable from "../../components/mytable";


export default function ProjectPropertiesTable({projectId}) {

    const [projectJson, setProjectJson] = React.useState(null)

    const [t] = useTranslation()

    React.useEffect(() => {
        ProjectsApi.getProjectStats(projectId).then((response) => {
            setProjectJson(response.data)
        })
    }, [])


    if (projectJson == null) {
        return (
            <Spinner animation="border" variant="danger" style={{
                margin: "auto"
            }}/>
        )
    }


    return (
        <MyTable>
            <thead>
            <tr>
                <th>{t("type")}</th>
                <th>{t("count")}</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>{t("spots")}:</td>
                <td>{projectJson.spots}</td>
            </tr>


            <tr>
                <td>{t("core.boards.info")}:</td>
                <td>{projectJson["InfoBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.video")}:</td>
                <td>{projectJson["VideoBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.sound")}:</td>
                <td>{projectJson["SoundBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.quiz")}:</td>
                <td>{projectJson["QuizBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.mesh")}:</td>
                <td>{projectJson["MeshBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.outline")}:</td>
                <td>{projectJson["OutlineBoard"]}</td>
            </tr>
            <tr>
                <td>{t("core.boards.pdf")}:</td>
                <td>{projectJson["DocumentationBoard"]}</td>
            </tr>
            {/*<tr>*/}
            {/*    <td>{t("core.boards.click")}:</td>*/}
            {/*    <td>{projectJson["ClickBoard"]}</td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*    <td>{t("core.boards.twin")}:</td>*/}
            {/*    <td>{projectJson["DigitalTwinBoard"]}</td>*/}
            {/*</tr>*/}
            </tbody>
        </MyTable>
    )
}
