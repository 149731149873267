import React from "react"

import * as sb from "react-pro-sidebar"

import {useTranslation} from "react-i18next";

import {useNavigate} from "react-router-dom";

import {Image} from "react-bootstrap";

import "./AppNavBar.css"

import {OnlyAdminView, OnlySuperAdminView} from "./components/user_dispatcher";

import SettingsOffcanvas from "./settings/SettingsView";

import {useActiveProject} from "./project/project_zustands/activeProjectZus";

import {COOKIE_MANAGER} from "./CookieManager";
import {useActiveTestTour} from "./zustands/activeTestTourZus";
import {useCookieBoolean} from "./devtools/hooks";
import UsersApi from "./api/UsersApi";
import urlcWithPath from "./urlc";
import LicenceManager from "./api/LicenceManager";

export default function AppNavBar() {

    const [t] = useTranslation()

    const navigate = useNavigate()

    const [isCollapsed, setisCollapsed] = useCookieBoolean("navbar-collapsed", false)

    const activeTaskZus = useActiveTestTour()

    const collapse = () => {
        setisCollapsed(!isCollapsed as any)
    }

    const aProject = useActiveProject()
    const activeTestZus = useActiveTestTour()


    const canSeeNavBar = !UsersApi.canUserIterateProjects() || activeTestZus.task

    if (canSeeNavBar) {
        return (
            <div style={{
                float: "left",
                height: "100vh",
                width: "0vh",
            }}/>
        )
    } else {

        return (

            <div style={{
                width: isCollapsed ? "79px" : "200px",
                height: "100vh",
                float: "left"
            }}
                 className={"not-draggable"}
            >
                <sb.Sidebar
                    backgroundColor={"rgba(0, 0,0, 0.5)"}
                    image={"/textures/appBackgroundBlured.png"}
                    width={isCollapsed ? "80px" : "201px"}
                    style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        touchAction: "none",
                        position: "fixed",
                        left: "0px",
                        height: "100vh"

                    }}
                >
                    <sb.Menu>
                        <div
                            style={{
                                padding: '24px',
                                fontWeight: 'bold',
                                fontSize: 14,
                                letterSpacing: '1px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: "white",
                                cursor: "pointer",
                                textAlign: "center"

                            }} onClick={() => {
                            collapse()
                        }}>
                            {isCollapsed ? ">" : " <  Infinity 360"}
                        </div>

                    </sb.Menu>

                    <sb.Menu>
                        <MyNavbarItem lang={"core.projects"} link={"/projects"} icon={urlcWithPath("i-icons/folders.svg")}/>

                        <OnlyAdminView>
                            <MyNavbarItem lang={"core.adminprojects"} link={"/adminprojects"}
                                          icon={urlcWithPath("i-icons/hierarchy.svg")}/>
                        </OnlyAdminView>

                        <MyNavbarItem lang={"core.search"} link={"/search"} icon={urlcWithPath("i-icons/search.svg")}/>

                        <OnlyAdminView>
                            <MyNavbarItem lang={"core.statistics"} link={"/statistics"} icon={urlcWithPath("i-icons/statistics.svg")}/>
                        </OnlyAdminView>

                        <OnlySuperAdminView>
                            <MyNavbarItem lang={"core.users"} link={"/users"} icon={urlcWithPath("i-icons/user.svg")}/>
                        </OnlySuperAdminView>


                        {
                            aProject.project &&
                            <>
                                <hr style={{color: "white", margin: "0px", marginLeft: "15px", marginRight: "15px"}}/>
                                <MyNavbarItem lang={"core.tour"}
                                              onClick={() => {
                                                  navigate(`/project/${aProject.project.uid}?spot=${COOKIE_MANAGER.activeSpotUid}`)
                                              }}

                                              icon={urlcWithPath("i-icons/spot.svg")}/>
                            </>
                        }

                    </sb.Menu>

                    <sb.Menu
                        style={{
                            position: "absolute",
                            bottom: "0px",
                            width: "100%"
                        }}
                    >
                        <hr style={{color: "lightgray"}}/>

                        <_SettingsNavItem/>

                        <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: '20px 24px',

                            }}
                        >
                            <div style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}>
                                <Image
                                    src={isCollapsed ? urlcWithPath("branding/branding_short.svg") : urlcWithPath("branding/branding_long.svg")}
                                    onClick={() => {
                                        window.open(LicenceManager.url, "_blank")
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        maxWidth: isCollapsed ? "79px" : "200px",
                                        maxHeight: "50px",

                                    }}
                                />
                            </div>


                        </div>

                    </sb.Menu>
                </sb.Sidebar>
            </div>

        )
    }

}

interface MyNavbarItemProps {
    lang: string
    icon: string
    link?: string
    onClick?: () => void
}

function MyNavbarItem({lang, icon, link, onClick}: MyNavbarItemProps) {

    const navigate = useNavigate()

    const [t] = useTranslation()

    let iconComponent = (
        <Image src={icon} style={{
            margin: "auto",
            width: "20px",
            height: "20px"
        }}/>
    )

    return (
        <sb.MenuItem
            icon={iconComponent}
            onClick={(e: MouseEvent) => {
                if (onClick) {
                    onClick()
                } else if (link) {

                    navigate(link)
                }
            }}
            className={"navbaritem"}
        >
            {t(lang)}

        </sb.MenuItem>
    )
}

function _SettingsNavItem({}) {

    const [show, setShow] = React.useState(false)

    return (
        <>
            <MyNavbarItem
                icon={urlcWithPath("i-icons/settings.svg")}
                lang={"core.settings"}
                onClick={() => {
                    setShow(true)
                }}
            />
            {
                show &&
                <SettingsOffcanvas onHide={() => setShow(false)}/>
            }
        </>
    )
}