import create from "zustand"

import {InfinityProject} from "../../infinity/InfinityProject";

interface ActiveProjectState {
    project: InfinityProject | any,
    setActiveProject: (project: InfinityProject | null) => void
}


export class ActiveProjectSingleton {
    static activeProject: InfinityProject | null = null
}


export const useActiveProject = create<ActiveProjectState>((set) => ({
    project: null,
    setActiveProject: (newProject) => set((newState) => {
        ActiveProjectSingleton.activeProject = newProject
        return {project: newProject}
    }),
}))
