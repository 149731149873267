import React from "react";
import {BasicMiddleSpinner} from "./spinners";

import {ActiveProjectSingleton, useActiveProject} from "../project/project_zustands/activeProjectZus";

import {InfinityProject} from "../infinity/InfinityProject";

import {useParams} from "react-router-dom";

import ProjectsApi from "../api/ProjectsApi";

import ErrorBoundary from "./ErrorBoundary";
import {useEffectOnce} from "../devtools/react_effects";
import DeviceDispatcher from "../devtools/DeviceDispatcher";
import {ProjectPlayer} from "../infinity/ProjectPlayer";


export default function MountProjectWrapper({children}) {

    const {projectid} = useParams()

    const projectZus = useActiveProject()

    const activeProject: InfinityProject = projectZus.project;

    const [isLoading, setIsLoading] = React.useState(true)

    const [projectError, setProjectError] = React.useState("")

    const fun = DeviceDispatcher.isDevelopment() ? useEffectOnce : React.useEffect

    useEffectOnce(() => {

        if (projectid && projectid !== "") {
            if (activeProject && (activeProject.uid === projectid)) {
                setIsLoading(false)
            } else {


                setIsLoading(true)
                //
                projectZus.setActiveProject(null)

                document.title = `Loading ...`

                ProjectsApi.getProjectWithUid(projectid).then((project) => {
                    project.activePlayer = ProjectPlayer.INSTANCE
                    projectZus.setActiveProject(project)
                    document.title = `Project-${project.name}`

                    setIsLoading(false)
                }).catch((e) => {
                    setProjectError(e)
                })
            }


        } else {
            setIsLoading(false)
        }
    }, [projectid])

    if (isLoading || !activeProject) {
        return (
            <div style={{
                display: "flex",
            }}>
                <div style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}>
                    <BasicMiddleSpinner/>
                </div>
            </div>
        )
    } else if (projectError !== "") {
        return (
            <div>{projectError}</div>
        )
    } else {
        return (
            <ErrorBoundary>
                <React.Suspense fallback={<BasicMiddleSpinner/>}>
                    {React.Children.toArray(children)}
                </React.Suspense>
            </ErrorBoundary>
        )
    }
}

