import React from "react";
import { Col, Container, Image, Row, Stack, Table } from "react-bootstrap";
import TourStatisticsApi from "../../api/TourStatisticsApi";

import "./TrainingPrintView.css";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import GuidedTourRecorder, {
  BoardRecord,
  GuidedTaskRecord,
  GuidedTourSpotRecord,
} from "../../GuidedTourRecorder";
import { BasicMiddleSpinner } from "../../components/spinners";
import MyTable from "../../components/mytable";
import { formatPercentage } from "../../formatters/percentage";
import {
  CloseButton,
  UniversalRoundButton,
} from "../../gui/icon_click_buttons";
import urlcWithPath from "../../urlc";

export default function TrainingPrintView() {
  const { recorduid } = useParams();

  return <_TourStatisticsPrintView recordUid={recorduid} />;
}

function _TourStatisticsPrintView({ recordUid }) {
  const [record, setRecord] = React.useState<any>(null);

  React.useEffect(() => {
    TourStatisticsApi.getStatRecord(recordUid).then((response) => {
      const record = new GuidedTourRecorder();
      record.from_json(response.data);
      setRecord(record);
    });
  }, [recordUid]);

  return (
    <div
      style={{
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "100%",
        minHeight: "100%",
        zIndex: 150,
        backgroundColor: "#5f6368",
      }}
    >
      {record ? <_RecordView record={record} /> : <BasicMiddleSpinner />}
    </div>
  );
}

function getResultIcon(percentage: number) {
  const color = percentage === 1 ? "green" : "red";

  const icon = percentage === 1 ? <span>&#10003;</span> : <span>&#10005;</span>;

  return <div style={{ color: color }}>{icon}</div>;
}

interface _RecordViewProps {
  record: GuidedTourRecorder;
}

function _RecordView({ record }: _RecordViewProps) {
  const [t] = useTranslation();

  const SHADOW = {
    boxShadow:
      "  0 2.8px 2.2px rgba(0, 0, 0, 0.1),\n" +
      "  0 6.7px 5.3px rgba(0, 0, 0, 0.1),\n" +
      "  0 10.5px 20px rgba(0, 0, 0, 0.1)",
  };

  return (
    <>
      <div
        style={{
          background: "#5F6368",
          width: "100%",
          height: "1000%",
          position: "fixed",
          top: "0px",
          left: "0px",
        }}
        className={"EndGuiBackground"}
      />

      <Stack className={"EndGuiWrapper"}>
        <Container
          style={{
            marginTop: "25px",
            background: "white",
            ...SHADOW,
          }}
        >
          <_PostBar statsResult={record} activeProject={record.project_uid} />
        </Container>

        <Container
          style={{
            maxWidth: "1200px",
            height: "auto",

            marginLeft: "auto",
            marginRight: "auto",

            padding: "25px 25px 25px 25px",
            marginTop: "25px",
            background: "white",
            ...SHADOW,
          }}
        >
          <GuidedTourResultsTableView record={record} />
        </Container>
      </Stack>
    </>
  );
}

export function GuidedTourResultsTableView({ record }) {
  const [t] = useTranslation();

  return (
    <>
      <Row>
        <Col>
          <h2 style={{ textAlign: "center" }}>{t("tour.result")}</h2>
        </Col>
      </Row>

      <div style={{ marginTop: "25px" }} />

      <_TrainingHeader record={record} />

      <_TableDefintionHeader record={record} />

      <_TablesBody record={record} />

      <Row>
        <Col>
          {" "}
          <Image className={"BrandingImage"} src={"/favicon.ico"} />
        </Col>
      </Row>

      <div style={{ height: "45px" }} />
    </>
  );
}

function _PostBar({}) {
  const [t] = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={"hstack gap-3"}>
      <button
        className={"btn btn-success"}
        style={{ margin: "10px" }}
        onClick={() => {
          window.print();
        }}
      >
        {t("tour.result.download")}
      </button>

      <div
        style={{
          marginLeft: "auto",
        }}
      >
        <button
          className={"btn btn-danger"}
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("X")}
        </button>
      </div>
    </div>
  );
}

function _TrainingHeader({ record }: _RecordViewProps) {
  const [t] = useTranslation();

  return (
    <MyTable>
      <tbody>
        <tr>
          <td>{t("name")}:</td>
          <td>
            <b>{record.trainee_name}</b>
          </td>
        </tr>
        <tr>
          <td>{t("training.start")}:</td>
          <td>{record.getStartTimeLocal()}</td>
        </tr>
        <tr>
          <td>{t("training.end")}:</td>
          <td>{record.getEndTimeLocal()}</td>
        </tr>
        <tr>
          <td>{t("training.duration")}:</td>
          <td>{record.duration}</td>
        </tr>
        <tr>
          <td>{t("training.result")}:</td>
          <td>{formatPercentage(record.success_rate)}</td>
        </tr>
      </tbody>
    </MyTable>
  );
}

function _TableDefintionHeader({ record }: _RecordViewProps) {
  const [t] = useTranslation();

  return (
    <MyTable>
      <thead>
        <tr>
          <td style={{ width: "50px", textAlign: "center" }}>
            <h5>Id.</h5>
          </td>
          <td style={{ textAlign: "center" }}>
            <h5>{t("spot.name")}</h5>
          </td>
          <td style={{ textAlign: "right", width: "100px" }}>
            <h5>{t("core.success.rate")}</h5>
          </td>
        </tr>
      </thead>
    </MyTable>
  );
}

function _TablesBody({ record }: _RecordViewProps) {
  const [t] = useTranslation();

  return (
    <>
      {record.records.map((value: GuidedTaskRecord, id) => {
        return (
          <MyTable key={value.uid}>
            <thead>
              <tr>
                <td style={{ width: "50px" }}>
                  <h6>{id + 1}.</h6>
                </td>
                <td>
                  <h6>{value.name}</h6>
                </td>

                <td style={{ textAlign: "right", width: "100px" }}>
                  {formatPercentage(value.percentage)}
                </td>
              </tr>
            </thead>

            <tbody>
              {Array.from(value.spotRecords.values()).map(
                (spotData: GuidedTourSpotRecord, index) => {
                  return Array.from(spotData.boardRecords.values()).map(
                    (boardData) => {
                      return (
                        <tr key={`${spotData.uid}/${boardData.uid}`}>
                          <td>
                            <div
                              style={{
                                width: "27px",
                                height: "27px",
                                background: "#727272",
                              }}
                            >
                              <Image
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  margin: "auto",
                                  padding: "auto",
                                }}
                                src={urlcWithPath(
                                  `infinity/${record.project_uid}/icons/${boardData.icon}.png`
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            {spotData.name} / {boardData.name}
                          </td>

                          <_ResultBoardTypeDispatched boardData={boardData} />
                        </tr>
                      );
                    }
                  );
                }
              )}
            </tbody>
          </MyTable>
        );
      })}
    </>
  );
}

function _ResultBoardTypeDispatched({ boardData }: { boardData: BoardRecord }) {
  if (boardData.board_type === "quiz") {
    return (
      <td style={{ textAlign: "right" }}>
        {formatPercentage(boardData.percentage)}
      </td>
    );
  } else {
    <td style={{ textAlign: "right" }}>
      {getResultIcon(boardData.percentage)}
    </td>;
  }
}
