export default class DeviceManager {
    static isIOS() {
        return false
    }

    //     return [
    //             'iPad Simulator',
    //             'iPhone Simulator',
    //             'iPod Simulator',
    //             'iPad',
    //             'iPhone',
    //             'iPod'
    //         ].includes(navigator.platform)
    //         // iPad on iOS 13 detection
    //         || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    // }

    static isAndroid() {
        const ua = navigator.userAgent;
        if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return true
        } else {
            return false
        }
    }

    static isOculus() {
        return /OculusBrowser/i.test(window.navigator.userAgent);
    }

    static isLowPerformance() {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return true
        }
        if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Mobile Safari|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return true
        }

        return false
    }

}