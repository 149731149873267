import React from "react";


import {Container, Spinner, Stack, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import AddUserToGroupModal from "./users_view/AddUserToGroupModal";
import IconClickButton from "../components/IconClickButton";
import {useActiveUserZus} from "../zustands/activeUserZus";
import EditUserOffcanvas from "./users_view/EditUserOffcanvas";
import ProjectGroupsApi from "../api/ProjectGroupsApi";
import UsersApi from "../api/UsersApi";
import {ViewContainer} from "../shared/containers";
import {useGlobalPopup} from "../zustands/globalPopupZus";
import {showYesNoModal} from "../components/AreYouSurePopup";
import MyTable from "../components/mytable";
import TimeManager from "../managers/TimeManager";
import urlcWithPath from "../urlc";


export default function UserView({}) {

    const userZus = useActiveUserZus()

    const activeUser = userZus.activeUser

    if (activeUser) {
        return (
            <ViewContainer>
                <Stack gap={3}>
                    {
                        activeUser.type === "superadmin" && <UsersManagementView/>
                    }
                </Stack>
            </ViewContainer>

        )
    } else {
        return (<div>Invalid user</div>)
    }


}

function UsersManagementView() {

    const superAdminsRef = React.useRef()
    const adminsRef = React.useRef()
    const usersRef = React.useRef()

    const [lastVisits, setLastVisits] = React.useState()
    const [groupAssignments, setGroupAssignments] = React.useState()

    const refresh = () => {
        console.log("Refreshing")

        refreshUserGroupsAssigments()
        superAdminsRef.current.refreshUsers()
        adminsRef.current.refreshUsers()
        usersRef.current.refreshUsers()

    }

    const refreshUserGroupsAssigments = () => {
        ProjectGroupsApi.getUsersAndGroupAssignments().then((result) => {
            setGroupAssignments(result.users)
        })
    }

    React.useEffect(() => {
        refreshUserGroupsAssigments()

        UsersApi.getAllUsersLastVisit().then((value) => {
            setLastVisits(value)
        })
    }, [])


    return (
        <Container>
            <Stack gap={3}>
                <UsersTableView
                    ref={superAdminsRef}
                    onRefresh={refresh}
                    group={"superadmin"}
                    projectGroupsEnabled={false}
                    last_visits={lastVisits}
                />

                <UsersTableView
                    ref={adminsRef}
                    onRefresh={refresh}
                    assignmnents={groupAssignments}
                    group={"admin"}
                    projectGroupsEnabled={true}
                    last_visits={lastVisits}
                />

                <UsersTableView
                    ref={usersRef}
                    onRefresh={refresh}
                    group={"user"}
                    projectGroupsEnabled={false}
                    last_visits={lastVisits}
                />
            </Stack>
        </Container>
    )

}

function UsersTableHead({projectGroupsEnabled}) {

    const STYLE = {
        textAlign: "center"
    }

    const [t] = useTranslation()

    return (
        <thead>
        <tr>
            <td style={STYLE}><b>{t("core.name")}</b></td>
            <td style={STYLE}><b>{t("core.email")}</b></td>
            {
                projectGroupsEnabled && <td style={STYLE}><b>{t("core.project.groups")}</b></td>
            }
            <td style={STYLE}><b>{t("core.actions")}</b></td>
            <td style={STYLE}><b>{t("core.last.visit")}</b></td>
        </tr>
        </thead>
    )
}


function AddUserToGroup({group, onRefresh}) {

    const popup = useGlobalPopup()

    const addClicked = () => {
        popup.pushElement(
            <AddUserToGroupModal expectedGroup={group}
                                 onHide={() => {
                                     popup.popElement()
                                 }}

                                 onRefresh={onRefresh}
            />
        )
    }

    return (
        <>

            <Stack>
                <IconClickButton icon={urlcWithPath("i-icons/add.svg")} lang={`core.add.${group}`} onclick={addClicked}/>
            </Stack>

        </>
    )
}


const UsersTableView = React.forwardRef(({
                                             group,
                                             onRefresh,
                                             addDisabled,
                                             assignmnents,
                                             last_visits,
                                             projectGroupsEnabled
                                         }, ref) => {

    const _lastVisits = last_visits ?? {}

    const usePopup = useGlobalPopup()

    const [t] = useTranslation()

    const [users, setUsers] = React.useState([])

    const [isLoading, setIsLoading] = React.useState(false)

    const refreshUsers = () => {
        setIsLoading(true)

        UsersApi.getAllUsersFromGroup(group).then((result) => {
            setUsers(result.data.users)
            setIsLoading(false)
        })
    }

    React.useImperativeHandle(ref, () => ({refreshUsers}))

    React.useEffect(refreshUsers, [])

    const activeUserZus = useActiveUserZus()

    const activeUser = activeUserZus.activeUser


    const removeConfirmed = (userIdentifier) => {
        UsersApi.removeUser(userIdentifier).then(() => {
            refreshUsers()
        })

    }

    const removeUserPressed = (userIdentifier) => {
        showYesNoModal(null, `${t("core.remove.user")}: <b>${userIdentifier}</b>`, () => {
            removeConfirmed(userIdentifier)
        })

    }


    const editUserPressed = (userIdentifier) => {
        usePopup.pushElement(<EditUserOffcanvas
            userIdentifier={userIdentifier}
            onRefresh={onRefresh}
            onHide={() => {
                usePopup.popElement()
            }}
        />)

    }


    return (
        <>

            <h2 style={{textAlign: "center"}}>{t(`core.usergroup.${group}`)}</h2>


            {
                !addDisabled &&
                <AddUserToGroup group={group} onRefresh={onRefresh}/>
            }


            {
                isLoading ? <>
                        <Spinner animation={"border"} variant={"danger"} style={{
                            margin: "auto"
                        }}/>
                    </> :
                    <>
                        <MyTable>
                            <UsersTableHead projectGroupsEnabled={projectGroupsEnabled}/>

                            <tbody>
                            {
                                users.map((value, index) => {
                                    return (
                                        <tr key={value.email}>
                                            <td>{value.name}</td>
                                            <td>{value.email}</td>
                                            {
                                                projectGroupsEnabled &&
                                                <td>
                                                    <_UserProjectGroupsView assignments={assignmnents}
                                                                            userIdentifier={value.email}/>
                                                </td>
                                            }

                                            <td>
                                                <Stack direction={"horizontal"} gap={2}>

                                                    <button
                                                        className={"btn btn-outline-primary btn-sm"}
                                                        onClick={() => {
                                                            editUserPressed(value.email)
                                                        }}
                                                    >
                                                        {t("core.edit")}
                                                    </button>

                                                    {
                                                        activeUser.email !== value.email &&
                                                        <button
                                                            className={"btn btn-outline-danger btn-sm"}
                                                            onClick={() => {
                                                                removeUserPressed(value.email)
                                                            }}
                                                        >
                                                            {t("core.remove")}
                                                        </button>
                                                    }


                                                </Stack>
                                            </td>
                                            <td>
                                                {
                                                    last_visits &&
                                                    <_VisitView lastVisit={_lastVisits[value.email]}/>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>

                        </MyTable>


                    </>
            }


        </>
    )
})

function _UserProjectGroupsView({userIdentifier, assignments}) {


    const _assignments = assignments ?? {}


    if (assignments) {
        const _user = _assignments[userIdentifier]

        if (_user) {

            const userGroupKeys = Object.keys(_user)

            return (
                <Stack gap={1} direction={"horizontal"}>
                    {
                        userGroupKeys.map((key, index) => {
                            const _data = _user[key]
                            return (
                                <div
                                    key={_data.uid}>{_data.name}{index != userGroupKeys.length - 1 && ","}
                                </div>
                            )
                        })
                    }
                </Stack>
            )
        } else {
            return (<></>)
        }


    }

    return <Spinner animation={"border"} variant={"danger"}/>
}


function _VisitView({lastVisit}) {

    const [t] = useTranslation()

    const [visit, setVisit] = React.useState<any>("")

    React.useEffect(() => {

        if (lastVisit) {
            const timeString = lastVisit

            if (timeString !== "1997-01-01 00:00:00") {
                const convertedDate = TimeManager.convertUTCDateToLocalDate(new Date(timeString))
                const secondsDiff = (new Date().getTime() - convertedDate) / 1000
                const minutesDiff = secondsDiff / 60
                const hoursDiff = minutesDiff / 60
                const daysDiff = hoursDiff / 24
                const yearsDiff = daysDiff / 365

                if (yearsDiff >= 1) {
                    setVisit(<div>{Math.floor(yearsDiff)} {t("core.last.visit.years")}</div>)
                } else if (daysDiff > 1) {
                    setVisit(<div>{Math.floor(daysDiff)} {t("core.last.visit.days")}</div>)
                } else if (hoursDiff > 1) {
                    setVisit(<div>{Math.floor(hoursDiff)} {t("core.last.visit.hours")}</div>)
                } else (
                    setVisit(<div style={{
                        color: minutesDiff <= 60 ? "green" : null
                    }}>{Math.floor(minutesDiff)} {t("core.last.visit.minutes")}</div>)
                )
            }

        }

    }, [])

    return (
        <div>
            {
                visit
            }
        </div>
    )

}