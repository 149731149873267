import DeviceDispatcher from "../devtools/DeviceDispatcher";
import LicenceApi from "./LicenceApi";


class Player360Functionality {

    hide_boards_selection_enabled = false
    spot_visits_monitoring = false
    quizzes_monitoring = false
    boards_monitoring = false
    visited_spot_in_map = false
    users_projects_listing = true
    can_user_search = true
    can_see_hud_icons = true
    can_see_minimap = true

    from_json_object(obj: any) {
        this.hide_boards_selection_enabled = obj.hide_boards_selection_enabled ?? false
        this.spot_visits_monitoring = obj.spot_visits_monitoring ?? false
        this.quizzes_monitoring = obj.quizzes_monitoring ?? false
        this.boards_monitoring = obj.boards_monitoring ?? false
        this.visited_spot_in_map = obj.visited_spot_in_map ?? false
        this.users_projects_listing = obj.users_projects_listing ?? true
        this.can_user_search = obj.can_user_search ?? true
        this.can_see_hud_icons = obj.can_see_hud_icons ?? true
        this.can_see_minimap = obj.can_see_minimap ?? true
    }
}


export default class LicenceManager {

    static licenseValid = false

    static url = "https://exe.sk"

    static editor360Valid = false
    static editorArValid = false
    static editorVrValid = false
    static organizationLink = ""

    static anyEditorValid = false
    static projectsMapEnabled = false

    static usesNewLayoutAsDefault = false

    static f360 = new Player360Functionality()

    static async init() {
        const isValid = await LicenceManager.hasValidLicense()

        if (DeviceDispatcher.isDesktop()) {
            LicenceManager.licenseValid = isValid
            LicenceManager.editor360Valid = isValid
            LicenceManager.editorArValid = isValid

            LicenceManager.anyEditorValid = (
                LicenceManager.editor360Valid ||
                LicenceManager.editorArValid ||
                LicenceManager.editorVrValid
            )
        } else {
            LicenceManager.licenseValid = true

            const data = await LicenceApi.getLicence()

            LicenceManager.url = data.url ?? LicenceManager.url

            LicenceManager.usesNewLayoutAsDefault = data.usesNewLayoutAsDefault ?? false

            LicenceManager.f360.from_json_object(data.player360functionality ?? {})

            LicenceManager.organizationLink = data.organizationLink ?? ""

            LicenceManager.projectsMapEnabled = data.projectsMapEnabled ?? false
            LicenceManager.editor360Valid = true
            LicenceManager.editorArValid = true
            LicenceManager.anyEditorValid = true

            //return result.data
        }

    }

    static async hasValidLicense(): Promise<boolean> {
        if (DeviceDispatcher.isDesktop()) {
            // return await TauriLicences.isLicenceValid()
            return true
        } else {
            return true
        }
    }
}


class LicensesType {
    static BASIC = "Basic"
    static PRO = "Pro"
    static CUSTOM = "Custom"

}