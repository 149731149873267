import * as three from "three";

import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory";

import { MyVRButton } from "../ela/world/vr/MyVRButton";

import DeviceManager from "../ela/deviceManager";
import urlcWithPath from "../urlc";

class VrAvatarHand {
  isTriggerPressed = false;
  gamePadButtons = null;

  onButtonX: Array<Function> = [null, null, null, null, null, null, null];

  buttonPressedX: Array<boolean> = [false, false, false, false, false, false];

  constructor(index, world, group: three.Group) {
    this.world = world;

    const controllerModelFactory = new XRControllerModelFactory();

    const controllerGrip = world.renderer.xr.getControllerGrip(index);

    const model = controllerModelFactory.createControllerModel(controllerGrip);

    model.visible = true;
    model.renderOrder = 1000;
    controllerGrip.add(model);

    // const objectsToAdd = []
    //
    // model.traverse((obj) => {
    //     objectsToAdd.push(obj)
    // })

    // objectsToAdd.forEach((object) => {
    //     object.add(new three.AxesHelper(0.5))
    // })

    controllerGrip.addEventListener("selectstart", this.onTriggerPressed);
    controllerGrip.addEventListener("selectend", this.onTriggerUnpressed);
    controllerGrip.addEventListener("squeezestart", this.onGripPressed);
    controllerGrip.addEventListener("squeezeend", this.onGripUnpressed);
    controllerGrip.addEventListener("connected", (e) => {
      this.gamePadButtons = e.data.gamepad;
    });

    this.intersectGroupA = world.secondaryGroup;
    this.intersectGroupB = world.secondaryNotRotatedGroup;

    this.controller = controllerGrip;

    group.add(controllerGrip);

    this.rayLineGroup = new three.Group();
    // this.rayLineGroup.add(new three.AxesHelper(4))

    const lineImage = new three.TextureLoader().load(
      urlcWithPath("assets/textures/guiclickertexture.png")
    );
    lineImage.encoding = three.sRGBEncoding;

    const geometry = new three.PlaneGeometry(0.01, 1);

    this.rayLineMaterial = new three.MeshStandardMaterial({
      transparent: true,
      map: lineImage,
      side: three.DoubleSide,
      color: new three.Color("white"),
    });

    const rayLine = new three.Mesh(geometry, this.rayLineMaterial);
    rayLine.name = "RaycasterLine" + index;
    rayLine.position.set(0, 0.5, 0);
    rayLine.rotateY(Math.PI / 2);

    const lineSubGroup = new three.Group();
    lineSubGroup.add(rayLine);
    lineSubGroup.rotateX(Math.PI / 2);
    lineSubGroup.rotateY(Math.PI / 4);

    //const controller = renderer.xr.getController(index)

    const spriteTexture = new three.TextureLoader().load(
      urlcWithPath("assets/textures/vrPointer.png")
    );

    const spriteMaterial = new three.SpriteMaterial({ map: spriteTexture });
    this.controllerSprite = new three.Sprite(spriteMaterial);
    this.controllerSprite.position.set(0, 0, 10);
    this.controllerSprite.scale.set(0.15, 0.15, 1);
    this.controllerSprite.renderOrder = 1200;

    this.rayLineGroup.add(lineSubGroup);
    this.rayLineGroup.add(this.controllerSprite);

    //this.rayLineGroup.rotateY(Math.PI / 2)
    this.rayLineGroup.rotation.set(Math.PI - Math.PI / 4, 0, 0);

    controllerGrip.add(this.rayLineGroup);

    this.selectedObject = null;

    this.rayCaster = new three.Raycaster();
  }

  step() {
    if (this.gamePadButtons) {
      this.checkButtonsStatus();
    }
    this.rayCast();
  }

  checkButtonsStatus() {
    for (let i = 0; i < 7; i++) {
      const btn = this.gamePadButtons.buttons[i];
      if (btn) {
        if (this.buttonPressedX[i] != btn.pressed) {
          this.buttonPressedX[i] = btn.pressed;

          if (this.onButtonX[i]) {
            this.onButtonX[i](btn.pressed, this.controller, this.rayLineGroup);
          }
        }
      }
    }
  }

  onTriggerPressed = () => {
    this.isTriggerPressed = true;

    const intersection = this.rayCast();

    this.setRayColor("lightblue");
    if (this.selectedObject) {
      this.selectedObject.clicked(intersection);
    }
  };

  onTriggerUnpressed = () => {
    this.isTriggerPressed = false;
    this.setRayColor("white");
  };

  onGripPressed = () => {};

  onGripUnpressed = () => {};

  rayCast = () => {
    const direction = new three.Vector3();
    this.rayLineGroup.getWorldDirection(direction);

    this.rayCaster.set(this.rayLineGroup.position, direction);

    let intersects = [];
    if (this.world.theatreModeActive == false) {
      intersects = this.rayCaster.intersectObject(this.intersectGroupA, true);

      if (intersects.length === 0) {
        intersects = this.rayCaster.intersectObject(this.intersectGroupB, true);
      }
    } else {
      intersects = this.rayCaster.intersectObject(
        this.world.theatreGroup,
        true
      );
    }

    if (intersects.length > 0) {
      const filtered = intersects.filter(function (res) {
        return (
          res &&
          res.object &&
          res.object.visible &&
          res.object.userData["handler"]
        );
      });

      // console.log(intersects)

      let res = null;

      if (filtered.length > 0) {
        res = filtered[0];
      }

      if (res) {
        let component: any = res.object.userData["handler"];

        if (this.selectedObject === component) {
          if (component.isSelected) {
            if (component.isSelected(res)) {
              this.onSelectStart();
            } else {
              this.onSelectEnd();
            }
          } else {
            this.onSelectStart();
          }

          return res;
        } else if (this.selectedObject === null) {
          if (component.isSelected) {
            if (component.isSelected(res)) {
              this.onSelectStart();
            }
          } else {
            this.onSelectStart();
          }

          this.selectedObject = component;
          this.selectedObject.setSelected();
        } else {
          this.selectedObject.setDeselected();
          this.selectedObject = component;
          this.selectedObject.setSelected();
        }

        return res;
      } else {
        if (this.selectedObject) {
          this.selectedObject.setDeselected();
        }

        this.selectedObject = null;
        this.onSelectEnd();
      }
    } else {
      if (this.selectedObject != null) {
        this.selectedObject.setDeselected();
        this.selectedObject = null;
      }

      this.onSelectEnd();
      this.selectedObject = null;
    }
  };

  setRayColor = (colorString: string) => {
    const color = new three.Color(colorString);
    this.rayLineMaterial.color = color;
    this.rayLineMaterial.needsUpdate = true;
    this.controllerSprite.material.color = color;
  };

  onSelectStart = () => {
    if (this.isTriggerPressed === false) {
      this.setRayColor("lightgreen");
    }
  };

  onSelectEnd = () => {
    if (this.isTriggerPressed === false) {
      this.setRayColor("white");
    }
  };
}

export default class InfinityVRAvatar {
  isLoaded = false;
  teleportEnabled = true;

  avatarGroup = new three.Group();

  leftHand: VrAvatarHand = null;
  rightHand: VrAvatarHand = null;

  //teleportacia link https://immersive-web.github.io/webxr-samples/teleportation.html
  //teleportacia link https://medium.com/@darktears/adding-support-for-vr-inputs-with-webxr-and-three-js-235b40beb6f0
  constructor(world) {
    if ("xr" in navigator) {
      const enabled = DeviceManager.isLowPerformance() == false;
      if (enabled) {
        navigator.xr.isSessionSupported("immersive-vr").then((supported) => {
          if (supported) {
            world.renderer.xr.enabled = true;
            document.body.appendChild(MyVRButton.createButton(world.renderer));
            world.scene.add(this.avatarGroup);

            const swapMapVisibleInvisible = (
              status,
              controller,
              rayLineGroup: three.Object3D
            ) => {
              if (status === true) {
                const newPosition = new three.Vector3();
                newPosition.copy(controller.position);

                const rotation = new three.Euler();
                rotation.copy(controller.rotation);

                var direction = new three.Vector3(1, 0, 0);
                // direction.applyQuaternion( rayLineGroup.quaternion );
                rayLineGroup.getWorldDirection(direction);

                world.worldMapSpawnOrHideRequested(newPosition, direction);
              }
            };

            this.leftHand = new VrAvatarHand(0, world, this.avatarGroup);
            this.leftHand.onButtonX[4] = swapMapVisibleInvisible;
            this.rightHand = new VrAvatarHand(1, world, this.avatarGroup);
            this.rightHand.onButtonX[4] = swapMapVisibleInvisible;

            this.isLoaded = true;
          }
        });
      } else {
        console.log("Vr found, not running VR avatar");
      }
    }
  }

  step() {
    if (this.isLoaded) {
      this.leftHand.step();
      this.rightHand.step();
    }
  }
}
