import React from "react";

import {useTranslation} from "react-i18next";
import Switch, {Case} from "react-switch-case";

import AppVisitsView from "./statistics/AppVisitsView";
import {
    isActiveUserAdmin,
    isActiveUserSuperAdmin,
    OnlyAdminView,
    OnlySuperAdminView
} from "../components/user_dispatcher";
import ProjectVisitsView from "./statistics/project_visits/ProjectVisitsView";
import ProjectAnalysisView from "./statistics/ProjectAnalysisView";
import useCookie from "react-use-cookie";
import {ViewContainer} from "../shared/containers";
import ProjectSpotsVisitView from "./statistics/project_visits/ProjectSpotsVisitView";
import LicenceManager from "../api/LicenceManager";
import IndividualQuizzesView from "./statistics/IndividualQuizzesView";
import BoardsVisitsView from "./statistics/boards_visits/BoardsVisitsView";

import {Box, Tab, Tabs} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel"
import {TabContext} from "@mui/lab";
import GlobalTrainingsView from "./statistics/GlobalTrainingsView";
import GlobalToursView from "./statistics/GlobalToursView";
import IndividualTrainingsView from "./statistics/IndividualTrainingsView";


const BOARD_STYLE: React.CSSProperties = {height: "100px"}
const VALUE_STYLE: React.CSSProperties = {textAlign: "center"}

export default function StatisticsView() {

    return (
        <ViewContainer>
            <_DemoTabsView/>
        </ViewContainer>
    )
}

interface Props {
    lang: string
    myKey: string
    activeKey: string
    onSelect: (value: string) => void
}


function _DemoTabsView() {

    const [t] = useTranslation()

    const [tabValue, setTabValue] = useCookie("stats-top", "0")

    const tabChanged = (e: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (
        <>

            <TabContext value={tabValue}>


                <Box sx={{borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper'}}>

                    <Tabs
                        value={tabValue}
                        onChange={tabChanged}
                        aria-label="basic tabs example"
                        centered
                        textColor="inherit"
                    >


                        <Tab label={t("core.global")} value={"0"}/>

                        <Tab label={t("core.trainings.or.tours")} value={"1"}/>

                        {
                            LicenceManager.f360.quizzes_monitoring &&
                            <Tab label={t("core.quizzes")} value={"2"}/>
                        }

                    </Tabs>
                </Box>

                <div
                    className={"vstack gap-3"}
                    style={{
                        background: "#f7f7f7",
                        borderColor: "#dfdfdf",
                        borderWidth: "5px",
                        padding: "5px",
                        marginTop: "15px"
                    }}>

                    <Switch condition={tabValue}>
                        <Case value="0">
                            <_NewGlobalSubView/>
                        </Case>
                        <Case value={"1"}>
                            <_TourOrTrainingSubView/>
                        </Case>
                        <Case value={"2"}>
                            <IndividualQuizzesView/>
                        </Case>
                    </Switch>


                </div>
            </TabContext>

        </>
    )
}

function _TourOrTrainingSubView({}) {

    const [t] = useTranslation()

    const [tabValue, setTabValue] = useCookie("stats-tourortraining", "0")

    const tabChanged = (e: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (

        <TabContext value={tabValue}>
            <Box sx={{borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper'}}>
                <Tabs
                    value={tabValue}
                    onChange={tabChanged}
                    aria-label="basic tabs example"
                    textColor="inherit"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {
                        isActiveUserAdmin() &&
                        <Tab label={t("core.overview.global")} value={"0"}/>
                    }

                    <Tab label={t("core.individual.trainings")} value={"1"}/>

                    <Tab label={t("core.visits")} value={"2"}/>

                </Tabs>
            </Box>
            <Box>
                <div
                    className={"vstack gap-3"}
                    style={{
                        background: "#f7f7f7",
                        borderColor: "#dfdfdf",
                        borderWidth: "5px",
                        padding: "5px"
                    }}>

                    <TabPanel value="0">
                        <ProjectAnalysisView/>
                    </TabPanel>

                    <TabPanel value="1">
                        <IndividualTrainingsView/>
                    </TabPanel>

                    <TabPanel value="2">
                        <_VisitorsSubView/>
                    </TabPanel>

                </div>
            </Box>

        </TabContext>


    )
}

function _NewGlobalSubView({}) {

    const [t] = useTranslation()

    const [tabValue, setTabValue] = useCookie("stats-global", "0")

    const tabChanged = (e: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (

        <TabContext value={tabValue}>
            <Box sx={{borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper'}}>
                <Tabs
                    value={tabValue}
                    onChange={tabChanged}
                    aria-label="basic tabs example"
                    textColor="inherit"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab label={t("core.tours")} value={"0"}/>

                    <Tab label={t("core.trainings")} value={"1"}/>

                    {
                        isActiveUserSuperAdmin() &&
                        <Tab label={t("core.app.visits")} value={"2"}/>
                    }

                </Tabs>
            </Box>
            <Box>
                <div
                    className={"vstack gap-3"}
                    style={{
                        background: "#f7f7f7",
                        borderColor: "#dfdfdf",
                        borderWidth: "5px",
                        padding: "5px"
                    }}>
                    <TabPanel value="0">
                        <GlobalToursView/>
                    </TabPanel>

                    <TabPanel value="1">
                        <GlobalTrainingsView/>
                    </TabPanel>

                    <TabPanel value="2">
                        <AppVisitsView/>
                    </TabPanel>
                </div>
            </Box>

        </TabContext>


    )
}

function _VisitorsSubView({}) {

    const [t] = useTranslation()


    const [tabValue, setTabValue] = React.useState("0")

    const tabChanged = (e: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (

        <TabContext value={tabValue}>
            <Box sx={{borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper'}}>
                <Tabs
                    value={tabValue}
                    onChange={tabChanged}
                    aria-label="basic tabs example"
                    textColor="inherit"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab label={t("core.project.visits")} value={"0"}/>

                    {
                        LicenceManager.f360.spot_visits_monitoring &&
                        <Tab label={t("core.spots.visits")} value={"1"}/>
                    }

                    {
                        LicenceManager.f360.boards_monitoring &&
                        <Tab label={t("core.board.visits")} value={"2"}/>
                    }

                </Tabs>
            </Box>

            <Box>
                <div
                    className={"vstack gap-3"}
                    style={{
                        background: "#f7f7f7",
                        borderColor: "#dfdfdf",
                        borderWidth: "5px",
                        padding: "5px"
                    }}>

                    <TabPanel value="0"> <ProjectVisitsView/> </TabPanel>
                    <TabPanel value="1"> <ProjectSpotsVisitView/> </TabPanel>
                    <TabPanel value="2"> <BoardsVisitsView/> </TabPanel>
                </div>
            </Box>
        </TabContext>

    )
}
