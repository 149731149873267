import create from "zustand"
import {TourTaskAsset} from "../ela/assets/asset_TourTask";
import GuidedTourRecorder, {GuidedTaskRecord} from "../GuidedTourRecorder";
import {InfinityBoardParent} from "../3d/boards/board_Parent";


interface ActiveTestTourZus {
    isPlaying: boolean
    isFinished: boolean

    task: GuidedTaskRecord | any
    setTask: (task: any) => any
    tourRecorded: GuidedTourRecorder

}

export const useActiveTestTour = create<ActiveTestTourZus>((set) => ({
    isPlaying: false,
    isFinished: false,
    task: null,
    tourRecorded: new GuidedTourRecorder(),
    configuration: {},
    setTask: (newTask: GuidedTaskRecord) => set((newState) => {
        return {task: newTask, isPlaying: true}
    }),

}))


export class GuidedTourManager {

    static _getTask(): TourTaskAsset {
        return useActiveTestTour.getState().task
    }

    static _getRecorder(): GuidedTourRecorder {
        return useActiveTestTour.getState().tourRecorded
    }


    static taskBoardClicked(board: InfinityBoardParent, status) {
        const task = GuidedTourManager._getTask()
        if (task) {
            console.log(task)
            console.log(board)
            GuidedTourManager._getRecorder().boardClicked(board, status)
            useActiveTestTour.getState().setTask(task)
        }
    }

    static nextTaskClicked(): boolean {
        const recorder = GuidedTourManager._getRecorder()

        const nextTask = recorder.goOnNextTask()

        useActiveTestTour.getState().setTask(nextTask)

        if (nextTask) {
            return true
        } else {
            return false
        }


    }

}