import {ButtonProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";

interface _InfinityButtonProps extends ButtonProps {
    label?: string
}


export function InfinityButton(props: _InfinityButtonProps | any) {

    const [t] = useTranslation()

    const _label = props.label ? props.label : ""

    return (
        <Button {...props} >

        {t(_label)}

        </Button>
    )
}
