import React from "react";

import * as three from "three";

import {ListGroup} from "react-bootstrap";

import {InfinityBoardParent} from "./board_Parent";

import {VideoPlayer} from "../../gui/content_players/video/VideoPlayer";

import {VideoAsset} from "../../ela/assets/asset_Video";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min";

import ProjectsApi from "../../api/ProjectsApi";

import AppBackendManager from "../../api/AppBackendManager";

import {GuidedTourManager} from "../../zustands/activeTestTourZus";
import urlcWithPath from "../../urlc";

class InWorldVideoController {
    constructor(
        videoPlane: three.Mesh,
        videoBoard: InfinityVideoBoard,
        aspect_x,
        aspect_y,
        close_btn_color,
        onRewind: number,
        onClose
    ) {
        this.onRewind = onRewind;

        const posY = -0.55;

        this.allgroup = new three.Group();
        videoPlane.add(this.allgroup);

        if (videoBoard.controls_enabled) {
            const backgroundMaterial = new three.MeshBasicMaterial({
                color: new three.Color("black"),
                transparent: true,
                opacity: 0.5,
            });
            const backgroundGeometry = new three.PlaneGeometry();

            const backgroundMesh = new three.Mesh(
                backgroundGeometry,
                backgroundMaterial
            );
            backgroundMesh.scale.set(1, 0.05, 0.0015);
            backgroundMesh.position.y = posY;
            backgroundMesh.userData["handler"] = this;

            this.allgroup.add(backgroundMesh);

            const sliderMaterial = new three.MeshBasicMaterial({
                color: new three.Color("gray"),
            });

            const sliderGeometry = new three.BoxGeometry();
            const sliderMesh = new three.Mesh(sliderGeometry, sliderMaterial);
            sliderMesh.scale.set(1, 0.005, 0.0025);
            sliderMesh.position.y = posY;
            this.allgroup.add(sliderMesh);

            this.handleMaterial = new three.MeshBasicMaterial({
                color: new three.Color("gray"),
            });

            const handleGeometry = new three.SphereGeometry();
            this.handleMesh = new three.Mesh(handleGeometry, this.handleMaterial);
            this.handleMesh.scale.set(0.01, 0.01, 0.01);
            this.handleMesh.position.y = posY;
            //handleMesh.position.x = (-aspect_x + (aspect_x * 0.97)) / 2
            this.handleMesh.position.x = -aspect_x / 2;

            this.handleStartReference = new three.Object3D();
            this.handleStartReference.position.x = -aspect_x / 2;

            this.handleEndReference = new three.Object3D();
            this.handleEndReference.position.x = aspect_x / 2;

            this.allgroup.add(this.handleEndReference);

            this.allgroup.add(this.handleEndReference);

            this.allgroup.add(this.handleMesh);

            const progressGroup = new three.Group();

            const progressGeometry = new three.BoxGeometry();
            const progressMesh = new three.Mesh(
                progressGeometry,
                this.handleMaterial
            );
            progressMesh.position.x = 0.5;

            progressGroup.add(progressMesh);

            progressMesh.scale.set(1, 0.005, 0.0025);
            progressMesh.position.x = -aspect_x / 2;
            progressMesh.position.y = progressMesh;

            this.allgroup.add(progressGroup);
            this.allgroup.visible = false;
        }

        if (videoBoard.closeEnabled) {
            const closeBtn = this.createCloseButton(onClose, close_btn_color);

            const closeBtnSize = 0.05;

            closeBtn.root.scale.set(closeBtnSize, closeBtnSize, closeBtnSize);
            closeBtn.root.position.x = aspect_x * 0.5 - closeBtnSize * 0.5;
            closeBtn.root.position.z = 0.0015;
            closeBtn.root.position.y = -posY - closeBtnSize * 1.5;

            this.allgroup.add(closeBtn.root);
        }
    }

    unhide() {
        this.allgroup.visible = true;
    }

    hide() {
        this.allgroup.visible = false;
    }

    updateSlideHandle(percentage: number) {
        if (this.handleMesh) {
            this.handleMesh.position.x = percentage - 0.5;
        }
    }

    isSelected(intersection: three.Intersection) {
        return true;
    }

    setSelected() {
        this.handleMaterial.color = new three.Color("blue");
    }

    setDeselected() {
        this.handleMaterial.color = new three.Color("gray");
    }

    clicked(intersection: three.Intersection) {
        const uvIntersection = intersection.uv;
        this.onRewind(uvIntersection.x);
    }

    createCloseButton(onClicked, close_btn_color: three.Vector3) {
        class CloseButton {
            constructor() {
                this.btnColor = new three.Color(
                    close_btn_color.x * 255,
                    close_btn_color.y * 255,
                    close_btn_color.z * 255
                );
                this.material = new three.MeshBasicMaterial({
                    color: this.btnColor,
                    side: three.DoubleSide,
                    transparent: true,
                    depthWrite: false,
                    map: new three.TextureLoader().load(
                        urlcWithPath("assets/icons/Core.Close.png")
                    ),
                });

                const planeG = new three.PlaneGeometry();
                const planeM = new three.Mesh(planeG, this.material);
                planeM.userData["handler"] = this;

                this.root = new three.Group();
                this.root.add(planeM);
            }

            isSelected(intersection: three.Intersection) {
                return true;
            }

            setSelected() {
                this.material.color = new three.Color("blue");
            }

            setDeselected() {
                this.material.color = this.btnColor;
            }

            clicked(intersection: three.Intersection) {
                if (onClicked) {
                    onClicked();
                }
            }
        }

        const closeButton = new CloseButton();

        return closeButton;
    }
}

const VideoPlayerComponent = ({asset, onEnded, onclose}) => {
    const _asset: VideoAsset = asset;

    const [visible, setVisible] = React.useState(true);

    const [src, setSrc] = React.useState("");

    React.useEffect(() => {
        if (AppBackendManager.isBackendAvailable) {
            ProjectsApi.getVideoSas(
                _asset.projectUid,
                _asset.uid,
                _asset.file_extension
            );
        } else {
            setSrc(_asset.getAbsolutePath());
        }
    });

    React.useEffect(() => {
        ProjectsApi.getVideoSas(_asset).then((result) => {
            setSrc(result.data);
        });
    }, []);

    return (
        <>
            {visible && (
                <div
                    style={{
                        backdropFilter: "blur(5px)",
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        minWidth: "100%",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {src !== "" && (
                        <VideoPlayer
                            uid={src}
                            onClose={() => {
                                setVisible(false);
                                onclose();
                            }}
                            videoPath={src}
                            onEnded={onEnded}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export class InfinityVideoBoard extends InfinityBoardParent {
    type = "video";

    f: three.Vector3 = null;
    t: three.Vector3 = null;
    r: three.Vector3 = null;

    activeVideoGroup: three.Group = null;
    activeVideoPlane: three.Mesh = null;

    videoController: InWorldVideoController = null;

    controls_enabled: boolean = false;

    loopingEnabled: boolean = false;

    constructor(spot) {
        super(spot);
        this.icon = "EI.Video";

        this.maximalizedPosition = new three.Vector3();
        this.maximalizedScale = new three.Vector3(0, 0, 0);

        this.theatreModeEnabled = false;

        // super.webTransparencySafe = true
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object);
        const multiplyScale = new three.Vector3(300, 300, 300);

        const pose = object["manual_position"];
        this.f = pose["f"];
        this.t = pose["t"];
        this.r = pose["r"];

        this.close_button_color = object["close_button_color"] ?? {
            x: 1,
            y: 1,
            z: 1,
        };

        this.maximalizedPosition.copy(this.position);
        this.maximalizedPosition.multiply(multiplyScale);

        this.maximalizedPosition.applyAxisAngle(
            new three.Vector3(0, 1, 0),
            this.parentSpot.spotRotation.y
        );

        this.maximalizedScale.copy(this.scale);
        this.maximalizedScale.multiply(multiplyScale);

        this.theatreModeEnabled = object["theatre_mode"];
        this.controls_enabled = object["controls_enabled"];

        this.loopingEnabled = object["looping_enabled"];
    }

    isContentValid(): boolean {
        return this.getVideoAsset();
    }

    videoPlayEnded() {
        super.fillBoardVisited(1);

        GuidedTourManager.taskBoardClicked(this, 1)


        this.playInModalHud(null)

        if (this.activeVideoGroup) {
            const aScale = this.activeVideoGroup.scale;

            if (this.videoController) {
                this.videoController.hide();
            }

            new TWEEN.Tween({
                x: aScale.x,
                y: aScale.y,
                z: aScale.z,
            })
                .to({x: 0.05, y: 0.05, z: 0.05}, 1000)
                .onUpdate((newValue) => {
                    if (newValue.x < this.maximalizedScale.x) {
                        this.activeVideoPlane.visible = false;
                    }

                    this.activeVideoGroup.scale.copy(newValue);
                })
                .onComplete(() => {
                    if (this.autoUnpack === false) {
                        this.clickableIcon.getRoot().visible = true;
                    } else {
                        super.fillToWorld(this.parentSpot.parentProject.getWorld());
                    }
                })
                .start();
        }
    }

    setVisibilityStatus(status: boolean) {
        if (this.isOpenedInWorld) {
            this.activeVideoGroup.visible = status;
        } else {
            super.setVisibilityStatus(status);
        }
    }

    isVisible(): boolean {
        if (this.isOpenedInWorld) {
            return this.activeVideoGroup.visible;
        } else {
            return super.isVisible();
        }
    }

    step() {
        if (this.videoController) {
            const videoElement = this.getVideoElement();

            if (videoElement) {
                if (videoElement.duration != 0) {
                    this.videoController.updateSlideHandle(
                        videoElement.currentTime / videoElement.duration
                    );
                }
            }
        }
    }

    clicked() {

        const world = this.getActivePlayer().worldWrapper.getWorld();
        if (world.renderer.xr.isPresenting) {
            world.enterTheatreMode();
            world.activeTheatreController = this;

            this.clickableIcon.getRoot().visible = false;
            this.fillInWorldPanel(world.theatreGroup, () => {
                if (this.loopingEnabled) {
                    this.getVideoElement().play();
                } else {
                    this.getVideoElement().pause();
                    this.videoPlayEnded();
                    world.leaveTheatreMode();
                }
            });
        } else {
            if (this.showAsPopup) {
                const videoComponent = this.getPlayerComponent();
                if (videoComponent) {
                    this.playInModalHud(videoComponent);
                }
            } else {
                if (this.theatreModeEnabled) {
                    world.enterTheatreMode(() => {
                        this.fillInWorldPanel(world.theatreGroup, () => {
                            if (this.loopingEnabled) {
                                this.getVideoElement().play();
                            } else {
                                this.videoPlayEnded();
                                world.leaveTheatreMode();
                            }
                        });
                    });
                    world.activeTheatreController = this;
                } else {
                    this.clickableIcon.getRoot().visible = false;
                    this.fillInWorldPanel(world.secondaryNotRotatedGroup, () => {
                        this.getVideoElement().pause();
                        this.videoPlayEnded();
                    });
                }
            }
        }
    }

    CleanUp() {
        this.getVideoElement().pause();
        this.removeVideoElement();
    }

    leaveTheatreModeRequested() {
        const element = this.getVideoElement();

        if (element) {
            this.getVideoElement().pause();
            this.videoPlayEnded();
        }
    }

    fillToWorld(world: World) {
        this.createVideoElement();

        if (this.autoUnpack) {
            this.fillInWorldPanel(world.secondaryNotRotatedGroup, () => {
                if (this.loopingEnabled) {
                    this.getVideoElement().play();
                } else {
                    this.videoPlayEnded();
                }
            });
        } else {
            super.fillToWorld(world);
        }
    }

    removeVideoElement() {
        this.getVideoElement().remove();
    }

    createVideoElement() {
        const myVideoElement = document.getElementById(`video-${this.uid}`);

        if (myVideoElement) {
        } else {
            const videosRoot = document.getElementById("videosroot");

            const video: HTMLVideoElement = document.createElement("video");
            video.id = `video-${this.uid}`;
            video.crossOrigin = true;
            video.playsInline = true;
            video.preload = "auto";
            video.muted = false;
            video.style.display = "none";

            const source = document.createElement("source");
            source.id = `video-source-${this.uid}`;

            video.appendChild(source);

            videosRoot.appendChild(video);
        }
    }

    getVideoElement(): HTMLVideoElement {
        return document.getElementById(`video-${this.uid}`);
    }

    getVideoSource(): HTMLElement {
        return document.getElementById(`video-source-${this.uid}`);
    }

    fillInWorldPanel(group: three.Group, onEnded) {
        const videoAsset: VideoAsset = this.getParentProject().videos.get(
            this.contentUid
        );

        if (videoAsset) {
            const videoElement = this.getVideoElement();
            const videoSource = this.getVideoSource();

            //Nezabudnut nastavi http://localhost:3000 na CORS storagu
            // if (false) {
            if (AppBackendManager.isBackendAvailable) {
                ProjectsApi.getVideoSas(videoAsset).then((value) => {
                    videoSource.setAttribute("src", value.data);
                    videoSource.setAttribute(
                        "type",
                        `video/${videoAsset.file_extension}`
                    );

                    try {
                        videoElement.load();
                    } catch (e) {
                        console.log(e);
                    }
                });
            } else {
                videoSource.setAttribute(
                    "src",
                    urlcWithPath(videoAsset.getAbsolutePath())
                );
                videoSource.setAttribute("type", `video/${videoAsset.file_extension}`);

                try {
                    videoElement.load();
                } catch (e) {
                    console.log(e);
                }
            }

            if (this.activeVideoGroup) {
                this.activeVideoGroup.scale.set(0, 0, 0);
            } else {
                const videoGroup = new three.Group();

                if (onEnded) {
                    videoElement.addEventListener("ended", onEnded);
                }

                const videoTexture = new three.VideoTexture(videoElement);
                //videoTexture.encoding = three.sRGBEncoding
                const material = new three.MeshBasicMaterial({
                    map: videoTexture,
                    side: three.DoubleSide,
                });

                const backgroundGeometry = new three.PlaneGeometry(1, 1);

                const backgroundMesh = new three.Mesh(
                    backgroundGeometry,
                    new three.MeshBasicMaterial({
                        color: new three.Color("black"),
                        transparent: true,
                        opacity: 0.25,
                        side: three.DoubleSide,
                    })
                );
                backgroundMesh.position.z = -0.0015;

                const geometry = new three.PlaneGeometry();

                const videoPlane = new three.Mesh(geometry, material);
                videoPlane.visible = false;

                this.activeVideoPlane = videoPlane;

                if (this.controls_enabled || this.closeEnabled) {
                    this.videoController = new InWorldVideoController(
                        videoGroup,
                        this,
                        videoAsset.video_ratio_x,
                        videoAsset.video_ratio_y,
                        this.close_button_color,

                        (newDuration) => {
                            videoElement.currentTime = videoElement.duration * newDuration;
                        },
                        () => {

                            this.getVideoElement().pause();
                            this.videoPlayEnded();

                            if (this.theatreModeEnabled || this.isInVR()) {
                                const world = this.getActivePlayer().worldWrapper.getWorld()
                                world.leaveTheatreMode()
                            }
                        }
                    );
                }

                videoGroup.add(backgroundMesh);

                videoPlane.position.copy(this.maximalizedPosition);
                videoGroup.position.copy(this.maximalizedPosition);

                if (this.use_automatic_rotation) {
                    videoGroup.lookAt(
                        new three.Vector3(0, this.maximalizedPosition.y, 0)
                    );
                    videoPlane.lookAt(
                        new three.Vector3(0, this.maximalizedPosition.y, 0)
                    );
                } else {
                    const rotationMatrix = new three.Matrix4();

                    rotationMatrix.set(
                        this.f.x,
                        this.t.x,
                        this.r.x,
                        0,
                        this.f.y,
                        this.t.y,
                        this.r.y,
                        0,
                        this.f.z,
                        this.t.z,
                        this.r.z,
                        0,
                        0,
                        0,
                        0,
                        1
                    );

                    videoPlane.rotation.setFromRotationMatrix(rotationMatrix);
                    videoGroup.rotation.setFromRotationMatrix(rotationMatrix);
                    videoGroup.updateMatrixWorld(true);
                    videoPlane.updateMatrixWorld(true);
                }

                this.activeVideoGroup = videoGroup;
            }

            group.add(this.activeVideoGroup);
            group.add(this.activeVideoPlane);

            const mscale = this.maximalizedScale;

            this.activeVideoPlane.visible = true;

            new TWEEN.Tween({x: 0.05, y: 0.05, z: 0.05})
                .to(
                    {
                        x: mscale.x,
                        y: mscale.y,
                        z: mscale.z,
                    },
                    1000
                )
                .onUpdate((newValue) => {
                    this.activeVideoGroup.scale.copy(newValue);
                })
                .onComplete(() => {
                    let finalScaleX = 1;
                    let finalScaleY = 1;

                    const scaleX = this.maximalizedScale.x;
                    const scaleY = this.maximalizedScale.y;

                    const aspectX = videoAsset.video_ratio_x;
                    const aspectY = videoAsset.video_ratio_y;

                    if (aspectX > aspectY) {
                        if (scaleX > scaleY) {
                            if (scaleX * 0.575 > scaleY) {
                                finalScaleX = scaleY / aspectY;
                                finalScaleY = scaleY;
                            } else {
                                finalScaleX = scaleX;
                                finalScaleY = scaleX * aspectY;
                            }
                        } else if (scaleX < scaleY) {
                            finalScaleX = scaleX;
                            finalScaleY = scaleX * aspectY;
                        } else {
                            finalScaleX = scaleX * aspectX;
                            finalScaleY = scaleY * aspectY;
                        }
                    } else if (aspectX === aspectY) {
                        //Video je stvorec
                        if (scaleX > scaleY) {
                            finalScaleX = scaleY;
                            finalScaleY = scaleY;
                        } else if (scaleX < scaleY) {
                            finalScaleX = scaleX;
                            finalScaleY = scaleX;
                        } else {
                            finalScaleX = scaleX;
                            finalScaleY = scaleX;
                        }
                    } else if (aspectX < aspectY) {
                        //Video je na vysku
                        if (scaleX > scaleY) {
                            finalScaleX = scaleY * aspectX;
                            finalScaleY = scaleY;
                        } else if (scaleX < scaleY) {
                            finalScaleX = scaleX;
                            finalScaleY = scaleX * aspectY;
                        } else {
                            finalScaleX = scaleY * aspectX;
                            finalScaleY = scaleY;
                        }
                    }

                    this.activeVideoPlane.scale.x = finalScaleX;
                    this.activeVideoPlane.scale.y = finalScaleY;

                    this.activeVideoPlane.visible = true;

                    if (this.videoController) {
                        this.videoController.unhide();
                    }

                    try {
                        this.getVideoElement().play();
                    } catch (e) {
                        console.error("Failed to play video because of:");
                        console.error(e);
                    }
                })
                .start();
        }
    }

    setVideoProgressPercentages(percentages: number) {
    }

    getPlayerComponent() {
        const asset = this.getParentProject().videos.get(this.contentUid);

        if (asset) {
            return (
                <VideoPlayerComponent
                    asset={asset}
                    onclose={() => {
                        this.videoPlayEnded()
                    }}
                    onEnded={() => {
                        super.fillBoardVisited(1);
                    }}
                />
            );
        } else {
            return null;
        }
    }

    getRightClickOptions() {
        return (
            <>
                {super.getRightClickOptions()}
                <ListGroup.Item action onClick={this.openInNewWindow}>
                    Open in new window
                </ListGroup.Item>
            </>
        );
    }

    getVideoAsset(): VideoAsset {
        return this.getParentProject().videos.get(this.contentUid);
    }

    openInNewWindow = () => {
        const video = this.getVideoAsset();
        if (video) {
            window.open(video.getAbsolutePath(), "_blank");
        }
    };

    getWebPreviewImage() {
        const video = this.getVideoAsset();
        if (video) {
            return video.getWebPreviewImage();
        } else {
            return "";
        }
    }
}
