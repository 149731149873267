import React from "react";
import {Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useAvailableProjectsZus} from "../../zustands/availableProjectsZus";
import ProjectsApi from "../../api/ProjectsApi";
import {InfinityModal} from "../../components/modals";


export default function RemoveProjectModal({onHide, projectId, projectName}) {

    const availableProjectsZus = useAvailableProjectsZus()


    const [t] = useTranslation()

    const [isNotNameSame, setIsNotNameSame] = React.useState(true)

    const removePressed = () => {
        axios.delete(`/api/deleteproject/${projectId}`).then((response) => {

            ProjectsApi.getAllProjects().then((response) => {
                availableProjectsZus.setAvailableProjects(response)

                onHide()

            })
        })
    }


    return (
        <InfinityModal

            closeButton={true}
            onHide={onHide}
            headerChildren={
                <Modal.Title>
                    {
                        t("core.project.remove")
                    }
                </Modal.Title>
            }
            bodyChildren={
                <div>
                    <Form.Group>
                        <Form.Text>
                            {t("core.project.remove.rewrite")}: <b>{projectName}</b> ({projectId})
                        </Form.Text>

                        <Form.Control type={"input"} onChange={(e) => {
                            setIsNotNameSame(!(e.target.value === projectName))
                        }} required
                        />
                    </Form.Group>
                </div>
            }
            footerChildren={
                <button
                    className={"btn btn-danger"}
                    disabled={isNotNameSame}
                    onClick={removePressed}
                >
                    {t("core.remove")}
                </button>
            }

        />

    )
}