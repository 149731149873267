import React from "react";
import {useTranslation} from "react-i18next";

export default function IpsSearchTableHeader({searchType}) {

    const [t] = useTranslation()

    return (
        <thead>
        <tr>
            <th>Id</th>
            <th>
                {
                    searchType === "byname" ?
                        <>{">"}{t("core.name")}{"<"}</> :
                        <>{t("core.name")}</>
                }
            </th>
            <th>
                {
                    searchType === "bytags" ?
                        <>{">"}{t("core.tags")}{"<"}</> :
                        <>{t("core.tags")}</>
                }
            </th>
            <th>{t("type")}</th>
            <th>{t("core.path.area-spot")}</th>
            <th>{t("core.action")}</th>
        </tr>
        </thead>
    )
}