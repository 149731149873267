import React from "react";
import {useTranslation} from "react-i18next";


type FakeLinkButtonProps = {
    lang?: string,
    onclick?: any,
    color?: string,
}

export default function FakeLinkButton({lang, onclick, color}: FakeLinkButtonProps) {
    const [t] = useTranslation()
    return (
        <div style={{
            color: color,
            cursor: "pointer",
        }} onClick={onclick}><u>{t(lang ?? "")}</u></div>

    )
}