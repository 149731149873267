import React from "react";
import {Form, Offcanvas, Stack, Table} from "react-bootstrap";
import {useActiveSpotZus} from "../../zustands/activeSpotZus";
import {InfinitySpot} from "../../infinity/InfinitySpot";
import {InfinityBoardParent} from "../../3d/boards/board_Parent";
import {useTranslation} from "react-i18next";
import {InfinityOffcanvasWrapper} from "../../components/offcanvas";
import MyTable from "../../components/mytable";
import {WorldSingleton} from "../../3d/world/NewWorld";


export default function ActiveSpotBoardsVisibilityOffcanvas({activeProject, onHide}) {

    const activeSpotZus = useActiveSpotZus()

    const activeSpot: InfinitySpot | any = activeSpotZus.activeSpot

    const [categoryView, setCategoryView] = React.useState<Array<any>>(new Array<any>())


    React.useEffect(() => {

        if (activeSpot) {
            const map: Map<string, Array<InfinityBoardParent | any>> = new Map()

            activeSpot.boards.forEach((value) => {

                const obj: any = map.get(value.type)

                if (obj) {
                    obj.push(value)

                } else {
                    map.set(value.type, new Array(value))
                }
            })

            const resultVector = new Array<any>()

            map.forEach((value, key,) => {
                resultVector.push({
                    name: key,
                    boards: value
                })
            })
            console.log(resultVector)
            setCategoryView(resultVector)
        }
    }, [activeSpotZus.activeSpot])


    return (
        <>
            <InfinityOffcanvasWrapper
                closeButton={true}
                onHide={onHide}
                header={<Offcanvas.Title>Menu</Offcanvas.Title>}
                body={
                    <Stack gap={3}>

                        {
                            categoryView.map((value) => {
                                return (
                                    <ControlGroupItem key={value.name} groupElement={value}
                                                      activeProject={activeProject}/>

                                )
                            })
                        }


                    </Stack>
                }

            />

        </>
    )
}


function ControlGroupItem({groupElement, activeProject}) {

    const [t] = useTranslation()

    return (
        <>
            <h5 style={{textAlign: "center"}}>
                {groupElement.name}
            </h5>

            <MyTable>
                <tbody>

                {
                    groupElement.boards.map((board: InfinityBoardParent) => {
                        return (<_TableRow
                            key={board.uid}
                            activeProject={activeProject}
                            board={board}/>)
                    })
                }

                </tbody>


            </MyTable>
        </>
    )
}


function _TableRow({board, activeProject}) {

    const [isVisible, setIsVisible] = React.useState(board.isVisible())

    const map = WorldSingleton.INSTANCE.hideIgnoreMap

    const valueChanged = (e) => {
        const newStatus = !isVisible
        setIsVisible(newStatus)

        const boardUid = board.uid

        if (map.has(boardUid)) {
            map.delete(boardUid)
        } else {
            map.add(boardUid)
        }

        WorldSingleton.INSTANCE.refreshVisibility()

        // activeProject.getWorld().selectBoardVisibility(board.uid, newStatus)
    }

    return (
        <tr>
            <td>
                {board.name === "" ? "-unnamed-" : board.name}
            </td>
            <td>
                <div style={{marginLeft: "auto"}}>
                    <Form.Check
                        type={"checkbox"}
                        checked={map.has(board.uid)}
                        onChange={valueChanged}
                    />
                </div>

            </td>
        </tr>

    )

}