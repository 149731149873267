import React from "react"

import {
    Bar,
    BarChart,
    CartesianGrid, Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

import VisitorsStatisticsApi from "../../../api/VisitorsStatisticsApi";


import {useTranslation} from "react-i18next";
import {useAvailableProjects} from "../../../api/ProjectsApi";
import useCookie from "react-use-cookie";

import Switch, {Case} from 'react-switch-case'

import {BasicMiddleSpinner} from "../../../components/spinners";
import MyTable from "../../../components/mytable";
import {SelectProjectMUISelect} from "../../../components/selectviews/ProjectSelectView";
import {FakeProject} from "../../inter_project_search_new/lightdata";
import {saveChartData} from "../stats_chart_tools";
import PeriodMUISelect from "../../../components/selectviews/PeriodSelectView";
import ViewAsMUISelect from "../../../components/selectviews/ViewAsSelectView";
import {DashBoardTileNoMiddle} from "../../../components/dashboard/DashBoardTile";
import ProjectsChartComparison from "./ProjectsChartComparison";


export default function ProjectVisitsView() {

    const [projects, isLoading] = useAvailableProjects()

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (
            <div className={"vstack gap-3"}>

                <_ProjectAttendanceView projects={projects}/>

            </div>
        )
    }
}

function _Separator() {
    return (<div style={{marginBottom: "70px"}}/>)
}

function _ProjectAttendanceView({projects}) {


    const [data, setData] = React.useState<Array<StatisticsItem | any>>([])

    const [isLoading, setIsLoading] = React.useState(false)

    const [period, setPeriod] = React.useState("W")

    const [viewAs, setViewAs] = useCookie("project-stats-viewas", "chart")

    const [activeProject, setActiveProject] = React.useState<FakeProject | any>(projects.at(0))

    const setNewActiveProject = (e) => {
        setActiveProject(e)
    }

    React.useEffect(() => {
        if (activeProject) {
            setIsLoading(true)

            VisitorsStatisticsApi.getAllProjectStatistics(activeProject.uid, period).then((result) => {
                const convertedData = result.data.data.map((value) => {
                    return {
                        "name": value.day,
                        "value": value.count,
                    }
                })
                saveChartData(convertedData)
                setData(convertedData)
                setIsLoading(false)
            })
        }


    }, [period, activeProject])

    return (
        <div className={"vstack gap-3"}>

            <div style={{margin: "auto"}}>
                <DashBoardTileNoMiddle>
                    <ProjectViewFilter
                        activeProject={activeProject}
                        period={period}
                        onPeriodChanged={setPeriod}
                        projectChanged={setNewActiveProject}
                        viewAs={viewAs}
                        setViewAs={setViewAs}
                    />
                </DashBoardTileNoMiddle>

            </div>

            {
                isLoading ? <BasicMiddleSpinner/> :
                    <Switch condition={viewAs}>
                        <Case value={"chart"}>
                            <div className={"vstack gap-3"}>
                                <_ChartView data={data}/>
                                <div style={{marginBottom: "50px"}}/>
                                <ProjectsChartComparison projectUid={activeProject.uid}/>
                            </div>
                        </Case>
                        <Case value={"table"}>
                            <_TableView data={data}/>
                        </Case>
                    </Switch>
            }


        </div>
    )
}

function ProjectViewFilter({
                               activeProject,
                               projectChanged,
                               period,
                               onPeriodChanged,
                               viewAs,
                               setViewAs
                           }) {

    const [t] = useTranslation()

    return (
        <div className={"hstack gap-3"}>

            <SelectProjectMUISelect activeProject={activeProject} setActiveProject={(project) => {
                projectChanged(project)
            }}/>
            <PeriodMUISelect
                period={period}
                onPeriodChanged={onPeriodChanged}
            />

            <ViewAsMUISelect viewAs={viewAs}
                             setViewAs={setViewAs}
            />

        </div>
    )
}

function _ChartView({data}) {
    return (
        <DashBoardTileNoMiddle style={{height: "600px"}}>
            <ResponsiveContainer width={"100%"} height={"600px"}>
                <div style={{
                    backgroundColor: "white",
                    margin: "auto",
                    padding: "25px",
                    overflowX: "scroll"
                }}>

                    <BarChart
                        width={1000}
                        height={500}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            dataKey="name"
                            tick={_CustomizedAxisTick}
                            // interval={0}
                        />
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="value" fill="#db333c"/>

                    </BarChart>

                </div>
            </ResponsiveContainer>
        </DashBoardTileNoMiddle>

    )
}

function _TableView({data}) {

    const [t] = useTranslation()

    return (
        <MyTable>

            <thead>
            <tr>
                <td><b>Id</b></td>
                <td><b>{t("core.time")}</b></td>
                <td><b>{t("core.count")}</b></td>
            </tr>
            </thead>

            <tbody>
            {
                data.map((record, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{record.name}</td>
                            <td>{record.value}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </MyTable>
    )
}

function _CustomizedAxisTick(props) {
    const {x, y, payload} = props;
    const name = payload.value;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0}
                  y={0}
                  dy={12}
                  fontSize="smaller"
                  textAnchor="end"
                  fill="#666"
                  transform="rotate(-45)"
                  style={{
                      fontSize: "15px"
                  }}
            >
                {name}

            </text>
        </g>
    );
};