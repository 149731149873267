import {FakeProject} from "./lightdata";
import {Badge, Stack, Table} from "react-bootstrap";
import IpsSearchTableHeader from "./new_ips_tables_header";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MyTable from "../../components/mytable";


const CHUNK_SIZE = 25


export default function UniversalInterProjectSearchTable({visibleProjectWithResult, searchType}) {


    return (
        <>
            {
                visibleProjectWithResult.map((project: FakeProject, index) => {
                    return (
                        <_ProjectSubView key={`${project.uid}-${index}`} project={project} searchType={searchType}/>
                    )
                })
            }


        </>
    )
}


function _ProjectSubView({project, searchType}) {

    const [t] = useTranslation()

    const [showAll, setShowAll] = React.useState(false)
    const [visibleChunk, setVisibleChunk] = React.useState(0)

    const onUp = () => {
        setVisibleChunk(visibleChunk + 1)
    }

    const onDown = () => {
        setVisibleChunk(visibleChunk - 1)
    }

    const chunk_size = project.searchCache.length / CHUNK_SIZE

    return (
        <Stack gap={3} key={project.uid}>
            <h2 style={{textAlign: "center"}}>{project.name}</h2>

            <MyTable key={project.uid}>
                <IpsSearchTableHeader searchType={searchType}/>

                <tbody>

                <_ChunkView
                    project={project}
                    index={showAll ? 0 : visibleChunk}
                    chunk_size={showAll ? null : 25}
                />

                </tbody>
            </MyTable>

            <Stack
                gap={3}
                direction={"horizontal"}
                style={{
                    marginLeft: "auto",
                }}
            >
                <button
                    className={"btn btn-outline-secondary btn-sm"}
                    onClick={() => {
                        setShowAll(!showAll)
                    }}

                >
                    {t(showAll ? "core.pack" : "core.unpack")}
                </button>

                {
                    showAll === false && <>
                        <button
                            disabled={visibleChunk === 0}
                            className={"btn btn-outline-primary btn-sm"}
                            onClick={onDown}>
                            {"<<"}
                        </button>

                        <div>{visibleChunk + 1}/{Math.ceil(chunk_size)}</div>

                        <button
                            disabled={!(visibleChunk < (chunk_size - 1))}
                            className={"btn btn-outline-primary  btn-sm"}
                            onClick={onUp}>
                            {">>"}
                        </button>
                    </>

                }


            </Stack>

        </Stack>
    )
}

function _TagView({tags}) {
    const _tags: string = tags

    const split = _tags.split("_/_")

    return (
        <Stack gap={1} direction={"horizontal"}>
            {
                split.map((value, index) => {
                    return (<Badge key={index} bg={"secondary"}>{value}</Badge>)
                })
            }
        </Stack>
    )
}

function chunksSize(num: number) {

}

interface _ChunkViewProps {
    project: FakeProject
    index: number
    chunk_size: number | null

}


function _ChunkView({project, index, chunk_size}: _ChunkViewProps) {

    const [t] = useTranslation()
    const navigate = useNavigate()

    const rows = new Array<any>()


    let length = chunk_size ?? project.searchCache.length

    for (let i = 0; i < length; i++) {
        const element = project.searchCache[(length * index) + i]

        const INDEX = (index * length) + i + 1

        if (element) {
            rows.push(
                <tr key={INDEX}>
                    <td>{INDEX}.</td>
                    <td>{element.name}</td>
                    <td>
                        <_TagView tags={element.tags}/>
                    </td>
                    <td>{element.type}</td>
                    <td>{element.navigationPath}</td>
                    <td>
                        <button
                            className={"btn btn-outline-primary btn-sm"}
                            onClick={() => {
                                navigate(element.urlPath)
                                navigate(0)
                            }}
                        >
                            {t("core.open")}
                        </button>

                    </td>
                </tr>
            )
        } else {


            if (CHUNK_SIZE > project.searchCache.length) {
                break
            }

            rows.push(
                <tr key={INDEX}>
                    <td>{INDEX}.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )


        }
    }

    return (
        <>
            {
                rows
            }
        </>
    )
}