import React from "react";
import { useTranslation } from "react-i18next";

const LABEL_STYLE = {
  width: "150px",
  fontSize: "15px",
};

const CONTENT_STYLE = {
  display: "flex",
  alignItems: "center",
  minHeight: "24px",
};

const INPUT_STYLE = {
  border: "2px solid transparent",
  borderRadius: "4px",
  width: "300px",
  fontSize: "15px",
};

interface TextInputProps {
  value: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onSubmited?: (value: any) => void;
}

export default function TextInput({
  value,
  label,
  disabled = false,
  onChange,
  onSubmited,
}: TextInputProps) {
  const [t] = useTranslation();
  const [textValue, setTextValue] = React.useState(value);

  return (
    <div style={CONTENT_STYLE}>
      <div style={LABEL_STYLE}>{t(label)}: </div>
      <input
        disabled={disabled}
        style={INPUT_STYLE}
        type={"text"}
        value={textValue}
        onChange={(event) => {
          const value = event.target.value;
          setTextValue(value);
          onChange && onChange(textValue)
        }}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && onSubmited) {
            const value = event.target.value;
            onSubmited && onSubmited(value)
            event.target.blur();
          }
        }}
      />
    </div>
  );
}
