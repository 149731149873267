import {InfinityModal} from "../../components/modals";
import {Modal, Spinner} from "react-bootstrap";
import React from "react";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import {useTranslation} from "react-i18next";
import {useActiveTestTour} from "../../zustands/activeTestTourZus";
import TourStatisticsApi from "../../api/TourStatisticsApi";
import {GuidedTourResultsTableView} from "../../routes/statistics/TrainingPrintView";


export function showSubmitTaskTourResult(onSubmited) {
    useGlobalPopup.getState().pushElement(<_SubmitViewModal onSubmited={onSubmited}/>)
}


function _SubmitViewModal({onSubmited}) {
    const [t] = useTranslation()

    const [isSubmiting, setIsSubmiting] = React.useState(false)

    const popupZus = useGlobalPopup()
    const recorderZus = useActiveTestTour()

    const onHide = () => {
        popupZus.popElement()
    }

    const submitPressed = () => {

        setIsSubmiting(true)

        TourStatisticsApi.saveGuidedTour(recorderZus.tourRecorded).then(() => {
            setIsSubmiting(false)
            onHide()
            onSubmited()
        })


    }

    return (
        <InfinityModal
            size={"xl"}
            headerChildren={<>
                <Modal.Title>{t("core.tour.ended")}</Modal.Title>

                {
                    (() => {
                        if (isSubmiting) {
                            return (<Spinner animation={"border"} variant={"danger"}/>)
                        } else {
                            return (
                                <button
                                    className={"btn btn-primary"}
                                    onClick={submitPressed}
                                >
                                    {t("core.submit")}
                                </button>
                            )
                        }
                    })()
                }


            </>
            }
            bodyChildren={<>
                <GuidedTourResultsTableView record={recorderZus.tourRecorded}/>
            </>}
            footerChildren={<>

            </>}
        />
    )
}


