import React from "react";


import create from "zustand"

import {ProjectGroupInterface} from "../api/ProjectGroupsApiInterfaces";

const _defaultvalue: Array<ProjectGroupInterface> = []

export const useAvailableProjectGroups = create((set) => ({
    availableGroups: [],
    setAvailableGroups: (groups: Array<ProjectGroupInterface>) => set((newState) => ({availableGroups: groups})),
}))

