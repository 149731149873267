import React from "react";


import create from "zustand"

import {InfinitySpot} from "../infinity/InfinitySpot";

interface ActiveSpotZus {
    activeSpot: any | InfinitySpot
    setActiveSpot: (newSpot: InfinitySpot | any) => void
}

export const useActiveSpotZus = create<ActiveSpotZus>((set) => ({
    activeSpot: null,
    setActiveSpot: (newSpot: InfinitySpot | any) => set((newState) => ({activeSpot: newSpot})),
}))

