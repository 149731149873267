import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import {Button} from "react-bootstrap";
import CardContent from "@mui/material/CardContent";

import {CardActions, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {InfinityButton} from "./InfinityButton";
import {ButtonProps} from "react-bootstrap/Button";

const IGui = {
    T: useTranslation,
    Card: Card,
    CardHeader: CardHeader,
    CardMedia: CardMedia,
    CardContent: CardContent,
    CardActions: CardActions,
    Typography: Typography,
    Button: InfinityButton,
    BButton: Button as React.FC<ButtonProps>

}


export default IGui