import React from "react"

import {Col, Row, Stack} from "react-bootstrap";

import ProjectsApi, {useAvailableProjects} from "../../api/ProjectsApi";

import {FakeProject} from "../inter_project_search_new/lightdata";
import ProjectPropertiesTable from "../projects/ProjectPropertiesTable";

import {BasicMiddleSpinner} from "../../components/spinners";

export default function ProjectAnalysisView({}) {

    const [projects, isLoading] = useAvailableProjects()

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (

            <>

                <Stack gap={3}>

                    <Row md={2}>
                        {
                            projects.map((value: FakeProject) => {
                                return (<Col key={value.uid}>
                                        <Stack gap={3}>
                                            <h3>{value.name}</h3>
                                            <ProjectPropertiesTable projectId={value.uid}/>
                                        </Stack>

                                    </Col>
                                )
                            })
                        }
                    </Row>


                </Stack>
            </>

        )
    }


}

