import React from "react";


import TourStatisticsApi from "../../../api/TourStatisticsApi";

import {FakeProject} from "../../inter_project_search_new/lightdata";

import ProjectVisitsFilteringBar from "./ProjectVisitsFilteringBar";

import {useTranslation} from "react-i18next";
import {InfinityProject} from "../../../infinity/InfinityProject";
import ProjectsApi, {useAvailableProjects} from "../../../api/ProjectsApi";
import ProjectVisits3DMap from "./ProjectVisits3DMap";
import {BasicMiddleSpinner} from "../../../components/spinners";
import useCookie from "react-use-cookie";
import MyTable from "../../../components/mytable";

import Switch, {Case} from 'react-switch-case';
import {DashBoardTileNoMiddle} from "../../../components/dashboard/DashBoardTile";


export default function ProjectSpotsVisitView({}) {

    const [projects, isLoading] = useAvailableProjects()

    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (
            <_ProjectSpotsVisitView projects={projects}/>
        )
    }

}

function _ProjectSpotsVisitView({projects}) {

    const [t] = useTranslation()

    const [projectUid, setProjectUid] = React.useState<FakeProject | any>(projects.at(0)?.uid)

    const [activeProject, setActiveProject] = React.useState<InfinityProject | any>(null)
    const [activeAreaUid, setActiveAreaUid] = React.useState<string | any>(null)
    const [activeProjectData, setActiveProjectData] = React.useState<any | any>(null)
    const [activeUserUid, setActiveUserUid] = React.useState("")

    const [viewAs, setViewAs] = useCookie("spot-visits-view-as", "table")

    React.useEffect(() => {

        setActiveProject(null)
        setActiveProjectData(null)
        setActiveAreaUid(null)

        if (projectUid) {

            ProjectsApi.getProjectWithUid(projectUid).then((project) => {
                setActiveProject(project)
                setActiveAreaUid(Array.from(project.areas.values()).at(0)?.uid)
            })

            if (activeUserUid !== "") {
                TourStatisticsApi.getProjectUserSpotsVisits(projectUid, activeUserUid).then((response) => {
                    setActiveProjectData(response)
                })
            } else {
                TourStatisticsApi.getProjectSpotsVisits(projectUid).then((response) => {
                    setActiveProjectData(response)
                })
            }


        }


    }, [projectUid, activeUserUid])

    if (!projectUid) {
        return (<>{t("core.no.projects")}</>)
    }


    if (activeProject && activeProjectData && activeAreaUid) {
        return (
            <div className={"vstack gap-3"}>

                <div

                    style={{
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>

                    <DashBoardTileNoMiddle>
                        <ProjectVisitsFilteringBar
                            projects={projects}
                            projectChanged={(project) => {
                                setProjectUid(project.uid)

                            }}
                            activeProject={activeProject}
                            enableChooseArea={viewAs === "map"}
                            activeAreaUid={activeAreaUid}
                            areaChanged={setActiveAreaUid}
                            activeUserUid={activeUserUid}
                            setActiveUserUid={setActiveUserUid}
                            viewAs={viewAs}
                            setViewAs={setViewAs}
                        />


                    </DashBoardTileNoMiddle>

                </div>


                <Switch condition={viewAs}>
                    <Case value={"table"}>
                        <_TableView
                            project={activeProject}
                            projectData={activeProjectData}
                            activeAreaUid={activeAreaUid}
                        />
                    </Case>
                    <Case value={"map"}>
                        <_WorldWrapper
                            project={activeProject}
                            projectData={activeProjectData}
                            activeAreaUid={activeAreaUid}
                        />
                    </Case>
                </Switch>

            </div>
        )
    } else {
        return (<BasicMiddleSpinner/>)
    }
}

interface _ProjectSpotsVisitView {
    project: InfinityProject
    projectData: Map<string, number>
    activeAreaUid: string
}

function _WorldWrapper({project, projectData, activeAreaUid}: _ProjectSpotsVisitView) {

    return (
        <div style={{
            height: "700px"
        }}>
            <ProjectVisits3DMap
                spots={Array.from(project.spots.values())}
                spotsData={projectData}
                activeArea={project.areas.get(activeAreaUid)}
            />
        </div>
    )
}


function _TableView({project, projectData, activeAreaUid}: _ProjectSpotsVisitView) {

    const [t] = useTranslation()

    return (
        <MyTable>
            <thead>
            <tr>
                <th>Id</th>

                <th>{t("core.area.name")}</th>
                <th>{t("core.spot.name")}</th>
                <th>{t("core.visits")}</th>
            </tr>
            </thead>

            <tbody>

            {
                Array.from(project.spots.values()).map((value, index) => {
                    return (
                        <tr key={value.uid}>
                            <td>{index + 1}.</td>
                            <td>{value.getParentAreaName()}</td>
                            <td>{value.name}</td>
                            <td>{projectData.get(value.uid) ?? 0}</td>

                        </tr>
                    )
                })
            }

            </tbody>

        </MyTable>
    )
}