import * as three from "three"
import {InfinityBoardParent} from "./board_Parent";

import {InfinitySpot} from "../../infinity/InfinitySpot";
import {OutlineAsset} from "../../ela/assets/asset_Outline";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min"
import {GuidedTourManager} from "../../zustands/activeTestTourZus";

export class InfinityOutlineBoard extends InfinityBoardParent {


    placeHolder = new three.Object3D()
    color = new three.Color(1, 1, 1)
    line_color = new three.Color(1, 1, 1)

    content_transparency = 1
    line_transparency = 1

    line_width = 1

    type = "outline"

    pulse_enabled = false
    line_pulse_included = false
    pulse_speed = 1

    activeTween: TWEEN = null

    contentMaterial: three.MeshBasicMaterial = null
    lineMaterial: three.MeshBasicMaterial = null

    constructor(spot: InfinitySpot) {
        super(spot)

        this.isOpenedInWorld = true

        super.worldAddPrority = 0
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)

        const color = object["color"]

        this.color.r = color["x"]
        this.color.g = color["y"]
        this.color.b = color["z"]

        const line_color = object["line_color"]
        this.line_color.r = line_color["x"]
        this.line_color.g = line_color["y"]
        this.line_color.b = line_color["z"]

        this.line_width = object["line_width"]
        this.line_transparency = object["line_transparency"]
        this.content_transparency = object["content_transparency"]

        this.pulse_enabled = object["pulse_enabled"]
        this.line_pulse_included = object["line_pulse_included"]
        this.pulse_speed = object["pulse_speed"]

    }


    CleanUp() {

        if (this.activeTween) {
            TWEEN.remove(this.activeTween)
        }

        this.placeHolder.traverse((mesh: three.Mesh) => {
            if (mesh.isMesh) {
                mesh.geometry.dispose()
                mesh.material.dispose()
            }
        })

        this.placeHolder.clear()
    }


    clicked() {
        GuidedTourManager.taskBoardClicked(this, 1)
    }

    setVisibilityStatus(status: boolean) {
        this.placeHolder.visible = status
    }


    isVisible(): boolean {
        if (this.isOpenedInWorld) {
            return this.placeHolder.visible
        } else {
            return super.isVisible();
        }
    }

    fillToWorld(world: World) {
        world.secondaryNotSelectableGroup.add(this.placeHolder)

        this.fillMeshToWorld()

        if (this._isEnabled === false) {
            this.hide()
        }

        if (this.pulse_enabled) {
            this.startTweenToZero()
        }

    }

    clickedExternaly() {
        this.unhide()

    }


    hide() {
        this.placeHolder.visible = false
    }

    unhide() {
        this.placeHolder.visible = true
    }

    startTweenToZero = () => {

        const from = {
            x: this.content_transparency,
            y: this.line_transparency
        }


        this.activeTween = new TWEEN.Tween(from).to({
            x: 0,
            y: 0
        }, this.pulse_speed * 1000).onUpdate(this.tweenOpacity).onComplete(this.startTweenToActiveValue).start()

    }

    startTweenToActiveValue = () => {

        const to = {
            x: this.content_transparency,
            y: this.line_transparency
        }

        this.activeTween = new TWEEN.Tween({
            x: 0,
            y: 0
        }).to(to, this.pulse_speed * 1000).onUpdate(this.tweenOpacity).onComplete(this.startTweenToZero).start()
    }

    tweenOpacity = (newValue) => {
        if (this.contentMaterial) {
            this.contentMaterial.opacity = newValue.x

            if (this.line_pulse_included) {
                this.lineMaterial.opacity = newValue.y
            }


        }

    }


    fillMeshToWorld() {
        const outline: OutlineAsset = this.getParentProject().outlines.get(this.contentUid)


        if (outline) {

            if (outline.vertices.length < 3) {
                return
            }

            const geometry = new three.BufferGeometry();

            const copy = this.getThreeVec3FromPoints(outline.vertices)

            if (this.content_transparency != 0) {
                const tmpVertices = this.getVerticesFromPoints(copy)

                geometry.setAttribute('position', new three.Float32BufferAttribute(tmpVertices, 3));
                geometry.computeVertexNormals();

                const colors = []
                for (let i = 0, n = geometry.attributes.position.count; i < n; ++i) {
                    colors.push(1, 1, 1);
                }
                geometry.setAttribute('color', new three.Float32BufferAttribute(colors, 3));

                this.contentMaterial = new three.MeshBasicMaterial({
                    color: this.color,
                    side: three.DoubleSide,
                    opacity: this.content_transparency,
                    transparent: true,
                    depthTest: false,
                    //` depthWrite: false,
                })//{color: 0xff0000});

                const mesh = new three.Mesh(geometry, this.contentMaterial);

                this.placeHolder.add(mesh);
            }

            if (this.line_transparency != 0) {
                this.addLines(copy)
            }

        }
    }


    getThreeVec3FromPoints(points: []) {
        const copy = Array.from(points)

        copy.push(points[points.length - 1])
        copy.push(points[0])

        return copy.map((aRaw) => {
            const a = new three.Vector3(aRaw["x"], aRaw["y"], aRaw["z"])
            a.normalize()
            a.multiply(new three.Vector3(500, 500, 500))
            return a
        })
    }


    getVerticesFromPoints(vertices: []) {

        if (vertices.length < 3) {
            return []
        }

        const buffer = []

        const thisPosition = new three.Vector3()
        thisPosition.copy(this.position)
        thisPosition.multiply(new three.Vector3(300, 300, 300))

        for (let i = 0; i < vertices.length - 1; i++) {

            const a = vertices[i]
            const b = vertices[i + 1]

            buffer.push(a.x)
            buffer.push(a.y)
            buffer.push(a.z)

            buffer.push(b.x)
            buffer.push(b.y)
            buffer.push(b.z)

            buffer.push(thisPosition.x)
            buffer.push(thisPosition.y)
            buffer.push(thisPosition.z)

        }

        return buffer
    }


    addLines(vertices: three.Vector3[] = []) {
        //const widthscale = 1
        const widthscale = this.line_width * 0.25

        const tmpVertices = [...vertices]
        tmpVertices.pop()
        tmpVertices.pop()

        var options = {}

        if (this.line_transparency != 1 || this.line_pulse_included) {
            options = {
                opacity: this.line_transparency,
                transparent: true,
            }
        }

        this.lineMaterial = new three.MeshBasicMaterial(options)
        this.lineMaterial.color = this.line_color
        const sphereGeometry = new three.SphereGeometry(1, 16, 16)

        for (let i = 0; i < tmpVertices.length; i++) {
            const pos = vertices[i]

            const mesh = new three.Mesh(sphereGeometry, this.lineMaterial)
            mesh.position.set(pos.x, pos.y, pos.z)
            mesh.scale.set(widthscale / 2, widthscale / 2, widthscale / 2)

            this.placeHolder.add(mesh)
        }

        const cylinderGeometry = new three.BoxGeometry()

        const tmpLooktAt = [...tmpVertices]
        tmpLooktAt.push(tmpVertices[0])

        for (let i = 0; i < tmpVertices.length; i++) {
            const pos = vertices[i]

            const meshGroup = new three.Group()

            const mesh = new three.Mesh(cylinderGeometry, this.lineMaterial)
            mesh.position.set(0, 0, 0.5)

            const nextPos = tmpLooktAt[i + 1]

            const scale = new three.Vector3()
            scale.copy(pos)
            scale.sub(nextPos)

            meshGroup.position.set(pos.x, pos.y, pos.z)
            meshGroup.scale.set(widthscale * 0.8, widthscale * 0.8, scale.length())
            meshGroup.lookAt(nextPos.x, nextPos.y, nextPos.z)

            meshGroup.add(mesh)

            this.placeHolder.add(meshGroup)
        }

    }


}