import React from "react";

import * as three from "three";

import * as Bs from "react-bootstrap";

import {StaticIncludes} from "../../static_includes";

import IconClickPlane from "../../ela/world/meshgui/IconClickPlane";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";
import {ellaMatrixToThreeJs} from "../math/math";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import LicenceManager from "../../api/LicenceManager";
import TourStatisticsApi, {
    SaveBoardVisitParams,
} from "../../api/TourStatisticsApi";
import urlcWithPath from "../../urlc";

export class InfinityBoardParent {
    static font_material = new three.MeshBasicMaterial({
        color: 0xffffff,
        side: three.DoubleSide,
    });

    worldAddPrority = 1;

    clickableIcon: IconClickPlane;

    type = "";

    tags = "";

    iconSize = 1;
    board_header_font_size = 1;
    position = new three.Vector3(1, 0, 1);
    rotation = new three.Vector3(0, 0, 0);

    scale = new three.Vector3(1, 1, 1);
    iconColorModifier = new three.Color(1, 1, 1);

    uid: string = "";
    icon: string = "Core.Deprecated";
    name: string = "";
    contentUid: string = "";

    autoUnpack: boolean = false;
    seenOnce: boolean = false;
    loadedOnce: boolean = false;

    showAsPopup: boolean = true;
    boardNameVisible: boolean = false;
    boardNameVisibleOnHover: boolean = true;

    use_automatic_rotation = true;

    parentSpot: {} = null;

    isCompleted: boolean = false;

    textMesh = null;

    webTransparencySafe = false;

    searchBuffer = "";

    closeEnabled = true;

    f: three.Vector3 = null;
    t: three.Vector3 = null;
    r: three.Vector3 = null;

    board_position_f: three.Vector3 = null;
    board_position_t: three.Vector3 = null;
    board_position_r: three.Vector3 = null;

    _isEnabled = true;

    constructor(spot) {
        this.parentSpot = spot;
    }

    iconRotationEnabled() {
        return false;
    }

    isContentValid() {
        return true;
    }

    getFinalPosition() {
        if (this.clickableIcon) {
            return this.clickableIcon.getRoot().position;
        } else {
            return new three.Vector3(1, 0, 0);
        }
    }

    loadFromJsonObject(object: any) {
        this.uid = object["uid"];
        this.position.set(object["pos_x"], object["pos_y"], object["pos_z"]);
        this.icon = object["icon"];
        this.contentUid = object["content_uid"];
        this.autoUnpack = object["auto_unpack"] && !object["show_as_popup"];
        this.showAsPopup = object["show_as_popup"];

        this.boardNameVisible = object["board_name_visible"];
        this.boardNameVisibleOnHover = object["board_name_visible_on_hover"];
        //Toto je /20 na desktope
        this.iconSize = object["icon_size"] * 0.005;
        this.board_header_font_size = object.board_header_font_size ?? 1;
        this.name = object["name"];
        this.tags = object.search_tags ?? "";
        this.use_automatic_rotation = object["use_automatic_rotation"];
        this.scale.set(object["scale_x"], object["scale_y"], object["scale_z"]);
        this.closeEnabled = object["close_enabled"];

        if (object.icon_color) {
            const color = object.icon_color;
            this.iconColorModifier = new three.Color(color.x, color.y, color.z);
        }

        this.searchBuffer += this.name;

        const rotation = object["manual_position"];
        this.f = rotation["f"];
        this.t = rotation["t"];
        this.r = rotation["r"];
    }

    getSearchableText() {
        return this.name;
    }

    postContruct() {
    }

    isSelected(intersection: three.Intersection) {
        return true;
    }

    clicked() {
        GuidedTourManager.taskBoardClicked(this, 1);
    }

    fillBoardVisited(success: number) {
        if (LicenceManager.f360.boards_monitoring) {
            if (this.isCompleted === false) {
                const parentProject = this.parentSpot.parentProject;

                const params = new SaveBoardVisitParams();
                params.board_name = this.name;
                params.board_uid = this.uid;
                params.spot_name = this.parentSpot.name;
                params.spot_uid = this.parentSpot.uid;
                params.project_name = parentProject.name;
                params.project_uid = parentProject.uid;

                params.success = success;

                TourStatisticsApi.saveBoardVisit(params);
            }
        }

        this.isCompleted = true;
    }

    CleanUp() {
    }

    theatreModeEnded() {
    }

    leaveTheatreModeRequested() {
    }

    setVisibilityStatus(status: boolean) {
        if (this.clickableIcon) {
            this.clickableIcon.mesh.visible = status;
            this.clickableIcon.meshB.visible = status;
        }

        if (this.textMesh) {
            if (status === true) {
                if (!this.boardNameVisibleOnHover) {
                    this.textMesh.visible = status;
                }

                if (this.boardNameVisible) {
                    this.textMesh.visible = status;
                }
            } else {
                this.textMesh.visible = status;
            }
        }
    }

    isVisible(): boolean {
        if (this.clickableIcon) {
            return this.clickableIcon.mesh.visible;
        }
        return false;
    }

    leaveVr = () => {
        const renderer = this.getActivePlayer().worldWrapper.getWorld().renderer;

        if (renderer.xr.isPresenting) {
            renderer.xr.getSession().end();
        }
    };

    isInVR() {
        const renderer = this.getActivePlayer().worldWrapper.getWorld().renderer;
        return renderer.xr.isPresenting
    };


    copyUrlClicked = () => {
        const board = this;

        const project = this.getParentProject();

        const spot = this.getActivePlayer().activeSpot;

        const data = {
            project: project.projectUid,
            spot: spot.uid,
            board: board.uid,
        };

        const location = window.location.origin;

        const path = location + `/?spot=${data.spot}&board=${data.board}`;

        navigator.permissions.query({name: "clipboard-write"}).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(path);
            }
        });
    };

    getRightClickOptions() {
        //   <Bs.ListGroup.Item action onClick={openInNewWindow}>Open in new window</Bs.ListGroup.Item>
        return (
            <>
                <Bs.ListGroup.Item action onClick={this.copyUrlClicked}>
                    Copy board url
                </Bs.ListGroup.Item>
            </>
        );
    }

    rightClicked(event: MouseEvent) {
    }

    worldRotated(world) {
        // console.log("Rotated")
        //
        // if (this.iconRotationEnabled()) {
        //     const rotationMatrix = ellaMatrixToThreeJs(this.f, this.t, this.r)
        //     this.clickableIcon.setMatrix(rotationMatrix)
        // }
    }

    taskEnded() {
        this.setVisibilityStatus(this._isEnabled)
    }

    fillToWorld(world) {

        const group = world.secondaryNotRotatedGroup;
        const texture = new three.TextureLoader().load(
            urlcWithPath(
                `infinity/${this.parentSpot.parentProject.uid}/icons/${this.icon}.png`
            )
        );

        const tmpColor = new three.Color(1, 1, 1);

        if (this.isContentValid()) {
            tmpColor.copy(this.iconColorModifier);
        } else {
            console.log("Setting color red");
            this.iconColorModifier = new three.Color("red");
        }

        let clickIcon: IconClickPlane = null;
        if (this.webTransparencySafe) {
            clickIcon = new IconClickPlane(texture, {
                transparencySafe: true,
                color: this.iconColorModifier,
            });
        } else {
            clickIcon = new IconClickPlane(texture, {
                color: this.iconColorModifier,
            });
        }

        this.clickableIcon = clickIcon;

        this.createText();

        const multiplier = 300;
        const position = new three.Vector3();
        position.copy(this.position);
        position.multiply(new three.Vector3(multiplier, multiplier, multiplier));
        position.applyAxisAngle(
            new three.Vector3(0, 1, 0),
            this.parentSpot.spotRotation.y
        );

        const newIconSize = this.iconSize * (multiplier + 100);

        clickIcon.scale_set(newIconSize, newIconSize, newIconSize);
        clickIcon.position_set(position.x, position.y, position.z);

        if (this.iconRotationEnabled()) {
            const rotationMatrix = ellaMatrixToThreeJs(this.f, this.t, this.r);
            this.clickableIcon.setMatrix(rotationMatrix);
        } else {
            clickIcon.lookAt(0, 0, 0);
        }

        clickIcon.onClicked = () => {
            this.receiveEvent();
        };

        clickIcon.onSelected = () => {
            if (this.boardNameVisible == false) {
                if (this.boardNameVisibleOnHover) {
                    if (this.textMesh) {
                        this.textMesh.visible = true;
                    }
                }
            }
        };
        clickIcon.onDeselected = () => {
            if (this.boardNameVisible == false) {
                if (this.boardNameVisibleOnHover) {
                    if (this.textMesh) {
                        this.textMesh.visible = false;
                    }
                }
            }
        };

        this.setVisibilityStatus(this._isEnabled)

        group.add(this.clickableIcon.getRoot());


    }

    receiveEvent() {
        this.clicked();
    }

    createText() {
        if (this.textMesh) {
            //Do nothing
        } else {
            if (this.boardNameVisible || this.boardNameVisibleOnHover) {
                const font = StaticIncludes._instnace.font;

                const fontSize = this.board_header_font_size * 0.15;

                const shapes = font.generateShapes(this.name, fontSize);

                const geometry = new three.ShapeGeometry(shapes);

                const textMesh = new three.Mesh(
                    geometry,
                    InfinityBoardParent.font_material
                );

                textMesh.geometry.computeBoundingBox()

                const bb = textMesh.geometry.boundingBox

                const fontX = -(textMesh.geometry.boundingBox.max.x * 0.5)
                const fontY = bb.max.y - bb.min.y

                textMesh.position.set(fontX, fontY, 0);
                textMesh.renderOrder = 999;
                textMesh.name = this.name;

                this.clickableIcon.fontGroup.add(textMesh);

                if (this.boardNameVisible == false) {
                    textMesh.visible = false;
                }

                this.textMesh = textMesh;
            }
        }
    }

    refreshVisualization() {
        if (this.clickableIcon) {
            this.clickableIcon.setColorObject(this.iconColorModifier);
        }
    }

    getPercentage() {
        //Toto je takto, lebo nie vsetky este podporuju auto unpack, cize staci ze sa na ne klikne
        if (this.isCompleted) {
            return 1;
        } else {
            if (this.autoUnpack) {
                if (this.loadedOnce) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (this.seenOnce) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }

    step() {
    }

    getWebPreviewImage() {
    }

    getIsCompleted() {
        return this.isCompleted;
    }

    setCompletedColor() {
        this.iconColorModifier = new three.Color(0.5, 0.5, 0.5);
        this.refreshVisualization();
    }

    getParentProject() {
        return this.parentSpot.parentProject;
    }

    getActivePlayer() {
        return this.getParentProject().activePlayer;
    }

    playInModalHud(object: HTMLElement) {
        useGlobalPopup.getState().pushElement(object);

        // this.getActivePlayer().worldWrapper.playInModalHud(object)
    }

    clickedExternaly() {
    }
}
