import React from 'react';
import {QuizView} from "./QuizView";
import {Quiz} from "../../../ela/assets/asset_Quiz";
import {InfinityProject} from "../../../infinity/InfinityProject";
import {Card, Form} from 'react-bootstrap';
import IGui from "../../../components/IGui";


interface QuizPlayerProps {
    activeQuiz: Quiz
    project: InfinityProject
    onClose: (userIdentifier: any) => any
    usesCustomUser: boolean

}

export function QuizPlayer({activeQuiz, usesCustomUser, project, onClose}: QuizPlayerProps) {

    if (activeQuiz.questions.length == 0) {
        return (
            <></>
        )
    } else {
        return (
            <>
                <div style={{
                    backdropFilter: "blur(5px)",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    minWidth: "100%",
                    width: "100%",
                    height: "100%",
                }}>
                    <div style={{
                        maxWidth: "75%",
                        minWidth: "50%",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",

                    }}>
                        <_EnterUserId
                            usesCustomUser={usesCustomUser}
                            activeQuiz={activeQuiz}
                            project={project}
                            onClose={(customId: string) => {
                                activeQuiz.isEvaluated = true
                                onClose(customId)
                            }}
                        />
                    </div>
                </div>

            </>);
    }

}


function _EnterUserId({activeQuiz, usesCustomUser, project, onClose}: QuizPlayerProps) {

    const [identifier, setIdentifier] = React.useState("")

    const [continuePressed, setContinuePressed] = React.useState(false)

    if (continuePressed || usesCustomUser == false) {
        return (
            <QuizView
                activeQuiz={activeQuiz}
                project={project}
                onQuizFinished={() => {
                    activeQuiz.isEvaluated = true
                    onClose(identifier)
                }}
            />
        )

    } else {
        return (
            <Card>
                <Card.Header>
                    User identifier
                </Card.Header>
                <Card.Body>
                    <Form.Control
                        type={"text"}
                        value={identifier}
                        onChange={(e) => {
                            setIdentifier(e.target.value)
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <div
                        className={"vstack gap-1"}

                    >
                        <IGui.Button
                            label={"continue"}
                            disabled={identifier.length < 4}
                            onClick={() => {
                                setContinuePressed(true)
                            }}
                            style={{
                                marginLeft: "auto"
                            }}
                        />
                    </div>

                </Card.Footer>
            </Card>
        )

    }


}

