import {InfinityBoardParent} from "./board_Parent";
import * as three from "three";
import {Object3D} from "three";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";


export class InfinityClickBoard extends InfinityBoardParent {

    onlyClick = true

    type = "click"

    maximalizedScale = new three.Vector3(0, 0, 0)

    click_group_boards: Set<string> = new Set()
    hover_group_boards: Set<string> = new Set()

    f: three.Vector3 = null
    t: three.Vector3 = null
    r: three.Vector3 = null

    isGood: boolean = true

    inWorldObject: Object3D = null

    constructor(spot) {
        super(spot)
        this.icon = "EI.Click"
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)

        const rotation = object["manual_position"]
        this.f = rotation["f"]
        this.t = rotation["t"]
        this.r = rotation["r"]

        this.isGood = object["is_good"]

        object.click_group_boards.forEach((value) => {
            this.click_group_boards.add(value)
        })

        object.hover_group_boards.forEach((value) => {
            this.hover_group_boards.add(value)
        })
    }

    fillToWorld(world) {

        if (this.isCompleted === false) {

            const material = new three.MeshBasicMaterial({
                color: 0xffffff,
                transparent: true,
                side: three.DoubleSide,
                depthWrite: false,
                depthTest: false,
                opacity: 0,
            });

            const geometry = new three.PlaneGeometry(this.scale.x, this.scale.y);
            const frontMesh = new three.Mesh(geometry, material);
            frontMesh.userData["handler"] = this


            const geometryGroup = new three.Group()
            geometryGroup.add(frontMesh)
            geometryGroup.scale.set(100, 100, 100)

            const rotatedPosition = new three.Vector3()
            rotatedPosition.copy(this.position)
            rotatedPosition.multiply(new three.Vector3(100, 100, 100))
            rotatedPosition.applyAxisAngle(new three.Vector3(0, 1, 0), this.parentSpot.spotRotation.y)

            geometryGroup.position.set(rotatedPosition.x, rotatedPosition.y, rotatedPosition.z)

            if (this.use_automatic_rotation) {
                geometryGroup.lookAt(new three.Vector3(0, rotatedPosition.y, 0))
            } else {
                const rotationMatrix = new three.Matrix4()

                rotationMatrix.set(
                    this.f.x, this.t.x, this.r.x, 0,
                    this.f.y, this.t.y, this.r.y, 0,
                    this.f.z, this.t.z, this.r.z, 0,
                    0, 0, 0, 1
                )

                geometryGroup.rotation.setFromRotationMatrix(rotationMatrix)
                geometryGroup.updateMatrixWorld(true)
            }

            world.secondaryNotRotatedGroup.add(geometryGroup)
            this.inWorldObject = geometryGroup
        }


    }

    clicked = () => {

        if (this.inWorldObject) {

            super.fillBoardVisited(1)

            const clickResult = GuidedTourManager.taskBoardClicked(this, 1)


            this.click_group_boards.forEach((value) => {
                if (this.parentSpot.boards.has(value)) {
                    const groupedBoard: InfinityBoardParent = this.parentSpot.boards.get(value)
                    groupedBoard.clickedExternaly()

                }
            })

            if (clickResult) {
                super.fillBoardVisited(1)

                this.click_group_boards.clear()
                this.inWorldObject.parent.remove(this.inWorldObject)
                this.inWorldObject.userData["handler"] = null
                this.inWorldObject = null
            }


        } else {
            console.log("Selection is still invalid,...")
        }

    }

    getPercentage(): number {

        if (this.isGood && this.isCompleted) {
            return 1
        } else if (this.isGood === false && this.isCompleted) {
            return 0
        } else if (this.isGood === false && this.isCompleted === false) {
            return 1
        }
        return 0
    }


    isSelected() {
        return false
    }

    setSelected() {

    }

    setDeselected() {

    }


}