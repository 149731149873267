import {useTranslation} from "react-i18next";
import React from "react";
import VisitorsStatisticsApi from "../../../api/VisitorsStatisticsApi";
import ProjectsApi from "../../../api/ProjectsApi";
import {Spinner} from "react-bootstrap";
import {DashBoardTileNoMiddle} from "../../../components/dashboard/DashBoardTile";
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {OnlySpinner} from "../../../components/spinners";

export default function ProjectsChartComparison({projectUid}) {

    const [t] = useTranslation()


    const [isLoading, setIsLoading] = React.useState(true)
    const [data, setData] = React.useState<Array<any>>([])


    React.useEffect(() => {
        setIsLoading(true)
        VisitorsStatisticsApi.getAllProjectsVisits().then((result) => {

            ProjectsApi.getAllProjects().then((value) => {

                const convertedData = value.map((fakeProject) => {
                    return {name: fakeProject.name, value: result[fakeProject.uid] ?? 0, uid: fakeProject.uid}
                })
                setData(convertedData)
            })

            setIsLoading(false)
        })
    }, [])

    const fillColor = "#db333c"

    if (isLoading) {
        return (
            <OnlySpinner/>
        )
    } else {
        return (
            <div className={"vstack gap-3"}>
                <h1 style={{textAlign: "center"}}>{t("core.project.comparison")}</h1>

                <DashBoardTileNoMiddle style={{height: "600px"}}>
                    <ResponsiveContainer
                        width="100%"
                        height="600px"
                    >

                        <div style={{
                            backgroundColor: "white",
                            margin: "auto",
                            padding: "25px",
                            overflowX: "scroll"
                        }}>
                            <BarChart
                                width={1000}
                                height={500}
                                data={data}
                                margin={{
                                    top: 15,
                                    right: 30,
                                    left: 20,
                                    bottom: 150,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis
                                    dataKey="name"
                                    tick={CustomizedAxisTick}
                                    interval={0}
                                />
                                <YAxis/>
                                <Tooltip/>
                                {/*<Legend/>*/}
                                <Bar dataKey="value" fill={fillColor} label={{position: 'top'}}>
                                    {
                                        data.map((entry, index) => {
                                            return (
                                                <Cell key={`cell-${index}`}
                                                      fill={entry.uid === projectUid ? '#0088FE' : fillColor}/>
                                            )
                                        })
                                    }
                                </Bar>

                            </BarChart>

                        </div>

                    </ResponsiveContainer>
                </DashBoardTileNoMiddle>
            </div>
        );
    }

}


function CustomizedAxisTick(props) {
    const {x, y, payload} = props;
    const name = payload.value;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0}
                  y={0}
                  dy={12}
                  fontSize="smaller"
                  textAnchor="end"
                  fill="#666"
                  transform="rotate(-45)"
                  style={{
                      fontSize: "15px"
                  }}
            >
                {name}

            </text>
        </g>
    );
};