import * as three from "three"

import {AssetParent} from "./asset_Parent";


export class PanoramaAsset extends AssetParent {

    file_lods: number = 1
    file_has_moons: boolean = false
    is_previewed = false

    folderName = "Panoramas"

    firstLod: three.Texture | any = null

    moons_x = 16
    moons_y = 4

    constructor() {
        super()
    }

    fromJsonObject(object: any) {
        super.fromJsonObject(object)

        this.file_lods = object["file_lods"]
        this.file_has_moons = object["file_has_moons"]

        this.moons_x = object.moons_x
        this.moons_y = object.moons_y
    }

    getFirstLod(): three.Texture {
        return this.firstLod
    }

    setFirstLod(lod: three.Texture) {
        this.firstLod = lod
    }

    getAbsolutePath(lod: number = 0) {
        return this.GetStorage() + this.uid + "/" + lod.toString() + "." + this.file_extension
    }

    getMoonUltraAbsolutePath(index: number) {
        return this.GetStorage() + this.uid + "/MOON_ULTRA_" + index.toString() + "." + this.file_extension
    }

    getMoonAbsolutePath(index: number) {
        return this.GetStorage() + this.uid + "/MOON_" + index.toString() + "." + this.file_extension
    }

    getFooterAbsolutePath() {
        return this.GetStorage() + this.uid + "/MOON_FOOTER." + this.file_extension
    }

    GetDefaultPath() {
        return this.GetStorage() + this.uid + "/default." + this.file_extension
    }

    get2048x1024() {
        return this.GetStorage() + this.uid + "/2048x1024." + this.file_extension
    }

    getHeaderAbsolutePath() {
        return this.GetStorage() + this.uid + "/MOON_HEADER." + this.file_extension
    }


}