import {Alert, Form, Stack} from "react-bootstrap"
import UsersApi from "../../api/UsersApi";
import React from "react";
import {useTranslation} from "react-i18next";
import IconClickButton from "../../components/IconClickButton";

import Switch, {Case} from "react-switch-case";
import {InfinityModal} from "../../components/modals";
import urlcWithPath from "../../urlc";


export default function AddUserToGroupModal({expectedGroup, onHide, onRefresh}) {

    const [t, _] = useTranslation()

    const [returnStatus, setReturnStatus] = React.useState("")

    const [isProcessing, setIsProcessing] = React.useState(false)

    const [email, setEmail] = React.useState("")

    const addPressed = () => {
        setIsProcessing(true)
        UsersApi.createUser(email, expectedGroup).then((result) => {
            if (result.data.status) {
                setReturnStatus("")
                onHide()
                onRefresh()
            } else {
                setReturnStatus("alreadyexists")
            }
            setIsProcessing(false)
        })
    }

    return (
        <InfinityModal
            onHide={onHide}
            closeButton={true}

            headerChildren={
                <>                       {
                    t("core.add.user")
                }</>
            }

            bodyChildren={<>
                <div>
                    <Form.Group id={"email"}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type={"input"} placeholder={"john.doe@exe.sk"}
                                      onChange={(e) => {
                                          setEmail(e.target.value)
                                      }}/>
                    </Form.Group>

                    <Form.Group id={"user_group"}>
                        <Form.Label>Group</Form.Label>
                        <Form.Control disabled={true} type={"input"} value={expectedGroup}/>
                    </Form.Group>

                </div>

            </>}

            footerChildren={<>

                <Switch condition={returnStatus}>
                    <Case value={""}>
                        <Stack gap={3} direction={"horizontal"}>
                            {
                                isProcessing === false &&

                                <IconClickButton icon={urlcWithPath("i-icons/add.svg")}
                                                 lang={"core.add.user"}
                                                 disabled={email === ""}

                                                 onclick={addPressed}/>
                            }

                        </Stack>
                    </Case>
                    <Case value={"alreadyexists"}>
                        <Alert variant={"danger"}>{t("core.user.alreadyexists")}</Alert>
                    </Case>

                </Switch>

            </>}

        />
    )

}