import {Image, Spinner, Stack} from "react-bootstrap";
import React from "react";
import {useGlobalAppLock} from "../zustands/globalAppLockZus";
import {useTranslation} from "react-i18next";
import urlcWithPath from "../urlc";


export default function AppLockSpinner() {

    const locker = useGlobalAppLock()

    const [t] = useTranslation()

    let content: any = null

    if (locker.isFailed === false) {
        content = <Spinner animation="border" variant="danger"/>
    } else {
        content = <Stack gap={3}>
            <Image src={urlcWithPath("i-icons/somethingswrong.svg")} style={{
                width: "50px", height: "50px", margin: "auto"
            }}/>
            <div style={{
                color: "white",
                textAlign: "center"
            }}>{t("core.somethingiswrong")} {locker.failError}: {locker.failMessage}</div>
        </Stack>

    }


    return (
        <div style={{
            position: "fixed",
            left: "0px",
            right: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1060,
            visibility: locker.isAppLocked ? "visible" : "hidden"
        }}>
            <div style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, 50%)"
            }}>
                {
                    content
                }

            </div>
        </div>
    )
}