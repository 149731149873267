import {map} from "react-bootstrap/ElementChildren";


class CookieManager {

    activeSpotUid = ""

    cookiesEnabled = true
    map = new Map<string, any>()


    put(key: string, value) {
        if (this.cookiesEnabled) {
            this.map[key] = value
        }

    }

    get(key: string, defaultValue: any = null) {
        if (this.map.has(key)) {
            return this.map[key]
        } else {
            return defaultValue
        }
    }

    addSpotToPath(projectId, spotUid) {
        const data = {
            spot: spotUid,
        }
        this.activeSpotUid = spotUid

        window.history.pushState(data, `navLink`, `/#/project/${projectId}?spot=${spotUid}`);
    }

    addCutomToSpotPath(custom) {
        window.history.pushState(custom, `navLink`, custom);
    }


}

export const COOKIE_MANAGER = new CookieManager()