import React from "react";
import {useTranslation} from "react-i18next";
import {InfinityOffcanvasWrapper} from "../offcanvas";
import {Offcanvas} from "react-bootstrap";

import SelectProjectView from "./SelectProjectView";


export default function ProjectSelectOffcavnas({onHide, onProjectSelected, projectType}) {
    const [t] = useTranslation()

    return (
        <InfinityOffcanvasWrapper
            closeButton={true}
            onHide={onHide}
            mainStyle={{
                width: "900px"
            }}
            header={<Offcanvas.Title>{t("core.projects")}:</Offcanvas.Title>}
            body={<SelectProjectView projectType={projectType} onSelected={onProjectSelected}/>}
        />
    )
}




