import * as three from "three";
import { InfinitySpot } from "../../../infinity/InfinitySpot";
import { AreaAsset } from "../../../ela/assets/asset_Area";
import { GLTFLoader } from "../../../infinity/GLTFLoader";
import urlcWithPath from "../../../urlc";

class MeshSpotHandler {
  constructor(loadedScene: three.Mesh, onClick) {
    this.onClick = onClick;

    loadedScene.traverse((mesh: three.Mesh) => {
      if (mesh.isMesh) {
        mesh.userData["handler"] = this;
      }
    });
  }

  isSelected(intersection: three.Intersection) {
    return true;
  }

  setSelected() {}

  setDeselected() {}

  clicked(intersection: three.Intersection) {
    this.onClick();
  }
}

export class MaximapVR {
  root: three.Group;
  spotsGroup: three.Group;

  textureMesh: three.Mesh;
  textureMaterial: three.MeshBasicMaterial;

  backgroundMesh: three.Mesh;

  activeArea: AreaAsset;

  constructor() {
    this.root = new three.Group();
    this.root.visible = false;

    this.spotsGroup = new three.Group();
    this.root.add(this.spotsGroup);

    this._loadPlane();
  }

  _loadPlane() {
    const planeGeometry = new three.PlaneGeometry();

    this.textureMaterial = new three.MeshBasicMaterial();
    this.textureMesh = new three.Mesh(planeGeometry, this.textureMaterial);
    this.textureMesh.position.y = 0.005;
    this.textureMesh.rotateX(-Math.PI / 2);

    const blackMaterial = new three.MeshBasicMaterial({
      color: new three.Color("black"),
      transparent: true,
      opacity: 0.75,
      // side: three.DoubleSide
    });
    const backgronudG = new three.PlaneGeometry();
    this.backgroundMesh = new three.Mesh(backgronudG, blackMaterial);
    this.backgroundMesh.rotateX(-Math.PI / 2);
    this.backgroundMesh.userData["handler"] = this;

    this.root.add(this.textureMesh);
    this.root.add(this.backgroundMesh);
    // this.root.add(new three.AxesHelper(100))
  }

  _loadMeshes(activeSpot: InfinitySpot, spots: Array<InfinitySpot>) {
    spots.forEach((spot: InfinitySpot) => {
      if (spot.areaId == activeSpot.areaId) {
        new GLTFLoader().load(urlcWithPath("spot3D.glb"), (result) => {
          const scene = result.scene;
          // const testG = new three.SphereGeometry()
          // const testM = new three.Mesh(testG, new three.MeshBasicMaterial({color: new three.Color("red")}))
          scene.position.set(spot.area_pos_x, spot.area_pos_y, spot.area_pos_z);
          scene.scale.set(20, 20, 20);

          new MeshSpotHandler(scene, () => {
            spot.parentProject.activePlayer.ActivateSpot(spot);
          });

          this.spotsGroup.add(scene);
        });
      }
    });
  }

  show(activeSpot: InfinitySpot) {
    const areaAsset = activeSpot.getMapAsset();

    if (areaAsset) {
      if (areaAsset != this.activeArea) {
        const xSize = areaAsset.scale_x;
        const ySize = areaAsset.scale_y;

        this.textureMaterial.map = new three.TextureLoader().load(
          areaAsset.getMaximapPath()
        );
        this.textureMaterial.needsUpdate = true;
        // const textureWidth = this.textureMaterial.map.image.width
        // const textureHeight = this.textureMaterial.map.image.height

        const textureWidth = areaAsset.scale_x;
        const textureHeight = areaAsset.scale_y;

        let planeCoefX = 1;
        let planeCoefY = 1;

        let textureScaleX = xSize * planeCoefX;
        let textureScaleY = ySize * planeCoefY;

        if (textureWidth > textureHeight) {
          planeCoefY = textureHeight / textureWidth;
          textureScaleY = planeCoefY * textureScaleX;
        } else if (textureWidth < textureHeight) {
          planeCoefX = textureWidth / textureHeight;
          textureScaleX = planeCoefX * textureScaleY;
        }

        this.textureMesh.scale.set(textureScaleX, textureScaleY, 1);
        this.backgroundMesh.scale.set(xSize, ySize, 1);
        this._loadMeshes(
          activeSpot,
          Array.from(activeSpot.parentProject.spots.values())
        );
      }

      this.root.visible = true;
      this.root.scale.set(0.0025, 0.0025, 0.0025);
    }
  }

  hide() {
    this.root.visible = false;
  }

  isSelected(intersection: three.Intersection) {
    return true;
  }

  setSelected() {}

  setDeselected() {}

  clicked(intersection: three.Intersection) {
    this.onClick();
  }
}
