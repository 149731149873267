import React from "react";
import {Image} from "react-bootstrap";
import * as three from "three";
import {Vector2} from "three";

import PanoramaCameraControl from "../../ela/world/panoramaCameraControl";
import {PanoramaAsset} from "../../ela/assets/asset_Panorama";
import {InfinityBoardParent} from "../boards/board_Parent";

import {SceneFooter} from "./SceneFooter";

import DefaultSceneConfigurator from "../../ela/world/defaultSceneConfigurator";

import PhotoDom from "../../ela/world/photoDom";
import {InfinitySpot} from "../../infinity/InfinitySpot";
import InfinityVRAvatar from "../../infinity/InfinityVRAvatar";
import {
    GlobalProgressBar,
    GlobalProgressBarInstance,
} from "../../gui/controls/LoadingProgress";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min";

import {Minimap} from "../../gui/content_players/map/Minimap";
import {ActiveTourRightBar} from "../../gui/controls/ActiveTourRightBar";
import {Branding} from "../../gui/Branding";
import DeviceManager from "../../ela/deviceManager";
import {MaximapVR} from "../../gui/content_players/map/MaximapVR";
import {InfinityClickBoard} from "../boards/board_Click";
import {useActiveSpotZus} from "../../zustands/activeSpotZus";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import LicenceManager from "../../api/LicenceManager";
import TourStatisticsApi from "../../api/TourStatisticsApi";
import urlcWithPath from "../../urlc";

let SPOT_NOTIFIED_TIMEOUT = null;

export class NewWorld extends React.Component {
    state = {
        activeSpot: null,
        previewModeEnabled: false,
        theatreModeActive: false,
    };

    canvasRef = React.createRef();

    componentDidMount() {
        this.canvasRef.current.appendChild(WorldSingleton.INSTANCE.getDomElement());

        if (this.props.onLoaded) {
            this.props.onLoaded(this);
        }
    }

    getWorld(): WorldSingleton {
        return WorldSingleton.INSTANCE;
    }

    loadSpot(spot: InfinitySpot, boardToLook: string = "") {
        useActiveSpotZus.setState({activeSpot: spot});
        this.setState({activeSpot: this.activeSpot});

        WorldSingleton.INSTANCE.loadSpot(spot, boardToLook);
    }

    enterTheatreMode = (onEntered, onTheatreModeForceEnd) => {
        this.setState({theatreModeActive: true});

        WorldSingleton.INSTANCE.enterTheatreMode(onEntered, onTheatreModeForceEnd);
    };

    leaveTheatreMode = () => {
        this.setState({theatreModeActive: false});

        WorldSingleton.INSTANCE.leaveTheatreMode();
    };

    setFooter(imagePath: string) {
        WorldSingleton.INSTANCE.setFooter(imagePath);
    }

    playInModalHud(object: HTMLElement) {
        if (object) {
            useGlobalPopup.getState().pushElement(object);
        } else {
            useGlobalPopup.getState().popElement();
        }

        this.setState({modalcontent: object});
    }

    render() {
        return (
            <div
                ref={this.canvasRef}
                style={{
                    backgroundColor: "red",
                }}
            >
                {this.state.theatreModeActive == false ? (
                    <>
                        <ActiveTourRightBar
                            isTheatreMode={this.state.theatreModeActive}
                            playInWorldHud={(content) => this.playInModalHud(content)}
                        />
                        <Branding/>
                    </>
                ) : (
                    <Image
                        src={urlcWithPath("i-icons/cross_button.svg")}
                        style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                        }}
                        onClick={() => {
                            this.leaveTheatreMode();
                        }}
                    />
                )}

                {
                    LicenceManager.f360.can_see_minimap &&
                    <Minimap
                        onMaximapActive={(isVisible) => {
                            // this.drawActive = !isVisible
                        }}
                    />
                }


                <GlobalProgressBar/>
            </div>
        );
    }
}

export class WorldSingleton {
    static INSTANCE = new WorldSingleton();

    scene: three.Scene;

    camera: three.PerspectiveCamera;

    renderer: three.WebGLRenderer;

    primaryGroup: three.Group;
    secondaryGroup: three.Group;
    secondaryNotSelectableGroup: three.Group;
    secondaryNotSelectableAndNotRotatableGroup: three.Group;
    secondaryNotRotatedGroup: three.Group;

    theatreGroup: three.Group;

    selectedObject = null;

    activeBoards: Map = new Map();

    rayCaster: three.Raycaster = new three.Raycaster();
    rayCastPointer: three.Vector2 = new three.Vector2();

    controls: PanoramaCameraControl;

    activeSpot: InfinitySpot = null;

    oldRotationIndex = 0;
    activeRotationIndex = new three.Vector3();

    boardToLookAfterLoad = "";

    theatreModeActive = false;

    drawActive = true;

    onTheatreModeForceEnd: Function = null;

    worldMap: MaximapVR = null;

    clickEnabled = true;

    theatreModeActive = false;

    hideIgnoreMap = new Set();
    hidePressed = false;

    constructor() {
        console.log("Mountingg");

        this.scene = new three.Scene();

        //Camera initialization
        this.camera = new three.PerspectiveCamera(
            95,
            window.innerWidth / window.innerHeight,
            0.1,
            100000
        );

        this.camera.position.set(0, 0, 0); //Camera position has to be 0.01 for Orbital control

        this.primaryGroup = new three.Group();
        this.primaryGroup.name = "Primary group";
        this.secondaryGroup = new three.Group();
        this.secondaryGroup.name = "Secondary group";

        this.secondaryNotSelectableGroup = new three.Group();
        this.secondaryNotSelectableGroup.name = "Secondary not selectable group";

        this.secondaryNotRotatedGroup = new three.Group();
        this.secondaryNotRotatedGroup.name = "Secondary not rotated group";

        this.secondaryNotSelectableAndNotRotatableGroup = new three.Group();
        this.secondaryNotSelectableAndNotRotatableGroup.name =
            "Secondary not rotated and not selectable group";

        this.theatreGroup = new three.Group();
        this.theatreGroup.visible = false;

        const configurator = new DefaultSceneConfigurator();

        //Renderer intialization
        this.renderer = configurator.createRenderer();
        this.renderer.setClearColor(new three.Color("red"));

        console.log(this.renderer.capabilities.isWebGL2);

        configurator.configurePanoramaRenderer(this.renderer);
        configurator.addPanoramaLightning(this.primaryGroup);
        configurator.initEnviromentEqirectangularMap(
            this.scene,
            urlcWithPath("assets/textures/envMap.png")
        );

        this.scene.add(this.primaryGroup);
        this.scene.add(this.secondaryGroup);
        this.scene.add(this.secondaryNotSelectableGroup);
        this.scene.add(this.secondaryNotSelectableAndNotRotatableGroup);
        this.scene.add(this.secondaryNotRotatedGroup);
        this.scene.add(this.theatreGroup);

        this.reconstructPhotoDom(32);

        this.rayCaster = new three.Raycaster();
        this.rayCastPointer = new three.Vector2();

        this.controls = new PanoramaCameraControl(
            this.camera,
            this.renderer,
            this._log
        );
        this.controls.enable();
        // const orbit = new OrbitControls(this.camera, this.getDomElement())
        // orbit.target = new three.Vector3(0, 0, 1)
        window.addEventListener("resize", () => this._onWindowResize(), false);

        this._registerEvents();

        this.startAutoRender();

        this.vrAvatar = new InfinityVRAvatar(this);

        this.createDatGui();

        document.onkeydown = (evt) => {
            var isEscape = false;
            if ("key" in evt) {
                isEscape = evt.key === "Escape" || evt.key === "Esc";
            } else {
                isEscape = evt.keyCode === 27;
            }
            if (isEscape) {
                if (this.theatreModeActive) {
                    this.leaveTheatreMode();
                }
            }
        };

        if (DeviceManager.isOculus()) {
            this.worldMap = new MaximapVR();
        }
        //this.loadTestMonkeys()

        // this.scene.add(new three.AxesHelper(5))
    }

    setSecondaryGroupVisibility(visible: boolean) {
        this.hidePressed = !visible;

        if (this.hideIgnoreMap.size === 0) {
            this.activeBoards.forEach((board) => {
                board.setVisibilityStatus(visible);
            });
        } else {
            this.hideIgnoreMap.forEach((boardUid) => {
                const board = this.activeBoards.get(boardUid);
                if (board) {
                    board.setVisibilityStatus(visible);
                }
            });
        }
    }

    refreshVisibility() {
        console.log(this.hidePressed);
        if (this.hidePressed) {
            this.activeBoards.forEach((board) => {
                board.setVisibilityStatus(true);
            });

            this.hideIgnoreMap.forEach((boardUid) => {
                const board = this.activeBoards.get(boardUid);
                if (board) {
                    board.setVisibilityStatus(!this.hidePressed);
                }
            });
        }
    }

    selectBoardVisibility(boardUid: string, visibility: boolean) {
        console.log(this.activeBoards.has(boardUid));
        console.log(visibility);

        if (this.activeBoards.has(boardUid)) {
            this.activeBoards.get(boardUid).setVisibilityStatus(visibility);
        }
    }

    setSelectedBoardsGroupVisibility(group: string, visibility: boolean) {
        alert("Unimplemented");
    }

    reset() {
        this.theatreModeActive = false;
        this.clearActiveContent();
    }

    enterTheatreMode(onEntered, onTheatreModeForceEnd) {
        this.theatreModeActive = true;
        this.onTheatreModeForceEnd = onTheatreModeForceEnd;

        this.secondaryGroup.visible = false;
        this.secondaryNotSelectableGroup.visible = false;
        this.secondaryNotSelectableAndNotRotatableGroup.visible = false;
        this.secondaryNotRotatedGroup.visible = false;
        this.theatreGroup.visible = true;

        this.photoDom.startFading(onEntered);
    }

    leaveTheatreMode() {
        this.photoDom.endFade(() => {
            this.theatreModeActive = false;
            this.secondaryGroup.visible = true;
            this.secondaryNotSelectableGroup.visible = true;
            this.secondaryNotSelectableAndNotRotatableGroup.visible = true;
            this.secondaryNotRotatedGroup.visible = true;
            this.theatreGroup.visible = false;
        });

        this.activeBoards.forEach((board) => {
            board.leaveTheatreModeRequested();
        });
    }

    panoramaLoadingProgress = (value) => {
        GlobalProgressBarInstance.setValue(value);
    };

    _registerEvents() {
        //Wheel event
        const myDoom = this.getDomElement();

        myDoom.oncontextmenu = this.rightClicked;

        let previousMousePos = new three.Vector2(0, 0);

        const dragEnd = (event) => {
            this.controls.onLeftUp(event);
            this.setCursorGrab();
        };

        //TODO implement touch here
        //Pointer down
        myDoom.addEventListener("pointerdown", (event) => {
            if (event.which === 1) {
                previousMousePos = new three.Vector2(event.clientX, event.clientY);

                if (this.selectedObject === null || this.selectedObject.onlyClick) {
                    this.controls.onLeftDown(event);
                    this.setCursorGrabbing();
                } else {
                    const result = this._rayCast(event.clientX, event.clientY);

                    this._PointerDown(event, result);
                }
            }
        });

        myDoom.addEventListener("pointerup", (event: PointerEvent) => {
            const distanceVector = previousMousePos.sub(
                new Vector2(event.clientX, event.clientY)
            );

            const distance = distanceVector.length();

            if (distance < 10) {
                dragEnd(event);
                const result = this._rayCast(event.clientX, event.clientY);
                this._LeftClick(event, result);

                this._PointerUp(event);
            } else {
                dragEnd(event);
            }
        });

        myDoom.addEventListener("touchstart", (event) => {
            if (!this.selectedObject) {
                this.controls.onFingerDown(event);
            } else {
                this._FingerDown(event);
            }
        });

        myDoom.addEventListener(
            "touchmove",
            (event: PointerEvent) => {
                this.controls.onFingerMove(event);
            },
            {supportsPassive: false}
        );

        myDoom.addEventListener("touchend", (event: PointerEvent) => {
            if (!this.selectedObject) {
                this.controls.onFingerUp(event);
            }
        });

        if (DeviceManager.isAndroid() == false) {
            myDoom.addEventListener("pointermove", (event: PointerEvent) => {
                this._PointerMove(event);
                this.controls.onPointerMove(event);
            });
            myDoom.addEventListener("mouseout", (event: PointerEvent) => {
                if (this.controls.cameraRotationActive) {
                    this.controls.onLeftUp(event);
                }
            });
        }

        myDoom.addEventListener("wheel", (event) => {
            this.controls.onWheel(event);
        });

        myDoom.addEventListener("drop", (event) => {
            console.log("Image dropped");
        });
    }

    reconstructPhotoDom(moons: number) {
        console.log("Reconstructing photo dom for:" + moons + " moons");
        this.photoDom = new PhotoDom(
            this.renderer,
            this.camera,
            this.primaryGroup,
            {
                onLoadingProgress: this.panoramaLoadingProgress,
                moonsCount: moons,
            }
        );
    }

    rightClicked = (event: MouseEvent) => {
        event.preventDefault();
        if (this.selectedObject) {
            this.setCursorGrab();
        }
    };

    _recalculateMapPosition(position: three.Vector3) {
        const posX = position.x;
        const posY = position.z;

        const rotationRad = Math.atan2(posY, posX);

        console.log(three.MathUtils.radToDeg(rotationRad));
        return rotationRad;
    }

    worldMapSpawnOrHideRequested(
        position: three.Vector3,
        direction: three.Vector3
    ) {
        if (this.worldMap.root.visible) {
            this.worldMap.hide();
            this.secondaryNotRotatedGroup.remove(this.worldMap.root);
        } else {
            const root = this.worldMap.root;

            const rotationY = this._recalculateMapPosition(position);

            // direction.setY(0)
            // direction.normalize()
            //
            // const top = new three.Vector3(0, 1, 0)
            //
            // const right = new three.Vector3()
            // right.crossVectors(direction, top)
            //
            // const rotationMatrix = new three.Matrix4()
            //
            //
            //
            // rotationMatrix.set(
            //     direction.x, top.x, right.x, 0,
            //     direction.y, top.y, right.y, 0,
            //     direction.z, top.z, right.z, 0,
            //     0, 0, 0, 1
            // )

            // const newPosition = new three.Vector3()
            // newPosition.copy(position)
            // newPosition.normalize()
            //
            // console.log(newPosition)
            // newPosition.setY(0)
            //
            // var angle = newPosition.angleTo(new three.Vector3(1, 0, 0))
            // console.log(angle)
            // // root.rotation.set(0,0,0)
            // // root.rotation.setFromRotationMatrix(rotationMatrix)
            // root.rotation.set(0, angle, 0)
            // root.updateMatrixWorld(true)

            root.position.set(0, 0, 0);
            root.rotation.set(0, 0, 0);
            root.rotateY(rotationY);
            root.position.copy(position);

            // root.translateY(-0.2)
            // root.translateZ(-0.5)
            //

            this.worldMap.show(this.activeSpot);
            this.secondaryNotRotatedGroup.add(root);
        }
    }

    _rayCast(clientX: number, clientY: number): three.Intersection {
        if (this.controls.cameraRotationActive) {
            return;
        }

        const domElement = this.getDomElement();

        const rect = domElement.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;

        this.rayCastPointer.x = (x / domElement.clientWidth) * 2 - 1;
        this.rayCastPointer.y = (y / domElement.clientHeight) * -2 + 1;

        this.rayCaster.setFromCamera(this.rayCastPointer, this.camera);

        let intersects = [];

        if (this.theatreModeActive == false) {
            intersects = this.rayCaster.intersectObject(this.secondaryGroup, true);

            if (intersects.length === 0) {
                intersects = this.rayCaster.intersectObject(
                    this.secondaryNotRotatedGroup,
                    true
                );
            }
        } else {
            intersects = this.rayCaster.intersectObject(this.theatreGroup, true);
        }

        if (intersects.length > 0) {
            const filtered = intersects.filter((res) => {
                if (res.object.userData["handler"]) {
                    return res && res.object && res.object.visible;
                } else {
                    return false;
                }
            });

            let res = null;

            if (filtered.length > 0) {
                res = filtered[0];
            }

            if (res) {
                let component: any = res.object.userData["handler"];

                if (this.selectedObject === component) {
                    if (component.isSelected) {
                        if (component.isSelected(res)) {
                            this.setCursorPointer();
                        } else {
                            this.setCursorGrab();
                        }
                    } else {
                        this.setCursorPointer();
                    }

                    return res;
                } else if (this.selectedObject === null) {
                    if (component.isSelected) {
                        this.selectedObject = component;
                        if (component.isSelected(res)) {
                            this.setCursorPointer();
                            this.selectedObject.setSelected();
                        }

                    } else {
                        this.setCursorPointer();
                        this.selectedObject = component;
                        this.selectedObject.setSelected();
                    }
                } else {
                    this.selectedObject.setDeselected();
                    this.selectedObject = component;
                    this.selectedObject.setSelected();
                }

                return res;
            } else {
                if (this.selectedObject) {
                    this.selectedObject.setDeselected();
                }

                this.selectedObject = null;
                this.setCursorGrab();
            }
        } else {
            if (this.selectedObject != null) {
                this.selectedObject.setDeselected();
                this.selectedObject = null;
            }

            this.setCursorGrab();
            this.selectedObject = null;
        }
    }

    _FingerDown(event: TouchEvent): void {
        return;
        if (this.selectedObject) {
            //this.selectedObject.SetLeftDown()
        } else {
        }
    }

    _PointerDown(event: PointerEvent): void {
        if (event.isPrimary === false) {
            return;
        }

        if (this.controls.cameraRotationActive) {
            this.setCursorGrabbing();
        }

        if (this.selectedObject) {
            //this.selectedObject.SetLeftDown()
        } else {
        }
    }

    _LeftClick(event: PointerEvent, intersection: three.Vector3): void {
        if (this.selectedObject) {
            this.selectedObject.clicked(intersection);
            //Znova sa raycasti lebo niektore elementy mozu po kliku zmiznut
            this._rayCast(event.clientX, event.clientY);
        } else {
        }
    }

    _PointerUp(event: PointerEvent): void {
        if (event.isPrimary === false) {
            return;
        }
    }

    _PointerMove(event: PointerEvent) {
        this._rayCast(event.clientX, event.clientY);
    }

    _onWindowResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }

    GetSecondaryGroup(): three.Group {
        return this.secondaryGroup;
    }

    draw = () => {
        this.renderer.render(this.scene, this.camera);
    };

    getDomElement(): HTMLCanvasElement {
        return this.renderer.domElement;
    }

    step = () => {
        if (this.drawActive) {
            this.animate();
            this.photoDom.step();
            this.vrAvatar.step();
            TWEEN.update();
            this.draw();
        }
    };

    startAutoRender() {
        this.renderer.setAnimationLoop(this.step);
    }

    animate() {
        this.controls.update();
        this.activeBoards.forEach((board) => {
            board.step();
        });
        // console.log(this.camera.rotation.y)
    }

    enableFloorGrid(size = 10, divisions = 10) {
        this.scene.add(new three.GridHelper(size, divisions));
    }

    clearActiveContent() {
        this.activeBoards.forEach((value) => {
            value.CleanUp();
        });
        this.activeBoards.clear();
        this.secondaryGroup.clear();
        this.secondaryNotSelectableGroup.clear();
        this.secondaryNotSelectableAndNotRotatableGroup.clear();
        this.secondaryNotRotatedGroup.clear();

        this.theatreGroup.clear();
    }

    loadSpot(spot: InfinitySpot, boardToLook: string = "") {
        this.hideIgnoreMap.clear();
        this.hidePressed = false;

        if (SPOT_NOTIFIED_TIMEOUT) {
            clearTimeout(SPOT_NOTIFIED_TIMEOUT);
        }

        this.clearActiveContent();

        this.boardToLookAfterLoad = boardToLook;

        spot.wasVisited = true;

        if (this.activeSpot) {
            this.activeSpot.is_previewed = false;
            this.activeSpot.GetPanoramaAsset().is_previewed = false;
        }

        this.activeSpot = spot;
        this.activeSpot.is_previewed = true;

        const panoramaAsset = spot.GetPanoramaAsset();
        panoramaAsset.is_previewed = true;

        this.activeRotationIndex = this.activeSpot.spotRotation;

        this.setCursorWait();

        this.loadPanorama(panoramaAsset, this.afterPanoramaLoad);
    }

    lookAtBoardWithId(boardUid: string) {
        if (this.activeBoards.has(boardUid)) {
            const lookAt = new three.Vector3();
            const tmp: three.Vector3 = this.activeBoards
                .get(boardUid)
                .getFinalPosition();
            lookAt.copy(tmp);
            lookAt.multiply(new three.Vector3(-10, -10, -10));
            this.camera.lookAt(lookAt.x, lookAt.y, lookAt.z);
        }
    }

    setCursorPointer = () => {
        this.getDomElement().style.cursor = "pointer";
    };
    setCursorGrab = () => {
        if (!this.photoDom.isloading) {
            this.getDomElement().style.cursor = "grab";
        }
    };

    setCursorGrabbing = () => {
        if (!this.photoDom.isloading) {
            this.getDomElement().style.cursor = "grabbing";
        }
    };

    setCursorWait = () => {
        this.getDomElement().style.cursor = "wait";
    };

    loadPanorama(panorama: PanoramaAsset, afterPanoramaLoad = null) {
        GlobalProgressBarInstance.setValue(0);

        this.photoDom.loadPanoramaAsset(panorama, {
            onPanoramaFullyLoaded: afterPanoramaLoad,
            newPanoramaRotation: this.activeRotationIndex,
            rotationVector: this.activeSpot.rotationMatrixVector,
            colorCorrection: this.activeSpot.colorCorrection,
        });
        this.setCursorGrab();
    }

    afterPanoramaLoad = () => {
        this.setCursorGrab();

        const boards = Array.from(this.activeSpot.GetBoards().values());

        this.addBoards(boards);

        console.log(LicenceManager.f360.spot_visits_monitoring);

        if (LicenceManager.f360.spot_visits_monitoring) {
            SPOT_NOTIFIED_TIMEOUT = setTimeout(() => {
                TourStatisticsApi.saveSpotVisit(
                    this.activeSpot.parentProject.uid,
                    this.activeSpot.uid
                );
            }, 1000);
        }
    };

    addBoards(boards: InfinityBoardParent[]) {
        this.secondaryGroup.rotation.set(0, 0, 0);
        this.secondaryNotSelectableGroup.rotation.set(0, 0, 0);

        //this.secondaryNotSelectableGroup.rotation.set(0, 0, 0)

        let externallyControlledBoards: Set<string> = new Set();

        boards.forEach((board) => {
            if (board instanceof InfinityClickBoard) {
                externallyControlledBoards = new Set([
                    ...externallyControlledBoards,
                    ...board.click_group_boards,
                ]);
            }
        });
        boards.forEach((board) => {
            this.activeBoards.set(board.uid, board);
            if (externallyControlledBoards.has(board.uid)) {
                board._isEnabled = false
            }


            board.fillToWorld(this, externallyControlledBoards.has(board.uid));


        });

        this.secondaryNotSelectableGroup.rotateY(this.activeRotationIndex.y);
        this.secondaryGroup.rotateY(this.activeRotationIndex.y);
        //this.secondaryNotSelectableGroup.rotateY(this.activeRotationIndex.y)

        boards.forEach((board) => {
            board.worldRotated(this);
        });

        if (this.boardToLookAfterLoad != "") {
            this.lookAtBoardWithId(this.boardToLookAfterLoad);
            this.boardToLookAfterLoad = "";
        }
    }

    enableNorthHelper() {
        const dir = new three.Vector3(1, 0, 0);
        //normalize the direction vector (convert to vector of length 1)
        dir.normalize();

        const origin = new three.Vector3(0, 0, 0);
        const length = 0.25;
        const hex = 0xffff00;

        const arrowHelper = new three.ArrowHelper(dir, origin, length, hex);
        arrowHelper.position.y = -1;

        this.scene.add(arrowHelper);
    }

    setFooter(imagePath: string) {
        this.primaryGroup.add(new SceneFooter(imagePath));
    }

    resetCamera() {
        // const vector = new three.Vector3(0, 0, 1)
        // vector.applyAxisAngle(new three.Vector3(0, 1, 0), this.activeSpot.spotRotation.y)

        const angle = this.activeSpot.spotRotation.y;

        var radians = angle * (Math.PI / 180); // Convert angle to radians
        var x = 5 * Math.cos(radians); // Calculate x-coordinate
        var y = 5 * Math.sin(radians); // Calculate y-coordinate

        this.camera.lookAt(x, 0, y);
        // this.controls.lookAt(, 0, 1)
    }

    enableDebugSphere() {
        const geometry = new three.SphereGeometry(1, 64, 40);
        const material = new three.MeshBasicMaterial({color: 0x00ff00});

        const sphere = new three.Mesh(geometry, material);

        sphere.position.set(0, 0, 0);
        sphere.scale.set(10, 10, 10);

        this.primaryGroup.add(sphere);
    }

    createDatGui() {
        //this.photoDom.fillDatGui(DATGUI)
    }
}
