import * as three from "three"

import {InfinityBoardParent} from "./board_Parent";


import {Quiz, QuizAsset} from "../../ela/assets/asset_Quiz";
import {QuizPlayer} from "../../gui/content_players/quiz/QuizPlayer";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";
import {useGlobalAppLock} from "../../zustands/globalAppLockZus";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import LicenceManager from "../../api/LicenceManager";
import TourStatisticsApi, {SaveQuizParams} from "../../api/TourStatisticsApi";
import {shuffle} from "../../devtools/Arrays";


function shuffleQuiz(quiz: Quiz, shuffleQuestions: boolean, shuffleAnswers: boolean) {

    if (shuffleQuestions) {
        quiz.questions = shuffle(quiz.questions)
    }

    if (shuffleAnswers) {
        quiz.questions.forEach((value) => {

            value.answers = shuffle(value.answers)

        })
    }


}


export class InfinityQuizBoard extends InfinityBoardParent {

    type = "quiz"

    quiz: Quiz = null


    quizStartTime: Date = null

    quizDurationMilis: number = 0

    random_answers = false
    random_questions = false

    constructor(spot) {
        super(spot)
        this.icon = "EI.QuizBoard"
    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)

        this.random_answers = object.random_answers ?? false
        this.random_questions = object.random_questions ?? false

    }

    getQuizAsset(): QuizAsset {
        const asset = this.getParentProject().quizzes.get(this.contentUid)
        if (asset) {
            return asset
        }
        return null
    }


    getQuiz(): Quiz {
        const asset = this.getParentProject().quizzes.get(this.contentUid)
        if (asset) {
            return asset.quiz
        }

        return null
    }

    isContentValid(): boolean {
        return this.getQuiz()
    }


    clicked() {

        this.leaveVr()

        var quizToPlay = null

        if (this.quiz) {
            /* Quiz was already played */
            quizToPlay = this.quiz


            this.playQuiz(quizToPlay)


        } else {


            const originalQuizAsset = this.getQuizAsset()

            if (originalQuizAsset) {

                if (originalQuizAsset.isLoaded) {
                    /* Quiz was already loaded*/

                    const originalQuiz = originalQuizAsset.quiz

                    if (originalQuiz) {

                        this.quiz = originalQuiz.getCopy()

                        shuffleQuiz(this.quiz, this.random_answers, this.random_answers)
                        quizToPlay = this.quiz
                        this.playQuiz(quizToPlay)
                    }
                } else {
                    /* Quiz was never loaded*/

                    useGlobalAppLock.getState().lock()

                    originalQuizAsset.sub_load_async().then(() => {
                        useGlobalAppLock.getState().unlock()

                        const originalQuiz = originalQuizAsset.quiz

                        this.quiz = originalQuiz.getCopy()
                        shuffleQuiz(this.quiz, this.random_answers, this.random_answers)
                        quizToPlay = this.quiz

                        this.playQuiz(quizToPlay)

                    })
                }
            } else {
                window.alert("No quiz assigned here")
            }


        }
    }

    playQuiz(quizToPlay: Quiz) {
        if (quizToPlay) {

            if (quizToPlay.questions.length == 0) {
                window.alert("No answers to play")
            } else {

                this.quizStartTime = new Date()

                useGlobalPopup.getState().pushElement(
                    <QuizPlayer
                        activeQuiz={quizToPlay}
                        usesCustomUser={this.parentSpot.parentProject.web_props.quizSettings.custom_user_id}
                        project={this.getParentProject()}
                        onClose={(userIdentifier) => {
                            useGlobalPopup.getState().popElement()
                            if (this.isCompleted) {

                            } else {
                                this.quizFinished(userIdentifier)
                            }
                        }}

                    />
                )


            }

        } else {
            window.alert("Sorry no quiz on this page")
        }
    }

    quizFinished = (userIdentifier) => {

        const status = this.quiz.getSuccessRate()

        if (this.parentSpot.parentProject.web_props.quizSettings.repeat_enabled === true) {

        } else {
            if (status == 1) {
                this.iconColorModifier = new three.Color(0, 1, 0)
                this.refreshVisualization()
            } else {
                this.iconColorModifier = new three.Color(1, 0, 0)
                this.refreshVisualization()
            }
        }

        if (LicenceManager.f360.quizzes_monitoring) {
            this.saveQuizToStatistics(userIdentifier)
        }

        super.fillBoardVisited(status)

        GuidedTourManager.taskBoardClicked(this, status)

        this.refreshVisualization()
    }

    saveQuizToStatistics(customUserIdentifier) {

        const resultJson = this.quiz.to_json_result()

        const parentProject = this.parentSpot.parentProject

        const saveParams = new SaveQuizParams()

        const startTime = this.quizStartTime;
        const endTime = new Date();


        saveParams.project_type = parentProject.type
        saveParams.project_uid = parentProject.uid
        saveParams.project_name = parentProject.name
        saveParams.spot_name = this.parentSpot.name
        saveParams.spot_uid = this.parentSpot.uid
        saveParams.board_name = this.name
        saveParams.board_uid = this.uid
        saveParams.success = resultJson["success"]
        saveParams.run_uid = parentProject.run_token
        saveParams.duration = endTime - startTime
        saveParams.user_uid = customUserIdentifier

        TourStatisticsApi.saveQuiz(saveParams, resultJson)
    }

    getPercentage() {
        if (this.quiz) {
            return this.quiz.getSuccessRate()
        } else {
            return 0
        }
    }


}