import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ViewAsMUISelect({
                                            viewAs,
                                            setViewAs
                                        }) {

    const [t] = useTranslation()

    return (
        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.viewas")}:</InputLabel>
            <Select
                value={viewAs}
                label={"ViewAs"}
                onChange={(e: SelectChangeEvent) => {
                    setViewAs(e.target.value as string)
                }}
            >
                <MenuItem value={"chart"}>{t("core.chart")}</MenuItem>
                <MenuItem value={"table"}>{t("core.table")}</MenuItem>
            </Select>
        </FormControl>

    )
}


interface ViewAsCustomMUISelectProps {
    viewAs: string
    setViewAs: (newValue: string) => void
    custom: Array<[string, string]>
}

export function ViewAsCustomMUISelect({
                                          viewAs,
                                          setViewAs,
                                          custom
                                      }) {

    const [t] = useTranslation()

    return (
        <FormControl style={{
            minWidth: "120px"
        }}>
            <InputLabel>{t("core.viewas")}:</InputLabel>
            <Select
                value={viewAs}
                label={"ViewAs"}
                onChange={(e: SelectChangeEvent) => {
                    setViewAs(e.target.value as string)
                }}
            >
                {
                    custom.map((value) => {
                        return (
                            <MenuItem key={value[0]} value={value[0]}>{t(value[1])}</MenuItem>
                        )
                    })
                }

            </Select>
        </FormControl>

    )
}