import MeshElement from "./MeshElement";
import * as three from "three";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min"


export default class IconClickPlane extends MeshElement {


    material: three.MeshBasicMaterial
    mesh: three.Mesh
    meshB: three.Mesh
    fontGroup: three.Group
    root: three.Group
    colorModifier = new three.Color("white")


    constructor(texture: three.Texture, options = {
        transparencySafe: false,
        color: new three.Color("white")
    }) {

        super()

        if (options.color) {
            this.colorModifier = options.color
        }

        this.material = new three.MeshBasicMaterial({
            map: texture,
            transparent: !options.transparencySafe,
            depthWrite: false,
            depthTest: false,
        })
        this.material.color.copy(this.colorModifier)

        let geometry = null

        if (options.transparencySafe) {
            geometry = new three.CircleGeometry(1, 64)
        } else {
            geometry = new three.PlaneGeometry(1, 1)
        }
        this.mesh = new three.Mesh(geometry, this.material)
        this.mesh.userData["handler"] = this
        this.mesh.renderOrder = 999

        this.meshB = new three.Mesh(geometry, this.material)
        this.meshB.userData["handler"] = this
        this.meshB.renderOrder = 999
        this.meshB.rotateY(Math.PI)

        this.fontGroup = new three.Group()
        this.fontGroup.position.set(0, 0.5, 0)

        // const sphereG = new three.BoxGeometry()
        // const sphereM = new three.Mesh(sphereG, new three.MeshBasicMaterial())
        // sphereM.scale.set(0.1, 0.1, 0.1)
        // sphereM.position.set(0, 0.05, 0)
        // this.fontGroup.add(sphereM)

        this.root = new three.Group()
        this.root.add(this.fontGroup)
        this.root.add(this.mesh)
        this.root.add(this.meshB)

    }

    setColorObject(obj: three.Color) {
        this.colorModifier = obj
    }


    isSelected(intersection: three.Intersection) {
        return true
    }

    setSelected() {
        this.material.color.set('#9e9e9e');
        super.setSelected()

    }

    setDeselected() {
        this.material.color.copy(this.colorModifier)
        super.setDeselected()

    }

    position_set(x: number, y: number, z: number) {
        this.root.position.set(x, y, z)
    }

    scale_set(_x: number, _y: number, _z: number) {
        this.root.scale.set(_x, _y, _z)

        this.mesh.material.opacity = 0
        this.meshB.material.opacity = 0

        new TWEEN.Tween({x: 0}).to({x: 1}, 750).onUpdate((newValue) => {
            this.mesh.material.opacity = newValue.x
            this.meshB.material.opacity = newValue.x
        }).start()

    }

    lookAt(x: number, y: number, z: number) {
        this.root.lookAt(x, y, z)
    }

    setMatrix(matrix: three.Matrix4) {
        this.root.rotation.setFromRotationMatrix(matrix)
        this.root.updateMatrixWorld(true)

    }

    getRoot() {
        return this.root
    }


}