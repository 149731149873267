import * as three from "three";
import { MeshAsset } from "../ela/assets/asset_Mesh";
// import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { InfinityProject } from "./InfinityProject";

import { GLTFLoader } from "./GLTFLoader";
import urlcWithPath from "../urlc";

export class InfinityMeshesLoader {
  constructor(project: InfinityProject) {
    this.project = project;
  }

  LoadMesh(meshAsset: MeshAsset, onLoadCallBack: Function) {
    if (meshAsset.file_extension === "fbx") {
      this._LoadFbx(meshAsset, onLoadCallBack);
    } else if (
      meshAsset.file_extension == "glb" ||
      meshAsset.file_extension == "gltf"
    ) {
      this._LoadGlb(meshAsset, onLoadCallBack);
    }
  }

  _LoadGlb(meshAsset: MeshAsset, onLoadCallBack: Function) {
    const gltfLoader = new GLTFLoader();
    console.log("Before load");
    gltfLoader.load(urlcWithPath(meshAsset.getAbsolutePath()), (object) => {
      //object.scene.scale.set(5 ,5 , 5)
      this._SetProbeRecursive(object.scene);

      if (onLoadCallBack) {
        onLoadCallBack("glb", object);
      }
    });
  }

  _SetProbeRecursive(object: three.Mesh) {
    object.children.forEach((child: three.Mesh) => {
      if (child.isMesh) {
        let material: three.MeshStandardMaterial = child.material;
        //material.envMapIntensity = 5
        material.envMap = this.project.getWorld().scene.environment;
      }

      this._SetProbeRecursive(child);
    });
  }

  _LoadFbx(meshAsset: MeshAsset, onLoadCallBack: Function) {
    const fbxLoader: FBXLoader = new FBXLoader();
    const path = urlcWithPath(meshAsset.getAbsolutePath());
    // console.error("Loading fbx: " + path)
    fbxLoader.load(urlcWithPath(path), (object) => {
      object.scale.set(0.01, 0.01, 0.01);

      // this.mixer = new THREE.AnimationMixer(object)

      // const animationAction = this.mixer.clipAction(
      //     (object as THREE.Object3D).animations[0]
      // )
      // placeHolder.add(object)
      //Mesh assignment
      // this.mesh = object
      //
      // this.placeHolder.add(this.mesh)
      // //
      // this.delayedScene.add(this.mesh)
      // this.delayedScene = null

      // animationActions.push(animationAction)
      // activeAction = animationActions[0]
      // activeAction.reset()
      // activeAction.play()

      if (onLoadCallBack) {
        onLoadCallBack("fbx", object);
      }
    });
  }
}
