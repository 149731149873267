import React from "react";

import "./VideoPlayer.css";
import { CloseButton } from "../../icon_click_buttons";

//Youtube videos tutorial
//https://www.youtube.com/watch?v=Rq5SEhs9lws

export function VideoPlayer({ uid, videoPath, onEnded, onClose }) {
  console.log(videoPath);

  if (videoPath === "") {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            width: "25px",
            height: "25px",
            top: "0px",
            right: "0px",
          }}
        >
          <CloseButton onClick={onClose} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={"auto-resizable-iframe"}>
        <video
          id={uid}
          autoPlay
          controls
          controlsList="nodownload"
          onEnded={onEnded}
        >
          <source
            style={{ objectFit: "cover" }}
            src={videoPath}
            type={"video/mp4"}
          />
        </video>
      </div>

      <div
        style={{
          position: "absolute",
          width: "25px",
          height: "25px",
          top: "0px",
          right: "0px",
        }}
      >
        <CloseButton onClick={onClose} style={{}} />
      </div>
    </>
  );
}
