import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import UserSelectOffcavnas from "../users/SelectUserOffcanvas";
import {Image} from "react-bootstrap";
import DashBoardTile from "../dashboard/DashBoardTile";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import urlcWithPath from "../../urlc";


interface SelectUserBarButtonProps {
    activeUser: string
    setActiveUser: (trainer: string) => void
    disableClearUser?: boolean
}

export function SelectUserView({activeUser, setActiveUser, disableClearUser}: SelectUserBarButtonProps) {

    const [t] = useTranslation()

    const popupZus = useGlobalPopup()

    const selectUserPressed = () => {
        popupZus.pushElement(
            <UserSelectOffcavnas onHide={popupZus.popElement} onUserSelected={(user) => {
                setActiveUser(user)
                popupZus.popElement()
            }}/>
        )
    }

    return (
        <>h
            <div>{t("core.user")}:</div>

            <button
                className={"btn btn-primary"}
                onClick={selectUserPressed}
                style={{
                    minWidth: "50px",
                    whiteSpace: "nowrap"
                }}>
                {
                    activeUser ? activeUser : "--"
                }
            </button>

            {
                (!disableClearUser && activeUser !== "") &&
                <Image style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                }}
                       onClick={() => {
                           setActiveUser("")
                       }}
                       src={urlcWithPath("i-icons/cross-red.svg")}

                />
            }

        </>
    )
}

export function SelectUserMUISelect({activeUser, setActiveUser, disableClearUser}: SelectUserBarButtonProps) {

    const [t] = useTranslation()

    const popupZus = useGlobalPopup()

    const selectUserPressed = () => {
        popupZus.pushElement(
            <UserSelectOffcavnas onHide={popupZus.popElement} onUserSelected={(user) => {
                setActiveUser(user)
                popupZus.popElement()
            }}/>
        )
    }

    return (
        <>

            <FormControl style={{
                minWidth: "120px"
            }}>
                <InputLabel>{t("core.user")}:</InputLabel>
                <Select
                    value={"0"}
                    label={"project"}
                    open={false}
                    onOpen={selectUserPressed}
                >
                    <MenuItem value={"0"}>
                        {activeUser ? activeUser : "    --    "}
                    </MenuItem>
                </Select>
            </FormControl>

            {
                (!disableClearUser && activeUser !== "") &&
                <Image style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                }}
                       onClick={() => {
                           setActiveUser("")
                       }}
                       src={urlcWithPath("i-icons/cross-red.svg")}

                />
            }

        </>
    )
}

