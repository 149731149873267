import React from "react";
import TourStatisticsApi, {QuizRecordResponse} from "../../api/TourStatisticsApi";
import {useTranslation} from "react-i18next";
import MyTable from "../../components/mytable";
import {useGlobalPopup} from "../../zustands/globalPopupZus";
import {formatPercentage} from "../../formatters/percentage";

import {Modal, Spinner, Table} from "react-bootstrap";

import {InfinityModal} from "../../components/modals";

import {QuizAsset} from "../../ela/assets/asset_Quiz";

import {useGlobalAppLock} from "../../zustands/globalAppLockZus";

import AnswerBase, {QuestionBase} from "../../ela/quiz/QuestionBase";
import QuizzesRatingManager from "./QuizzesRatingManager";


interface WorstQuizzesViewProps {
    data: Array<QuizRecordResponse>
}


export default function QuizzesRatingView({data}: WorstQuizzesViewProps) {

    const [t] = useTranslation()

    const [isLoading, setIsLoading] = React.useState(false)
    const [calculatedResult, setCalculatedResult] = React.useState<Array<QuizRecordResponse>>([])

    React.useEffect(() => {
        setIsLoading(true)
        QuizzesRatingManager.SortQuizzesDueToSuccess(data).then((result) => {
            setCalculatedResult(result)
            setIsLoading(false)
        })
    }, [data])

    return (
        <>
            <h3 style={{textAlign: "center"}}>{t("core.quiz.rating")}</h3>

            {
                isLoading ? <Spinner variant={"danger"}/> : <_StatisticsViewList data={calculatedResult}/>
            }

        </>
    )
}

function _StatisticsViewList({data}: { data: Array<QuizRecordResponse> }) {

    const [t] = useTranslation()

    return (

        <MyTable>
            <thead>
            <tr>
                <th>Id</th>
                <th>{t("core.board.name")}</th>
                <th>{t("core.success.average")}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                data.map((value, index) => {
                    return (<_QuizRecordResponseTableRow index={index} item={value} key={value.RowKey}/>)
                })
            }

            </tbody>
        </MyTable>

    )
}

function _QuizRecordResponseTableRow({item, index}: { item: QuizRecordResponse, index: number }) {

    const [t] = useTranslation()

    const popup = useGlobalPopup()

    const openClicked = (projectUid: string, spotUid: string, boardUid: string) => {

        popup.pushElement(<_QuizResultViewModal
                onHide={popup.popElement}
                projectUid={projectUid}
                spotUid={spotUid}
                boardUid={boardUid}
            />
        )
    }

    return (
        <tr>
            <td>{index + 1}.</td>
            <td>{item.BoardName}</td>
            <td>{formatPercentage(item.Success)}</td>
            <td>
                <button
                    className={"btn btn-outline-primary btn-sm"}
                    onClick={() => {

                        openClicked(item.PartitionKey, item.SpotUid, item.BoardUid)
                    }}

                >
                    {t("core.open")}
                </button>
            </td>
        </tr>
    )
}

class UsersSelectedAnswer {
    answerText = ""
    validity = false
    selectionCount = 0
}


class UsersSelectedAnswerResult {
    questionName = ""
    answers = new Array<UsersSelectedAnswer>()


}


function _calculateQuizPercentage(data: Array<any>): Array<UsersSelectedAnswerResult> {

    const questions = new Map<string, Map<string, [boolean, number]>>()

    data.forEach((quizRecordString) => {
        const nQuiz = QuizAsset.getQuizFromJsonWithResult(JSON.parse(quizRecordString))

        nQuiz.questions.forEach((value) => {

            const tmpQuestion = questions.get(value.name)

            let tmpAnswersDict = new Map<string, [boolean, number]>()

            //Osetrenie toho aby dictionary bol prazdny
            if (tmpQuestion) {
                tmpAnswersDict = tmpQuestion
            } else {
                questions.set(value.name, tmpAnswersDict)
            }

            value.answers.forEach((answer: AnswerBase) => {

                const answersCounter = tmpAnswersDict.get(answer.text)

                if (answersCounter) {
                    if (answer.isSelected) {

                        tmpAnswersDict.set(answer.text, [answer.validity, answersCounter[1] + 1])
                    }
                } else {
                    if (answer.isSelected) {
                        tmpAnswersDict.set(answer.text, [answer.validity, 1])
                    } else {
                        tmpAnswersDict.set(answer.text, [answer.validity, 0])
                    }
                }
            })

        })

    })

    let result = new Array<UsersSelectedAnswerResult>()

    questions.forEach((value, key) => {
        const tmp = new UsersSelectedAnswerResult()
        tmp.questionName = key


        value.forEach((answerVal, answerName) => {

            const usersSelectedAnswer = new UsersSelectedAnswer()
            usersSelectedAnswer.answerText = answerName
            usersSelectedAnswer.validity = answerVal[0]
            usersSelectedAnswer.selectionCount = answerVal[1]

            tmp.answers.push(usersSelectedAnswer)

        })

        result.push(tmp)
    })


    return result
}

function _QuizResultViewModal({onHide, projectUid, spotUid, boardUid}) {

    const [t] = useTranslation()

    const lockZus = useGlobalAppLock()

    const [result, setResult] = React.useState<Array<UsersSelectedAnswerResult> | any>(null)

    React.useEffect(() => {

        lockZus.lock()

        TourStatisticsApi.getAllSameQuizesWithIdentifiers(projectUid, spotUid, boardUid).then((value) => {
            setResult(_calculateQuizPercentage(value))
            lockZus.unlock()
        })

    }, [])


    if (result) {
        return (
            <InfinityModal
                closeButton
                size={"xl"}
                onHide={onHide}
                headerChildren={<Modal.Title>{t("core.result")}</Modal.Title>}
                bodyChildren={<_QuizResultViewBody data={result}/>}
                footerChildren={<div style={{height: "25px"}}/>}
            />
        )
    } else {

        return (
            <></>
        )

    }

}

function _QuizResultViewBody({data}: { data: Array<UsersSelectedAnswerResult> }) {


    const [t] = useTranslation()

    return (
        <>
            <div className={"vstack gap-3"}>
                {
                    data.map((question, index) => {

                        return (
                            <div key={index}>

                                <h5 style={{textAlign: "center"}}>{question.questionName}</h5>

                                <MyTable>
                                    <thead>

                                    <tr>
                                        <th>Id</th>
                                        <th>{t("core.answer.text")}</th>
                                        <th>{t("core.question.validity")}</th>
                                        <th>{t("core.question.selected")}</th>
                                    </tr>

                                    </thead>

                                    <tbody>

                                    {
                                        question.answers.map((value, index) => {


                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}.</td>
                                                    <td>{value.answerText}</td>
                                                    <td>
                                                        <_ValidityCell valid={value.validity}/>
                                                    </td>
                                                    <td>
                                                        {value.selectionCount === 0 ? "" : value.selectionCount}
                                                    </td>
                                                </tr>
                                            )

                                        })
                                    }

                                    </tbody>
                                </MyTable>
                            </div>
                        )

                    })
                }

            </div>

        </>
    )

}


function _ValidityCell({valid}) {

    if (valid) {
        return (
            <div style={{
                margin: "auto",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "lightgreen"
            }}/>
        )
    } else {
        return null
    }
}

