import {InfinityBoardParent} from "./board_Parent";

import React from "react";
import {GuidedTourManager} from "../../zustands/activeTestTourZus";

export class InfinityFinishBard extends InfinityBoardParent {
    type = "finish"

    constructor(spot) {
        super(spot)
        this.icon = "VT.Finish"

    }

    loadFromJsonObject(object) {
        super.loadFromJsonObject(object)
    }

    fillToWorld(world) {
        super.fillToWorld(world);
    }

    clicked = () => {
        GuidedTourManager.taskBoardClicked(this, 1)

        const activeProject = this.getActivePlayer().activeProject

        window.location.replace("/")

    }


}