import React from "react";
import {Offcanvas, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import SettingsGeneral from "./SettingsGeneral";
import Settings360 from "./Settings360";
import {InfinityOffcanvasWrapper} from "../components/offcanvas";


export default function SettingsOffcanvas({onHide}) {

    const [t] = useTranslation()

    return (
        <InfinityOffcanvasWrapper
            onHide={onHide}
            closeButton={true}
            header={<Offcanvas.Title>{t("core.settings")}</Offcanvas.Title>}
            body={<SettingsView/>}
        />

    )
}


export function SettingsView() {

    const [t] = useTranslation()


    return (
        <>
            <Tabs defaultActiveKey={"general"}
                  className="mb-3"
                  fill>

                <Tab title={t("core.settings.general")} eventKey={"general"}>
                    <SettingsGeneral/>
                </Tab>

                <Tab title={t("core.settings.projects360")} eventKey={"projects360"}>
                    <Settings360/>
                </Tab>

                {/*{*/}
                {/*    LicenceManager.editorArValid &&*/}
                {/*    <Tab title={t("core.settings.projectsAr")} eventKey={"projectsAr"}>*/}
                {/*        <SettingsAr/>*/}
                {/*    </Tab>*/}
                {/*}*/}


            </Tabs>

        </>
    )
}
