export default class WebProjectProperties {

    quizSettings = new WebProjectQuizSeetings()


    ToJson() {
        return {
            "quiz_settings": this.quizSettings.ToJson()
        }
    }

    FromJson(jobj: any) {
        this.quizSettings.FromJson(jobj.quiz_settings ?? {})

    }


}


export class WebProjectQuizSeetings {

    custom_user_id = false
    repeat_enabled = false

    ToJson() {
        return {
            "custom_user_id": this.custom_user_id,
            "repeat_enabled": this.repeat_enabled
        }
    }

    FromJson(jobj: any) {
        this.custom_user_id = jobj.custom_user_id ?? false
        this.repeat_enabled = jobj.repeat_enabled ?? false
    }
}