import React from "react";

import { useTranslation } from "react-i18next";

import { InfinityProjectFetch } from "../infinity/InfinityProject";
import {
  Col,
  Container,
  Form,
  Modal,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";

import UploadProjectView from "./projects/UploadProjectView";
import ProjectAdminThumbnail from "./projects/ProjectAdminThumbnail";

import ManageProjectGroupOffCanvas from "./projects/ProjectGroups";
import IconClickButton from "../components/IconClickButton";
import ProjectsApi from "../api/ProjectsApi";
import ProjectGroupsApi from "../api/ProjectGroupsApi";
import { useActiveUserZus } from "../zustands/activeUserZus";

import { useAvailableProjectGroups } from "../zustands/projectGroupsZus";
import { useAvailableProjectsZus } from "../zustands/availableProjectsZus";
import { OnlySuperAdminView } from "../components/user_dispatcher";
import { BasicMiddleSpinner } from "../components/spinners";
import { ViewContainer } from "../shared/containers";
import { showYesNoModal } from "../components/AreYouSurePopup";
import urlcWithPath from "../urlc";

export default function ProjectsAdminViewWrapper() {
  const [projectsLoading, setProjectsLoading] = React.useState(false);
  const [groupsLoading, setGroupsLoading] = React.useState(false);

  React.useEffect(() => {
    setProjectsLoading(true);
    setGroupsLoading(true);

    ProjectsApi.refreshZustand().then(() => [setProjectsLoading(false)]);

    ProjectGroupsApi.refreshZustand(false).then(() => {
      setGroupsLoading(false);
    });
  }, []);

  if (projectsLoading && groupsLoading) {
    return (
      <ViewContainer>
        <Container>
          <BasicMiddleSpinner />
        </Container>
      </ViewContainer>
    );
  } else {
    return <ProjectsAdminView />;
  }
}

const ProjectsAdminView = () => {
  const availableProjectsZus = useAvailableProjectsZus();
  const availableGroupsZus = useAvailableProjectGroups();
  const activeUser = useActiveUserZus();

  const [visibleProjects, setVisibleProjects] = React.useState<
    Map<string, any>
  >(new Map());

  const refreshProjects = () => {
    const tmpMap: Map<string, Array<InfinityProjectFetch>> = new Map();

    if (availableProjectsZus.availableProjects) {
      availableProjectsZus.availableProjects.forEach((project) => {
        if (tmpMap.has(project.project_group)) {
          tmpMap.get(project.project_group).push(project);
        } else {
          tmpMap.set(project.project_group, [project]);
        }
      });

      setVisibleProjects(tmpMap);
    } else {
      console.error("availableProjectsZus.availableProjects are somehow null");
    }
  };

  React.useEffect(refreshProjects, [
    availableProjectsZus.availableProjects,
    availableGroupsZus.availableGroups,
  ]);

  const recalculateProjectsConfirmed = () => {
    ProjectsApi.refreshProjects(true).then(() => {
      ProjectsApi.refreshZustand();
    });
  };

  const recalculateProjectsPressed = () => {
    showYesNoModal(
      "core.confirm",
      "core.refresh.project.whattodo",
      recalculateProjectsConfirmed
    );
  };

  const { t, _ } = useTranslation();

  return (
    <ViewContainer>
      <Container>
        <Stack gap={4}>
          <Stack
            direction={"horizontal"}
            gap={3}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            {/*<StreamFileUploadTest/>*/}

            <UploadProjectButton />
            {
              <OnlySuperAdminView>
                <ManageProjectGroupsButton />
                <IconClickButton
                  icon={urlcWithPath("i-icons/reset_camera.svg")}
                  onclick={recalculateProjectsPressed}
                  lang={"core.project.refresh"}
                />
              </OnlySuperAdminView>
            }
          </Stack>

          {availableGroupsZus.availableGroups.map((group) => {
            return (
              <ProjectsInGroupView
                key={group.uid}
                groupId={group.uid}
                groupName={group.name}
                mapPool={visibleProjects.get(group.uid)}
              />
            );
          })}
        </Stack>
      </Container>
    </ViewContainer>
  );
};

function ProjectsInGroupView({ groupId, groupName, mapPool }) {
  const [t] = useTranslation();

  const _mapPool: Array<InfinityProjectFetch> = mapPool ? mapPool : [];

  return (
    <div>
      <h3>{groupName}</h3>
      <Stack gap={3}>
        {_mapPool.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            {t("core.noprojectsingroup")}
          </div>
        ) : (
          <>
            <Row xs={1} md={4} className="g-4">
              {mapPool.map((value) => {
                return (
                  <Col key={value.uid}>
                    <ProjectAdminThumbnail
                      fakeProject={value}
                      route={"/project/" + value.uid}
                      icon={urlcWithPath(
                        "infinity/" +
                          value.uid +
                          "/Panoramas/" +
                          value.welcome_panorama +
                          "/preview.png"
                      )}
                      activeGroup={groupId}
                      beeingDeleted={value.health === "deleting"}
                    />
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        <hr style={{ color: "rgba(255, 0, 0, 0.45)" }} />
      </Stack>
    </div>
  );
}

const ManageProjectGroupsButton = () => {
  const [offCanvasVisible, setOffCanvasVisible] = React.useState(false);
  return (
    <>
      <IconClickButton
        icon={urlcWithPath("i-icons/hierarchy.svg")}
        onclick={() => setOffCanvasVisible(true)}
        lang={"core.manage.projectgroups"}
      />

      {offCanvasVisible && (
        <ManageProjectGroupOffCanvas
          show={offCanvasVisible}
          onHide={() => setOffCanvasVisible(false)}
        />
      )}
    </>
  );
};

const UploadProjectButton = () => {
  const [offCanvasVisible, setOffCanvasVisible] = React.useState(false);
  return (
    <>
      <IconClickButton
        icon={urlcWithPath("i-icons/upload.svg")}
        onclick={() => setOffCanvasVisible(true)}
        lang={"core.project.upload"}
      />
      <UploadProjectModal
        show={offCanvasVisible}
        onHide={() => setOffCanvasVisible(false)}
      />
    </>
  );
};

const UploadProjectModal = ({ show, onHide }) => {
  const [t, _] = useTranslation();

  const [projectToUpdate, setProjectToUpdate] = React.useState({
    projectId: "",
    projectFiles: [],
  });

  const [closeEnabled, setCloseEnabled] = React.useState(true);
  const [uploadFinished, setUploadFinished] = React.useState(false);

  const filesChanged = (event) => {
    const files = Array.from(event.target.files)
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    const map: Map<string, File> = new Map();

    if (files.length > 0) {
      const split: [] = files[0].webkitRelativePath.split("/");

      if (split.length > 1) {
        files.forEach((value) => {
          map.set(value.webkitRelativePath, value);
        });

        setProjectToUpdate({
          projectId: split[0],
          projectFiles: map,
        });
      }
    }
  };

  const uploadStarted = () => {
    setCloseEnabled(false);
  };

  const hide = () => {
    setProjectToUpdate({
      projectId: "",
      projectFiles: [],
    });
    onHide();
  };

  const afterEnd = () => {
    setUploadFinished(false);
    setProjectToUpdate({
      projectId: "",
      projectFiles: [],
    });
  };

  const uploadEnded = () => {
    setCloseEnabled(true);
    setUploadFinished(true);
    ProjectsApi.refreshZustand();
    afterEnd();
    hide();
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        if (closeEnabled) {
          hide();
        }
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={closeEnabled} className="custom-white">
        <Offcanvas.Title>{t("core.project.upload")}</Offcanvas.Title>
      </Modal.Header>

      {uploadFinished === true && (
        <>
          <div>{t("core.upload.finished")}</div>
        </>
      )}

      {uploadFinished === false && (
        <>
          <Modal.Body className="custom-white">
            <Stack gap={3}>
              <div className="picker">
                <Form.Control
                  type={"file"}
                  id="picker"
                  webkitdirectory={"true"}
                  multiple
                  name="fileList"
                  onChange={filesChanged}
                  disabled={!closeEnabled}
                />
              </div>
              {projectToUpdate.projectFiles.size > 0 && (
                <UploadProjectView
                  projectId={projectToUpdate.projectId}
                  filesMap={projectToUpdate.projectFiles}
                  onUploadStarted={uploadStarted}
                  onUploadEnded={uploadEnded}
                />
              )}
            </Stack>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
