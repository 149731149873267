import React from "react";
import {useTranslation} from "react-i18next";
import {InfinityOffcanvasWrapper} from "../offcanvas";
import {Offcanvas} from "react-bootstrap";
import {useAvailableUsers} from "../../api/UsersApi";
import {BasicMiddleSpinner} from "../spinners";
import {BasicStateSearchInput} from "../searchInput";
import MyTable from "../mytable";


export default function UserSelectOffcavnas({onHide, onUserSelected}) {
    const [t] = useTranslation()

    return (
        <InfinityOffcanvasWrapper
            closeButton={true}
            onHide={onHide}
            mainStyle={{
                width: "700px"
            }}
            header={<Offcanvas.Title>{t("core.users")}:</Offcanvas.Title>}
            body={<_UserSelectView onUserSelected={onUserSelected}/>}
        />
    )
}

function _UserSelectView({onUserSelected}) {

    const searchRef = React.useRef()

    const [t] = useTranslation()

    const [searchValue, setSearchValue] = React.useState("")

    const [users, isLoading] = useAvailableUsers()

    const [visibleUsers, setVisibleUsers] = React.useState<Array<any>>([])

    React.useEffect(() => {
        setVisibleUsers(users)
    }, [users])

    const searchTyping = (e) => {

        if (e !== "") {
            setVisibleUsers(users.filter(val => val.email.search(e) != -1))
        } else {
            setVisibleUsers(users)
        }
        setSearchValue(e)

    }


    if (isLoading) {
        return (<BasicMiddleSpinner/>)
    } else {
        return (
            <div className={"vstack gap-3"}>

                <BasicStateSearchInput
                    searchKey={searchValue}
                    searchTyping={searchTyping}
                />

                <MyTable>
                    <thead>
                    <tr>
                        <th>{t("core.name")}</th>
                        <th>{t("core.email")}</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        visibleUsers.map((value, index) => {
                            return (
                                <tr key={value.email}>
                                    <td>{value.name}</td>
                                    <td>{value.email}</td>
                                    <td>
                                        <button
                                            className={"btn btn-outline-primary btn-sm"}
                                            onClick={() => {
                                                onUserSelected(value.email)
                                            }}
                                        >
                                            {t("core.select")}
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>

                </MyTable>
            </div>
        )

    }


}


