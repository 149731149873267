import {useGlobalAppLock} from "../zustands/globalAppLockZus";
import {AxiosResponse} from "axios";


export const lockAppBeforeResponse = () => {
    useGlobalAppLock.setState({isAppLocked: true})
}

export const unlockAppOnResponse = () => {
    useGlobalAppLock.setState({isAppLocked: false})
}


export const handleGlobalAppFail = (e: AxiosResponse) => {
    console.error(e)

    let stateToSet = {
        failError: e.status,
        failMessage: "",
        sFailed: true
    }

    if (e.status === 403) {
        stateToSet.failMessage = "Access denied"
    }

    useGlobalAppLock.setState(stateToSet)


}


