import axios from "axios";

export default class UploadStreamer {


    //chunkSize = Math.pow(2, 24)
    chunkSize = 1024 * 1024 * 4

    constructor() {

    }


    StartStreaming(file: File, onUpdate: Function, onFinish: Function) {

        axios.post(`/api/projects/prepare-file-stream?file_name=${file.webkitRelativePath}`).then(() => {
            this.upload(file, onUpdate).then((lastIndex: number) => {
                // onFinish()
                axios.post(`/api/projects/commit-file-chunk?file_name=${file.webkitRelativePath}&index=${lastIndex}`).then(() => {
                    onFinish()
                })
            })

        })


    }

    async upload(file: File, onUpdate) {
        let xIndex = 0;
        let offset = 0;

        console.log(file.size)

        while (offset < file.size) {
            const chunk = await this.readFileSlice(file, offset, offset + this.chunkSize);

            if (chunk) {
                await this.sendChunk(file.webkitRelativePath, chunk, xIndex);
                offset += chunk.byteLength;
                xIndex += 1

                onUpdate(offset + this.chunkSize)
            }

        }

        return xIndex
    }

    async readFileSlice(file, start, end) {
        return new Promise(resolve => {
            const reader = new FileReader();

            reader.onload = (event) => {
                // reader.onload = null
                if (event.target) {
                    resolve(event.target.result)
                }
            };
            reader.readAsArrayBuffer(file.slice(start, end));
        });
    }

    async sendChunk(fileName: string, chunk, offset) {
        const formData = new FormData();
        formData.append('file', new Blob([chunk]));

        const config = {

            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Offset': offset,
                'File-Name': fileName
            }
        };

        console.log(`Sending offset: ${offset}`)

        await axios.post(`/api/projects/upload-file-chunk`, formData, config).catch((e) => {
            // this.sendChunk(fileName, chunk, offset)
        });
    }
}